import { Box, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import ClientService from "../../../../services/client.service";
import RequesterModal from "../CreateRequesterModal";
import RequesterSummary from "./RequesterSummary";
import { useLocation } from "react-router-dom";

type Props = {
  editClient: any;
};

const tabs = [
  {
    index: 0,
    label: "Resumo",
  },
];

const RightSideBar: React.FC<Props> = ({ editClient }: Props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const { data, isLoading, error } = useQuery({
    queryKey: [`requesters-data`],
    queryFn: () =>
      ClientService.getRequesters(
        editClient?._id || searchParams?.get("customerId")
      ),
    refetchOnWindowFocus: false,
  });

  const [tabActive, setTabActive] = useState(0);
  const [showCreateRequesterModal, setShowCreateRequesterModal] =
    useState(false);

  function handleChangeTab(index: number) {
    setTabActive(index);
  }

  return (
    <Box
      width={"20%"}
      border={"1px solid #eee"}
      padding={2}
      boxShadow={2}
      height={"80vh"}
    >
      <RequesterModal
        isOpen={showCreateRequesterModal}
        editClient={editClient}
        onClose={function (): void {
          setShowCreateRequesterModal(false);
        }}
      />
      <Tabs
        value={tabActive}
        onChange={(e, index) => handleChangeTab(index)}
        indicatorColor="primary"
        sx={{ textTransform: "none", margin: 1 }}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.index}
            label={tab.label}
            sx={{ textTransform: "none" }}
          />
        ))}
      </Tabs>
      <span>Solicitantes</span>
      <hr style={{ width: "100%" }} />
      <Box
        overflow={"auto"}
        sx={{
          backgroundColor: "#F9FCFF",
          maxHeight: '70%'
        }}
      >
        {!!data && data.length > 0
          ? data.map((el, index) => {
              return (
                <RequesterSummary
                  requesterData={{
                    name: el?.name,
                    phone: el?.phone,
                    email: el?.email,
                    _id: el?._id,
                    client: el?.client,
                  }}
                />
              );
            })
          : ""}
      </Box>
      <Box
        sx={{ cursor: "pointer" }}
        p={2}
        onClick={() => {
          setShowCreateRequesterModal(true);
        }}
      >
        <span style={{ color: "#015EFF" }}>+ Novo Solicitante</span>
      </Box>
    </Box>
  );
};

export default RightSideBar;
