import { useContext, useEffect, useState } from "react";
import { IClientData, IExportProps } from "../../types/export";
import { styles } from "./styles";
import { PDFViewer, Document } from "@react-pdf/renderer";
import { ProjectContext } from "../../contexts/project";
import { ExportPage } from "../../components/ExportPage";
import { DetailsExportPage } from "../../components/DetailsExportPage";

export function Export(props: any) {
  const {
    customerRegister,
    briefing,
    mmCode,
    project,
    exportData,
    exportType,
  } = useContext(ProjectContext);
  const [clientData, setClientData] = useState<IClientData>({
    clientName: customerRegister.companyName
      ? customerRegister.companyName
      : "",
    eventName: briefing.eventName ? briefing.eventName : "",
    mmCode: mmCode,
    initialDate: project.initialDate ? project.initialDate : "",
    finalDate: project.finalDate ? project.finalDate : "",
    clientRequester: customerRegister.applicantName
      ? customerRegister.applicantName
      : "",
    attendance: customerRegister.attendance_1 ? customerRegister.attendance_1 : "",
    coordinator: customerRegister.coordinator_1
      ? customerRegister.coordinator_1
      : "",
    product: "Product",
    pfz: briefing.pfizerInputs ? briefing.pfizerInputs : "",
    mapp: briefing.mappInputs ? briefing.mappInputs : "",
  });
  const exportedBudgetsData = exportData.exported_budgets.sort(
    (a: any, b: any) => {
      if (a.saved_services.length < b.saved_services.length) return -1;
      else if (a.saved_services.length > b.saved_services.length) return 1;
      else return 0;
    }
  );

  return (
    <PDFViewer style={styles.viewer}>
      <Document title="Comparação de ssto">
        {exportedBudgetsData.map((el: any, index: any) => {
          if (index === 0)
            return (
              <ExportPage
                key={Math.random()}
                clientData={clientData}
                showHeader={true}
                exportedBudget={el}
                index={index}
              />
            );
          else
            return (
              <ExportPage
                key={Math.random()}
                clientData={clientData}
                exportedBudget={el}
                showHeader={false}
                index={index}
              />
            );
        })}
        {exportedBudgetsData.map((el: any, index: any) => {
          if (index === 0)
            return (
              <DetailsExportPage
                key={Math.random()}
                clientData={clientData}
                showHeader={true}
                exportedBudget={el}
                index={index}
              />
            );
          else
            return (
              <DetailsExportPage
                key={Math.random()}
                clientData={clientData}
                exportedBudget={el}
                showHeader={false}
                index={index}
              />
            );
        })}
      </Document>
    </PDFViewer>
  );
}
