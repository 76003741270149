import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  padding: 10px 0;
  cursor: pointer;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Status = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const Notification = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #a7d6ff;
  text-align: center;
  font-size: 0.9em;
`;
