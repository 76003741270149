import React, { useContext } from "react";
import { Container, UserInfo } from "./styles";
import { AuthContext } from "../../contexts/auth";
import StatusCount from "../../components/StatusCount";

export default function Dashboard() {
  return (
    <Container>
     <StatusCount/>
    </Container>
  );
} 
  