import styled from "styled-components";

export const Container = styled.div`
width: 100%;
height: 70%;
padding: 20px;
display: flex;
align-items: center;
justify-content: center;
flex-flow: column nowrap;
font-size: 18px;
color: #555555;
border: 2px #c3c3c3 dashed;
border-radius: 12px;
`
