import { Button } from "@mui/material";
import { useState } from "react";
import { GiCancel } from "react-icons/gi";

import CustomModal from "../CustomModal";

interface IAlertModalProps {
  handleClose(target: string): void;
  isOpen: boolean;
  makeDecision(flag: boolean): void;
  dontShowAgain?: boolean;
  mainText?: string;
}

export function AlertModal({
  makeDecision,
  isOpen,
  handleClose,
  dontShowAgain = true,
  mainText = "Certifique-se de salvar suas alterações antes de alterar de aba!",
}: IAlertModalProps) {
  const [hideAlert, setHideAlert] = useState(false);
  function _makeDecision(flag: boolean) {
    if (hideAlert === true) {
      localStorage.setItem("hideAlert", "0");
    }
    makeDecision(flag);
  }
  return (
    <CustomModal handleClose={handleClose} isOpen={isOpen} target="alertModal">
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <h4>Alerta!</h4>
        <div style={{ cursor: "pointer" }}>
          <GiCancel
            size={20}
            onClick={() => {
              handleClose("alertModal");
            }}
          />
        </div>
      </div>
      <div>{mainText}</div>
      <br />

      {dontShowAgain && (
        <div
          onClick={() => {
            setHideAlert(true);
          }}
        >
          <input
            type="checkbox"
            onChange={(e) => {
              setHideAlert(e.target.checked);
            }}
          />
          Não mostrar mais essa mensagem
        </div>
      )}
      <br />
      <Button
        style={{
          background: "#1361A4",
          color: "#FFFFFF",
          width: "150px",
          marginRight: "15px",
        }}
        onClick={() => {
          _makeDecision(true);
        }}
      >
        Continuar
      </Button>

      <Button
        style={{
          background: "#1361A4",
          color: "#FFFFFF",
          width: "150px",
        }}
        onClick={() => {
          _makeDecision(false);
        }}
      >
        Voltar
      </Button>
    </CustomModal>
  );
}
