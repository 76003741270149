import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  margin-right: 15px;
`;

export const OptionIconDiv = styled.div`
  padding: 10px;
  border-radius: 6px;
  background-color: #f1f1f1;
  margin-right: 5px;
`;

export const OptionDetailsDiv = styled.div`
  flex-direction: column;
  align-items: flex-start;
  strong {
    display: block;
    font-size: 0.9em;
    color: #4b465c;
  }
  span {
    color: #c9c9c9;
    font-size: 0.8em;
  }
`;
