import {AutocompleteInputChangeReason, Box, CircularProgress, createFilterOptions, TextField} from "@mui/material";
import React, {SyntheticEvent, useState} from "react";
import {useQuery} from "react-query";
import {LabeledAutoComplete} from "../../UI/LabeledAutoComplete";
import {GridSortDirection} from "@mui/x-data-grid";
import SupplierService from '../../../services/supplier.service'


const INITIAL_STATE = {
  page: 0,
  pageSize: 10,
  order: "asc" as GridSortDirection,
  sortField: "name",
  search: "",
  origin: ""
}

type PropTypes = {
  onSelectSupplier(value: string, field: string): void
  provider_id: string
  title: string
}

const SupplierAutoComplete: React.FC<PropTypes> = ({
  onSelectSupplier,
  provider_id,
  title
}: PropTypes) => {

  const { data: provider } = useQuery({
    queryKey: ['provider', provider_id],
    queryFn: () =>  SupplierService.getProviders({id: provider_id}),
  });
  const [supplierAutoCompleteSearch, setSupplierAutoCompleteSearch] = useState<any>(INITIAL_STATE);
  const { isLoading, data } = useQuery({
    queryKey: ['providers_search', supplierAutoCompleteSearch],
    queryFn: () => SupplierService.search(supplierAutoCompleteSearch),
  });

  
 

  const [inputSupplierValue, setInputSupplerValue] = useState(!!provider ? provider[0]?.name : '')
  const [supplierValue, setsupplerValue] = useState(null)
  function supplierChangeHandler(
    e: SyntheticEvent<Element, Event>,
    newValue: any | null
  ) {
    setsupplerValue(newValue);
    onSelectSupplier(newValue?._id || '', 'provider_id')
  }
  function supplierInputChangeHandler(
    e: SyntheticEvent<Element, Event>,
    newValue: string,
    reason: AutocompleteInputChangeReason
  ) {
    if (newValue === "" && reason === "reset") return;
    setInputSupplerValue(newValue);
  }


  const filterOptions = createFilterOptions({
    stringify: (option: any) => option.name + option.tax_id,
  });



  return <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  }}>
    <LabeledAutoComplete
      filterOptions={filterOptions}
      options={data?.providers || []}
      getOptionLabel={(option) => option.name || ""}
      size="small"
      inputValue={inputSupplierValue}
      value={supplierValue}
      onInputChange={supplierInputChangeHandler}
      onChange={supplierChangeHandler}
      label={`Fornecedor`}
      renderInput={(params) => (
        <TextField
          placeholder="Selecione um fornecedor"
          {...params}
          fullWidth
          style={{background: "#FFF"}}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.startAdornment}
              </>
            ),
          }}
        />
      )}
    />

  </Box>
}


export default SupplierAutoComplete