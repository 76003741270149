import {
  FormControl,
  FormLabel,
  RadioGroup as RadioGroupMaterial,
  RadioGroupProps,
} from "@mui/material";
import React, { forwardRef } from "react";

interface Props extends RadioGroupProps {
  label: string;
  error?: boolean;
}

const RadioGroupRoot = forwardRef(
  ({ label, children, error, ...props }: Props, ref) => {
    return (
      <FormControl>
        <FormLabel sx={{ fontSize: 13 }} error={error}>
          {label}
        </FormLabel>

        <RadioGroupMaterial ref={ref} {...props}>
          {children}
        </RadioGroupMaterial>
      </FormControl>
    );
  }
);

export default RadioGroupRoot;
