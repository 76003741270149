export enum RoleUser {
  USER_ADMINISTRATOR = "Administrador",
  USER_GUEST = "Usuário",
}

export const RoleUserMap: { [key: string]: string } = {
  USER_ADMINISTRATOR: "Administrador",
  USER_GUEST: "Usuário",
};

export interface Group {
  _id: string;
  name: string;
  description: string;
  leader_user_ids: string[];
  coordinators_user_ids: string[];
  createdAt: Date;
  updatedAt: Date;
}

export interface User {
  _id: string;
  name: string;
  email: string;
  role: RoleUser;
  createdAt: Date;
  updatedAt: Date;
}
