import { StyleSheet } from "@react-pdf/renderer";
export const styles = StyleSheet.create({
  // novo export
  headerDiv: {
    width: "49%",
    padding: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "14px",
  },
  backgroundBlack: {
    backgroundColor: "#000",
  },
  textDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 0",
  },

  backgroundGray: {
    backgroundColor: "#ddd",
  },
  whiteText: {
    color: "#FFF",
  },
  grayText: {
    color: "#555",
  },
  childHeaderDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  textSpan: {
    fontSize: 12,
  },
  textHeader: {
    fontSize: 16,
    fontWeight: "bold",
  },

  // fim novo export

  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    height: "100%",
    padding: "15px",
    fontSize: "14px",
  },

  exportHeader: {
    display: "flex",
    margin: "15px auto",
    width: "98%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  view: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    margin: "0 auto",
  },
  header2: {
    fontSize: 20,
  },
  viewer: {
    width: window.innerWidth - 36, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  clientDataDiv: {
    width: "60%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    fontSize: 16,
    padding: 5,
    margin: 15,
  },
  budgetDataDiv: {
    width: "13%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    border: "1px solid #bbb",
    justifyContent: "flex-start",
    padding: 5,
  },
  verticaltext: {
    transform: "rotate(-90deg)",
    position: "absolute",

    // -ms-transform: 'rotate(-90deg)',
    // -ms-transform-origin:r'ight, top',
    // -webkit-transform: 'rotate(-90deg)',
    // -webkit-transform-origin:r'ight, top',
  },
  childBudgetDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    margin: "8px 0",
  },
  mainDataDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    borderBottom: "1px solid #eee",
  },

  table: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#eee",
    padding: 4,
    width: "100%",
    justifyContent: "space-between",
    fontSize: 10,
  },
  header: {
    borderTop: "none",
    margin: "0 auto",
    fontSize: 18,
  },
  bold: {
    fontWeight: "bold",
  },
  row1: {
    width: "50%",
  },
  row2: {
    width: "16%",
    margin: "0 auto",
    alignItems: "center",
  },
  row3: {
    width: "6.25%",
    alignItems: "center",
  },
  row4: {
    width: "7%",
    alignItems: "center",
  },
  row5: {
    width: "27%",
  },
});
