import React from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";
import styled from "styled-components";

interface IItemProps {
    title: string;
    count: number;
    target: string;
    columnIndex: number;
    onClick(target: string): void;
}

const UpperPart = styled.div<{ color: string }>`
    flex: 8;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    background-color: ${({color}) => color};
    width: 100%;
    cursor: default;
`;

const LowerPart = styled.div<{ color: string }>`
    flex: 2;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: ${({ color }) => color};
    width: 100%;
    cursor: pointer;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
    padding-right: 8px;
`;

const Count = styled.h2`
    margin: 0;
    font-size: 26px;
    color: #f2f2f2;
`;

const Title = styled.h4`
    margin: 4px 0;
    font-size: 14px;
    align-items: end;
    color: #f2f2f2;
    text-align: right;
`;

const Details = styled.div`
    font-size: 12px;
    cursor: pointer;
    padding-left: 12px;
    color: #f2f2f2;
`;

export function Item(props: IItemProps) {
    const history = useHistory();

    const lowerColor = getLowerColor(props.columnIndex);

    function handleClick() {
        props.onClick(props.target);
    }

    function handleDetailsClick() {
        history.push("/detalhes");
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{
                width: "150px",
                height: "90px",
                mb: 2,
                borderRadius: "6px",
                overflow: "hidden",
            }}
            onClick={handleClick}
        >
            <UpperPart color={getUpperColor(props.columnIndex)}>
                <Content>
                    <Count>{props.count}</Count>
                    <Title>{props.title || "não deu"}</Title>
                </Content>
            </UpperPart>
            <LowerPart color={lowerColor} onClick={handleDetailsClick}>
                <Details>+ detalhes</Details>
            </LowerPart>
        </Box>
    );
}

function getUpperColor(columnIndex: number): string {
    const upperColors = ["#4281B6", "#BDC039", "#AD3536", "#509C37"];
    return upperColors[columnIndex % upperColors.length];
}

function getLowerColor(columnIndex: number): string {
    const lowerColors = ["#84ADCF", "#D4D67E", "#CA7C7C", "#8DBF7D"];
    return lowerColors[columnIndex % lowerColors.length];
}
