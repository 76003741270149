import React, { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import background from "../../assets/background.png";
import logoImg from "../../assets/logo_text.png";

import {
  TextField,
  Button,
  Box,
  Card,
  Typography,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import { HiOutlineLockClosed } from "react-icons/hi";
import UserService from "../../services/user.service";

export default function ConfirmNewUser() {
  const { params } = useRouteMatch();
  const { token } = params as { token: string };
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    password: "",
    confirmPassword: "",
  });

  function submitHandler() {
    if (!form.password) return toast.error("Insira uma senha válida");
    if (!form.confirmPassword)
      return toast.error("Insira uma confirmação de senha válida");

    if (form.password !== form.confirmPassword)
      return toast.error("As senhas não coincidem");

    if (token) {
      setLoading(true);
      UserService.confirmUser(token, form.password)
        .then(() => {
          toast.success("Senha atualizada com sucesso!");
          history.push("/login");
        })
        .catch((error) => {
          toast.error("Erro ao atualizar senha");
        })
        .finally(() => setLoading(false));
    }
  }

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      width={"100vw"}
      height={"100vh"}
      sx={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          p: 3,
          pt: 4,
          width: 408,
          borderRadius: "16px",
        }}
      >
        <Box display={"flex"} justifyContent={"center"}>
          <img src={logoImg} alt="" />
        </Box>

        <Typography variant={"h4"}>Criar uma senha</Typography>
        <Typography variant={"body1"} color={"#475467"}>
          A senha deve conter no mínimo 8 caracteres.
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box ml={"5px"} mr={"12px"}>
            <HiOutlineLockClosed size={"24px"} color="#A0A3A6" />
          </Box>

          <TextField
            label={"Senha"}
            size="small"
            onChange={(e: any) =>
              setForm({ ...form, password: e.target.value })
            }
            value={form.password}
            variant="outlined"
            fullWidth
            type="password"
          />
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box ml={"5px"} mr={"12px"}>
            <HiOutlineLockClosed size={"24px"} color="#A0A3A6" />
          </Box>

          <TextField
            label={"Redigite a senha"}
            size="small"
            onChange={(e: any) =>
              setForm({ ...form, confirmPassword: e.target.value })
            }
            value={form.confirmPassword}
            variant="outlined"
            fullWidth
            type="password"
          />
        </Box>

        <Box display={"flex"} flexDirection={"column"} gap={2}>
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={submitHandler}
          >
            {!loading ? "Redefinir senha" : <CircularProgress />}
          </Button>
        </Box>
      </Card>
    </Box>
  );
}
