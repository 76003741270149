import axios from "axios";
const api = axios.create({
  baseURL: process.env.REACT_APP_HOST
  ? process.env.REACT_APP_HOST
  : "http://localhost:3002",

});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  // eslint-disable-next-line func-names
  async function (error) {
    if (error.response) {
      if (
        error.response.status === 401 &&
        window.location.pathname !== "/login"
      ) {
        localStorage.removeItem("@mmeventos:access_token");
        localStorage.removeItem("@mmeventos:renew_token");
        localStorage.removeItem("@mmeventos:user");

        window.location.href = "/login";
      }
    } else if (error?.response?.data?.errors) {
      // eslint-disable-next-line no-alert
      alert(`Ops ocorreu um erro: ${error?.response?.data?.errors}`);
    } else {
      // eslint-disable-next-line no-alert
      alert(`Ops ocorreu um erro: ${error?.message}`);
    }
    return Promise.reject(error);
  }
);

export { api };
