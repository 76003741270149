import { Box } from "@mui/material";
import { Container, OptionDetailsDiv, OptionIconDiv } from "./styles";

interface Props {
  title: string;
  icon: any;
  lastUpdate?: string;
  isActive: boolean;

  onClick?: () => void;
}

export function OptionItem(props: Props) {
  const { onClick, isActive } = props;

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      px={"10px"}
      gap={"10px"}
      sx={{ cursor: "pointer" }}
      onClick={onClick}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        width={40}
        height={40}
        bgcolor={!isActive ? "#f1f1f1" : "#1361A4"}
        borderRadius={"6px"}
        sx={{
          "& svg": {
            color: !isActive ? "#000" : "#fff",
          },
        }}
      >
        {props.icon}
        {/* <img src={props.icon} alt="" /> */}
      </Box>

      <OptionDetailsDiv>
        <strong>{props.title}</strong>
        <span>{props.lastUpdate}</span>
      </OptionDetailsDiv>
    </Box>
  );
}
