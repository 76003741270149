export const ACCOMMODATIONS = [
    { text: 'Apto. single', value: 'APT_SINGLE' },
    { text: 'Apto. duplo', value: 'DOUBLE_APT' },
    { text: 'Apto. triplo', value: 'TRIPLE_APT' },
    { text: 'Early check-in - apto. duplo', value: 'EARLY_CHECK_IN_DOUBLE_APT' },
    { text: 'Early check-in - apto. single', value: 'EARLY_CHECK_IN_APT_SINGLE' },
    { text: 'Early check-in - apto. triplo', value: 'EARLY_CHECK_IN_TRIPLE_APT' },
    { text: 'Late ckeck-out - apto. duplo', value: 'LATE_CHECK_OUT_DOUBLE_APT' },
    { text: 'Late ckeck-out - apto. single', value: 'LATE_CHECK_OUT_APTO_SINGLE' },
    { text: 'Late ckeck-out - apto. triplo', value: 'LATE_CHECK_OUT_TRIPLE_AP' },
    { text: 'Frigobar', value: 'FRIDGE' },
    { text: 'Taxa alteração de frigobar', value: 'FRIDGE_CHANGE_FEE' },
    { text: 'Internet', value: 'INTERNET_IN_THE_APARTMENTS' },
    { text: 'Maleiro', value: 'SUITCASE' },
    { text: 'Outros', value: 'OTHERS' },
];

export const ROOMS = [
    { text: 'Espaço para coquetel', value: 'COCKTAIL_SPACE' },
    { text: 'Espaço para festa de encerramento', value: 'CLOSING_PARTY_SPACE' },
    { text: 'Espaço privativo para almoço', value: 'PRIVATE_LUNCH_SPACE' },
    { text: 'Espaço privativo para jantar', value: 'PRIVATE_DINNER_SPACE' },
    { text: 'Taxa para alteração de lay out', value: 'LAYOUT_CHANGE_FEE' },
    { text: 'Sala de Reunião', value: 'MEETING_ROOM' },
];

export const AEB = [
    { text: 'Água em sala', value: 'WATER_IN_ROOM' },
    { text: 'Alimentos e bebidas camarim', value: 'DRESSING_ROOM_AEB' },
    { text: 'Almoços ', value: 'LUNCH' },
    { text: 'Bebidas', value: 'DRINKS' },
    { text: 'Coffee Break ', value: 'COFFEE_BREAK' },
    { text: 'Coquetel', value: 'COCKTAIL' },
    { text: 'Happy Hour', value: 'HAPPY_HOUR' },
    { text: 'Welcome drink', value: 'WELCOME_DRINK' },
    { text: 'Welcome coffee', value: 'WELCOME_COFFEE' },
    { text: 'Lanche box', value: 'SNACK_BOX' },
    { text: 'Jantares ', value: 'DINNER' },
    { text: 'Taxa de transferência', value: 'TRANSFER_TAX' },
    { text: 'Térmica de café', value: 'COFFEE_IN_ROOM' },
    { text: 'Térmica de chá', value: 'TEA_IN_ROOM' },

   
];

export const EQUIPMENTS = [
    { text: 'Caixa amplificada', value: 'AMPLIFIED_BOX' },
    { text: 'Cartucho para impressora', value: 'PRINTER_REFIL' },
    { text: 'Chaveador eletrônico', value: 'ELETRONIC_SWITCH' },
    { text: 'Flip chart', value: 'FLIP_CHART' },
    { text: 'Full lighting for plenary room', value: 'FULL_LIGHTING_PLENARY_ROOM' },
    { text: 'Gooseneck microphone', value: 'GOOSENECK_MIC' },
    {
        text: 'Grid in box truss Q25 to structure lighting and projection',
        value: 'BOX_GRID_FOR_PROJECTION_LIGHTING_STRUCTURING'
    },
    { text: 'Iluminação', value: 'LIGHTING' },
    { text: 'Iluminação básica para sala plenária', value: 'BASIC_LIGHTING_PLENARY_ROOM' },
    { text: 'Iluminação completa para sala plenária', value: 'FULL_LIGHTING_PLENARY_ROOM' },
    { text: 'Iluminação para ambientação de sala', value: 'ROOM_AMBIENCE_LIGHTING' },
    { text: 'Iluminação para balada / dj', value: 'NIGHTCLUB_LIGHTING' },
    { text: 'Iluminação para palco', value: 'STAGE_LIGHTING' },
    { text: 'Impressora laser colorida', value: 'LASER_PRINTER_COLOR' },
    { text: 'Impressora laser preta e branco', value: 'LASER_PRINTER_B_AND_W' },
    { text: 'Inch screen', value: 'INCHES_SCREEN' },
    { text: 'Inch screen with lycra-wrapped box truss', value: '100_INCHES_SCREEN_WITH_LYCRA_WRAPPED_BOX' },
    { text: 'Inch screen with lycra-wrapped box truss', value: '120_INCHES_SCREEN_WITH_LYCRA_WRAPPED_BOX' },
    { text: 'Inch screen with lycra-wrapped box truss', value: '150_INCHES_SCREEN_WITH_LYCRA_WRAPPED_BOX' },
    { text: 'Inch screen with lycra-wrapped box truss', value: '180_INCHES_SCREEN_WITH_LYCRA_WRAPPED_BOX' },
    { text: 'Inch screen with lycra-wrapped box truss', value: '200_INCHES_SCREEN_WITH_LYCRA_WRAPPED_BOX' },
    { text: 'Inch screen with lycra-wrapped box truss', value: '250_INCHES_SCREEN_WITH_LYCRA_WRAPPED_BOX' },
    { text: 'Kramer electronic switch', value: 'KRAMER_SWITCHER' },
    { text: 'Kramer video matrix', value: 'KRAMER_VIDEO_MATRIX' },
    { text: 'Lapel microphone', value: 'LAPEL_MIC' },
    { text: 'LCD TV 32" (pedestal)', value: '32_INCHES_TV_PEDESTAL' },
    { text: 'LCD TV 32" (stage return)', value: '32_INCHES_TV_STAGE_RETURN' },
    { text: 'LCD TV 42" (stage return)', value: '42_INCHES_TV_STAGE_RETURN' },
    { text: 'LCD TV 50" (pedestal)', value: '50_INCHES_TV_PEDESTAL' },
    { text: 'LCD TV 50" (stage return)', value: '50_INCHES_TV_STAGE_RETURN' },
    { text: 'Lighting for stage', value: 'STAGE_LIGHTING' },
    { text: 'Microfone para púlpito', value: 'PULPIT_MIC' },
    { text: 'Microfone sem fio', value: 'WIRELLESS_MIC' },
    { text: 'Mouse sem fio com laser pointer', value: 'WIRELESS_MOUSE_WITH_POINTER' },
    { text: 'Nightclub lighting', value: 'NIGHTCLUB_LIGHTING' },
    { text: 'Notebook', value: 'NOTEBOOK' },
    { text: 'Operator fee', value: 'OPERATOR_FEE' },
    { text: 'Operator technician (assembly/disassembly)', value: 'TECHNINAL_OPERATOR_ASSEMBLY_DISASSEMBLY' },
    { text: 'Operator technician', value: 'TECHNINAL_OPERATOR' },
    {
        text: 'Painel em box truss Q15 envelopado com lycra preta para sustentação da tela',
        value: 'BOX_PANEL_FOR_LIGHTING_SUPPORT'
    },
    { text: 'Power ruler', value: 'POWER_RULER' },
    { text: 'Praticável', value: 'PRACTICABLE' },
    { text: 'Projector', value: 'PROJECTOR' },
    { text: 'Projector 3000 ansi lumens', value: 'PROJECTOR_3000_ANSI_LUMENS' },
    { text: 'Projector 4500 ansi lumens', value: 'PROJECTOR_4500_ANSI_LUMENS' },
    { text: 'Projector 5000 ansi lumens', value: 'PROJECTOR_5000_ANSI_LUMENS' },
    { text: 'Projector 5500 ansi lumens', value: 'PROJECTOR_5500_ANSI_LUMENS' },
    { text: 'Projector 6500 ansi lumens', value: 'PROJECTOR_6500_ANSI_LUMENS' },
    { text: 'Radio communicator', value: 'RADIO_COMMUNICATOR' },
    { text: 'Refil para impressora', value: 'PRINTER_REFIL' },
    { text: 'Régua de energia', value: 'POWER_RULER' },
    { text: 'Room ambience lighting', value: 'ROOM_AMBIENCE_LIGHTING' },
    { text: 'Screen', value: 'SCREEN' },
    { text: 'Screen 32"', value: '32_INCHES_SCREEN' },
    { text: 'Screen 42"', value: '42_INCHES_SCREEN' },
    { text: 'Screen 50"', value: '50_INCHES_SCREEN' },
    { text: 'Screen 65"', value: '65_INCHES_SCREEN' },
    { text: 'Screen 75"', value: '75_INCHES_SCREEN' },
    { text: 'Screen 85"', value: '85_INCHES_SCREEN' },
    { text: 'Screen 100"', value: '100_INCHES_SCREEN' },
    { text: 'Screen 120"', value: '120_INCHES_SCREEN' },
    { text: 'Screen 150"', value: '150_INCHES_SCREEN' },
    { text: 'Screen 180"', value: '180_INCHES_SCREEN' },
    { text: 'Screen 200"', value: '200_INCHES_SCREEN' },
    { text: 'Screen 250"', value: '250_INCHES_SCREEN' },
    { text: 'Screen with lycra-wrapped box truss', value: 'SCREEN_WITH_LYCRA_WRAPPED_BOX' },
    { text: 'Switcher kramer', value: 'KRAMER_SWITCHER' },
    { text: 'T-shirt', value: 'T_SHIRT' },
    { text: 'Tap house', value: 'TAP_HOUSE' },
    { text: 'Taxa de transporte', value: 'TRANSPORT_FEE' },
    { text: 'TV', value: 'TV' },
    { text: 'TV LCD 32" (pedestal)', value: '32_INCHES_TV_PEDESTAL' },
    { text: 'TV LCD 32" (stage return)', value: '32_INCHES_TV_STAGE_RETURN' },
    { text: 'TV LCD 42" (stage return)', value: '42_INCHES_TV_STAGE_RETURN' },
    { text: 'TV LCD 50" (pedestal)', value: '50_INCHES_TV_PEDESTAL' },
    { text: 'TV LCD 50" (stage return)', value: '50_INCHES_TV_STAGE_RETURN' },
    { text: 'Voucher creation - Backdrop', value: 'CREATION_BACKDROP' },
    { text: 'Voucher creation - Balcony', value: 'CREATION_BALCONY' },
    { text: 'Voucher creation - Digital or printed', value: 'CREATION_DIGITAL_PRINTED_VOUCHER' },
    { text: 'Voucher creation - Digital or printed certificate', value: 'CREATION_DIGITAL_PRINTED_CERTIFICATE' },
    { text: 'Voucher creation - Emkt', value: 'CREATION_EMKT' },
    { text: 'Voucher creation - Envelope', value: 'CREATION_ENVELOPE' },
    { text: 'Voucher creation - Static and animated vinheta', value: 'CREATION_ANIMATED_JINGLE' },
    { text: 'Voucher creation - Stickers', value: 'CREATION_STICKERS' },
    { text: 'Voucher creation - T-shirt', value: 'CREATION_SHIRT' },
    { text: 'Wired microphone', value: 'WIRED_MIC' },
    { text: 'Wireless microphone', value: 'WIRELESS_MIC' },
    { text: 'Wireless mouse with laser pointer', value: 'WIRELESS_MOUSE_WITH_POINTER' },
];
export const COMMUNICATIONS = [
    { text: 'Criação - Adaptação de formato de fotos (não é tratamento)', value: 'CREATION_PHOTO_ADAPTATION' },
    { text: 'Criação - Adesivos', value: 'CREATION_STICKERS' },
    { text: 'Criação - Apresentação (PPT)', value: 'CREATION_PPT_PRESENTATUIB' },
    { text: 'Criação - Area instagramavel', value: 'CREATION_INSTAGRAM_AREA' },
    { text: 'Criação - Backdrop', value: 'CREATION_BACKDROP' },
    { text: 'Criação - Balcony', value: 'CREATION_BALCONY' },
    { text: 'Criação - Banner impresso e digital', value: 'CREATION_DIGITAL_PRINTED_BANNER' },
    { text: 'Criação - BG\'s', value: 'CREATION_BGS' },
    { text: 'Criação - Brindes', value: 'CREATION_GIFTS' },
    { text: 'Criação - Cardapio', value: 'CREATION_MENU' },
    { text: 'Criação - Certificado impresso ou digital', value: 'CREATION_DIGITAL_PRINTED_CERTIFICATE' },
    { text: 'Criação - Convites impressos', value: 'CREATION_PRINTED_INVITATION' },
    { text: 'Criação - Cordão de crachá', value: 'CREATION_BADGE_STRING' },
    { text: 'Criação - Crachá', value: 'CREATION_BADGE' },
    { text: 'Criação - GC\'S', value: 'CREATION_GCS' },
    { text: 'Criação - Hotsite/LandingPage', value: 'CREATION_LANDING_PAGE' },
    { text: 'Criação - Kv', value: 'CREATION_KV' },
    { text: 'Criação - Kv Animado', value: 'CREATION_ANIMATED_KV' },
    { text: 'Criação - LandingPage', value: 'CREATION_LANDING_PAGE' },
    { text: 'Criação - Máscaras', value: 'CREATION_MASKS' },
    { text: 'Criação - Materias gráficos (ficha de palco, carta, folder, flyer)', value: 'CREATION_GRAPHIC_MATERIAL' },
    { text: 'Criação - Molduras', value: 'CREATION_FRAMES' },
    { text: 'Criação - Outros', value: 'CREATION_OTHERS' },
    { text: 'Criação - Peças cenografia', value: 'CREATION_SCENOGRAPHY_PLAY' },
    { text: 'Criação - Placas', value: 'CREATION_PLATE' },
    { text: 'Criação - Plataforma/Hotsite/LandingPage', value: 'CREATION_LANDING_PAGE' },
    { text: 'Criação - Post', value: 'CREATION_POST' },
    { text: 'Criação - Prisma mesa', value: 'CREATION_PRISM' },
    { text: 'Criação - Pulseira', value: 'CREATION_BRACELET' },
    { text: 'Criação - Tag', value: 'CREATION_TAG' },
    { text: 'Criação - Template', value: 'CREATION_TEMPLATE' },
    { text: 'Criação - Testeiras', value: 'CREATION_FOREHEADS' },
    { text: 'Criação - Totem impresso e digital', value: 'CREATION_DIGITAL_PRINTED_TOTEM' },
    { text: 'Criação - Troféu', value: 'CREATION_TROPHY' },
    { text: 'Criação - Voucher impresso ou digital', value: 'CREATION_DIGITAL_PRINTED_VOUCHER' },
    { text: 'Criação - Vinheta estatica e animada', value: 'CREATION_ANIMATED_JINGLE' },
];

export const TRANSLATIONS = [
    { text: 'Equipamentos', value: 'EQUIPMENTS' },
    { text: 'Intérpretes', value: 'TRANSLATORS' },
];

export const SUPPORT = [
    { text: 'Administrador(a)', value: 'ADMIN' },
    { text: 'Coordenador(a)', value: 'COORDINATOR' },
    { text: 'Despesa de alimentação', value: 'FOOD_EXPENSE' },
    { text: 'Despesa de transporte', value: 'TRANSPORT_EXPENSE' },
    { text: 'Diretor Técnico', value: 'TECHNICAL_DIRECTOR' },
    { text: 'Diretor Artístico', value: 'ARTISTIC_DIRECTOR' },
    { text: 'Produtor', value: 'PRODUCER' },
    { text: 'Recepcionista', value: 'RECEPTIONIST' },
    { text: 'Roteirista', value: 'SCREENWRITER' },
];

export const SUBSCRIPTION = [

    { text: 'Inscrição médico sócio', value: 'MEMBER_MEDICAL_REGISTRATION' },
    {
        text: 'Inscrição médico não sócio',
        value: 'NON_MEMBER_MEDICAL_REGISTRATION',
    },
    { text: 'Inscrição residente', value: 'RESIDENT_ENROLLMENT' },
    { text: 'Taxa de inscrição', value: 'REGISTRATION_FEE' },
];

export const RSVP = [
    { text: 'RSVP', value: 'RSVP' },
    { text: 'RSVP emergencial', value: 'RSVP_EMERGENCIAL' },
];

export const AIR = [
    { text: 'Previsão por pessoa', value: 'AERIAL_FORECAST_PER_PERSON' },
    { text: 'Seguir planilha aérea', value: 'AERIAL_WORKSHEET' },
    { text: 'Seguro viagem', value: 'TRAVEL_INSURANCE' },
    { text: 'Taxa de emissão', value: 'ISSUANCE_FEE' },
];

export const TRANSFER = [
    { text: 'Aeroporto / hotel / aeroporto - previsão', value: '' },
    { text: 'City tour', value: 'CITY_TOUR' },
    { text: 'Coordenação aeroporto', value: 'AIRPORT_COORDINATION' },
    { text: 'Hotel / restaurante / hotel', value: 'HOTEL_RESTAURANT_HOTEL' },
    { text: 'Transfer extra', value: 'TRANFER_EXTRA' },
    { text: 'Transfer in', value: 'TRANFER_IN' },
    { text: 'Transfer out', value: 'TRANFER_OUT' },
    {
        text: 'Transfer residência / aeroporto / residência',
        value: 'RESIDENCE_AIRPORT_RESIDENCE',
    },
    {
        text: 'Transfer residência / hotel / residência',
        value: 'RESIDENCE_HOTEL_RESIDENCE',
    },
];

export const SEVERAL = [
    {
        text: 'Decoração para festa de encerramento',
        value: 'CLOSING_PARTY_DECORATION',
    },
    { text: 'DJ para o festa de encerramento', value: 'CLOSING_PARTY_DJ' },
    { text: 'Ecad para banda/dj', value: '' },
    { text: 'Estacionamento', value: 'PARKING' },
    { text: 'Fotógrafo', value: 'PHOTOGRAPHER' },
    {
        text: 'Impressão de materiais gráficos',
        value: 'GRAPHIC_MATERIAL_PRINTING',
    },
    { text: 'Limpeza', value: 'CLEANING' },
    { text: 'Link dedicado', value: 'DEDICATED_LINK' },
    { text: 'Lunch box', value: 'LUNCH_BOX' },
    { text: 'Manobrista', value: 'WALLET' },
    { text: 'Mobiliário', value: 'FURNITURE' },
    { text: 'Pesquisa de satisfação', value: 'SATISFACTION_SURVEY' },
    { text: 'Ponto de internet', value: 'ACCESS_POINT' },
    { text: 'Ramal telefônico', value: 'TELEPHONE_EXTENSION' },
    { text: 'Segurança', value: 'SECURITY' },
    { text: 'Seguro Viagem', value: 'TRAVEL_INSURANCE' },
    { text: 'Stand', value: 'STAND' },
    { text: 'Telefônia', value: 'TELEPHONY' },
];
interface StringMap { [key: string]: any; }
export const  ServiceOptions: StringMap  = {
    ACCOMMODATIONS: ACCOMMODATIONS,
    ROOMS: ROOMS,
    AEB: AEB,
    EQUIPMENTS: EQUIPMENTS,
    COMMUNICATIONS: COMMUNICATIONS,
    TRANSLATIONS: TRANSLATIONS,
    SUPPORT: SUPPORT,
    SUBSCRIPTION: SUBSCRIPTION,
    RSVP: RSVP,
    AIR: AIR,
    TRANSFER: TRANSFER,
    SEVERAL: SEVERAL,
}