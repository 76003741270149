import { api } from "./apiClient";

export default class ReportService {
  static async create(data: any): Promise<any> {
    return api.post(`/report`, data).then((response) => response.data);
  }

  static async getAll(): Promise<[]> {
    return api.get("/report").then((response) => response.data);
  }

  static async download(idReport: string): Promise<any> {
    return api
      .get(`/report/download`, { params: { id: idReport } })
      .then((response) => response.data);
  }

  static async delete(idReport: string): Promise<any> {
    return api.delete(`/report/${idReport}`).then((response) => response.data);
  }
}
