import { Autocomplete, Box, Button } from "@mui/material";
import { error } from "console";
import React, { useEffect, useRef, useState } from "react";
import ReactInputMask from "react-input-mask";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";

import { useProject } from "../../../contexts/project.context";
import RequestingService from "../../../services/requesting.service";
import { ProjectRequester } from "../../../types";
import { Input } from "../../Input";

interface ProjectRequesterProps {
  requester: any;
  client?: string;
  onUpdateRequestings?: (newRequesting: ProjectRequester) => void;
}

const RequesterInfo = ({
  requester,
  client,
  onUpdateRequestings,
}: ProjectRequesterProps) => {
  const { showNotification, updateRequester } = useProject();
  const {
    isLoading,
    error,
    data: requestersData,
  } = useQuery({
    queryKey: [`requesters`, client],
    queryFn: () => RequestingService.getBySearch({ client }),
    enabled: !!client,
  });

  const [requesterData, setRequesterData] = useState({
    name: requester.name || "",
    email: requester.email || "",
    phone: requester.phone || "",
  } as ProjectRequester);

  const originalClient = useRef("");

  useEffect(() => {
    if (client) {
      if (!originalClient?.current) {
        originalClient.current = client;
        setRequesterData({
          name: requester.name || "",
          email: requester.email || "",
          phone: requester.phone || "",
        });
      } else if (originalClient.current !== client) {
        setRequesterData({
          name: "",
          email: "",
          phone: "",
        });
      }
    } else {
      setRequesterData({
        name: "",
        email: "",
        phone: "",
      });
    }
  }, [client]);

  useEffect(() => {
    setRequesterData(requester);
  }, [requester]);

  return (
    <Box mt={1}>
      <Autocomplete
        options={requestersData || []}
        getOptionLabel={(option) => {
          if (option.name !== "") return option.name;
          if (option.name === "" && !!option._id)
            return "Solicitante sem nome. Atualize na área de clientes";
          return "";
        }}
        value={requesterData}
        isOptionEqualToValue={(option, value) => option._id === value?._id}
        onChange={(_, requester) => {
          if (!requester) {
            const emptyRequester = {
              name: "",
              email: "",
              phone: "",
            };
            setRequesterData(emptyRequester);
            updateRequester(emptyRequester);
            onUpdateRequestings && onUpdateRequestings(emptyRequester);
          } else {
            setRequesterData(requester);
            updateRequester(requester);
            onUpdateRequestings && onUpdateRequestings(requester);
          }
        }}
        loading={isLoading}
        loadingText={"Carregando..."}
        renderInput={(params) => <Input label="Solicitante *" {...params} />}
      />

      <Box display={"flex"} gap={2} mt={1}>
        <ReactInputMask
          // @ts-ignore
          maskPlaceholder={null}
          disabled
          mask="(99) 99999-9999"
          value={requesterData.phone}
          name="phone"
        >
          <Input
            name="phone"
            disabled
            value={requesterData.phone}
            label="Telefone"
            placeholder="(00) 0 0000 0000"
          />
        </ReactInputMask>

        <Input
          name="email"
          disabled
          value={requesterData.email}
          label="Email"
          placeholder="email@dominio.com"
        />
      </Box>
    </Box>
  );
};

export default RequesterInfo;
