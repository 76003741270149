import { yupResolver } from "@hookform/resolvers/yup";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FaCaretDown } from "react-icons/fa";
import { useMutation } from "react-query";
import { useLocation } from "react-router-dom";
import * as yup from "yup";

import { useProject } from "../../../../../contexts/project.context";
import ProjectSummaryService from "../../../../../services/projectSummary.service";
import { BudgetStatus } from "../../../../../types/budget/budget";
import { NotificationFields } from "../../../../../types/notification";
import {
  formatCurrency,
  formatProfitability,
} from "../../../../../utils/formatters";
import { AlertModal } from "../../../../AlertModal";
import CurrencyInputForm from "../../../../CurrencyInputForm";
import { Input } from "../../../../Input";
import Notification from "../../../../UI/Notification";
import SummarySection from "../../SummarySection";

const validationSchema = yup.object({
  totalValueOfEvents: yup.number(),
  directPaymentOfClients: yup.number(),
  observations: yup.string(),
  logisticFee: yup.number(),
  logisticMarkup: yup.number(),
  logisticComission: yup.number(),
  airFee: yup.number(),
  airMarkup: yup.number(),
  airComission: yup.number(),
  RSVP: yup.number(),
  communicationFee: yup.number(),
  taxes: yup.number(),
  totalFees: yup.number(),
  totalMarkupAndOverhead: yup.number(),
  profitability: yup.string(),
  totalRevenue: yup.number(),
  invoicedMM: yup.string(),
  logisticTotal: yup.number(),
  airTotal: yup.number(),
  comissionTotal: yup.number(),
  supplierCostTotal: yup.string(),
});

const SUMMARY_STATUS = BudgetStatus.ON_APPROVAL;

const OnApprovalAccordion = () => {
  const { project, updateSummaryContext } = useProject();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get("projectId") || "";

  const [notification, setNotification] = useState<NotificationFields>({
    open: false,
    severity: "success",
    message: "",
  });
  const [hasOpened, setHasOpened] = useState(false);
  const [summaryData, setSummaryData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [alertModal, setAlertModal] = useState(false);
  const [tempForm, setTempForm] = useState({});

  const canEdit =
    project?.status?.status === "BUDGET" ||
    project?.status?.status === "ON_APROVAL";

  const methods = useForm({
    defaultValues: {
      totalValueOfEvents: 0,
      directPaymentOfClients: 0,
      observations: "",
      logisticFee: 0,
      logisticMarkup: 0,
      logisticComission: 0,
      airFee: 0,
      airMarkup: 0,
      airComission: 0,
      RSVP: 0,
      communicationFee: 0,
      taxes: 0,
      totalFees: 0,
      totalMarkupAndOverhead: 0,
      profitability: "",
      totalRevenue: 0,
      invoicedMM: "",
      logisticTotal: 0,
      airTotal: 0,
      comissionTotal: 0,
      supplierCostTotal: "",
    },
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const {
    setValue,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const getSummary = async () => {
    reset();
    setLoading(true);
    const data = await ProjectSummaryService.get({
      project: projectId,
      status: SUMMARY_STATUS,
    });
    if (!data?.length) {
      await Promise.all([
        ProjectSummaryService.create({
          project: projectId,
          status: BudgetStatus.ON_APPROVAL,
        }),
        ProjectSummaryService.create({
          project: projectId,
          status: BudgetStatus.APPROVED,
        }),
        ProjectSummaryService.create({
          project: projectId,
          status: BudgetStatus.CLOSURE,
        }),
        ProjectSummaryService.create({
          project: projectId,
          status: BudgetStatus.AUDITED,
        }),
      ]);
      getSummary();
    } else {
      setSummaryData(data?.[0]);
      updateSummaryContext("ON_APPROVAL", data?.[0]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (hasOpened) getSummary();
  }, [hasOpened]);

  useEffect(() => {
    setValue("totalValueOfEvents", summaryData?.totalValueOfEvents || 0);
    setValue(
      "directPaymentOfClients",
      summaryData?.directPaymentOfClients || 0
    );
    setValue("logisticFee", summaryData?.logisticFee || 0);
    setValue("logisticMarkup", summaryData?.logisticMarkup || 0);
    setValue("logisticComission", summaryData?.logisticComission || 0);
    setValue("airFee", summaryData?.airFee || 0);
    setValue("airMarkup", summaryData?.airMarkup || 0);
    setValue("airComission", summaryData?.airComission || 0);
    setValue("RSVP", summaryData?.RSVP || 0);
    setValue("communicationFee", summaryData?.communicationFee || 0);
    setValue("taxes", summaryData?.taxes || 0);
    setValue("observations", summaryData?.observations || "");
    setValue("totalFees", summaryData?.totalFees || 0);
    setValue(
      "totalMarkupAndOverhead",
      summaryData?.totalMarkupAndOverhead || 0
    );
    setValue("profitability", formatProfitability(summaryData?.profitability));
    setValue("totalRevenue", summaryData?.totalRevenue || 0);
    setValue("invoicedMM", formatCurrency(summaryData?.invoicedMM));

    setValue(
      "logisticTotal",
      summaryData?.logisticFee +
        summaryData?.logisticMarkup +
        summaryData?.logisticComission || 0
    );
    setValue(
      "airTotal",
      summaryData?.airFee +
        summaryData?.airMarkup +
        summaryData?.airComission || 0
    );
    setValue(
      "comissionTotal",
      summaryData?.logisticComission + summaryData?.airComission || 0
    );

    setValue(
      "supplierCostTotal",
      formatCurrency(
        summaryData?.invoicedMM -
          summaryData?.totalFees -
          summaryData?.totalMarkupAndOverhead
      )
    );
  }, [summaryData]);

  function closeNotification() {
    setNotification({ ...notification, open: false });
  }

  const { mutate: updateSummary, isLoading: isLoadingUpdate } = useMutation(
    (state: any) => ProjectSummaryService.update(state.id, state),
    {
      onSuccess: () => {
        setNotification({
          open: true,
          severity: "success",
          message: "Status salvo com sucesso!",
        });
        getSummary();
      },
      onError: (error) => {
        setNotification({
          open: true,
          severity: "error",
          message: "Não foi possível salvar",
        });
      },
    }
  );

  function checkEmptyValues(obj: any) {
    const blackList = [
      "comissionTotal",
      "invoicedMM",
      "profitability",
      "supplierCostTotal",
      "totalFees",
      "totalMarkupAndOverhead",
      "totalRevenue",
      "logisticTotal",
      "airTotal",
    ];

    for (const key in obj) {
      if (
        obj.hasOwnProperty(key) &&
        !blackList.includes(key) &&
        obj[key] === 0
      ) {
        return true;
      }
    }
    return false;
  }

  const onSubmit = async (form: any) => {
    if (checkEmptyValues(form)) {
      setAlertModal(true);
      setTempForm(form);
    } else {
      updateSummary({
        id: summaryData?._id,
        project: projectId,
        status: SUMMARY_STATUS,
        ...form,
      });
    }
  };

  const makeDecision = (flag: boolean) => {
    if (flag) {
      updateSummary({
        id: summaryData?._id,
        project: projectId,
        status: SUMMARY_STATUS,
        ...tempForm,
      });
    }
    setAlertModal(false);
    setTempForm({});
  };

  return (
    <>
      <AlertModal
        handleClose={() => setAlertModal(false)}
        isOpen={alertModal}
        makeDecision={makeDecision}
        dontShowAgain={false}
        mainText="Existem valores preenchidos como zero no formulário. Deseja prosseguir ?"
      />
      <Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary
          aria-controls="panel1a-content"
          expandIcon={<FaCaretDown color="white" />}
          id="panel1a-header"
          style={{
            maxHeight: "40px",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#D09C17",
          }}
          onClick={() => setHasOpened(true)}
        >
          <Notification
            notification={notification}
            closeNotification={closeNotification}
          />
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ color: "white" }}>Em aprovação</span>
            {isLoadingUpdate ? (
              <CircularProgress
                style={{ color: "white", marginRight: 20 }}
                size={24}
              />
            ) : (
              canEdit && (
                <div
                  style={{
                    color: "white",
                    marginRight: 16,
                    cursor: "pointer",
                    padding: 8,
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSubmit(onSubmit)();
                  }}
                >
                  Salvar
                </div>
              )
            )}
          </div>
        </AccordionSummary>

        <AccordionDetails>
          {loading ? (
            <Box
              display={"flex"}
              flex={1}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CircularProgress style={{ margin: 16 }} />
            </Box>
          ) : (
            <FormProvider {...methods}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: 2,
                }}
              >
                <SummarySection title="Previsão de Custo e Financeiro">
                  <Box display={"flex"} margin={"4px 0px"} gap={2}>
                    <CurrencyInputForm
                      label="Valor Total do Evento"
                      name="totalValueOfEvents"
                      disabled={!canEdit}
                    />
                    <CurrencyInputForm
                      label="Pagamento Direto do Cliente"
                      name="directPaymentOfClients"
                      disabled={!canEdit}
                    />
                  </Box>
                </SummarySection>

                <span style={{ fontWeight: "bold" }}>Total de Receitas</span>

                <SummarySection title="Totais de receita Logistica / Produção">
                  <Box display={"flex"} margin={"4px 0px"} gap={2}>
                    <CurrencyInputForm
                      label="Fee"
                      name="logisticFee"
                      disabled={!canEdit}
                    />
                    <CurrencyInputForm
                      label="Markup / Over"
                      name="logisticMarkup"
                      disabled={!canEdit}
                    />
                    <CurrencyInputForm
                      label="Comissão"
                      name="logisticComission"
                      disabled={!canEdit}
                    />
                    <CurrencyInputForm
                      label="Total Receitas"
                      name="logisticTotal"
                      disabled
                    />
                  </Box>
                </SummarySection>

                <SummarySection title="Totais de receita Aéreo">
                  <Box display={"flex"} margin={"4px 0px"} gap={2}>
                    <CurrencyInputForm
                      label="Fee"
                      name="airFee"
                      disabled={!canEdit}
                    />
                    <CurrencyInputForm
                      label="Markup / Over"
                      name="airMarkup"
                      disabled={!canEdit}
                    />
                    <CurrencyInputForm
                      label="Comissão/Incentivo"
                      name="airComission"
                      disabled={!canEdit}
                    />
                    <CurrencyInputForm
                      label="Total Receitas"
                      name="airTotal"
                      disabled
                    />
                  </Box>
                </SummarySection>

                <SummarySection title="Outras receitas">
                  <Box display={"flex"} margin={"4px 0px"} gap={2}>
                    <CurrencyInputForm
                      label="RSVP"
                      name="RSVP"
                      disabled={!canEdit}
                    />
                    <CurrencyInputForm
                      label="Comunicação"
                      name="communicationFee"
                      disabled={!canEdit}
                    />
                  </Box>
                </SummarySection>

                <SummarySection title="Impostos">
                  <Box display={"flex"} margin={"4px 0px"} gap={2}>
                    <CurrencyInputForm
                      label="Total de impostos"
                      name="taxes"
                      disabled={!canEdit}
                    />
                  </Box>
                </SummarySection>

                <SummarySection title="Resumo Financeiro - Totais">
                  <Box display={"flex"} margin={"4px 0px"} gap={2}>
                    <CurrencyInputForm label="Fee" name="totalFees" disabled />
                    <CurrencyInputForm
                      label="Markup/Over"
                      name="totalMarkupAndOverhead"
                      disabled
                    />
                    <CurrencyInputForm
                      label="Comissão"
                      name="comissionTotal"
                      disabled
                    />
                    <CurrencyInputForm
                      label="Total Receitas"
                      name="totalRevenue"
                      disabled
                    />
                    <Input
                      label="Rentabilidade"
                      disabled
                      {...register("profitability")}
                      marginTop={0}
                    />
                  </Box>
                  <Box display={"flex"} margin={"4px 0px"} gap={2}>
                    <CurrencyInputForm label="Impostos" name="taxes" disabled />
                    <CurrencyInputForm
                      label="Pag. Direto"
                      name="directPaymentOfClients"
                      disabled
                    />
                    <Input
                      label="Custo Fornecedor"
                      disabled
                      {...register("supplierCostTotal")}
                      marginTop={0}
                    />
                    <Input
                      label="Faturado MM"
                      disabled
                      {...register("invoicedMM")}
                      marginTop={0}
                    />
                    <CurrencyInputForm
                      label="Valor Total Evento"
                      name="totalValueOfEvents"
                      disabled
                    />
                  </Box>
                </SummarySection>
                <Input
                  label="Observações"
                  disabled={!canEdit}
                  {...register("observations")}
                  placeholder="Exemplo: quem aprova o orçamento"
                />
              </Box>
            </FormProvider>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default OnApprovalAccordion;
