import { api } from "./apiClient";

export default class UserService {
  static async users(params: any): Promise<{ total: number; users: any[] }> {
    return api.get("/users", { params }).then((response) => response.data);
  }

  static async userById(id:string): Promise<any> {
    return api.get(`/users/get-by-id/`, {params: {id}}).then((response) => response.data);
  }

  static async allUsers(): Promise<any[]> {
    return api.get("/users/all").then((response) => response.data);
  }

  static async create(data: any): Promise<any> {
    return api.post("/users", data).then((response) => response.data);
  }

  static async update(id: string, data: any): Promise<any> {
    return api.patch(`/users/${id}`, data).then((response) => response.data);
  }

  static async delete(id: string): Promise<any> {
    return api.delete(`/users/${id}`).then((response) => response.data);
  }

  static async recoveryPassword(email: string): Promise<any> {
    return api
      .post(`/users/recovery-password/${email}`)
      .then((response) => response.data);
  }

  static async sendNewPassword(
    token: string,
    newPassword: string
  ): Promise<any> {
    return api
      .post(`/users/send-new-password`, null, {
        params: { recoverPasswordHash: token, newPassword },
      })
      .then((response) => response.data);
  }

  static async confirmUser(token: string, newPassword: string): Promise<any> {
    return api
      .get(`/users/confirm-account/${token}`, {
        params: { newPassword },
      })
      .then((response) => response.data);
  }
  static async getMyProfile() {
    return api.get("/users/me").then((response) => response.data);
  }
  static async updateMyProfile(body: any) {
    return api.patch("/users/me", body).then((response) => response.data);
  }
}
