import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridSortDirection,
} from "@mui/x-data-grid";
import React, { useContext, useEffect, useMemo, useState } from "react";
import UserService from "../../../../services/user.service";
import UserModal from "./userModal";
import { useQuery, useQueryClient } from "react-query";
import { RoleUserMap } from "../../../../types/user.type";
import { MdEdit, MdSearch } from "react-icons/md";
import TableComponent from "../../../CustomDataGrid";
import { Column } from "react-table";
import { Type } from "typescript";
import { FaCircle, FaPen, FaUserPlus } from "react-icons/fa";
import {
  formatDateTime,
  formatPhoneNumber,
} from "../../../../utils/formatters";
import { LoginDiv } from "./styles";
import { TbEyeEdit } from "react-icons/tb";
import format from "date-fns/format";
import { VscChevronDown } from "react-icons/vsc";
import { AuthContext } from "../../../../contexts/auth";
const Users = () => {
  const {actionOptions} = useContext(AuthContext)
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [tableData, setTableData] = useState<any>({
    page: 0,
    pageSize: 10,
    order: "asc" as GridSortDirection,
    sortField: "name",
    search: "",
    isActive: undefined,
  });

  const updatePage = (pageNumber: number) => {
    setTableData({...tableData, page: pageNumber})
  }
  const updateRowsPerPage = (limit: number) => {
    setTableData({...tableData, pageSize: limit, page: 0})
  }

  const { isLoading, data, error } = useQuery({
    queryKey: ["users", tableData],
    queryFn: () => UserService.users(tableData),
  });
  const [userEdit, setUserEdit] = useState<any>();
  const [showUserModal, setShowUserModal] = useState(false);
  const [filter, setFilter] = useState({ search: "", status: "all" });

  const rowData = useMemo(()=> data, [data])

  interface IUserRow extends Type {
    _id: string;
    name: string;
    email: string;
    phone: string;
    role_id: string;
    active: boolean;
    renewTokenData: any[];
    projectData: any[];
  }
  const columns: Column<IUserRow>[] = [
    {
      Header: "DADOS DO USUÁRIO",
      accessor: "name",
      Filter: "",
      Cell: ({ cell }) => {
        return (
          <LoginDiv style={{}}>
            <div>
              <span>
                {cell.row.original.active ? (
                  <FaCircle size={10} color="green" />
                ) : (
                  <FaCircle size={10} color="red" />
                )}{" "}
              </span>
            </div>
            <div>
              <span>{cell.row.values.name}</span>
              <br />
              <span>{cell.row.original.email}</span>
            </div>
          </LoginDiv>
        );
      },
    },
    {
      Header: "ÚLTIMO ACESSO",
      accessor: "renewTokenData",
      Filter: "",
      Cell: ({ cell }) => (
        <span>
          {" "}
          {!!cell.value[0] ? formatDateTime(cell.value[0].created_date) : "---"}
        </span>
      ),
    },
    {
      Header: "PROJETOS (#MM'S)",
      accessor: "projectData",
      Filter: "",
      Cell: ({ cell }) => (
        <span>
          {cell.value.filter((el: any) => el.active === true).length} ativos;
          {cell.value.filter((el: any) => el.active !== true).length} inativos
        </span>
      ),
    },
    {
      Header: "",
      accessor: "_id",
      Cell: ({ cell }) => (
        <div
          onClick={() => handleUserEdit(cell.value)}
          style={{ cursor: "pointer" }}
        >
          {" "}
          <TbEyeEdit size={18} style={{ cursor: "pointer" }} />
        </div>
      ),
      Filter: "",
      disableSortBy: true,
    },
  ];


  function handleUserEdit(id: string) {
    let selectedUser = data?.users.find((user) => user._id === id);
    if (!!selectedUser) {
      setUserEdit(selectedUser);
      setShowUserModal(true);
    }
  }
  const isDisabled = !actionOptions['createControle de acesso']
  return (
    <Box px={"24px"} pt={"24px"} pb={"28px"} sx={{ flex: 1 }}>
      {(showUserModal || userEdit) && (
        <UserModal
          user={userEdit}
          onClose={() => {
            setShowUserModal(false);
            setUserEdit(undefined);
          }}
          onSuccess={() => queryClient.invalidateQueries("users")}
        />
      )}

      <Box display={"flex"} mb={4} alignItems={"center"} flex={1}>
        <Box display={"flex"} alignItems={"center"} flex={1} gap={2}>
          <Button
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="outlined"
            onClick={(e) => setAnchorEl(e.currentTarget)}
            endIcon={<VscChevronDown />}
            sx={{
              borderColor: "#DBDADE",
              color: "#4B465C",
              fontSize: "14px",
              textTransform: "none",
            }}
          >
            Ações
          </Button>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
            disabled={isDisabled}
              onClick={() => {
                setShowUserModal(true);
                setAnchorEl(null);
              }}
            >
             <FaUserPlus color="#1361A4"/>&nbsp; Adicionar usuário
            </MenuItem>
          </Menu>

          <FormControl sx={{ width: "20%" }}>
            <InputLabel id="filter-status" size="small">
              Status
            </InputLabel>
            <Select
              labelId="filter-status"
              label="Status"
              size="small"
              value={tableData.isActive}
              onChange={(e) => setTableData({ ...tableData, page: 0, isActive: e.target.value === 'all' ? undefined : e.target.value  })}
            >
              <MenuItem value={"all"}>Todos</MenuItem>
              <MenuItem value={"true"}>Ativos</MenuItem>
              <MenuItem value={"false"}>Inativos</MenuItem>
            </Select>
          </FormControl>
         
        </Box>
        <TextField
          size="small"
          placeholder="Pesquisar por nome ou email"
          value={tableData.search}
          onChange={(e) => setTableData({ ...tableData, search: e.target.value })}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <MdSearch />
              </InputAdornment>
            ),
          }}
          sx={{ width: "30%" }}
        />
      </Box>

      <Box sx={{ minHeight: 400 }}>
        <TableComponent
          columns={columns}
          isLoading={isLoading}
          data={rowData?.users || []}
          givenPage={tableData.page}
          rowsPerPage={tableData.pageSize}
          total={data?.total}
          onPageChange={updatePage}
          onRowsPerPageChange={updateRowsPerPage}
        />
      </Box>
    </Box>
  );
};

export default Users;
