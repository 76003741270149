export type AerialFieldType = {
  _id?: string;
  paymentMethod:string;
  flightClasses:string[];
  aerialDateChanges:string;
  dateChangesOptions:string;
  dateChangeDeadline:string;
  ticketChangeAfterEmission:string;
  mandatoryDocs: string[]
 
  paymentMethodObs: string;
  maxValueObs: number;
  aerialDateChangesObs: string;
  ticketChangeObs: string;
  mandatoryDocsObs: string;
};


export enum FlightClassEnum {
  ECONOMY = 'Econômica',
  PREMIUM_ECONOMY = 'Econômica Premium',
  BUSINESS = 'Executiva',
  FIRST = 'Primeira',
}


export enum MandatoryDocsEnum {
  DRIVER_LICENSE = 'CNH',
  IDENTITY_DOCUMENT = 'Documento de identidade',
  VALID_PASSPORT = 'Passaporte com 06 meses de validade a contar da data de retorno ao país de origem',
  VALID_CONSULAR_VISA = "Visto consular válido para o período da viagem - favor especificar em 'Outros' se Americano / Canadense, etc",
  YELLOW_FEVER_VACCINATION_CERTIFICATE = 'Certificado Internacionais de Vacinação contra a Febre Amarela',
  TRIPLE_VIRAL_VACCINATION_CERTIFICATE = 'Certificado Internacional de Vacinação contra Tríplice Viral',
  // TRAVEL_INSURANCE_BY_CUSTOMER = 'Seguro viagem (por conta do cliente)',
  // TRAVEL_INSURANCE_BY_GUEST = 'Seguro viagem (por conta do convidado)',
}
