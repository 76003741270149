import { api } from "./apiClient";

export default class BoothService {


  static async get(projectId: string): Promise<any> {
     return api.get(`/booth/${projectId}`).then((response) => response.data);
  }
  static async create(data: any): Promise<any> {
    return api.post('/booth/create', data).then((response) => response.data)
  }
  static async update(id: string, data: any): Promise<any> {
    return api.put(`/booth/${id}`, data).then((response) => response.data)
  }

}
