import { View, Text, Image } from "@react-pdf/renderer";
import { styles } from "./styles";
import { add, format } from "date-fns";
import {
  ACCOMMODATIONS,
  ROOMS,
  AEB,
  RSVP,
  SUBSCRIPTION,
  SUPPORT,
  AIR,
  SEVERAL,
  EQUIPMENTS,
  TRANSFER,
  COMMUNICATIONS,
  TRANSLATIONS,
} from "../../utils/serviceTypesFromBudget";

import aerialIcon from "../../assets/styled-icons/export-icons/aerial.png";
import accommodationsIcon from "../../assets/styled-icons/export-icons/accommodations.png";
import aebIcon from "../../assets/styled-icons/export-icons/aeb.png";
import equipmentIcon from "../../assets/styled-icons/export-icons/equipments.png";
import otherIcon from "../../assets/styled-icons/export-icons/other.png";
import rsvpIcon from "../../assets/styled-icons/export-icons/rsvp.png";
import subscriptionIcon from "../../assets/styled-icons/export-icons/subscription.png";
import supportIcon from "../../assets/styled-icons/export-icons/support.png";
import transferIcon from "../../assets/styled-icons/export-icons/transfer.png";
import translationsIcon from "../../assets/styled-icons/export-icons/translations.png";
import roomsIcon from "../../assets/styled-icons/export-icons/rooms.png";

interface IServiceRowProps {
  servicesList: any[];
  type: string;
  total: number;
  totalConverted: number;
}
export function ServiceRow(props: IServiceRowProps) {
  const formatterReal = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "BRL",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  const formatterRealCompact = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "BRL",
    notation: "compact",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  function getProductName(service: string) {
    let productName: any = "";
    switch (props.type) {
      case "ACCOMMODATIONS":
        productName = ACCOMMODATIONS.find((el) => el.value === service)?.text;
        break;
      case "ROOMS":
        productName = ROOMS.find((el) => el.value === service)?.text;
        break;
      case "AEB":
        productName = AEB.find((el) => el.value === service)?.text;
        break;
      case "RSVP":
        productName = RSVP.find((el) => el.value === service)?.text;
        break;
      case "SUBS":
        productName = SUBSCRIPTION.find((el) => el.value === service)?.text;
        break;
      case "SUPPORT":
        productName = SUPPORT.find(
          (el) => el.value === service
        )?.text;
        break;
      case "AIR":
        productName = AIR.find((el) => el.value === service)?.text;
        break;
      case "SEVERAL":
        productName = SEVERAL.find(
          (el) => el.value === service
        )?.text;
        break;
      case "EQUIPMENT":
        productName = EQUIPMENTS.find(
          (el) => el.value === service
        )?.text;
        break;
      case "TRANSLATION":
        productName = TRANSLATIONS.find(
          (el) => el.value === service
        )?.text;
        break;
      case "TRANSFER":
        productName = TRANSFER.find((el) => el.value === service)?.text;
        break;
      case "COMMUNICATIONS":
        productName = COMMUNICATIONS.find(
          (el) => el.value === service
        )?.text;
        break;
    }
    return productName;
  }
  function getServiceIcon() {
    let serviceIcon = "";
    switch (props.type) {
      case "ACCOMMODATIONS":
        serviceIcon = accommodationsIcon;
        break;
      case "ROOMS":
        serviceIcon = roomsIcon;
        break;
      case "AEB":
        serviceIcon = aebIcon;
        break;
      case "RSVP":
        serviceIcon = rsvpIcon;
        break;
      case "SUBS":
        serviceIcon = subscriptionIcon;
        break;
      case "SUPPORT":
        serviceIcon = supportIcon;
        break;
      case "AIR":
        serviceIcon = aerialIcon;
        break;
      case "SEVERAL":
        serviceIcon = otherIcon;
        break;
      case "EQUIPMENT":
        serviceIcon = equipmentIcon;
        break;
      case "TRANSLATION":
        serviceIcon = translationsIcon;
        break;
      case "TRANSFER":
        serviceIcon = transferIcon;
        break;
      case "COMMUNICATIONS":
        serviceIcon = transferIcon;
        break;
    }
    return serviceIcon;
  }

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <View
        style={{
          ...styles.serviceTypeDiv,
          height: `${30 * (props.servicesList.length + 1)}px`,
        }}
      >
        <Image src={getServiceIcon()} style={{ width: "60%", color: "#000" }} />
      </View>
      <View>
        {props.servicesList.map((service, index) => {
          const formatterCurrency = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: service.currency === "" ? "BRL" : service.currency,
          });
          const formatterCurrencyCompact = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: service.currency === "" ? "BRL" : service.currency,
            notation: "compact",
          });
          return (
            <View key={Math.random()} style={styles.rowNormal}>
              <View style={styles.enumDiv}>
                <Text style={styles.text}>{index + 1} </Text>
              </View>
              <View style={styles.typeDiv}>
                <Text style={styles.text}>
                  {getProductName(service.service_type)}
                </Text>
              </View>
              <View style={styles.typeDiv}>
                <Text style={styles.text}>{"Fornecedor"}</Text>
              </View>
              <View style={styles.obsDiv}>
                <Text style={styles.text}>{service.observation}</Text>
              </View>
              <View style={styles.date}>
                <Text style={styles.text}>
                  {format(
                    add(new Date(service.input_date), { hours: 3 }),
                    "dd/MM/yyyy"
                  )}
                </Text>
              </View>
              <View style={styles.date}>
                <Text style={styles.text}>
                  {format(
                    add(new Date(service.out_date), { hours: 3 }),
                    "dd/MM/yyyy"
                  )}
                </Text>
              </View>
              <View style={styles.small}>
                <Text style={styles.text}>{service.daily}</Text>
              </View>
              <View style={{ ...styles.smallBorder }}>
                <Text style={styles.text}>{service.quantity}</Text>
              </View>
              <View style={styles.tariff}>
                <Text style={styles.text}>
                  {" "}
                  {service.tariff.value.length < 7
                    ? formatterCurrency.format(Number(service.tariff.value))
                    : formatterCurrencyCompact.format(
                        Number(service.tariff.value)
                      )}
                </Text>
              </View>
              <View style={styles.tariff}>
                <Text style={styles.text}>
                  {" "}
                  {service.tariff.full_coin_service.length < 7
                    ? formatterCurrency.format(
                        Number(service.tariff.full_coin_service)
                      )
                    : formatterCurrencyCompact.format(
                        Number(service.tariff.full_coin_service)
                      )}
                </Text>
              </View>
              <View style={styles.total}>
                <Text style={styles.text}>
                  {service.total.length < 7
                    ? formatterCurrency.format(Number(service.total))
                    : formatterCurrencyCompact.format(Number(service.total))}
                </Text>
              </View>
              <View style={styles.total}>
                <Text style={styles.text}>
                  {service.total_converted.length < 7
                    ? formatterReal.format(Number(service.total_converted))
                    : formatterRealCompact.format(
                        Number(service.total_converted)
                      )}
                </Text>
              </View>
              <View style={styles.total}>
                <Text style={styles.text}>
                  {service.service_tax.value.length < 7
                    ? formatterCurrency.format(
                        Number(service.service_tax.value)
                      )
                    : formatterCurrencyCompact.format(
                        Number(service.service_tax.value)
                      )}
                </Text>
              </View>
              <View style={styles.total}>
                <Text style={styles.text}>
                  {service.local_tax.value.length < 7
                    ? formatterCurrency.format(Number(service.local_tax.value))
                    : formatterCurrencyCompact.format(
                        Number(service.local_tax.value)
                      )}
                </Text>
              </View>
              <View style={styles.total}>
                <Text style={styles.text}>
                  {service.tourism_tax.value.length < 7
                    ? formatterCurrency.format(
                        Number(service.tourism_tax.value)
                      )
                    : formatterCurrencyCompact.format(
                        Number(service.tourism_tax.value)
                      )}
                </Text>
              </View>
              <View style={styles.small}>
                <Text style={styles.text}>
                  {service.attr.length < 7
                    ? formatterReal.format(Number(service.exchange))
                    : formatterRealCompact.format(Number(service.exchange))}
                </Text>
              </View>
              <View style={styles.total}>
                <Text style={styles.text}>
                  {service.fee.value.length < 7
                    ? formatterCurrency.format(Number(service.fee.value))
                    : formatterCurrencyCompact.format(
                        Number(service.fee.value)
                      )}
                </Text>
              </View>
              <View style={styles.small}>
                <Text style={styles.text}>
                  {service.type_nf_mm.type_calculate.toUpperCase()}
                </Text>
              </View>
            </View>
          );
        })}
        <View style={{ ...styles.rowNormal, padding: 0 }}>
          <View style={{ width: "75%" }}></View>
          <View style={styles.rowTotal}>
            <Text>Total Moeda</Text>
            <Text>R$ {props.total}</Text>
          </View>
          <View style={styles.rowTotal}>
            <Text>Total Convertido</Text>
            <Text>R$ {props.totalConverted}</Text>
          </View>
        </View>
      </View>
    </div>
  );
}
