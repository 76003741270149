import { api } from "./apiClient";

export default class RolePermissionsService {
    static async roles(params: any): Promise<any> {
        return api.get('/roles/get', {params}).then((response) => response.data)
    }
    static async allRoles(): Promise<any> {
        return api.get('/roles/all').then((response) => response.data)
    }
    static async create(data: any): Promise<any> {
        return api.post('/roles/create', data).then((response) => response.data)
    }
    static async update(id:string, data: any): Promise<any> {
        return api.put(`/roles/update/${id}`, data).then((response) => response.data)
    }
    static async delete(id:string): Promise<any> {
        return api.delete(`/roles/delete/${id}`).then((response) => response.data)
    }

}