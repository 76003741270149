import { Box, Button } from "@mui/material";
import React, { useContext, useState } from "react";
import { FaRegSave } from "react-icons/fa";

import { AuthContext } from "../../../contexts/auth";
import { useProject } from "../../../contexts/project.context";

interface Props {
  children: React.ReactNode;
  isLast?: boolean;
  onSubmit: () => void;
  showSubmit?: boolean;
}

const TabContent: React.FC<Props> = ({
  children,
  isLast = false,
  onSubmit,
  showSubmit = true,
}) => {
  const { project } = useProject();
  const { actionOptions } = useContext(AuthContext);

  const [isSubmitting, setIsSubmitting] = useState(false);
  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setIsSubmitting(true);
    await onSubmit();
    setIsSubmitting(false);
  }
  const isDisabled = !project
    ? !actionOptions.createProjetos
    : !actionOptions.updateProjetos;
  return (
    <Box
      sx={{ flex: 1 }}
      px={"24px"}
      pt={"24px"}
      pb={"28px"}
      overflow={"auto"}
      maxHeight={"64vh"}
    >
      <form onSubmit={handleSubmit}>
        <Box
          display={"grid"}
          alignContent={"flex-start"}
          gap={2}
          width={"100%"}
        >
          {children}

          {showSubmit && (
            <Box display={"flex"} justifyContent={"flex-end"} mt={6}>
              <Button
                startIcon={<FaRegSave />}
                type="submit"
                size="medium"
                disabled={isSubmitting || isDisabled}
              >
                {project
                  ? "Atualizar projeto"
                  : isLast
                  ? "Criar projeto"
                  : "Avançar"}
              </Button>
            </Box>
          )}
        </Box>
      </form>
    </Box>
  );
};

export default TabContent;
