import { Page, View, Text, Image } from "@react-pdf/renderer";
import { IListByProvider } from "../../types/export";
import { styles } from "./styles";
import { DetailsRow } from "../DetailsRow";
import budgetIcon from "../../assets/styled-icons/export-icons/budget.png";

import {
  AEB,
  AIR,
  COMMUNICATIONS,
  EQUIPMENTS,
  ACCOMMODATIONS,
  SEVERAL,
  ROOMS,
  RSVP,
  SUBSCRIPTION,
  SUPPORT,
  TRANSFER,
  TRANSLATIONS,
} from "../../utils/serviceTypesFromBudget";
import AerialImg from "../../assets/styled-icons/export-icons/aerial.png";
import commucicationsImg from "../../assets/styled-icons/export-icons/commucications.png";
import aebImg from "../../assets/styled-icons/export-icons/aeb.png";
import rsvpImg from "../../assets/styled-icons/export-icons/rsvp.png";
import transferImg from "../../assets/styled-icons/export-icons/transfer.png";
import subscriptionImg from "../../assets/styled-icons/export-icons/subscription.png";
import accommodationsImg from "../../assets/styled-icons/export-icons/accommodations.png";
import othersImg from "../../assets/styled-icons/export-icons/others.png";
import roomsImg from "../../assets/styled-icons/export-icons/rooms.png";
import translationsImg from "../../assets/styled-icons/export-icons/translations.png";
import { useEffect, useState } from "react";
import { add, format } from "date-fns";

interface IPageProps {
  savedServicesByProvider: IListByProvider[];
  title: string;
  exportType: string;
  pageType: string;
  // budgetsList: {
  //     budget_name: string,
  //     budget_id: string
  // }[]
}
export function DetailsExportPage(props: any) {
  const [servicesRendered, setServicesRendered] = useState(<></>);

  function renderToScreen() {
    const accomodationServices = props.exportedBudget.saved_services.filter(
      (service: any) => service.type === "ACCOMMODATIONS"
    );
    const roomServices = props.exportedBudget.saved_services.filter(
      (service: any) => service.type === "ROOMS"
    );
    const AEB = props.exportedBudget.saved_services.filter(
      (service: any) => service.type === "FOOD_AND_DRINKS"
    );
    const EQUIPMENTS = props.exportedBudget.saved_services.filter(
      (service: any) => service.type === "EQUIPMENT"
    );
    const TRANSLATIONS = props.exportedBudget.saved_services.filter(
      (service: any) => service.type === "SIMULTANEOUS_TRANSLATION"
    );
    const supportServices = props.exportedBudget.saved_services.filter(
      (service: any) => service.type === "SUPPORT_TEAMS"
    );
    const subscriptionServices = props.exportedBudget.saved_services.filter(
      (service: any) => service.type === "SUBSCRIPTION"
    );
    const RSVP = props.exportedBudget.saved_services.filter(
      (service: any) => service.type === "RSVP"
    );
    const AIR = props.exportedBudget.saved_services.filter(
      (service: any) => service.type === "AIR"
    );
    const TRANSFER = props.exportedBudget.saved_services.filter(
      (service: any) => service.type === "TRANSFER"
    );
    const COMMUNICATIONS = props.exportedBudget.saved_services.filter(
      (service: any) => service.type === "COMMUNICATIONS"
    );
    const miscelaneousServices = props.exportedBudget.saved_services.filter(
      (service: any) => service.type === "SEVERAL"
    );
    return (
      <>
        <View style={styles.servicesDiv}>
          <View style={styles.rowTitles}>
            <View style={{ ...styles.simpleDiv, marginLeft: "3%" }}>
              <Text style={styles.text}>Produto</Text>
            </View>
            <View style={styles.simpleDiv}>
              <Text style={styles.text}>Fornecedor</Text>
            </View>
            <View style={styles.simpleDiv}>
              <Text style={styles.text}>Complemento</Text>
            </View>
            <View style={styles.doubleDiv}>
              <Text style={styles.text}>Composição</Text>
            </View>
            <View style={styles.doubleDiv}>
              <Text style={styles.text}>Forma de Pagamento</Text>
            </View>
            <View style={styles.doubleDiv}>
              <Text style={styles.text}>Politica de Cancelamento</Text>
            </View>
          </View>
          {accomodationServices.length > 0 && (
            <DetailsRow
              servicesList={accomodationServices}
              type="ACCOMMODATIONS"
            />
          )}
          {roomServices.length > 0 && (
            <DetailsRow servicesList={roomServices} type="ROOMS" />
          )}
          {AEB.length > 0 && (
            <DetailsRow servicesList={AEB} type="AEB" />
          )}
          {EQUIPMENTS.length > 0 && (
            <DetailsRow servicesList={EQUIPMENTS} type="EQUIPMENT" />
          )}
          {TRANSLATIONS.length > 0 && (
            <DetailsRow servicesList={TRANSLATIONS} type="TRANSLATION" />
          )}
          {supportServices.length > 0 && (
            <DetailsRow servicesList={supportServices} type="SUPPORT" />
          )}
          {subscriptionServices.length > 0 && (
            <DetailsRow servicesList={subscriptionServices} type="SUBS" />
          )}
          {RSVP.length > 0 && (
            <DetailsRow servicesList={RSVP} type="RSVP" />
          )}
          {AIR.length > 0 && (
            <DetailsRow servicesList={AIR} type="AIR" />
          )}
          {TRANSFER.length > 0 && (
            <DetailsRow servicesList={TRANSFER} type="TRANSFER" />
          )}
          {COMMUNICATIONS.length > 0 && (
            <DetailsRow
              servicesList={COMMUNICATIONS}
              type="COMMUNICATIONS"
            />
          )}
          {miscelaneousServices.length > 0 && (
            <DetailsRow servicesList={miscelaneousServices} type="SEVERAL" />
          )}
        </View>
      </>
    );
  }
  useEffect(() => {
    let servicesList = renderToScreen();
    setServicesRendered(servicesList);
  }, [props]);

  function renderHeader() {
    return (
      <>
        <View style={styles.exportHeader}>
          <div style={{ ...styles.headerDiv, ...styles.backgroundBlack }}>
            <div style={styles.childHeaderDiv}>
              <Text
                style={{
                  ...styles.whiteText,
                  fontSize: 22,
                  fontWeight: "bold",
                }}
              >
                {props.clientData.clientName}
              </Text>
              <br />
              <Text
                style={{ ...styles.grayText, fontSize: 22, fontWeight: "bold" }}
              >
                Dorflex
              </Text>
            </div>
          </div>
          <div style={{ ...styles.headerDiv, ...styles.backgroundGray }}>
            <div
              style={{
                ...styles.childHeaderDiv,
                ...styles.textSpan,
                ...styles.grayText,
              }}
            >
              <div style={{ padding: "20px 0" }}>
                <Text>Evento</Text>
              </div>
              <br />
              <Text>{props.clientData.eventName}</Text>
              <div style={styles.textDiv}>
                <Text style={{ color: "#000" }}>Inicio: </Text>
                <Text>{props.clientData.initialDate}</Text>
              </div>
              <div style={styles.textDiv}>
                <Text style={{ color: "#000" }}>Término: </Text>
                <Text>{props.clientData.finalDate}</Text>
              </div>
              <div style={styles.textDiv}>
                <div style={styles.textDiv}>
                  <Text style={{ color: "#000" }}>PFZ: </Text>
                  <Text>{props.clientData.pfz}</Text>
                </div>
                <div style={styles.textDiv}>
                  <Text style={{ color: "#000" }}> MAAP: </Text>
                  <Text>{props.clientData.mapp}</Text>
                </div>
              </div>
            </div>
            <div style={styles.childHeaderDiv}>
              <div style={{ ...styles.textDiv, padding: "10px 0" }}>
                <Text>Nº MM</Text>
                <Text style={{ ...styles.textSpan, ...styles.grayText }}>
                  {props.clientData.mmCode}
                </Text>
              </div>

              <br />
              <Text>Atendimento</Text>
              <div style={styles.textDiv}>
                <Text style={{ ...styles.textSpan }}>Solicitante </Text>
                <Text style={{ ...styles.textSpan, ...styles.grayText }}>
                  {props.clientData.clientRequester}
                </Text>
              </div>
              <div style={styles.textDiv}>
                <Text style={{ ...styles.textSpan }}>Atend/Comercial </Text>
                <Text style={{ ...styles.textSpan, ...styles.grayText }}>
                  {props.clientData.attendance}
                </Text>
              </div>
              <div style={styles.textDiv}>
                <Text style={{ ...styles.textSpan }}>Coordenador </Text>
                <Text style={{ ...styles.textSpan, ...styles.grayText }}>
                  {props.clientData.coordinator}
                </Text>
              </div>
            </div>
          </div>
        </View>
        <View style={styles.view}>
          <div style={{ width: "90%" }}>
            <Text style={{ ...styles.header2, fontWeight: "bold" }}>
              Proposta
            </Text>
            <Text
              style={{
                ...styles.header2,
                fontSize: "14px",
                ...styles.grayText,
                marginTop: "10px",
              }}
            >
              Comparações de orçamentos
            </Text>
          </div>
        </View>
      </>
    );
  }
  return (
    <Page size="A3" orientation="landscape" style={styles.page}>
      {props.showHeader && renderHeader()}
      <View style={{ ...styles.view, marginTop: "10px" }}>
        <div style={{ width: "98%", backgroundColor: "#eeeeee" }}>
          <div style={styles.infoDiv}>
            <div style={{ width: "15%" }}>
              <div style={styles.budgetTitleDiv}>
                <Image
                  src={budgetIcon}
                  style={{ width: "14px", marginRight: "10px" }}
                />
                <Text>Orçamento {props.index + 1}</Text>
              </div>
              <div style={styles.deadlineDiv}>
                <Text style={{ ...styles.grayText, ...styles.textSpan }}>
                  DEADLINE {props.exportedBudget.budget_data.deadline}
                </Text>
              </div>
            </div>
            <div style={{ width: "30%" }}>
              <div style={styles.locationDiv}>
                <Text style={styles.textHeader}>
                  {props.exportedBudget.budget_data.place} -{" "}
                </Text>
                <Text style={{ ...styles.grayText, ...styles.textSpan }}>
                  {props.exportedBudget.budget_data.address}
                </Text>
              </div>
              <div style={styles.locationDiv}>
                <div style={styles.childBudgetDiv}>
                  <Text style={{ ...styles.grayText, ...styles.textSpan }}>
                    Início{" "}
                  </Text>
                  <Text style={{ ...styles.grayText, ...styles.textSpan }}>
                    {format(
                      add(new Date(props.clientData.initialDate), { hours: 3 }),
                      "dd/MM/yyyy"
                    )}
                  </Text>
                </div>
                <div style={styles.childBudgetDiv}>
                  <Text style={{ ...styles.grayText, ...styles.textSpan }}>
                    Término{" "}
                  </Text>
                  <Text style={{ ...styles.grayText, ...styles.textSpan }}>
                    {format(
                      add(new Date(props.clientData.finalDate), { hours: 3 }),
                      "dd/MM/yyyy"
                    )}
                  </Text>
                </div>
              </div>
            </div>
            <div style={{ width: "15%" }}>
              <div style={styles.tagsDiv}>
                <Text style={{ ...styles.grayText, ...styles.textSpan }}>
                  RFP:{" "}
                </Text>
                <Text style={{ ...styles.grayText, ...styles.textSpan }}>
                  {props.exportedBudget.budget_data.pp === "yes"
                    ? "SIM"
                    : "NÃO"}
                </Text>
              </div>
              <div style={styles.tagsDiv}>
                <Text style={{ ...styles.grayText, ...styles.textSpan }}>
                  TAG GMP:{" "}
                </Text>
                <Text style={{ ...styles.grayText, ...styles.textSpan }}>
                  {props.exportedBudget.budget_data.tag_map}
                </Text>
              </div>
            </div>
            <div style={{ width: "15%" }}>
              <div style={styles.tagsDiv}>
                <Text style={{ ...styles.grayText, ...styles.textSpan }}>
                  {props.exportedBudget.budget_data.disponibility}
                </Text>
              </div>
              <div style={styles.tagsDiv}>
                <Text style={{ ...styles.grayText, ...styles.textSpan }}>
                  {props.exportedBudget.budget_data.site}
                </Text>
              </div>
            </div>
            <div style={{ width: "25%" }}>
              <div style={styles.locationDiv}>
                <Text style={{ ...styles.grayText, ...styles.textSpan }}>
                  obs
                  {props.exportedBudget.budget_data.description}
                </Text>
              </div>
            </div>
          </div>
          <div style={styles.table}>{servicesRendered}</div>
        </div>
      </View>
    </Page>
  );
}
