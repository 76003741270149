import { createContext, useCallback, useEffect, useState } from "react";
import { api } from "../services/apiClient";

import {
  AuthProviderType,
  LoginData,
  User,
  AuthState,
  AuthContextData,
} from "../types/authContext";

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

const AuthProvider: React.FC<AuthProviderType> = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const access_token = localStorage.getItem("@mmeventos:access_token");
    const renew_token = localStorage.getItem("@mmeventos:access_token");
    const user = localStorage.getItem("@mmeventos:user");

    if (access_token && user && renew_token) {
      api.defaults.headers.common.authorization = `Bearer ${access_token}`;

      return { access_token, renew_token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });
  const [language, setLanguage] = useState("pt");
  const [accessOptions, setAccessOptions] = useState([])
  const [actionOptions, setActionOptions] = useState({} as any)
  const signIn = useCallback(async (data: LoginData) => {
    const response = await api.post("/auth/login", data);
    const { access_token, renew_token, user } = response.data;
    
    if (access_token !== undefined && user !== undefined) {
      localStorage.setItem("@mmeventos:access_token", access_token);
      localStorage.setItem("@mmeventos:renew_token", renew_token);
      localStorage.setItem("@mmeventos:user", JSON.stringify(user));
      localStorage.setItem("@mmeventos:keep_connected", JSON.stringify(data.keep_connected));
      api.defaults.headers.common.authorization = `Bearer ${access_token}`;

      setData({ access_token, renew_token, user });
      handleSetRoleAndActions()

     


    } else {
      throw new Error("Erro ao fazer login");
    }
  }, []);
  useEffect(()=> {
    handleSetRoleAndActions()
  },[])
  const handleSetRoleAndActions = async () => {
    const userString = localStorage.getItem("@mmeventos:user")
    const user = !!userString ? JSON.parse(userString) : {}

    if(!!user) {
      const rolesData = await api.get('/roles/get', {
        params: {
          id: user.function_id
        }
      })
      createActionOptions(rolesData.data.modules)
       setAccessOptions(rolesData.data.modules)
    }
    const rolesData = await api.get('/roles/get', {
      params: {
        id: user.function_id
      }
    })
    createActionOptions(rolesData.data.modules)
     setAccessOptions(rolesData.data.modules)
  }
  const signOut = useCallback(() => {
    localStorage.removeItem("@mmeventos:access_token");
    localStorage.removeItem("@mmeventos:renew_token");
    localStorage.removeItem("@mmeventos:user");

    setData({} as AuthState);
    setAccessOptions([])
  }, []);

  const updateUser = useCallback((user: User) => {
    localStorage.setItem("@mmeventos:user", JSON.stringify(user));

    setData({
      access_token: data.access_token,
      user,
      renew_token: data.renew_token,
    });
  }, []);
  const setChosenLanguage = (language: string) => {
    setLanguage(language);
  };

  function createActionOptions(moduleArr: any[]) {
    const currActionOptions = {} as any;
    moduleArr.forEach((module) => {
      const moduleName = module.name;
      const moduleType = module.type;
      Object.keys(moduleType).forEach((action) => {
       
          currActionOptions[`${action}${moduleName}`] = moduleType[action].active;
        
        
      });
    });
    setActionOptions(currActionOptions);
  }
  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        accessOptions,
        actionOptions,
        signIn,
        signOut,
        updateUser,
        language,
        setChosenLanguage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider };
