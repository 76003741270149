import React, { useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Input } from "../../../../components/Input";
import { Box, Button, FormControl, FormControlLabel, FormGroup, FormLabel, InputAdornment, TextField } from "@mui/material";
import { Switch } from "../../../UI/Switch";
import { FormInputMultiCheckbox } from "../../../novos/RSVP/PreCongress/FormMultipleCheckBox";
import styled from "styled-components";
import { createOptionsFromEnum } from "../../../../utils/formatters";
import { BillingEnum, PaymentMethodsEnum } from "../../../../types/aerial/enums";
import { RadioGroup } from "../../../UI/RadioGroup";
import { StyledBox } from "../../RSVP/styles";
import { SaveButton } from "../../../UI/SaveButton";
import { AuthContext } from "../../../../contexts/auth";
import { ProjectUploadFile } from "../../../UI/ProjectUploadFile";
import { useProject } from "../../../../contexts/project.context";



type PropTypes = {
  onSubmit(form: any): void
  financialData: any
  projectId: string
}

const initialState = {
  routeNetWork: false,
  insurance: false,
  paymentMethod: [],
  paymentMethodObs: '',
  billing: [],
  billingObs: ''
}
const FinancialForm = ({ onSubmit, financialData, projectId }: PropTypes) => {
  const {actionOptions} = useContext(AuthContext)

  const isDisabled = !actionOptions.createProjetos && !actionOptions.updateProjetos
  const methods = useForm({
    defaultValues: initialState,
    mode: "all",
  });
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { errors },
  } = methods

  const paymentMethodOptions = createOptionsFromEnum(PaymentMethodsEnum)
  const billingOptions = createOptionsFromEnum(BillingEnum)
  const routeNetWorkWatch = watch('routeNetWork')
  const insuranceWatch = watch('insurance')
  const paymentMethodWatch = watch('paymentMethod', financialData?.paymentMethod);
  const billingWatch = watch('billing', financialData?.billing);

  // const [paymentMethodState, setPaymentMethodState] = useState()

  useEffect(() => {
    if (!!financialData) reset(financialData)
  }, [financialData]);

  function handleUpload(files: File[]) {
    let reader = new FileReader();

    reader.readAsDataURL(files[0]);

    reader.onload = function () {
      let e = {
        target: {
          name: 'image_url',
          value: reader.result
        }
      } as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    };

    reader.onerror = function () {
    };
  }

  return (
    <FormProvider {...methods}>
      <StyledBox onSubmit={handleSubmit(onSubmit)}>
        <Box display={'flex'}>
          <Box sx={{
            display: 'flex',
            width: "50%",
            flexDirection: 'column'
          }} >
            <Switch
              valueLabel="Cotação malha aérea"
              label=""

              {...register('routeNetWork')}
              checked={routeNetWorkWatch}
            />

            <Switch
              valueLabel="Seguro em todos"
              label=""

              {...register("insurance")}
              checked={insuranceWatch}
            />
            <FormGroup>
              <FormLabel>Forma de pagamento</FormLabel>
              <FormInputMultiCheckbox
                name={"paymentMethod"}
                control={control} label={'label'}
                options={paymentMethodOptions}
                setValue={setValue}
                previousSelected={paymentMethodWatch} // adicionar quando integrar com o backend
                optionSelectAll={false}
              />
            </FormGroup>
            <Input label="Descrever" placeholder="Descrever as formas de pagamento" {...register('paymentMethodObs')} />

            <FormGroup>
              <FormLabel id="demo-radio-buttons-group-label">Faturamento</FormLabel>
              <FormInputMultiCheckbox
                name={"billing"}
                control={control} 
                label={'label'}
                options={billingOptions}
                setValue={setValue}
                previousSelected={billingWatch}
                optionSelectAll={false}
              />
            </FormGroup>
            <Input label="Observação" placeholder="Observação" {...register('billingObs')} />

          </Box>
          <Box sx={{
            display: 'flex',
            width: "40%",
            margin: '0 auto',
            flexDirection: 'column'
          }} >
            {/*<ProjectUploadFile projectId={projectId} area={"air"}/>*/}
             
             
          
          </Box>
        </Box>
        <SaveButton isDisabled={isDisabled} />
      </StyledBox>
    </FormProvider>
  );
};

export default FinancialForm;
