import { IFinancialData } from "../types/initialBriefing";
import { IRSVPStates } from "../types/rsvp";

export const INITIAL_BRIEFING = {
  eventName: "",
  briefing_length: "",
  eventType: "",
  briefingDate: "",
  deliveryDate: "",
  presentationDate: "",
  customerInputs: null,
  inputTypes: "",
  competition: null,
  competitionObs: "",
  projectArea: null,
  fileName: null,
  observations: "",
  isPfizer: null,
  isMapp: null,
  pfizerInputs: "",
  mappInputs: "",
};

export const CUSTOMER_REGISTER = {
  id: "",
  companyName: "",
  companyRegisterNumber: "",
  address: "",
  applicantName: "",
  contact: "",
  email: "",
  coordinator_1: "",
  cell: "",
  cellLeader: "",
  attendance_1: "",
  attendance_2: "",
  coordinator_2: "",
  commercial_new_business: "",
  clientSegment: "",
};

export const FINANCIAL_DATA = {
  isBudgetDefined: null,
  budgetValue: 0,
  advanceValue: 0,
  budgetValueAerial: 0,
  budgetValueAerialFeeMM: 0,
  budgetDirectCustomerPayment: 0,
  budgetValueCreative: 0,
  budgetValueFeeService: 0,
  budgetValueRSVP: 0,
  budgetTotalValueInvoiced: 0,

  paymentDate: "",
  fees: null,
  feesObs: "",
  advance: null,
  observations: "",
  budgetValueLogistics: "",
  budgetValueProduction: "",
  billingPeriod: "",
  paymentMethod: "",
  debtNote: "",
  financing: "",
};
export const PROJECT_DATA = {
  eventResponsible: "",
  project_event_location: "",
  eventType: "",
  initialDate: "",
  finalDate: "",
  initialHour: "",
  finalHour: "",
  streamed: null,
  eventDuration: "",
  eventObjective: "",
  customerChallenges: "",
  public: "",
  profile: "",
  guestAmount: 0,
  address: "",
  observations: "",
};

export const CRIATIVO_DATA = {
  theme: null,
  directional: "",
  keys: "",
  art: null,
  materialType: "",
  materialTypeObs: "",
  ownKV: null,
  ownKVObs: "",
  contentResponsibility: "",
  contentResponsibilityObs: "",
  art2: null,
  art2MaterialType: "",
  art2MaterialTypeObs: "",
  d3: null,
  d3Obs: "",
  scenographyExpense: "",
  multipleOptions: null,
  multipleOptionsObs: "",
  fileName: null,
};


export const PROJECT_CRIATIVE_DATA = {
  theme: "",
  briefing_id: "",
  contentResponsibility: [],
  contentResponsibilityObs: "",
  creativeDirectionals: [],
  creativeDirectionalsExemple: "",
  keyMessages: "",
  typeArtMaterial: "",
  typeArtMaterialObs: "",
  typeKVMaterial: "",
  typeMaterialObs: "",
  scenographyDigital3d: "",
  scenography3dExpense: "",
  createArtMM: "",
  gifts: "",
  moreOneArtOption: "",
  file_s3: "",
  file_url: "",
}



export const  PROJECT_PRODUCAO_DATA = {
  briefing_id: '',
  speaker: [],
  speakerObs: '',
  musicalAttraction: [],
  musicalAttractionObs: '',
  content: [],
  contentObs: '',
  equipment: [],
  equipmentObs: '',
  activations: [],
  specialMoment: [],
  teamBuilding: '',
  transportValet: [],
  scenography3d: '',
  actives: '',
  gifts: '',
  decorationsObs: '',
  speakersMCs: '',
  equipmentsText: '',
  attentionPoints: [],
  extras: []
};

export const PRODUCAO_DATA = {
  decoration: null,
  decorationObs: "",
  scenography: null,
  scenographyCompany: "",
  gifts: null,
  giftsObs: "",
  activation: null,
  activationObs: "",
  physycalMaterial: null,
  directional: "",
  material: "",
  logistics: "",
  musicShow: null,
  musicShowObs: "",
  speakers: null,
  speakersObs: "",
  residual: null,
  residualObs: "",
  equipment: null,
  equipmentObs: "",
  generalObs: "",
};
export const AEB_DATA = {
  aeb: null,
  aebOptions: "",
  equipments: null,
  equipmentsObs: "",
  equipmentsQtd: "",
  admin: null,
  adminType: "",
  adminQtd: "",
  aebCheckIn: "",
  aebCheckOut: "",
  aebObs: "",
};
export const LOGISTICA_DATA = {
  specificKPI: null,
  kpiObs: "",
};
export const MEETINGS_DATA = {
  meetingsRoom: null,
  meetingsRoomObs: "",
  meetingsRoomGuestNumber: "",
  meetingsRoomsNumber: "",
  meetingsRoomCheckIn: "",
  meetingsRoomCheckOut: "",
};
export const TRANFERS_DATA = {
  diversos: null,
  diversosQtd: "",
  diversosCheckIn: "",
  diversosCheckOut: "",
  diversosObs: "",
};
export const PARKING_DATA = {
  parking: null,
  parkingValue: "",
  parkingObs: "",
};
export const WEB_CONNECTIONS = {
  webConnection: null,
  webConnectionSpeed: "",
  webConnectionObs: "",
  webConnectionQtd: "",
  webConnectionCheckIn: "",
  webConnectionCheckOut: "",
};
export const MANAGEMENT_DATA = {
  fields: "",
  data: "",
  insurance: null,
  observations: "",
};
export const RSVP_DATA: IRSVPStates = {
  id: "",
  airportChange: "",
  airportChangeOthers: "",
  parking: "",
  parkingOthers: "",
  vehicleAuthorization: "",
  vehicleAuthorizationOthers: "",
  refund: "",
  refundOthers: "",
  presence: "",
  presenceOthers: "",
  congressLogistics: "",
  congressLogisticsOthers: "",
  rsvpSystem: false,
  rsvpSystemObs: "",
  sponsorDivergence: "",
  sponsorDivergenceOthers: "",
  guestsRequiredData: "",
  defaultDocumentsOthers: "",
  memberInstitution: "",
  memberInstitutionOthers: "",
  institutionConfirmation: "",
  foodService: "",
  foodServiceOthers: "",
  defaultDocuments: false,
  aptSingle: {
    extra: "",
    obs: "",
    pension: "",
  },
  aptDouble: {
    extra: "",
    obs: "",
    pension: "",
  },
  aptTriple: {
    extra: "",
    obs: "",
    pension: "",
  },
  singleExDouble: {
    extra: "",
    obs: "",
    pension: "",
  },
  singleExTriple: {
    extra: "",
    obs: "",
    pension: "",
  },
  extraBed: {
    extra: "",
    obs: "",
    pension: "",
  },
  cradle: {
    extra: "",
    obs: "",
    pension: "",
  },
  childPolicy: {
    extra: "",
    obs: "",
    pension: "",
  },
  accomodationManagement: "",
  accomodationManagementOthers: "",
  responsibleContact: false,
  valueUpgrade: "",
  companionOption: "",
  companionOptionOthers: "",
  hasntAttended: "",
  optedForCompanion: "",
  passport: false,
  vist: false,
  travelInsuranceClient: false,
  travelInsuranceGuest: false,
  vaccinCardTripleViral: false,
  vaccinCardYellowFever: false,
  docRg: false,
  docCnh: false,
  others: "",
  passport_name: false,
  passport_number: false,
  passport_validity: false,
  vaccin_card: false,
  tag_name: false,
  birth_day: false,
  cpf: false,
  phone: false,
  landline: false,
  crm_crf_coren: false,
  specialty: false,
  institution: false,
  complete_adress: false,
  all: false,
  economic: false,
  ecnomicPremium: false,
  executive: false,
  first: false,
  paymentMethod: "",
  paymentMethodOthers: "",
  paymentMethodMaxValue: "",
  guestSolicitations: "",
  changeInAccomodation: false,
  changeInFlight: false,
  guestSolicitationPeriod: "",
  guestSolicitationOthers: "",
  changeInPlaneTickets: "",
  changeInPlaneTicketsOthers: "",
  guestSolicitationsPeriod: "",
  details: "",
  actions: "",
  rsvpDetail: "",
  shirtSize: "",
  traditionalReport: "",
  agency: false,
  hotel: false,
  responsibleData: false,
  responsibleName: false,
  responsiblePhone: false,
  responsibleEmail: false,
  docVist: false,
  rg: false,
  transferCompanion: "",
  companionValue: "",
  companionOthers: "",
  scheduleChange: "",
  scheduleChangeOthers: "",
};

export const PAGAMENTO_DATA = {
  defaultPaymentMethod: null,
  paymentMethod: "",
  observations: "",
};
