import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import "./styles/global.css";
import { Routes } from "./routers";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import { AppProvider } from "./contexts";
import "./translations/i18n";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider, createTheme } from "@mui/material";
import { theme } from "./material.theme";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ptBR } from "date-fns/locale";

// Create a client
const queryClient = new QueryClient();
function App() {
  return (
    <BrowserRouter>
      <AppProvider>
        <QueryClientProvider client={queryClient}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={ptBR}
          >
            <ThemeProvider theme={theme}>
              <Routes />
            </ThemeProvider>
          </LocalizationProvider>
        </QueryClientProvider>
        <ToastContainer />
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;
