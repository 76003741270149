import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Chip, Divider, IconButton, Tooltip } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";

import { useProject } from "../../../../../contexts/project.context";
import createBoothDtoSchema from './schema'
import { Input } from "../../../../Input";
import TabContent from "../../components/TabContent";
import BoothService from "../../../../../services/booth.service";
import { RadioGroup } from "../../../../UI/RadioGroup";
import { createOptionsFromEnum } from "../../../../../utils/formatters";
import { BoothTypeEnum } from "../../../../../types/booth/enums";
import { Switch } from "../../../../UI/Switch";
import { FaInfoCircle, FaQuestionCircle } from "react-icons/fa";
import ToolTipContent from '../../../../../assets/Content.png'
import { MdDeleteOutline } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { NotificationFields } from "../../../../../types/notification";

import Notification from "../../../../UI/Notification";


const initialBoothDto = {
  project: '',
  squareFootage: '',
  location: '',
  type: '', // Deverá ser preenchido com um valor de BoothTypeEnum
  meetingRooms: [],
  hasMeetingRooms: false,
  hasAuditorium: false,
  hasCustomBuildExpectation: false,
  hasStorage: false,
  hasProductDisplay: false,
  meetingRoomsObs: '',
  auditoriumObs: '',
  customBuildExpectationObs: '',
  storageObs: '',
  productDisplayObs: ''
};

const typeOptions = createOptionsFromEnum(BoothTypeEnum)

const Booth = () => {
  const [notification, setNotification] = useState<NotificationFields>({
    open: false,
    severity: 'success',
    message: ''
  })

  const { project } = useProject();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get("projectId");

  const [meetingRooms, setMeetingRooms] = useState([] as any[])


  const {
    handleSubmit,
    setValue,
    reset,
    watch,
    clearErrors,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: { ...initialBoothDto, project: projectId ? projectId : '' },
    resolver: yupResolver(createBoothDtoSchema),
    mode: "all",
  });

  const hasMeetingRoomsWatch = watch('hasMeetingRooms')
  const hasAuditoriumWatch = watch('hasAuditorium')
  const hasCustomBuildExpectationWatch = watch('hasCustomBuildExpectation')
  const hasStorageWatch = watch('hasStorage')
  const hasProductDisplayWatch = watch('hasProductDisplay')
  const hasId = watch('_id')



  const { data: boothData, isLoading: boothLoading } = useQuery({
    queryKey: ['booth'],
    queryFn: () => BoothService.get(project?._id || ''),
    onSuccess: (data) => {
      if (!!data){  reset(data)
        setMeetingRooms(data.meetingRooms)}
    }
  }
  );

  const { mutate: createBooth, isLoading: isLoadingCreate } = useMutation(
    (state: any) => BoothService.create(state),
    {
      onSuccess: (data) => {
        console.log(data)
        setNotification({
          open: true,
          severity: "success",
          message: "Estande salvo com sucesso!",
        });
      },
      onError: (error) => {
        setNotification({
          open: true,
          severity: "error",
          message: "Não foi possível salvar",
        });
      },
    }
  );

  const { mutate: updateBooth, isLoading: isLoadingUpdate } = useMutation(
    (state: any) => BoothService.update(state._id, state),
    {
      onSuccess: () => {
        setNotification({
          open: true,
          severity: 'success',
          message: 'Salvo com sucesso!'
        })
      },
      onError: (error) => {
        setNotification({
          open: true,
          severity: 'error',
          message: 'Não foi possível salvar'
        })
      }
    }
  );

  function closeNotification() {
    setNotification({ ...notification, open: false })
  }

  function handleDeleteMeetingRoom(index: number) {
    const newMeetingRooms = meetingRooms ? [...meetingRooms] : [];
    newMeetingRooms.splice(index, 1);
    setMeetingRooms(newMeetingRooms);
  }
  function handleMeetingRoom(value: string, index: number, field: string) {
    const newMeetingRooms = meetingRooms ? [...meetingRooms] : [];
    newMeetingRooms[index] = { ...newMeetingRooms[index], [field]: value }
    setMeetingRooms(newMeetingRooms)
  }

  function onSubmit(form: any) {
    console.log(form)
    !form._id ? createBooth({...form, meetingRooms}) : updateBooth({...form, meetingRooms})
  }
  useEffect(() => {
    if (errors) {
      console.log(errors)
      for (const [key, value] of Object.entries(errors)) {
        if (value.message) toast.error(value.message);
      }
    }
  }, [errors]);

  useEffect(()=> {
    if(!hasMeetingRoomsWatch) {
      setMeetingRooms([])
      setValue('meetingRoomsObs', '')
    }
  },[hasMeetingRoomsWatch])
  useEffect(()=> {
    if(!hasAuditoriumWatch) setValue('auditoriumObs', '')
  },[hasAuditoriumWatch])

  useEffect(()=> {
    if(!hasCustomBuildExpectationWatch) setValue('customBuildExpectationObs', '')
  },[hasCustomBuildExpectationWatch])

  useEffect(()=> {
    if(!hasStorageWatch) setValue('storageObs', '')
  },[hasStorageWatch])

  useEffect(()=> {
    if(!hasProductDisplayWatch) setValue('productDisplayObs', '')
  },[hasProductDisplayWatch])
  

  return (
    <TabContent onSubmit={handleSubmit(onSubmit)} hasId={!!hasId}>
      <Notification notification={notification} closeNotification={closeNotification} />

      <Box>
        <span style={{ fontSize: '14px', fontWeight: '500' }}>Informações do estante</span>
        <Divider />
      </Box>
      <Box>
        <Input placeholder="Digite a metragem em metros quadrados" label="Metragem" {...register("squareFootage")} sx={{ flex: 1 }} />
        <Input placeholder="Digite o número do estande" label="Localização" {...register("location")} sx={{ flex: 1 }} />
        <Box py={1} display={'flex'} alignItems={'flex-start'}>
          <RadioGroup.Root
            label={'Tipo'}
            value={watch("type")}
            onChange={(e) => setValue("type", e.target.value)}
          >
            {typeOptions.map(
              (
                { value, label }: any,
                index: React.Key | null | undefined
              ) => (
                <RadioGroup.Item key={Math.random()} label={label} value={value} />
              )
            )}
          </RadioGroup.Root>
          <Tooltip
            style={{ marginLeft: '-100px', marginTop: '-9px' }}
            placement="right-start"
            title={
              <img src={ToolTipContent} />
            }
          >
            <IconButton>
              <FaQuestionCircle size="16" style={{ cursor: 'pointer' }} color="#98A2B3" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box>
        <span style={{ fontSize: '13px' }}>Necessidades estruturais</span>
        <Divider />
      </Box>
      <Box gap={4}>
        <Box marginTop={0.5}>
          <Switch
            label="Sala de reuniões"
            valueLabel={hasMeetingRoomsWatch ? "Sim" : "Não"}
            {...register("hasMeetingRooms")}
            checked={hasMeetingRoomsWatch}
          />
        </Box>

        <Input multiline placeholder="Digite o número"
          disabled={!hasMeetingRoomsWatch}
          label="Salas"
          {...register("meetingRoomsObs")}
          sx={{ flex: 1 }}
        />
      </Box>


      {meetingRooms.map((meetingRoom, index) => (
        <Fragment key={index}>

          <Box gridTemplateColumns={'1fr 70%'}>
            <Input placeholder="Previsto" value={meetingRoom.pax} onChange={(e) => handleMeetingRoom(e.target.value, index, 'pax')} label={`Sala ${index + 1} - Pax`} fullWidth={false} />
            <Input placeholder="Caso tenha alguma observação importante sobre formato ou necessidades. " value={meetingRoom.observation} onChange={(e) => handleMeetingRoom(e.target.value, index, 'observation')} label={`Necessidades da sala ${index + 1}`} />
          </Box>

          {index > -1 && (
            <Box display={"flex"} alignItems={"center"} gap={1}>
              <Divider sx={{ flex: 1 }} />
              <Button
                variant="text"
                startIcon={<MdDeleteOutline />}
                color="error"
                onClick={() => handleDeleteMeetingRoom(index)}
                sx={{ width: 150 }}
              >
                Remover Sala {index + 1}
              </Button>
            </Box>
          )}
        </Fragment>
      ))}

      <Box>
        <Button
          disabled={!hasMeetingRoomsWatch}
          variant="text"
          onClick={() => setMeetingRooms([...meetingRooms, { pax: '', observation: '' }])}
        >
          + Adicionar Sala
        </Button>
      </Box>



      <Box gap={4}>
        <Box marginTop={0.5}>
          <Switch
            label="Auditório"
            valueLabel={hasAuditoriumWatch ? "Sim" : "Não"}
            {...register("hasAuditorium")}
            checked={hasAuditoriumWatch}
          />
        </Box>

        <Input placeholder="Descrever. Exemplo: Auditório será fechado (estilo sala de aula), ou aberto, sem paredes (com ou sem uso de fones de ouvido – palestra silenciosa)? Fale um pouco sobre que tipo de conteúdo será passado neste ambiente."
          multiline
          minRows={3}
          disabled={!hasAuditoriumWatch}
          label=""
          {...register("auditoriumObs")}
          sx={{ flex: 1 }}
        />
      </Box>
      <Box gap={4}>
        <Box marginTop={0.5}>
          <Switch
            label="Expectativa de construcao sob medida"
            valueLabel={hasCustomBuildExpectationWatch ? "Sim" : "Não"}
            {...register("hasCustomBuildExpectation")}
            checked={hasCustomBuildExpectationWatch}
          />
        </Box>

        <Input multiline placeholder="Se nao marcado, a expectativa e básica e inclusa na cota de patrocínio, caso marcado descrever necessidades do cliente"
          disabled={!hasCustomBuildExpectationWatch}
          label=""
          {...register("customBuildExpectationObs")}
          sx={{ flex: 1 }}
        />
      </Box>
      <Box gap={4}>
        <Box marginTop={0.5}>
          <Switch
            label="Deposito"
            valueLabel={hasStorageWatch ? "Sim" : "Não"}
            {...register("hasStorage")}
            checked={hasStorageWatch}
          />
        </Box>

        <Input multiline placeholder="xxxx"
          disabled={!hasStorageWatch}
          label=""
          {...register("storageObs")}
          sx={{ flex: 1 }}
        />
      </Box>
      <Box gap={4}>
        <Box marginTop={0.5}>
          <Switch
            label="Exposição de produtos"
            valueLabel={hasProductDisplayWatch ? "Sim" : "Não"}
            {...register("hasProductDisplay")}
            checked={hasProductDisplayWatch}
          />
        </Box>

        <Input multiline placeholder="xxxx"
          disabled={!hasProductDisplayWatch}
          label=""
          {...register("productDisplayObs")}
          sx={{ flex: 1 }}
        />
      </Box>
    </TabContent>
  );
};

export default Booth;
