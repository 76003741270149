import { RsvpType } from "../types/rsvp/index";
import { api } from "./apiClient";

export default class RsvpService {
  static async create(dto: any): Promise<RsvpType> {
    return api.post("/novo-rsvp", dto).then((response) => response.data);
  }

  static async update(id: string, dto: any): Promise<RsvpType> {
    return api.put(`/novo-rsvp/${id}`, dto).then((response) => response.data);
  }


  static async get(projectId: string): Promise<RsvpType> {
    return api.get(`/novo-rsvp/${projectId}`).then((response) => response.data);
  }


}
