import { AutocompleteInputChangeReason, Box, TextField } from "@mui/material";
import React, { SyntheticEvent, useState } from "react";
import { LabeledAutoComplete } from "../../UI/LabeledAutoComplete";

type PropTypes = {
  onSelectServiceType(value: string, field: string): void;
  previousSelectedServiceType: string;
  serviceOptions: { text: string; value: string }[];
};

const GenericServiceType: React.FC<PropTypes> = ({
  onSelectServiceType,
  previousSelectedServiceType,
  serviceOptions,
}: PropTypes) => {
  const [inputServiceValue, setInputServiceValue] = useState(
    serviceOptions.find(
      (option) => option.value === previousSelectedServiceType
    )?.text || ""
  );
  const [serviceValue, setServiceValue] = useState(previousSelectedServiceType);

  function serviceChangeHandler(
    e: SyntheticEvent<Element, Event>,
    newValue: any | null
  ) {
    setServiceValue(newValue);
    onSelectServiceType(newValue?.value || "", "service_type");
  }
  function serviceInputChangeHandler(
    e: SyntheticEvent<Element, Event>,
    newValue: string,
    reason: AutocompleteInputChangeReason
  ) {
    if (newValue === "" && reason === "reset") return;
    setInputServiceValue(newValue);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <LabeledAutoComplete
        options={serviceOptions}
        getOptionLabel={(option) => option.text}
        size="small"
        inputValue={inputServiceValue}
        onInputChange={serviceInputChangeHandler}
        onChange={serviceChangeHandler}
        label={`Tipo de serviço`}
        renderInput={(params) => (
          <TextField
            placeholder="Clique para selecionar"
            {...params}
            fullWidth
          />
        )}
      />
    </Box>
  );
};

export default GenericServiceType;
