export const SEGMENT_OPTIONS = [
  '3º SETOR',
  'ALIMENTOS E BEBIDAS',
  'ATACADISTA',
  'AUTOMOBILÍSTICA',
  'ÁGUA',
  'ÁREAS DA SAÚDE',
  'CONSULTORIA',
  'COSMÉTICO',
  'EDUCAÇÃO',
  'ENERGIA',
  'ENTRETENIMENTO',
  'FARMACÊUTICA',
  'FINANCEIRO',
  'JÓIAS, PRESENTES',
  'ÓLEO',
  'SAÚDE ANIMAL',
  'SEGURANÇA DO TRABALHO',
  'SEGUROS',
  'TECNOLOGIA',
  'VAREJO',
];
