import { StyleSheet } from "@react-pdf/renderer";
export const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    height: "100%",
    padding: "15px",
    fontSize: "12px",
  },
  exportHeader: {
    display: "flex",
    margin: "15px auto",
    fontSize: 32,
    borderBottom: "1px solid #eee",
  },
  view: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    margin: "0 auto",
  },
  double: {
    width: "26%",

    alignItems: "center",
  },
  header2: {
    fontSize: 20,
  },
  viewer: {
    width: window.innerWidth - 36, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  enumDiv: {
    width: "5%",
    alignItems: "center",
  },

  clientDataDiv: {
    width: "40%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    fontSize: 16,
    border: "1px solid #bbb",
    padding: 5,
    margin: 15,
  },
  budgetDataDiv: {
    width: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    fontSize: 16,
    border: "1px solid #bbb",
    padding: 5,
    margin: 15,
  },
  mainDataDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  mainDataDivBorders: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  textDiv1: {
    width: 300,
    padding: "0 10px",
    backgroundColor: "#82d29a",
  },
  textDiv2: {
    width: 300,
    padding: "0 10px",
    backgroundColor: "#76b6ef",
  },
  textDiv3: {
    width: 300,
    padding: "0 10px",
    backgroundColor: "#f1cf71",
  },
  textDiv: {
    width: 300,
    padding: "0 10px",
  },
  table: {
    margin: 10,
    width: "100%",
  },
  row: {
    marginTop: 15,
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#DDD",
    padding: 8,
    width: "100%",
    justifyContent: "flex-start",
  },
  serviceTypeDiv: {
    backgroundColor: "#B3B3B3",
    width: "5%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #B3B3B3",
  },
  rowTitles: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-start",
    fontSize: "14px",
    backgroundColor: "blue",
  },
  rowNormal: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-start",
    fontSize: "10px",
    color: "#373737",
    backgroundColor: "#EEE",
    borderBottom: "1px solid #ddd",
    padding: "2px 0",
    height: "30px",
  },
  header: {
    borderTop: "none",
    margin: "0 auto",
    fontSize: 18,
  },
  bold: {
    fontWeight: "bold",
  },
  row1: {
    width: "50%",
  },
  rowTotal: {
    width: "20%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    borderLeft: "1px solid #bbb",
    backgroundColor: "#ddd",
    padding: "2px 0",
  },
  providerDiv: {
    width: "15%",
    alignItems: "center",
  },
  typeDiv: {
    width: "15%",
    alignItems: "center",
  },
  obsDiv: {
    width: "15%",
    alignItems: "center",
  },
  date: {
    width: "15%",
  },
  small: {
    width: "8%",
  },
  smallBorder: {
    borderRight: "1px solid #BBB",
    width: "8%",
  },
  tariff: {
    width: "15%",
  },
  total: {
    width: "15%",
  },
  orcamentosDiv: {
    width: "20%",
  },
  servicesDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "70%",
    margin: 0,
  },
  singleServiceDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "70%",
  },
  text: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    margin: "auto",
  },
});
