import { Box, Typography } from "@mui/material";
import { RxCaretDown, RxCaretRight } from 'react-icons/rx'
import { Status } from "./styles";
import { BiRadioCircle } from "react-icons/bi";
import React, { useContext, useState } from "react";
import { ProjectContext } from "../../../contexts/project";
import { useHistory } from "react-router-dom";
interface IItemProps {
  icon: any;
  title: string;
  isActive: boolean;
  submenus: any[]
  route?: string
  onClick(target: string): void;
}

export function Item(props: IItemProps) {
  const history = useHistory();
  function handleClick(e: React.MouseEvent) {
    props.onClick(props.title)
    if(props.submenus.length < 1 && props.route) history.push(props.route)
  }
  const activeStyle = {
    color: '#FFF',
    backgroundColor: "#1361A4",
    borderRadius: '4px'
  }
  return (
    <Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        py={1}
        my={1}
        sx={(props.isActive && props.submenus.length < 1 )? activeStyle : { cursor: "pointer", backgroundColor: "rgba(75, 70, 92, 0.04)", borderRadius: '4px',  }}
        onClick={handleClick}
        
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          width={24}
          mr={1}
        >
          {props.icon}
        </Box>

        <Typography flex={1}>{props.title}</Typography>

        <Status>
          {(props.isActive && props.submenus.length > 0)? (
            <RxCaretDown size="22" />
          ) : (
            <RxCaretRight size="22" />
          )}
        </Status>
      </Box>
      <Box flexDirection={"column"} sx={{cursor:'pointer'}}>
        {(props.isActive && props.submenus.length > 0) && props.submenus.map((submenu, index) => <Box 
          key={index}
          padding={1}
          my={1} 
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          style={

            window.location.pathname.includes(submenu.route.slice(0,-1)) ? activeStyle : {}}
          onClick={() => {
            history.push(submenu.route)
          }}>
          <Box>
          <BiRadioCircle size={10}/> &nbsp; {submenu.title}
          </Box>
          <RxCaretRight size="22" />
          
          </Box>)}
      </Box>
    </Box>
  );
}
