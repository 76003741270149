import { Button } from "@mui/material";

type Props = {
  isDisabled: boolean
}
export function SaveButton ({isDisabled}: Props) {
  return   <Button
  disabled={isDisabled}
  variant="contained"
  
  style={{ marginLeft: "85%", width: '120px' }}
  type="submit"
>
  Salvar
</Button>
}