import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, FormControlLabel, FormGroup, FormLabel } from "@mui/material"
import { Switch } from "../../../UI/Switch"
import React, { useEffect, useState } from "react"
import { StyledH2Before } from "./styles"
import { FaCaretDown, FaSave, FaTrashAlt } from "react-icons/fa"
import { AirportSearch } from "../../../Aereo/AirportSearch"
import { Input } from "../../../Input"
import { createOptionsFromEnum } from "../../../../utils/formatters"
import { FlightClass } from "../../../../types/aerial/enums"
import { add, format } from "date-fns"


type AirportSearchNames = 'outwardAirport' | 'returnAirport' | 'airportConnections'

type PropTypes = {
  title: string
  data: any
  handleDeleteService(id: number): void
  handleSaveService(form: any, index: number): void
  index: number
  expanded: boolean
  onChange(index: number, state: boolean): void
}

const AerialServiceItem: React.FC<PropTypes> = ({ title, data, handleDeleteService, handleSaveService, index, expanded, onChange }: PropTypes) => {
  const [state, setState] = useState(
    {
      outwardQuantity: data?.outwardQuantity ? data.outwardQuantity : 0,
      returnQuantity: data?.returnQuantity ? data.returnQuantity : 0,
      outwardDate: data?.outwardDate ? format(add(new Date(data.outwardDate), { hours: 4 }), 'yyyy-MM-dd') : '',
      returnDate: data?.returnDate ? format(add(new Date(data.returnDate), { hours: 4 }), 'yyyy-MM-dd') : '',
      aerialMesh: data?.aerialMesh ? data.aerialMesh : false,
      outwardAirport: data?.outwardAirport ? data.outwardAirport : '',
      returnAirport: data?.returnAirport ? data.returnAirport : '',
      airportConnections: data?.airportConnections ? data.airportConnections : '',
      airportPreference: data?.airportPreference ? data.airportPreference : false,
      flightClass: data?.flightClass ? data.flightClass : [] as string[],
      insurance: data?.insurance ? data.insurance : false,
    }
  )

  useEffect(()=> {
    setState({
      outwardQuantity: data?.outwardQuantity ? data.outwardQuantity : 0,
      returnQuantity: data?.returnQuantity ? data.returnQuantity : 0,
      outwardDate: data?.outwardDate ? format(add(new Date(data.outwardDate), { hours: 4 }), 'yyyy-MM-dd') : '',
      returnDate: data?.returnDate ? format(add(new Date(data.returnDate), { hours: 4 }), 'yyyy-MM-dd') : '',
      aerialMesh: data?.aerialMesh ? data.aerialMesh : false,
      outwardAirport: data?.outwardAirport ? data.outwardAirport : '',
      returnAirport: data?.returnAirport ? data.returnAirport : '',
      airportConnections: data?.airportConnections ? data.airportConnections : '',
      airportPreference: data?.airportPreference ? data.airportPreference : false,
      flightClass: data?.flightClass ? data.flightClass : [] as string[],
      insurance: data?.insurance ? data.insurance : false,
    })
  }, [data])
  const flightClassOptions = createOptionsFromEnum(FlightClass)
  function addAirport(airport: any, fieldName: AirportSearchNames) {
    const e = {
      target: {
        name: fieldName,
        value: airport
      }
    } as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    handleChange(e)
  }
  function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) {
    if (['insurance', 'airportPreference', 'aerialMesh'].includes(e.target.name)) {
      setState({ ...state, [e.target.name]: e.target.checked })
    } else setState({ ...state, [e.target.name]: e.target.value })

  }
  const handleSelect = (value: string) => {
    const isPresent = state.flightClass.indexOf(value);
    if (isPresent !== -1) {
      const remaining = state.flightClass.filter((item: any) => item !== value);
      setState({ ...state, flightClass: remaining });
    } else {
      setState({ ...state, flightClass: [...state.flightClass, value] });

    }
  };

  function addAirportConnection() {
    setState({ ...state, airportConnections: [...state.airportConnections, ''] })
  }

  function addConnection(airport: any, index: number) {
    const newConnections = state.airportConnections
    newConnections[index] = airport
    setState({ ...state, airportConnections: newConnections })
  }
  function removeConnection(connectionIndex: number) {
    setState({ ...state, airportConnections: state.airportConnections.filter((_: any, index: number) => index !== connectionIndex) })
  }
  // useEffect(() => {
  //   if (!data._id) {
  //     return
  //   }
  //   setState()
  // }, [data])

  useEffect(() => {
    handleSaveService(state, index)
  }, [state])
  return <Accordion
    style={{
      width: '100%',
      margin: '5px'
    }}
    expanded={expanded}
    TransitionProps={{ unmountOnExit: true }}
    onChange={(_, expanded) => onChange(index,expanded)}

  >

    <AccordionSummary
      aria-controls="panel1a-content"
      expandIcon={<FaCaretDown />}
      id="panel1a-header"
      style={{ maxHeight: "40px", display: "flex", alignItems: 'center' }}
    >
      <span>{title}</span> <hr style={{ border: '0', height: '1px', background: '#333' }} />
    </AccordionSummary>

    <AccordionDetails style={{ width: "96%", margin: 'auto' }}>
      <Box>
        <Box display={"flex"} gap={2}>
          <Input value={state.outwardQuantity} onChange={handleChange} name="outwardQuantity" label={title + ' - Quantidade ida *'}  type="number" />
          <Input value={state.returnQuantity} onChange={handleChange} name="returnQuantity" label={title + ' - Quantidade volta *'} type="number" />
        </Box>
        <Box display={"flex"} gap={2} >
          <Input value={state.outwardDate} onChange={handleChange} name="outwardDate" label={title + ' - Data ida *'} type="date" />
          <Input value={state.returnDate} onChange={handleChange} name="returnDate" label={title + ' - Data volta *'} type="date" />
        </Box>
        <Box display={"flex"} gap={2} >
          <AirportSearch
            onSelectAirport={addAirport}
            apiData={state.outwardAirport}
            name="outwardAirport"
            label="Aeroporto de Origem *"
          />
          <AirportSearch
            onSelectAirport={addAirport}
            apiData={state.returnAirport}
            name="returnAirport"
            label="Aeroporto de Destino *"
          />
        </Box>
        <Box display={"flex"} gap={3} >
          <Switch
            valueLabel="Ponte Aérea"
            label=""
            name="aerialMesh"
            checked={state.aerialMesh}
            onChange={handleChange}
          />
          <Switch
            valueLabel="Preferencia de Aeroportos"
            label=""
            onChange={handleChange}
            checked={state.airportPreference}
            name="airportPreference"
          />
          <Switch
            valueLabel="Seguro"
            label=""
            onChange={handleChange}
            name="insurance"
            checked={state.insurance}
          />

        </Box>
        {state.aerialMesh && <>
          <Box display={'flex'} gap={2} mt={1}>
            {state.airportConnections.map((el: any, index: number) => {
              return <Box display={'flex'} gap={1} alignItems={'flex-end'} width={"100%"} key={index}>
                <AirportSearch
                  onSelectAirport={addConnection}
                  apiData={el}
                  name={`${index}`}
                  label="Aeroporto de Conexão"
                />
                <Box mb={1} >
                  <FaTrashAlt onClick={() => removeConnection(index)} />
                </Box>
              </Box>
            })}
          </Box>
          <Box margin={1}>
            <span style={{
              color: '#015EFF',
              cursor: 'pointer',
              padding: '5px'
            }} onClick={() => addAirportConnection()} >  Adicionar Parada </span>
          </Box>
        </>}

        <FormGroup>
          <FormLabel>Categoria de Vôo</FormLabel>
          {flightClassOptions.map((option: any) => {
            return <FormControlLabel
              label={option.label}
              control={
                <Checkbox
                  checked={state.flightClass.includes(option.value)}
                  onChange={() => handleSelect(option.value)}
                />
              }
            />
          })}
        </FormGroup>


        <StyledH2Before>
          <span onClick={() => handleDeleteService(index)} >  <FaTrashAlt size={14} color={'#FF6262'} /> Apagar {title}</span>
        </StyledH2Before>

      </Box>
    </AccordionDetails>
  </Accordion>
}

export { AerialServiceItem }