import { Box, Typography } from "@mui/material";

interface Props {
  title: string;
  description?: string;
}

export function VerticalTabContent({ title, description }: Props) {
  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Typography variant="body2" fontWeight={500} color={"#4B465C"}>
        {title}
      </Typography>

      <Typography variant="caption" color={"#A1A1A1"}>
        {description}
      </Typography>
    </Box>
  );
}
