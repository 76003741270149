import { Box } from "@mui/material";
import ActionSelect from "../ActionSelect";
import { useState } from "react";
import EditRequesterModal from "../../EditRequesterModal";

type Props = {
  requesterData: {
    _id?: string;
    name: string;
    email: string;
    phone: string;
    client: string;
  };
};
const RequesterSummary: React.FC<Props> = ({ requesterData }: Props) => {
  const [showEditModal, setShowEditModal] = useState(false);
  function triggerAction(action: string) {
    if (action === "edit") {
      setShowEditModal(true);
    }
  }

  return (
    <Box m={1}>
      <EditRequesterModal
        isOpen={showEditModal}
        onClose={() => setShowEditModal(false)}
        requesterData={requesterData}
      />
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        borderBottom={1}
        padding={1}
      >
        <Box sx={{ cursor: "pointer", display: "flex" }}>
          <Box flexDirection={"column"}>
            <span> {requesterData.name} </span> <br />
            <span style={{ color: "#667085", fontSize: "15px" }}>
              {requesterData.email}
            </span>
            <br />
            <span style={{ color: "#667085", fontSize: "15px" }}>
              {" "}
              {requesterData.phone}{" "}
            </span>
          </Box>
        </Box>
        <ActionSelect triggerAction={triggerAction} />
      </Box>
    </Box>
  );
};

export default RequesterSummary;
