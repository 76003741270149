import { Box, Card, Typography } from "@mui/material";
import { OptionItem } from "./OptionItem";
import { useState } from "react";
import {
  PiUserThin,
  PiUsersThreeThin,
  PiUserGearThin,
  PiUsersFourThin,
  PiCardholderThin,
} from "react-icons/pi";
import Users from "../../components/templates/AcessControl/Users";
import Cells from "../../components/templates/AcessControl/Cells";
import Roles from "../../components/templates/AcessControl/Roles";
import Profile from "../../components/templates/AcessControl/Profile";
import { useHistory } from "react-router-dom";

export function AccessControl() {
  const history = useHistory();
  const [optionActive, setOptionActive] = useState(0);

  function handleChangeActiveScreen(destination: string) {
    switch (destination) {
      case "users":
        setOptionActive(3);
        break;
      case "projects":
        history.push("/briefings");
        break;
    }
  }

  const options = [
  
    { title: "Perfil", icon: <PiUserThin />, template: <Profile/> },
    { title: "Células", icon: <PiUsersThreeThin />, template: <Cells goTo={handleChangeActiveScreen}/> },
    {
      title: "Funções",
      icon: <PiUsersFourThin />,
      template: <Roles />,
    },
    {
      title: "Usuários do sistema",
      icon: <PiUserGearThin />,
      template: <Users />,
    },
  ];
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      flex={1}
      py={4}
      px={3}
      bgcolor={"#F9FCFF"}
    >
      <Box display={"flex"}>
        <Typography variant={"h5"}>Controle de Acesso</Typography>
      </Box>

      <Card
        sx={{
          display: "flex",
          mt: 4,
          boxShadow: "0px 4px 18px 0px #4B465C1A",
          overflow: "auto",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={1.5}
          px={"24px"}
          pt={"24px"}
          pb={"44px"}
          borderRight={"1px solid #DBDADE"}
        >
          {options.map((option, index) => (
            <OptionItem
              key={index}
              title={option.title}
              icon={option.icon}
              onClick={() => setOptionActive(index)}
              // lastUpdate={"última atualização 10/11 23h10"}
              isActive={optionActive === index}
            />
          ))}
        </Box>

        {options[optionActive] && options[optionActive].template}
      </Card>
    </Box>
  );
}
