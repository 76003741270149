import { Box } from "@mui/material";
import React from "react";

export const VerticalTabsRoot = ({ children, noBorder }: any) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={1.5}
      px={"24px"}
      pt={"24px"}
      pb={"44px"}
      borderRight={noBorder ? '' : "1px solid #DBDADE"}
    >
      {children}
    </Box>
  );
};
