import { Project } from "../types";
import {
  CreateProjectFinancialSummaryDto,
  GetProjectFinancialSummaryDto,
} from "../types/projectSummary.type";
import { api } from "./apiClient";

export default class ProjectSummaryService {
  static async create(dto: CreateProjectFinancialSummaryDto): Promise<Project> {
    return api
      .post(`/project-financial-summary`, dto)
      .then((response) => response.data);
  }

  static async update(
    id: string,
    dto: CreateProjectFinancialSummaryDto
  ): Promise<Project> {
    return api
      .put(`/project-financial-summary/${id}`, dto)
      .then((response) => response.data);
  }

  static async get(params: GetProjectFinancialSummaryDto) {
    return api
      .get(`/project-financial-summary`, { params })
      .then((response) => response.data);
  }

  static async getById(id: string) {
    return api
      .get(`/project-financial-summary/${id}`)
      .then((response) => response.data);
  }
}
