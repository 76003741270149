export const BriefingStatusMap: { [key: string]: string } = {
  BUDGET: "Orçamento",
  ON_APROVAL: "Em Aprovação",
  APPROVED: "Aprovado",
  APPROVED_AND_CANCELED: "Aprovado/Cancelado",
  CANCELED: "Cancelado",
  LOST: "Perdido",
  DUPLICATE: "Duplicidade",
  FINISHED: "Finalizado",
  PRE_CLOSING: "Pré-fechamento",
  ON_CHECK: "Em Conferência",
  WAITING_CLIENT: "Aguardando Cliente",
  FINANCIAL: "Financeiro",
  LEGAL_REPORTING: "Prestação de Contas",
  BILLED: "Faturado",
  AUDITED: "Auditado",
  CANCELED_AND_CHARGED: "Cancelado/Cobrado",
  CANCELED_AND_AUDITED: "Cancelado/Auditado",
};
