import React from 'react'
import { Container } from './styles';
import { toast } from 'react-toastify';

interface DragAndDropProps {
  onUpload(files: File[]): void
  children: React.ReactElement | React.ReactElement[];
  count?: number
  formats?: string[]
}

export default function DragAndDrop({
  onUpload,
  children,
  count,
  formats
}: DragAndDropProps) {

  const drop = React.useRef<null | HTMLDivElement>(null)
  const input = React.useRef<null | HTMLInputElement>(null)
  React.useEffect(() => {
    if (drop.current) {
      drop.current.addEventListener('dragover', handleDragOver)
      drop.current.addEventListener('drop', handleDrop)
    }
    return () => {
      if (drop.current) {
        drop.current.removeEventListener('dragover', handleDragOver)
        drop.current.removeEventListener('drop', handleDrop)
      }
    }
  }, [])
  const handleDragOver = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    const files = Array.from(e.dataTransfer?.files || [])
    if (validateFiles(files)) onUpload(files)


  }
  const handleSelectFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
 

    const files = Array.from(e.target.files || [])
   
    if(validateFiles(files)) onUpload(files)
   
  };

  function validateFiles(files: File[]) {
    if (!files) return false
    if (count && count < files.length) {
        toast.warn(`O limite de arquivos por upload é ${count}`)
        return false
      }
      if (formats
        && files.some((file) => !formats.some(
          (format) => file.
            name.
            toLowerCase()
            .endsWith(format.toLowerCase())))) {
              toast.warn(`Formato não permitido, use um listado a seguir: ${formats}`)
              return false
            
      }
      return true
  }
  return <Container ref={drop} onClick={()=> {input.current?.click()}} >
    {children}
    <input
          ref={input}
          type='file'
          style={{display: 'none'}}
          accept={formats ? formats.map((format) => `.${format}`).join(', ') : undefined}
          multiple={!count || count > 1}
          onChange={handleSelectFiles}
        />
  </Container>
}