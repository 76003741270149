export const forceDownload = (
  url: string,
  fileName: string,
  defaultExtension?: string
) => {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.onload = function () {
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL(this.response);
    const tag = document.createElement("a");
    tag.href = imageUrl;

    const fileType = xhr?.response?.type;
    const extension = fileType?.split("/");

    tag.download = `${fileName}.${defaultExtension || extension[1] || ""}`;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  };
  xhr.send();
};
