export const projectTypes = [
  "A&B",
  "Ações de Endomarketing",
  "Ativações",
  "Brindes",
  "Campanha de Incentivo",
  "Construção de estande",
  "Convenção",
  "Criação de Arte",
  "Evento Exclusivo",
  "Fee",
  "Festa",
  "Grupo Congresso",
  "Lançamento",
  "Mini Meeting",
  "Patrocínio",
  "Peças gráficas",
  "Premiação",
  "Reunião",
  "Serviços estande",
  "Simpósio",
  "Treinamento extemo",
  "Treinamento interno",
  "Viagem de Incentivo",
];
interface Obj {
  [key: string]: any
}
export const projectTypeDescription: Obj = {
  "A&B": 'Contratação de A & B(somente).',
  "Ações de Endomarketing": 'Ações para público intemo.Ex: Dias das mães, Páscoa, presentes, welcome kit',
  "Ativações": 'Ações para público externo para ativação de marca.Ex: Contratação de carrinho gourmet personalizado com marca.',
  "Brindes": "Produção ou compra de de brindes que não estão atreladosa ação do endomarketing. (troféus, voucher's, etc.).",
  "Campanha de Incentivo": 'Campanha desenvolvida pela MM com durabilidade e metas a serem atingidas.',
  "Construção de estande": 'Quando a MM é responsável pela construção do estande',
  "Convenção": 'Convenção de Vendas ou Kick Off.',
  "Criação de Arte": 'Quando envolver somente criação.',
  "Evento Exclusivo": 'Reunião acima de 51 convidados',
  "Fee": 'Cobrança de FEE mensal e anual.',
  "Festa": 'Festa de abertura, encerramento, happy hour, final de ano.',
  "Grupo Congresso": 'Considerado congresso acima de 10 pessoas.',
  "Lançamento": 'Lançamento de produto, carro, de marca e inauguração',
  "Mini Meeting": 'Jantar ou roadshow com contratação de espaço privativo, A & B, equipamentos.',
  "Patrocínio": 'Patrocínio até 09 pessoas.',
  "Peças gráficas": 'Peças gráficas personalizadas para evento.',
  "Premiação": 'Premiações em geral.',
  "Reunião": 'Reunião até 50 convidados',
  "Serviços estande": 'Quando temos apenas contratações adicionais como taxas obrigatórias, A & B, segurança e limpeza',
  "Simpósio": 'Apoio no simpósio para o congresso com lunch box, equipamentos, intemet, tradução, etc.',
  "Treinamento extemo": 'Treinamento para público extemo.Ex: Treinamento médico, hands - on.',
  "Treinamento interno": 'Treinamento para público interno.Ex: Treinamento de líderes, curso de novos, força de vendas',
  "Viagem de Incentivo": 'Organização de viagem de incentivo.',
}
 
export const eventTypes = ["Presencial", "Híbrido", "Virtual"];


export const clientSegment = [
  "FARMACEUTICA",
  "AUTOMOBILISTICA",
  "TECNOLOGIA",
  "ÁREAS DA SAÚDE",
  "FINANCEIRO",
  "ALIMENTOS E BEBIDAS",
  "SEGUROS",
  "EDUCAÇÃO",
  "3º SETOR",
  "JÓIAS,PRESENTES",
  "ENERGIA",
  "COSMÉTICO",
  "SAÚDE ANIMAL",
  "SEGURANÇA DO TRABALHO",
  "ÁGUA",
  "VAREJO",
  "ATACADISTA",
  "CONSULTORIA",
  "ENTRETENIMENTO",
  "QUÍMICO E PETROQUÍMICO",
  'AGRONEGÓCIOS',
  'INDÚSTRIA'
]