import { Box, Card, IconButton, Tab, Tabs, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import BudgetTab from "../../components/Orcamento";
import { BriefingDetailsTab } from "../../components/templates/Briefing/BriefingDetails/BriefingDetailsTab";
import { ProjectContext } from "../../contexts/project";
import { MdEdit } from "react-icons/md";
import { StatusModal } from "../../components/StatusModal";
import BriefingService from "../../services/briefing.service";
import { BriefingStatusMap } from "../../types/briefing.type";
import { AlertModal } from "../../components/AlertModal";

// const mainTabs = [
//   { index: 0, label: "Briefing", template: BriefingDetailsTab },
//   { index: 1, label: "Orçamento", template: BudgetTab },
//   // { index: 2, label: "Negociação", template: Trading },
//   // { index: 3, label: "Fechamento", template: Closure },
//   // { index: 4, label: "Auditoria", template: Auditoria },
// ];

const BriefingDetail = () => {
  const { setProjectID, briefing, mmCode, projectId } =
    useContext(ProjectContext);

  const { params } = useRouteMatch<{ id?: string }>();
  const [showStatusModal, setShowStatusModal] = React.useState(false);
  const [showAlertModal, setShowAlertModal] = React.useState(false);
  const [status, setStatus] = React.useState<any>();
  const [tabActive, setTabActive] = React.useState(0);
  const [pendingTab, setPendingTab] = React.useState(-1);
  // const Template = mainTabs[tabActive].template;

  const briefingId = params.id;

  useEffect(() => {
    if (briefingId) {
      setProjectID(briefingId);
      loadStatus(briefingId);
    } else {
      setProjectID("");
    }
  }, [briefingId]);

  useEffect(() => {
    if (pendingTab >= 0) {
      if (localStorage.getItem("hideAlert") !== "0") {
        setShowAlertModal(true);
      } else {
        setTabActive(pendingTab);
        setPendingTab(-1);
      }
    }
  }, [pendingTab]);

  function loadStatus(id: string) {
    BriefingService.getBriefingStatus(id).then((status) => {
      setStatus(status);
    });
  }

  function handleCloseStatusModal(type: string) {
    setShowStatusModal(false);

    if (type === "addStatus") {
      loadStatus(String(params.id));
    }
  }

  function changeTab(status: boolean) {
    if (status) setTabActive(pendingTab);

    setShowAlertModal(false);
    setPendingTab(-1);
  }

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      flex={1}
      py={4}
      px={3}
      bgcolor={"#F9FCFF"}
    >
      {showStatusModal && (
        <StatusModal
          isOpen
          handleClose={handleCloseStatusModal}
          target={"statusModal"}
          status={status}
          briefingId={briefingId || projectId}
        />
      )}

      <AlertModal
        handleClose={() => changeTab(false)}
        isOpen={showAlertModal}
        makeDecision={changeTab}
      />

      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box>
          <Typography variant={"h5"} color={"black"}>
            {briefing.eventName}
          </Typography>

          <Typography variant={"h6"} color={"black"}>
            MM-{mmCode}
          </Typography>
        </Box>

        <Box display={"flex"} alignItems={"center"}>
          <Typography
            variant={"body1"}
            color={"#BBA030"}
            style={{
              background: "rgba(187, 160, 48, 0.1)",
              padding: "3px",
              borderRadius: "4px",
            }}
          >
            Status: {status?.type && BriefingStatusMap[status.type]}
          </Typography>

          <IconButton onClick={() => setShowStatusModal(true)}>
            <MdEdit size={20} />
          </IconButton>
        </Box>
      </Box>

      <Card
        sx={{
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          mt: 4,
          boxShadow: "0px 4px 18px 0px #4B465C1A",
          overflow: "auto",
        }}
      >
        {/* <Tabs
          value={tabActive}
          onChange={(e, index) => setPendingTab(index)}
          indicatorColor="primary"
          sx={{ textTransform: "none" }}
        >
          {mainTabs.map((tab) => (
            <Tab
              key={tab.index}
              label={tab.label}
              sx={{ textTransform: "none" }}
            />
          ))}
        </Tabs>

        <Box mt={4}>{projectId ? <Template /> : <Template />}</Box> */}

        <BriefingDetailsTab />
      </Card>
    </Box>
  );
};

export default BriefingDetail;
