import { Avatar, Card, IconButton, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { MdOutlineLogout } from "react-icons/md";
import { useLocation } from "react-router-dom";

import logoMMImg from "../../assets/logo_MM.png";
import aclIcon from "../../assets/styled-icons/sidebar/acesscontrol.svg";
import colapseIcon from "../../assets/styled-icons/sidebar/colapse.png";
import customerIcon from "../../assets/styled-icons/sidebar/customer.svg";
import projectIcon from "../../assets/styled-icons/sidebar/project.svg";
import reportIcon from "../../assets/styled-icons/sidebar/reports.svg";
import showIcon from "../../assets/styled-icons/sidebar/show.png";
import dashIcon from "../../assets/styled-icons/sidebar/smart-home.svg";
import { AuthContext } from "../../contexts/auth";
import { RoleUserMap } from "../../types/user.type";
import { stringAvatar } from "../../utils/string.utils";
import { Item } from "./Item";
import {
  Container,
  Footer,
  Header,
  LogoDiv,
  MainContent,
  StyledIcon,
  UserInfo,
} from "./styles";

export const options = [
  {
    title: "Dashboard",
    icon: <img src={dashIcon} alt="Icon" />,
    isActive: false,
    route: "/dashboard",
    submenus: [],
  },
  {
    title: "Projetos",
    icon: <img src={projectIcon} alt="Icon" />,
    isActive: false,
    route: "/projetos",
    submenus: [],
  },
  {
    title: "Parceiros",
    icon: <img src={customerIcon} alt="Icon" />,
    isActive: false,
    route: "/partners",
    submenus: [
      {
        title: "Clientes",
        route: "/partners/customers",
      },
      {
        title: "Fornecedores",
        route: "/partners/suppliers",
      },
    ],
  },
  {
    title: "Relatórios",
    icon: <img src={reportIcon} alt="Icon" />,
    isActive: false,
    route: "/reports",
    submenus: [],
  },
  {
    title: "Controle de acesso",
    icon: <img src={aclIcon} alt="Icon" />,
    isActive: false,
    route: "/access-control",
    submenus: [],
  },
];

export default function SideBar() {
  const location = useLocation();

  const { accessOptions } = useContext(AuthContext);
  const [optionsState, setOptionsState] = useState(options);
  const { user, signOut } = useContext(AuthContext);
  const [sidebarView, setSideBarView] = useState(true);

  function handleShowSubMenus(target: string) {
    const updatedOptions = optionsState.map((el) => {
      if (el.title === target) el.isActive = true;
      else el.isActive = false;
      return el;
    });
    setOptionsState(updatedOptions);
  }

  useEffect(() => {
    const target = location.pathname.includes("partners")
      ? "Parceiros"
      : optionsState.find((el) => el.route === location.pathname)?.title;
    handleShowSubMenus(target || "Projetos");
  }, [location.pathname]);

  function renderOption(item: any, index: number) {
    const accessForItem = accessOptions.find(
      (option: any) => option.name === item.title
    );
    const hasAccess = false;

    if (!accessForItem || !accessForItem.type.view.active) return null;

    return (
      <Item
        key={index}
        icon={<StyledIcon isActive={item.isActive}>{item.icon}</StyledIcon>}
        title={item.title}
        isActive={item.isActive}
        submenus={item.submenus}
        route={item.route}
        onClick={handleShowSubMenus}
      />
    );
  }

  function renderToScreen() {
    return sidebarView ? (
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 260,
          p: 2,
          boxShadow: "0px 2px 4px 0px #A5A3AE4D",
          mr: 1,
        }}
      >
        <Header>
          <LogoDiv>
            <img src={logoMMImg} alt="Logo" />
            <strong>MMIDD</strong>
          </LogoDiv>
          <img
            src={colapseIcon}
            alt="Colapse"
            onClick={() => {
              setSideBarView(!sidebarView);
            }}
          />
        </Header>

        <MainContent>
          {optionsState.map((item, index) => renderOption(item, index))}
        </MainContent>

        <Footer>
          <Avatar {...stringAvatar(user.name)} />

          <UserInfo>
            <Typography variant="body1" color={"#000"}>
              {user.name}
            </Typography>
            <Typography variant="caption" color={"#000"}>
              {RoleUserMap[user.role_id]}
            </Typography>
          </UserInfo>

          <IconButton onClick={signOut}>
            <MdOutlineLogout size={20} />
          </IconButton>
        </Footer>
      </Card>
    ) : (
      <Container style={{ width: "20px" }}>
        <Header>
          <img
            src={showIcon}
            alt="Colapse"
            onClick={() => {
              setSideBarView(!sidebarView);
            }}
          />
        </Header>
      </Container>
    );
  }

  return <>{renderToScreen()}</>;
}
