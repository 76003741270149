import {
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
} from "@mui/material";
import { LabeledInput } from "../UI/LabeledInput";
import React, { useContext, useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import SupplierService from "../../services/supplier.service";
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../../contexts/auth";

type Props = {
  edit: any;
};
export function Supplier({ edit }: Props) {
  const { actionOptions } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation()
  const supplierId = location.search.split('=')

  const { data: clientData, isLoading: clientLoading } = useQuery({
    queryKey: ["supplier"],
    queryFn: () => SupplierService.getProviders({ id: supplierId[1] })
  });
 
  const [state, setState] = useState({} as any)
  useEffect(() => {
    if(!!clientData) setState(clientData[0])
  }, [clientData])
  const { mutate: fetchCnpjData, isLoading: isLoadingFetchCnpj } = useMutation(
    SupplierService.fetchCnpjData,
    {
      onSuccess: (apiData) => {
        if (apiData) {
          setState((prevState: any) => ({
            ...prevState,
            zip_code: apiData.CEP || "",
            country: "Brasil",
            state: apiData.UF || "",
            city: apiData.MUNICIPIO || "",
            neighborhood: apiData.BAIRRO || "",
            street_type: apiData["LOGRADOURO"] || "",
            email: apiData.EMAIL || "",
            number: apiData.NUMERO || "",
            complement: apiData.COMPLEMENTO || "",
          }));
        }
      },
      onError: (error) => {
        console.error("Error fetching CNPJ data", error);
      },
    }
  );

  const { mutate: createSupplier, isLoading: isLoadingCreate } = useMutation(
    SupplierService.create,
    {
      onSuccess: () => {
        toast.success("Fornecedor criado com sucesso!");
        history.push("/partners/suppliers");
      },
      onError: (error) => {
        toast.error("Não foi possível criar o fornecedor");
      },
    }
  );

  const { mutate: updateSupplier, isLoading: isLoadingUpdate } = useMutation(
    (data: any) => SupplierService.update(state._id, data),
    {
      onSuccess: () => {
        toast.success("Fornecedor atualizado com sucesso!");
        history.push("/partners/suppliers");
      },
      onError: (error) => {
        toast.error("Não foi possível atualizar o fornecedor");
      },
    }
  );
  const handleSubmmit = (e: React.MouseEvent) => {
    const data = {
      ...state,
    };
    delete data._id;
    !!state._id ? updateSupplier(data) : createSupplier(data);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.name === "number") {
      const numberRegex = new RegExp("^[0-9]*$");
      if (!numberRegex.test(e.target.value)) {
        e.target.value = e.target.value.replace(/\D/g, "");
      }
    }
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleTaxIdBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const unmaskedCnpj = value.replace(/\D/g, "");
    fetchCnpjData(unmaskedCnpj);
  };
  const isDisabled =
    !actionOptions.updateParceiros || !actionOptions.createParceiros;

  return (
    <Box display={"flex"} width={"100%"} columnGap={2} py={1}>
      <Card style={{ width: "100%", padding: "5px 10px" }}>
        <Box display={"flex"} flexDirection={"column"} width={"90%"}>
          <FormControl>
            <FormLabel>Fornecedor</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.has_supplier}
                    onChange={(e) =>
                      setState({
                        ...state,
                        has_supplier: e.target.checked,
                      })
                    }
                  />
                }
                label={"Sim"}
              />
            </FormGroup>
          </FormControl>
          <FormControl>
            <FormLabel>Colaborador MM</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.collaborator_mm}
                    onChange={(e) =>
                      setState({
                        ...state,
                        collaborator_mm: e.target.checked,
                      })
                    }
                  />
                }
                label={"Sim"}
              />
            </FormGroup>
          </FormControl>
          <FormControl>
            <FormLabel id="person-type">Pessoa</FormLabel>
            <RadioGroup
              aria-labelledby="person-type"
              value={state.document_type}
              name="document_type"
              onChange={handleChange}
            >
              <FormControlLabel
                value="person"
                control={<Radio />}
                label="Física"
              />
              <FormControlLabel
                value="company"
                control={<Radio />}
                label="Jurídica"
              />
            </RadioGroup>
          </FormControl>

          <FormControl>
            <FormLabel id="event-location">Local</FormLabel>
            <RadioGroup
              aria-labelledby="event-location"
              defaultValue="nacional"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="nacional"
                control={<Radio />}
                label="Nacional"
              />
              <FormControlLabel
                value="internacional"
                control={<Radio />}
                label="Internacional"
              />
            </RadioGroup>
          </FormControl>
          <ReactInputMask
            //@ts-ignore
            maskPlaceholder={null}
            mask={
              state.document_type === "person"
                ? "999.999.999 - 99"
                : "99.999.999/9999-99"
            }
            value={state.cnpj_cpf}
            onChange={handleChange}
            onBlur={handleTaxIdBlur}
            name="cnpj_cpf"
          >
            <LabeledInput
              style={{ width: "90%" }}
              size="small"
              label={state.document_type === "person" ? "CPF" : "CNPJ"}
              name="cnpj_cpf"
              value={state.cnpj_cpf}
              onChange={handleChange}
              placeholder={
                state.document_type === "person"
                  ? "000.000.000 - 00"
                  : "00.000.000/0000-00"
              }
            />
          </ReactInputMask>
          <LabeledInput
            size="small"
            style={{ width: "90%" }}
            label="Solicitante do Cliente"
            name="client_requester"
            value={state.client_requester}
            onChange={handleChange}
          />
          <LabeledInput
            size="small"
            style={{ width: "90%" }}
            label="Nome"
            name="name"
            value={state.name}
            onChange={handleChange}
          />
          <LabeledInput
            size="small"
            style={{ width: "90%" }}
            label="Nome de uso/apelido"
            name="name"
            value={state.name}
            onChange={handleChange}
          />
          <LabeledInput
            size="small"
            style={{ width: "90%" }}
            label="Nome Grupo"
            name="business_group_name"
            value={state.business_group_name}
            onChange={handleChange}
          />

          <FormLabel
            style={{
              borderBottom: "1px solid",
              marginTop: "15px",
              width: "105%",
            }}
          >
            Contato
          </FormLabel>

          <LabeledInput
            size="small"
            style={{ width: "90%" }}
            fullWidth
            label="E-mail"
            name="email"
            value={state.email}
            onChange={handleChange}
          />
          <ReactInputMask
            //@ts-ignore
            maskPlaceholder={null}
            mask="(99) 99999-9999"
            value={state.phone}
            onChange={handleChange}
            name="phone"
          >
            <LabeledInput
              style={{ width: "90%" }}
              size="small"
              label="Telefone"
              name="phone"
              value={state.phone}
              onChange={handleChange}
              placeholder="(00) 0 0000 0000"
            />
          </ReactInputMask>

          <FormLabel
            style={{
              borderBottom: "1px solid",
              marginTop: "15px",
              width: "105%",
            }}
          >
            Endereço
          </FormLabel>

          <ReactInputMask
            //@ts-ignore
            maskPlaceholder={null}
            mask="99999-999"
            value={state.zip_code}
            onChange={handleChange}
            name="zip_code"
          >
            <LabeledInput
              style={{ width: "90%" }}
              size="small"
              label="CEP"
              name="zip_code"
              value={state.zip_code}
              onChange={handleChange}
              placeholder="00000-000"
            />
          </ReactInputMask>
          <LabeledInput
            size="small"
            style={{ width: "90%" }}
            fullWidth
            label="País"
            value={state.country}
            name="country"
            onChange={handleChange}
          />

          <LabeledInput
            size="small"
            style={{ width: "90%" }}
            fullWidth
            label="Estado"
            value={state.state}
            name="state"
            onChange={handleChange}
          />

          <LabeledInput
            size="small"
            style={{ width: "90%" }}
            fullWidth
            label="Município"
            value={state.city}
            name="city"
            onChange={handleChange}
          />

          <LabeledInput
            size="small"
            style={{ width: "90%" }}
            fullWidth
            label="Bairro"
            value={state.neighborhood}
            name="neighborhood"
            onChange={handleChange}
          />

          <LabeledInput
            size="small"
            style={{ width: "90%" }}
            fullWidth
            label="Logradouro"
            value={state.street_type}
            name="street_type"
            onChange={handleChange}
          />
          <Box display={"flex"} width={"90%"} columnGap={1}>
            <LabeledInput
              size="small"
              style={{ width: "100%" }}
              fullWidth
              label="Número"
              value={state.number}
              name="number"
              onChange={handleChange}
            />

            <LabeledInput
              size="small"
              style={{ width: "100%" }}
              fullWidth
              label="Complemento"
              value={state.complement}
              name="complement"
              onChange={handleChange}
            />
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}>
          <Button
            style={{
              marginRight: "15px",
              textTransform: "none",
            }}
            variant="outlined"
            onClick={() => history.push("/partners/suppliers")}
          >
            Cancelar
          </Button>

          <Button
            disabled={isDisabled}
            style={{
              background: "#1361A4",
              marginRight: "15px",
              color: "#FFFFFF",
              textTransform: "none",
            }}
            variant="contained"
            onClick={handleSubmmit}
          >
            Salvar e Fechar
          </Button>
        </Box>
      </Card>
    </Box>
  );
}
