import * as yup from 'yup';

// Define the enum values as a list for validation purposes
const BoothTypeEnumValues = ['ISLAND', 'CORNER', 'BOX', 'ISLAND_END'];

// Define the schema for the meeting rooms
const meetingRoomSchema = yup.object().shape({
  pax: yup.string().required('Pax is required'),
  observation: yup.string().required('Observation is required')
});

// Define the schema for the main DTO
const createBoothDtoSchema = yup.object().shape({
  _id: yup.string().optional(),
  project: yup.string().required('Projeto é obrigatório é obrigatório'),
  squareFootage: yup.string(),//.required('Metragem é obrigatório'),
  location: yup.string(),//.required('Localização é obrigatório'),
  type: yup.mixed().oneOf(BoothTypeEnumValues, 'Escolha o tipo').required('Tipo é obrigatório'),
  meetingRooms: yup.array().of(meetingRoomSchema).optional(),
  hasMeetingRooms: yup.boolean().required('Salas é obrigatório'),
  hasAuditorium: yup.boolean().required('Auditório é obrigatório'),
  hasCustomBuildExpectation: yup.boolean().required('Expectativa de construcao sob medida é obrigatório'),
  hasStorage: yup.boolean().required('Deposito é obrigatório'),
  hasProductDisplay: yup.boolean().required('Exposição de produtos é obrigatório'),
  meetingRoomsObs: yup.string().optional(),
  auditoriumObs: yup.string().optional(),
  customBuildExpectationObs: yup.string().optional(),
  storageObs: yup.string().optional(),
  productDisplayObs: yup.string().optional()
});

export default createBoothDtoSchema;
