import { Button, FormControlLabel, Switch } from "@mui/material";
import { Input } from "../../../Input";
import { InputDiv, SummaryDiv, RadioDiv, InputRow, StyledBox } from "../styles";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useRsvp } from "../../../../contexts/rsvp.context";
import { SpeakersFieldType, RsvpType } from "../../../../types/rsvp/index";

type Props = {
  onSubmitNav: () => void
  isDisabled: boolean
}

export function Speakers({onSubmitNav, isDisabled}: Props) {
  const { rsvpId, updateSpeakers, createRsvp, speakers } = useRsvp()
  const obj: SpeakersFieldType = {
    detailsObs: "",
    diferentialActionsObs: ""
  }
  const methods = useForm({
    defaultValues: obj,
  })
  const { handleSubmit, register, reset } = methods
  const [isSwitchOn, setSwitchOn] = useState(false);
  const [observation, setObservation] = useState('');

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setSwitchOn(checked);
  };

const handleObservationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setObservation(event.target.value);
};

  function onSubmit(form: SpeakersFieldType) {
    rsvpId ? updateSpeakers(form) : createRsvp({ speakers: form } as RsvpType)
    onSubmitNav()
  }

  useEffect(() => {
    if (speakers) {
      reset(speakers);
    }
  }, [speakers]);
  return (<FormProvider {...methods}>
    <StyledBox onSubmit={handleSubmit(onSubmit)}>
      <h3>7.Palestrantes/Speakers/Convidados VIPs</h3>
      <InputDiv>
        <SummaryDiv>
          <strong>Detalhes</strong>
          <span>Algum detalhe específico e importante que devemos saber</span>
        </SummaryDiv>
        <RadioDiv>
          <Input
            label="Detalhes importantes"
            multiline
            size="small"
            {...register('detailsObs')}
          />
        </RadioDiv>

      </InputDiv>
      <InputDiv>
        <SummaryDiv>
          <strong>Ações diferenciais</strong>
          <span>Especificar as pessoas</span>
        </SummaryDiv>
        <RadioDiv>
          <Input
            label="Ações especificas"
            type="text-area"
            multiline
            size="small"
            {...register('diferentialActionsObs')}
          />
        </RadioDiv>
      </InputDiv>

      <InputDiv>
        <SummaryDiv>
          <strong>Convidados e Speakers/VIPs podem ser tratados no mesmo ambiente no FastRsvp?</strong>
          <br/>
          <span>Padrão de complience limita ou não permite acompanhantes</span>
        </SummaryDiv>
        <RadioDiv>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <FormControlLabel
              control={<Switch checked={isSwitchOn} onChange={handleSwitchChange} />}
              label={isSwitchOn ? 'Sim' : 'Não'}
            />
            {isSwitchOn && (
              <Input
                value={observation}
                onChange={handleObservationChange}
                placeholder="Observações"
                multiline
                size="small"
                style={{ width: '158%' }}
              />
            )}
          </div>
        </RadioDiv>
      </InputDiv>
    
      <Button
        disabled={isDisabled}
        variant="contained"
        
        style={{ position: 'relative', left: '90%', top: '50%' }}
        type="submit"
      >
        Salvar
      </Button>
    </StyledBox>
  </FormProvider>
  );
}
