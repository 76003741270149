import styled from "styled-components";

export const NewBudgetDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px auto;
  align-items: center;
  div {
    margin: 5px;
  }
`;
