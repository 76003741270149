import styled from "styled-components";

export const ActionButton = styled.div`
background-color: #EFEFEF;
padding: 3px 8px;
font-weight: bold;
color: #A0A0A0;
border-radius: 4px;
cursor: pointer;
margin: 0 3px;
`
export const MainPagination = styled.div`
display: flex;
align-items: center;
width: 100%;
justify-content: flex-end;
`