import styled from "styled-components";

export const BriefingNav = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BriefingContent = styled.div``;
export const BudgetHelperDiv = styled.div`
  width: 50%;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

export const InputDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  > div {
    flex: 1;
  }

  .fake {
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    padding: 4px 0 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-sizing: content-box;
    background: none;
    height: 1.3575em;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    width: 70%;
    margin-right: 10px;
    -webkit-animation-name: mui-auto-fill-cancel;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    padding-top: 1px;
    padding: 8.5px 14px;
  }
`;
