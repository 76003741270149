import { TextField } from "@mui/material";
import styled from "styled-components";

export const HeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h1 {
    font-weight: normal;
  }
`;

export const Navigation = styled.nav`
  display: flex;
  margin-left: -70px;
  justify-content: flex-start;
  ul {
    display: flex;
    list-style: none;
    width: 100%;
    justify-content: space-evenly;
  }
  li {
    padding: 10px;
    cursor: pointer;
  }
  li.active {
    color: #1361a4;
    font-weight: bold;
    background-color: #eaedf4;
    border-radius: 6px;
  }
`;

export const ContentDiv = styled.div`
  margin: 10px 0;
`;
export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;
  flex-wrap: wrap;

  .description-span {
    padding: 0 5px;
    font-size: 0.9em;
    font-weight: 500;
    width: 100%;
  }
`;

export const FooterDiv = styled.div``;

export const StatusDiv = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid blue;
  margin: 0 10px;
  padding: 5px;
  border-radius: 6px;
  color: #ddd;
  .active {
    color: blue;
    font-weight: bold;
  }
  span {
    padding: 3px;
  }
`;

export const TitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #555;
  text-align: left;
  margin-bottom: 10px;
`;

export const DescriptionTextField = styled(TextField)`
  width: 50%;
`;

export const StyledP = styled.p`
  color: #1361a4;
  cursor: pointer;
  width: 100%;
  text-align: right;
`;

export const StyledSpan = styled.span`
  color: #1361a4;
  cursor: pointer;
`;
