import { ContentDiv, TitleDiv, RowDiv, StyledSpan } from "../styles";

interface IProps {
  onGoTo(destination: string):void
}
export function ProjectStep({onGoTo}: IProps) {
  return (
    <>
      <ContentDiv>
        <TitleDiv>
          <span>Usuários da Célula</span>
          <StyledSpan onClick={()=> {onGoTo('users')}} >Ir para Usuários</StyledSpan>
        </TitleDiv>
        
        <RowDiv>
      <span className="description-span">3 associados: José Alcântara(coordenador), Maria Alcântara(Atendimento)</span>
        </RowDiv>
      </ContentDiv>
      <br />
      <ContentDiv>
        <TitleDiv>
          <span>Clientes 0</span>
        </TitleDiv>
      </ContentDiv>
      <br />
      <ContentDiv>
        <TitleDiv>
          <span>Projetos 1 eventos(5)</span>
          <StyledSpan onClick={()=> {onGoTo('projects')}}>Ir para projetos</StyledSpan>
        </TitleDiv>
        
      </ContentDiv>
    </>
  );
}
