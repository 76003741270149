import { VerticalTabContent } from "./VerticalTabContent";
import { VerticalTabIcon } from "./VerticalTabIcon";
import { VerticalTabItem } from "./VerticalTabItem";
import { VerticalTabsRoot } from "./VerticalTabsRoot";

export const VerticalTab = {
  Root: VerticalTabsRoot,
  Item: VerticalTabItem,
  Icon: VerticalTabIcon,
  Content: VerticalTabContent,
};
