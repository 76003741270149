import {Accordion, AccordionDetails, AccordionSummary, Box, Button} from "@mui/material"
import React, {useContext} from "react"
import {useMutation, useQuery, useQueryClient} from "react-query"
import {SupplierLogistic} from "../../../services/supplier-logistic.service"
import {FaCaretDown} from "react-icons/fa"
import {LogisticServiceItem} from "../LogisticsServiceItem"
import {toast} from "react-toastify"
import {AuthContext} from "../../../contexts/auth"

type PropTypes = {
    logisticsId: string
    accordionType: 'ACCOMMODATIONS' | 'ROOMS' | 'AEB' | 'EQUIPMENTS' | 'COMMUNICATIONS' | 'TRANSLATIONS' | 'SUPPORT' | 'SUBSCRIPTION' | 'RSVP' | 'AIR' | 'TRANSFER' | 'SEVERAL'
    title: string
}
const hasOpenFieldForService = ['EQUIPMENTS' ,'COMMUNICATIONS' ,'SUBSCRIPTION', ,'TRANSFER' ,'SEVERAL']
const LogisticAccordion: React.FC<PropTypes> = ({ accordionType, title, logisticsId }: PropTypes) => {
const {actionOptions} = useContext(AuthContext)
  const queryClient = useQueryClient()
  const { isLoading, data: servicesData, error } = useQuery({
    queryKey: [`${accordionType}-logistics`],
    queryFn: () => SupplierLogistic
      .getServicesByProjectIdAndType(
        { logistics_id: logisticsId, service_type: accordionType }
      )
  });
  const { mutate: createLogisticService, isLoading: isLoadingCreateService } = useMutation(
    (state: any) => SupplierLogistic.createService(state),
    {
      onSuccess: () => {
        queryClient.invalidateQueries()
      },
      onError: (error) => {
        toast.error('Não foi possível criar')
      }
    }
  );
  const { mutate: updateLogisticService, isLoading: isLoadingUpdateService } = useMutation(
    (state: any) => SupplierLogistic.updateService(state._id, state),
    {
      onSuccess: () => {
        queryClient.invalidateQueries()
        toast.info(`Registro salvo com sucesso!`)

            },
            onError: (error) => {
                toast.error('Não foi possível criar')
            }
        }
    );
    const {mutate: deleteLogisticService, isLoading: isLoadingDeleteService} = useMutation(
        (id: string) => SupplierLogistic.deleteService(id),
        {
            onSuccess: () => {
                toast.success("Serviço excluído!");
                queryClient.invalidateQueries()
            },
            onError: (error) => {
                toast.error('Não foi possível excluir')
            }
        }
    );

    function handleAddEmptyService() {
        if (isLoadingCreateService || isLoadingDeleteService || isLoadingUpdateService) return
        const data = {
            type: accordionType,
            provider_id: null,
            briefing_id: '',
            logistics: logisticsId,
            service_type: '',
            daily: 0,
            quantity: 0,
            input_date: new Date(),
            out_date: new Date(),
            description: '',
            observation: ''
        }
        createLogisticService(data)
    }

    const isDisabled = !actionOptions.createProjetos

    return <Accordion
        TransitionProps={{unmountOnExit: true}}
    >
        <AccordionSummary
            aria-controls="panel1a-content"
            expandIcon={<FaCaretDown/>}
            id="panel1a-header"
            style={{maxHeight: "40px", display: "flex", alignItems: 'center'}}
        >

            <span>{title}</span>
            <hr style={{border: '0', height: '1px', background: '#333'}}/>
        </AccordionSummary>

        <AccordionDetails style={{width: "100%"}}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: "100%",
            }}>
                {servicesData?.map((service: any, index: any) => {
                    return <Box>
                        <LogisticServiceItem
                            handleDeleteService={deleteLogisticService}
                            handleSaveService={updateLogisticService}
                            data={service}
                            title={`${title} ${index + 1}`}
                            type={accordionType}
                               serviceIsOpen={hasOpenFieldForService.includes(accordionType)} />
                    </Box>
                })}
                <Button style={{maxWidth: '250px'}} onClick={handleAddEmptyService} disabled={isDisabled}
                        variant="outlined">
                    + Adicionar {title}
                </Button>

            </Box>

        </AccordionDetails>
    </Accordion>


}

export {LogisticAccordion}