// event
export enum InvoiceTypeEnum {
  INVOICE = 'Nota fiscal',
  DEBIT_NOTE = 'Nota de débito',
}
export enum PaymentMethodEnum {
  BILLED_TO_CLIENT_BILLED_TO_SUPPLIER = 'Faturado para o cliente, faturado no fornecedor (Gera fatura)',
  BILLED_TO_CLIENT_CREDIT_CARD_SUPPLIER = 'Faturado para o cliente, com cartão de crédito no fornecedor (Gera fatura)',
  BILLED_TO_CLIENT_INVOICE_SUPPLIER = 'Faturado para o cliente, com invoice pro fornecedor (Gera fatura)',
  INTERNAL_EXPENSE_BILLED_TO_SUPPLIER = 'Despesa interna, faturada no fornecedor (Gera fatura)',
  CLIENT_CREDIT_CARD_SUPPLIER_CREDIT_CARD = 'Cartão de crédito do cliente, com cartão de crédito no fornecedor (Apenas Fee)',
  DIRECT_PAYMENT = 'Pagamento direto (Apenas Fee)',
}