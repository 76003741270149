import {
  Box,
  Typography,
  Card,
  Divider,
  Autocomplete,
  TextField,
  Button,
  IconButton,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  InputLabel,
  Checkbox,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridSortItem,
  ptBR,
} from "@mui/x-data-grid";
import { TbDownload, TbFileExport, TbTrash } from "react-icons/tb";
import { BRIEFING_STATUS_OPTIONS } from "../../utils/briefingStatus";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { api } from "../../services/apiClient";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ReportService from "../../services/report.service";
import { format } from "date-fns";
import ptBRLocale from "date-fns/locale/pt-BR";
import { forceDownload } from "../../utils/download.util";
import ProjectService from "../../services/project.service";
import ClientService from "../../services/client.service";
import { AuthContext } from "../../contexts/auth";
import { Input } from "../../components/Input";
import { FaCheck } from "react-icons/fa";


import MuiToggleButton from "@mui/material/ToggleButton";
import { styled } from "@mui/material/styles";

export function Reports() {
  const { actionOptions } = useContext(AuthContext);
  const [mmCodesFilter, setMMCodesFilter] = useState({
    status: [],
    selectedClients: [],
    initialDate: "",
    finalDate: "",
    filterDateType: "CREATION",
  });
  const [selectedMMCodesArr, setSelectedMMCodesArr] = useState<any[]>([
    { _id: "select-all" },
  ]);

  const [exportName, setExportName] = useState("");

  const [sortModel, setSortModel] = useState<GridSortItem[]>([
    { field: "created_at", sort: "desc" },
  ]);

  const {
    isLoading,
    data: mmCodes,
    error,
  } = useQuery({
    queryKey: ["get-mm-codes", mmCodesFilter],
    queryFn: () => ProjectService.getMMCodes(mmCodesFilter),
  });
  const queryClient = useQueryClient();
  const { data: reports } = useQuery("reports", ReportService.getAll);
  const { data: clients } = useQuery({
    queryKey: ["get-all-clients"],
    queryFn: () => ClientService.getClients(),
  });

  const { mutate: createReport, isLoading: isLoadingCreate } = useMutation(
    (data: any) => ReportService.create(data),
    {
      onSuccess: (response) => {
        toast.success("Relatório gerado com sucesso!");
        queryClient.invalidateQueries("reports");
        downloadReport(response._id);
      },
    }
  );

  const columns: GridColDef[] = [
    {
      field: "report_name",
      flex: 1,
      headerName: "Nome do relatório",
    },
    {
      field: "user",
      flex: 1,
      headerName: "Solicitante",
      valueGetter: (params) => params.value?.name || "",
    },
    {
      field: "created_at",
      flex: 1,
      headerName: "Data Exportado",
      renderCell: (params) =>
        params.value
          ? format(new Date(params.value), "dd/MM/yyyy HH:mm:ss", {
              locale: ptBRLocale,
            })
          : "",
    },
    {
      field: "action",
      headerName: "",
      renderCell: (params: GridRenderCellParams) => (
        <Box border={'1px solid #1976D2'} borderRadius={2}>
          <IconButton
            aria-label="edit"
            onClick={() => {
              downloadReport(params.row._id);
            }}
          >
            <TbDownload size={14} color="#1976D2" />
          </IconButton>

          {/* <IconButton
            aria-label="delete"
            onClick={() => deleteReport(params.row._id)}
          >
            <TbTrash size={14} />
          </IconButton> */}
          {/* <IconButton aria-label="edit" disabled onClick={() => { }}>
                        <TbFileExport size={16} />
                    </IconButton> */}
        </Box>
      ),
    },
  ];

  async function downloadReport(id: string) {
    const response = await ReportService.download(id);

    if (!!response.file_url) {
      forceDownload(response.file_url, response.report_name, "xlsx");
    }
  }

  async function deleteReport(id: string) {
    await ReportService.delete(id);
    toast.success("Relatório deletado com sucesso!");
    queryClient.invalidateQueries("reports");
  }

  async function createReportHandler() {
    if (!exportName) return toast.warn("Informe um nome para o relatório");
    if (selectedMMCodesArr.length < 1)
      return toast.warn("Selecione pelo menos um código MM");

    const payload = {
      reportName: exportName,
      mmCodes: selectedMMCodesArr,
    };
    if (
      selectedMMCodesArr.length === 1 &&
      selectedMMCodesArr[0]._id === "select-all"
    ) {
      payload.mmCodes = mmCodes.filter((el: any) => el._id !== "select-all");
    }
    payload.mmCodes = payload.mmCodes.map((el) => el.mmCode);

    createReport(payload);
  }

  function handleSelectMMCode(e: any, newValue: any | null) {
    console.log(newValue);
    if (newValue.length < 1) {
      setSelectedMMCodesArr([{ _id: "select-all" }]);
    } else {
      setSelectedMMCodesArr(
        newValue.filter((el: any) => el._id !== "select-all")
      );

    }
  }


  
  const ToggleButton = styled(MuiToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: '#1976D2'
    }
  });

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      flex={1}
      py={4}
      px={3}
      bgcolor={"#F9FCFF"}
      width={"80%"}
    >
      <Box display={"flex"}>
        <Typography variant={"h5"}>Relatórios</Typography>
      </Box>

      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 4,
          px: 2,
          py: 2,
          boxShadow: "0px 4px 18px 0px #4B465C1A",
          overflow: "auto",
        }}
      >
        <Typography variant="body1">Filtros: Refine sua exportação</Typography>

        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          gap={1}
          mt={1}
          width={"100%"}
          p={1}
          bgcolor={"#F9FCFF"}
          border={"1px solid #eee"}
        >
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Autocomplete
              options={clients || []}
              disablePortal
              getOptionLabel={(option) =>
                `${option.handle_id} - ${option.name}` || ""
              }
              fullWidth
              size="small"
              multiple
              renderInput={(params) => (
                <Input {...params} label="Clientes" fullWidth />
              )}
              onChange={(e, newValue: any | null) =>
                newValue !== null
                  ? setMMCodesFilter({
                      ...mmCodesFilter,
                      selectedClients: newValue.map((el: any) => el._id),
                    })
                  : ""
              }
            />
            <Autocomplete
              options={BRIEFING_STATUS_OPTIONS}
              disablePortal
              getOptionLabel={(option) => option.text || ""}
              fullWidth
              size="small"
              renderInput={(params) => (
                <Input {...params} label="Status" fullWidth />
              )}
              multiple
              onChange={(e, newValue: any | null) =>
                newValue !== null
                  ? setMMCodesFilter({
                      ...mmCodesFilter,
                      status: newValue.map((el: any) => el.value),
                    })
                  : ""
              }
            />

            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"flex-start"}
              alignItems={"flex-end"}
              width={"100%"}
              gap={2}
            >
              <Box>
                <InputLabel>Filtrar por:</InputLabel>

                <ToggleButtonGroup
          
                  size="small"
                  color="primary"
                  value={mmCodesFilter.filterDateType}
                  onChange={(e, newValue) => {
                    setMMCodesFilter({
                      ...mmCodesFilter,
                      filterDateType: newValue,
                    });
                  }}
                  exclusive
                  aria-label="Platform"
                >
                  <ToggleButton
                    style={{ textTransform: "capitalize" }}
                    value="CREATION"
                  >
                    {mmCodesFilter.filterDateType === "CREATION" && <FaCheck />}
                    &nbsp; Data de criação
                  </ToggleButton>
                  <ToggleButton
                    style={{ textTransform: "capitalize" }}
                    value="EVENT"
                  >
                    {mmCodesFilter.filterDateType === "EVENT" && <FaCheck />}
                    &nbsp; Data do evento
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"center"}
                alignItems={'flex-start'}
                bgcolor={"#F9FCFF"}
                gap={1}
              >
                <Input
                  label="Ínicio"
                  size="small"
                  type="date"
                  style={{maxWidth: '250px'}}
                  InputLabelProps={{ shrink: true }}
                  value={mmCodesFilter.initialDate}
                  onChange={(e) =>
                    setMMCodesFilter({
                      ...mmCodesFilter,
                      initialDate: e.target.value,
                    })
                  }
                />

                <Input
                  label="Fim"
                  size="small"
                  type="date"
                  style={{maxWidth: '250px'}}
                  InputLabelProps={{ shrink: true }}
                  value={mmCodesFilter.finalDate}
                  onChange={(e) =>
                    setMMCodesFilter({
                      ...mmCodesFilter,
                      finalDate: e.target.value,
                    })
                  }
                />
              </Box>
            </Box>
          </Box>
          <Autocomplete
            options={!!mmCodes ? [{ _id: "select-all" }, ...mmCodes] : []}
            disablePortal
            multiple
            getOptionLabel={(option: any) =>
              option._id === "select-all"
                ? "Selecionar Todos"
                : `${option.mmCode}/${option.yearCode} - ${option.event}` || ""
            }
            renderOption={(props, option: any) => {
              return (
                <li {...props} key={option._id}>
                  {option._id === "select-all"
                    ? "Selecionar Todos"
                    : `${option.mmCode}/${option.yearCode} - ${option.event}` ||
                      ""}
                </li>
              );
            }}
            fullWidth
            size="small"
            renderInput={(params) => <Input {...params} label="Código MM" />}
            onChange={handleSelectMMCode}
            value={selectedMMCodesArr}
          />
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={'flex-end'}
          justifyContent={'space-between'}
          mb={2}
        >
          <Input
            label="Nome da exportação"
            value={exportName}
            onChange={(e) => {
              setExportName(e.target.value);
            }}
          />
          <Button
            disabled={isLoadingCreate || !actionOptions["createRelatórios"]}
            variant="outlined"
            size="small"
            onClick={createReportHandler}
            style={{
              textTransform: "none",
              padding: "5px",
              width: "100px",
              borderRadius: "8px",
              margin: '2px 10px'

            }}
          >
            {!isLoadingCreate ? (
              <>
                <TbFileExport />
                Exportar
              </>
            ) : (
              <>
                Gerando...
                <CircularProgress size={20} />
              </>
            )}
          </Button>

          {/* <FormControlLabel
                    control={
                        <Switch
                            checked={true}

                            onChange={() => {

                            }}
                        />
                    }
                    label={"Enviar via email"}
                /> */}
        </Box>
        <Typography variant="body1">Gerenciar exportações</Typography>
        <Divider variant="fullWidth" color="#222222" />
        <Box sx={{ minHeight: 250 }} mt={3}>
          <DataGrid
            autoHeight
            rows={reports || []}
            columns={columns}
            getRowId={(row) => row._id}
            pageSize={10}
            rowsPerPageOptions={[10]}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
          />
        </Box>
      </Card>
    </Box>
  );
}
