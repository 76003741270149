import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Input } from "../../../components/Input";
import { Box, Button, Checkbox, FormControlLabel, FormGroup, FormLabel, InputLabel } from "@mui/material";
import { FormInputMultiCheckbox } from "../../novos/RSVP/PreCongress/FormMultipleCheckBox";
import { ContentTypeEnum, CreativeDirectionalEnum } from "../../../types/creative/enums";
import styled from "styled-components";
import CurrencyInputForm from "../../CurrencyInputForm";
import { useQuery } from "react-query";
import { StyledBox } from "../../novos/RSVP/styles";
import { Autocomplete } from "../../UI/Autocomplete";
import ProductService from "../../../services/product.service";
import { AuthContext } from "../../../contexts/auth";


const HorizontalDivider = styled.hr`
width: 100%;
border-color: #fefefe;
color: #fefefe;
`
type CreativeFormProps = {
  creativeData: any
  onSubmit(form: any): void
}
const initialState = {
  contentType: [],
  contentTypeObs: '',
  creativeDirectional: '',
  creativeDirectionalExample: '',
  keyMessages: '',
  kvMaterialType: '',
  kvMaterialTypeObs: '',
  artMaterialType: [], // ou array de strings
  artMaterialTypeObs: '',
  digitalSchenographyMaterialType: '', // ou array de ''s
  digitalSchenographyMaterialTypeValue: 0,
  artCreation: '', // ou array de ''s
  artCreationAditionalOptions: '',
  gifts: '',
  files: []

}

const CreativeForm = ({ creativeData, onSubmit }: CreativeFormProps) => {
  const {actionOptions} = useContext(AuthContext)
  const isDisabled = !actionOptions.createProjetos && !actionOptions.updateProjetos
  const methods = useForm({
    defaultValues: initialState,
    // resolver: yupResolver(schema),
    mode: "all",
  });
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { errors },
  } = methods

  const materialsWatch = watch('artMaterialType')
  const contentReponsibilityOptions = (Object.keys(ContentTypeEnum) as Array<keyof typeof ContentTypeEnum>).map(el => {
    return { label: ContentTypeEnum[el], value: el }
  })
  const directionalTypeOptions = (Object.keys(CreativeDirectionalEnum) as Array<keyof typeof CreativeDirectionalEnum>).map(el => {
    return { label: CreativeDirectionalEnum[el], value: el }
  })
  const codes = ["3D", "ABER", "BRIN", "CENO", 'CONT', 'CRIAÇÃO', 'DECO', 'EQUIPE', 'M.GRAF', 'MIMO']
  const [applyFilters, setApplyFilters] = useState(true)
  const { data: productsData, isLoading: usersLoading } = useQuery({
    queryKey: ["products-for-creative", applyFilters],
    queryFn: () => applyFilters ? ProductService.getForCreativeMaterials({ codes }) : ProductService.getForCreativeMaterials({ codes: [] })
  });

  useEffect(() => {
    if (!!creativeData) {
      reset(creativeData);
      setContentTypeState(creativeData.contentType)
      setCreativeDirectional(creativeData.creativeDirectional)
    }

  }, [creativeData]);
  useEffect(() => {
    reset(initialState)
  }, []);

  // controlled states
  const [contentTypeState, setContentTypeState] = useState([] as string[])
  const [creativeDirectional, setCreativeDirectional] = useState([] as string[])
  return (
    <FormProvider {...methods}>
      <StyledBox onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormLabel>Responsabilidade de conteúdo</FormLabel>
          <FormInputMultiCheckbox
            name={"contentType"}
            control={control} label={'label'}
            options={contentReponsibilityOptions}
            setValue={setValue}
            previousSelected={contentTypeState} // adicionar quando integrar com o backend
            optionSelectAll={false}
            setControlledState={setContentTypeState}
          />
        </FormGroup>
        <Input
          multiline
          label="Responsabilidade conteúdo - Observações"
          {...register("contentTypeObs")}
          placeholder="Escrever observações (Ex: agência: KV, Cliente: arte)"
        />
        <HorizontalDivider />
        <FormGroup>
          <FormLabel>Direcionais de criativo</FormLabel>
          <FormInputMultiCheckbox
            name={"creativeDirectional"}
            control={control} label={'label'}
            options={directionalTypeOptions}
            setValue={setValue}
            previousSelected={creativeDirectional} // adicionar quando integrar com o backend
            optionSelectAll={false}
            setControlledState={setCreativeDirectional}
          />
        </FormGroup>
        <Input
          multiline
          label="Exemplo direcional de criativo   "
          {...register("creativeDirectionalExample")}
          placeholder="Escrever exemplo/guide (Ex: Nome: Festa junina da firma; Tema: Junino e HH; KV: guia da marca"
        />
        <HorizontalDivider />
        <Input
          multiline
          label="Key Messages"
          {...register("keyMessages")}
          placeholder="Escrever Key messages para criativo"
        />

        <HorizontalDivider />
        <InputLabel>Filtro de produtos criativo</InputLabel>
        <FormControlLabel
          control={<Checkbox checked={applyFilters} onChange={(e) => setApplyFilters(e.target.checked)} />}
          label="3D. Abertura de porta, Brindes, Cenografia, Conteúdo, Criação, Decoração, Equipe, Material gráfico, mimos."
        />
        <Autocomplete
          options={productsData || []}
          multiple
          getOptionLabel={(option) => option.subService || ""}
          value={materialsWatch}
          isOptionEqualToValue={(option, value) => option._id === value?._id}
          onChange={(_, product) => {
            setValue("artMaterialType", product);
          }}
          loading={usersLoading}
          loadingText={"Carregando..."}
          renderInput={(params) => (
            <Input
              label="Selectione material da arte*"
              {...params} />
          )} label={""} />

        <HorizontalDivider />
        <Input
          multiline
          label="Tipo material KV"
          {...register("kvMaterialType")}
          placeholder="Escrever frase/palavra chave"
        />
        <Input
          multiline
          label="Tipo material KV - observações"
          {...register("kvMaterialTypeObs")}
          placeholder="Escrever observações"
        />
        <HorizontalDivider />

        <Input
          multiline
          label="3d/cenografia digital"
          {...register("digitalSchenographyMaterialType")}
          placeholder="Descrever Materiais"
        />
        <CurrencyInputForm
          placeholder="Valor"
          label="3d/cenografia digital - Expectativa de gastos"
          name="digitalSchenographyMaterialTypeValue"
        />

        <HorizontalDivider />

        <Input
          multiline
          label="Criação de arte(time mm)"
          {...register("artCreation")}
          placeholder="Descrever materiais"
        />
        <Input
          multiline
          label="Mais de uma opção de arte"
          {...register("artCreationAditionalOptions")}
          placeholder="Descrever quantas"
        />
        <Input
          multiline
          label="Brindes"
          {...register("gifts")}
          placeholder="Descrever quais"
        />
        <Button
          variant="contained"
          disabled={isDisabled}
          style={{ position: 'relative', left: '90%', marginTop: '10px', width: '80px' }}
          type="submit"
        >
          Salvar
        </Button>
      </StyledBox >
    </FormProvider>
  );
};

export default CreativeForm;
