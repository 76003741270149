export enum SpeakerEnum {
  LINKED_TO_CONCEPT = 'Atrelado ao conceito',
  MOTIVATIONAL = 'Motivacional',
  SALES = 'Vendas',
  TEAM_BUILDING = 'Team building',
  ECONOMY = 'Economia',
  OTHER_SPECIFY = 'Outro(s) (especificar)',
}

export enum MusicalAttractionEnum {
  LINKED_TO_CONCEPT = 'Atrelado ao conceito',
  SPECIFIC_GENRE = 'Gênero específico',
}

export enum ContentEnum {
  OPENING_VIDEO = 'Vídeo de abertura',
  INTRO = 'Vinheta',
  OTHER_SPECIFY = 'Outro(s) (especificar)',
}

export enum EquipmentEnum {
  LED_PANEL = 'Painel LED',
  TRANSLATION = 'Tradução',
  RECORDING = 'Gravação',
  ELECTRONIC_VOTING = 'Votação eletrônica',
  OTHER_SPECIFY = 'Outro(s) (especificar)',
}
export enum ActivationEnum {
  THIRD_PARTY_AGENCY = 'Agência terceira',
  CLIENT = 'Cliente',
  MM = 'MM',
}
export enum SpecialMomentEnum {
  LINKED_TO_CONCEPT = 'Atrelado ao conceito',
  AWARD_DINNER = 'Jantar de premiação (especificar premiados)',
  OPENING_WITH_PERFORMANCE = 'Abertura de plenária com performance',
  OTHER_SPECIFY = 'Outro(s) (especificar)',
}

export enum TransportationServiceEnum {
  OFFICE_TO_EVENT = 'Do escritório até o evento',
  GUEST_VALET = 'Valet para convidados',
}
export enum KeyPointEnum {
  CLIENT_PREFERRED_SUPPLIERS = 'Fornecedores preferenciais do cliente',
  CLIENT_REHEARSAL_DAYS_INCLUDED = 'Contemplar dias de ensaio do cliente',
}
export enum ProductLaunchEvent {
  PRODUCT_LAUNCH = 'Lançamento de produto',
  PRESIDENTIAL_OPENING_BY_TRANSMISSION = 'Abertura do presidente por transmissão (presidente ausente fisicamente)',
  OTHER_SPECIFY = 'Outro(s) (especificar)',
}
export enum EventServicesEnum {
  SIGN_LANGUAGE_INTERPRETER = 'Libras',
  WEB_APP_SUPPORT = 'Web app',
  CLOAKROOM = 'Chapelaria',
  PHOTOGRAPHER = 'Fotógrafo',
  MODEL = 'Modelo',
  CLEANING = 'Limpeza',
  DAYTIME_SECURITY = 'Segurança diurno',
  NIGHTTIME_SECURITY = 'Segurança noturno',
  OTHER_SPECIFY = 'Outro(s) (especificar)',
}
export type ProductionFormFields = {
  speaker: string[]
  speakerObs: string
  musicalAttraction: string[]
  musicalAttractionObs: string
  content: string[]
  contentObs: string
  equipment: string[]
  equipmentObs: string
  agencyActivation: string[]
  agencyActivationObs: string
  specialMoment: string[]
  specialMomentDinnerObs: string
  specialMomentObs: string
  teamBuilding: string
  transportationService: string[]
  transportationServiceObs: string
  decoration: string;
  schenography: string;
  activation: string;
  gifts: string;
  musicalAttractionDescription: string;
  speakerDescription: string;
  equipmentDescription: string;
  keyPoint: string[]
  keyPointObs: string
  productLaunchEvent: string[]
  productLaunchEventObs: string
  eventServices: string[]
  eventServicesObs: string
}


export const productionDefaultValues = {
  speaker: [],
  speakerObs: '',
  musicalAttraction: [],
  musicalAttractionObs: '',
  content: [],
  contentObs: '',
  equipment: [],
  equipmentObs: '',
  agencyActivation: [],
  agencyActivationObs: '',
  specialMoment: [],
  specialMomentDinnerObs: '',
  specialMomentObs: '',
  teamBuilding: '',
  transportationService: [],
  transportationServiceObs: '',
  decoration: '',
  activation: '',
  gifts: '',
  musicalAttractionDescription: '',
  speakerDescription: '',
  equipmentDescription: '',
  keyPoint: [],
  keyPointObs: '',
  productLaunchEvent: [],
  productLaunchEventObs: '',
  eventServices: [],
  eventServicesObs: '',
  schenography: ""
}