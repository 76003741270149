import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { GridSortDirection } from "@mui/x-data-grid";
import React, { useContext, useState } from "react";
import UserService from "../../../../services/user.service";
import CellModal from "./CellsModal";
import { useQuery, useQueryClient } from "react-query";
import TableComponent from "../../../CustomDataGrid";
import { Column } from "react-table";
import { Type } from "typescript";
import { FaCircle, FaPen } from "react-icons/fa";
import { formatPhoneNumber } from "../../../../utils/formatters";
import { TbEyeEdit } from "react-icons/tb";
import { CellService } from "../../../../services/cell.service";
import { CellDescDiv } from "./styles";
import { VscChevronDown } from "react-icons/vsc";
import { AuthContext } from "../../../../contexts/auth";

interface IProps {
  goTo(destination: string): void
}

const Cells = ({goTo}: IProps) => {
  const {actionOptions} = useContext(AuthContext)
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
 

  const { isLoading, data, error } = useQuery({
    queryKey: ["cells"],
    queryFn: () => CellService.allCells(),
  });
  const [cellEdit, setCellEdit] = useState<any>();
  const [showCellModal, setShowCellModal] = useState(false);
  const [filter, setFilter] = useState({ search: "", status: "all" });

  interface ICellRow extends Type {
    _id: string;
    name: string;
    leader_user_ids: string;
    active: boolean;
    projects: string;
  }
  const columns2: Column<ICellRow>[] = [
    {
      Header: "NOME DA CÉLULA",
      accessor: "name",
      Filter: "",
      Cell: ({ cell }) => {
        return (
          <CellDescDiv style={{}}>
            <div>
              <span>
                {cell.row.original.active ? (
                  <FaCircle size={10} color="green" />
                ) : (
                  <FaCircle size={10} color="red" />
                )}{" "}
              </span>
            </div>
            <div>
              <span>{cell.row.values.name}</span>
            </div>
          </CellDescDiv>
        );
      },
    },
    {
      Header: "USUÁRIOS",
      accessor: "leader_user_ids",
      Filter: "",
      Cell: ({ cell }) => (
        <span> {`${cell.value.length} ativos; 0 inativos`}</span>
      ),
    },
    {
      Header: "PROJETOS (#MM'S)",
      accessor: "projects",
      Cell: ({ cell }) => <span> {formatPhoneNumber(cell.value)}</span>,
      Filter: "",
    },
    {
      Header: "",
      accessor: "_id",
      Cell: ({ cell }) => (
        <div
          onClick={() => handleCellEdit(cell.value)}
          style={{ cursor: "pointer" }}
        >
          {" "}
          <TbEyeEdit size={18} style={{ cursor: "pointer" }} />
        </div>
      ),
      Filter: "",
      disableSortBy: true,
    },
  ];

  function handleCellEdit(id: string) {
    let selectedCell = data?.find((cell: { _id: string }) => cell._id === id);
    if (!!selectedCell) {
      setCellEdit(selectedCell);
      setShowCellModal(true);
    }
  }

  return (
    <Box px={"24px"} pt={"24px"} pb={"28px"} sx={{ flex: 1 }}>
      {(showCellModal || cellEdit) && (
        <CellModal
          cell={cellEdit}
          onGoTo={goTo}
          onClose={() => {
            setShowCellModal(false);
            setCellEdit(undefined);
          }}
          onSuccess={() => queryClient.invalidateQueries("cells")}
        />
      )}

      <Box display={"flex"} mb={4} alignItems={"center"} flex={1}>
        <Box display={"flex"} alignItems={"center"} flex={1} gap={2}>
          <Button
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="outlined"
            onClick={(e) => setAnchorEl(e.currentTarget)}
            endIcon={<VscChevronDown />}
            sx={{
              borderColor: "#DBDADE",
              color: "#4B465C",
              fontSize: "14px",
              textTransform: "none",
            }}
          >
            Ações
          </Button>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              disabled={!actionOptions['createControle de acesso']}
              onClick={() => {
                setShowCellModal(true);
                setAnchorEl(null);
              }}
            >
              Adicionar Célula
            </MenuItem>
            <MenuItem onClick={() => {}}>Histórico</MenuItem>
          </Menu>
        </Box>
      </Box>

      <Box sx={{ minHeight: 400 }}>
        <TableComponent
          columns={columns2}
          isLoading={isLoading}
          data={data || []}
        />
      </Box>
    </Box>
  );
};

export default Cells;
