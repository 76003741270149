import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  TextField,
  FormGroup,
  Switch,
  Checkbox,
  Box,
  Button,
} from "@mui/material";
import { InputDiv, SummaryDiv, RadioDiv, InputRow, StyledBox } from "../styles";
import SaveImg from "../../../../assets/styled-icons/Save.png";
import { BsCaretDown, BsCaretUp } from "react-icons/bs";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { AccomodationFieldType, ResponsibleAgencyEnum, ResponsibleContactEnum, RsvpType } from "../../../../types/rsvp/index";

import { FormProvider, useForm } from "react-hook-form";
import { useRsvp } from "../../../../contexts/rsvp.context";
import { Input } from "../../../Input";
import { FormInputMultiCheckbox } from "../PreCongress/FormMultipleCheckBox";

type Props = {
  onSubmitNav: () => void
  isDisabled: boolean
}

export function Accomodation({ onSubmitNav, isDisabled }: Props) {
  const { rsvpId, updateAccomodation, createRsvp, accomodation } = useRsvp()

  const agencyOptions = (Object.keys(ResponsibleAgencyEnum) as Array<keyof typeof ResponsibleAgencyEnum>).map(el => {
    return { label: ResponsibleAgencyEnum[el], value: el }
  })

  const responsibleContactOptions = (Object.keys(ResponsibleContactEnum) as Array<keyof typeof ResponsibleContactEnum>).map(el => {
    return { label: ResponsibleContactEnum[el], value: el }
  })
  const [responsibleAgencyState, setResponsibleAgencyState] = useState([] as string[])
  const [responsibleContactState, setResponsibleContactState] = useState([] as string[])


  const obj: AccomodationFieldType = {
    accomodationManagement: "",
    responsibleContact: [],
    accomodationUpgrades: "",
    upgradeValues: {
      aptSingle: {
        description: "",
        valueType: "",
        obs: ""
      },
      aptDouble: {
        description: "",
        valueType: "",
        obs: ""
      },
      aptTriple: {
        description: "",
        valueType: "",
        obs: ""
      },
      proDouble: {
        description: "",
        valueType: "",
        obs: ""
      },
      singleTriple: {
        description: "",
        valueType: "",
        obs: ""
      },
      extraBed: {
        description: "",
        valueType: "",
        obs: ""
      },
      cradle: {
        description: "",
        valueType: "",
        obs: ""
      },
      policyForChildren: {
        description: "",
        valueType: "",
        obs: ""
      }
    },
    accomodationManagementObs: "",
    responsibleAgency: []
  }
  const methods = useForm({
    defaultValues: obj,
  })
  const { handleSubmit, register, watch, setValue, reset, control } = methods

  function onSubmit(form: AccomodationFieldType) {
    rsvpId ? updateAccomodation(form) : createRsvp({ accomodation: form } as RsvpType)
    onSubmitNav()
  }

  const valueFields = (Object.keys(obj.upgradeValues) as Array<keyof typeof obj.upgradeValues>).map(el => {
    return <InputRow key={el}>
      <Input
        key={`upgradeValues.${el}.description`}
        {...register(`upgradeValues.${el}.description`)}
        label="Diária extra em apt single"
      />
      <Input
        key={`upgradeValues.${el}.valueType`}
        {...register(`upgradeValues.${el}.valueType`)}
        label="Tipo de pensão"
      />
      <Input
        key={`upgradeValues.${el}.obs`}
        {...register(`upgradeValues.${el}.obs`)}
        label="Obs"
      />
    </InputRow>
  })

  useEffect(() => {
    if (accomodation) {
      setResponsibleAgencyState(accomodation.responsibleAgency)
      setResponsibleContactState(accomodation.responsibleContact)
      reset(accomodation);
    }
  }, [accomodation]);
  return (<FormProvider {...methods}>
    <StyledBox onSubmit={handleSubmit(onSubmit)}>
      <h3>3.Hospedagem</h3>
      <InputDiv>
        <SummaryDiv>
          <strong>Antecipar ou estender hospedagem</strong>
          <span>
            Como será o deslocamento. Caso tenha reembolso confirmar em RSVP e
            alinhar com convidado
          </span>
        </SummaryDiv>
        <RadioDiv>
          <FormControl>
            <RadioGroup
              value={watch("accomodationManagement")}
              onChange={(e) => setValue("accomodationManagement", e.target.value)}
            >
              <FormControlLabel
                value="REQUESTS_DIRECTL_WITHOUT_REFUND"
                control={<Radio />}
                label="Gerencia por conta (PAX solicita diretamente com o hotel, sem reembolso)"
              />
              <FormControlLabel
                value="SHIPPING_TO_MM_TRAVEL"
                control={<Radio />}
                label="Envio para a MM Travel via departamento corporativo. Informações coletadas pelo consultor RSVP (Nome completo e idade de crianças)"
              />
              <FormControlLabel
                value="VALIDATE_WITH_CLIENT"
                control={<Radio />}
                label="Validar com o cliente"
              />
            </RadioGroup>
          </FormControl>
          <Input
            {...register('accomodationManagementObs')}
            label="Outros"
            multiline
            size="small"

          />
        </RadioDiv>
      </InputDiv>
      <InputDiv>
        <SummaryDiv>
          <strong>Contatos de responsável</strong>
        </SummaryDiv>
        <RadioDiv>

          <FormGroup>
            <FormLabel>Contato</FormLabel>
            <FormInputMultiCheckbox
              name={"responsibleAgency"}
              control={control} label={'label'}
              options={agencyOptions}
              setValue={setValue}
              previousSelected={responsibleAgencyState}
              setControlledState={setResponsibleAgencyState}
              optionSelectAll={false}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Dados do Responsável</FormLabel>
            <FormInputMultiCheckbox
              name={"responsibleContact"}
              control={control} label={'label'}
              options={responsibleContactOptions}
              setValue={setValue}
              previousSelected={responsibleContactState}
              setControlledState={setResponsibleContactState}
              optionSelectAll={true}
            />
          </FormGroup>

        </RadioDiv>
      </InputDiv>
      <InputDiv>
        <SummaryDiv>
          <strong>Upgrades em valores na hospedagem</strong>
          <span>
            Procedimentos informados em 8.Outros detalhados para confirmação no
            RSVP
          </span>
        </SummaryDiv>
        <RadioDiv>
          <FormControl>
            <RadioGroup
              value={watch("accomodationUpgrades")}
              onChange={(e) => setValue("accomodationUpgrades", e.target.value)}
            >
              <FormControlLabel
                value="NO_ACCESS_UNTIL_CHECK_IN"
                control={<Radio />}
                label="Não, o convidado só terá acesso aos valores no momento do check-in"
              />
              <FormControlLabel
                value="INFORM_COSTS_ACCORDING_TO_TABLE_BELOW"
                control={<Radio />}
                label="Sim, informar os custos ao convidado conforme tabela abaixo"
              />
            </RadioGroup>
          </FormControl>
        </RadioDiv>
      </InputDiv>
      <InputDiv>
        {(watch('accomodationUpgrades') === 'INFORM_COSTS_ACCORDING_TO_TABLE_BELOW') ?
          <>
            <SummaryDiv>
              <strong>Valores</strong>
            </SummaryDiv>
            <RadioDiv>
              {valueFields}

            </RadioDiv>
          </>
          :
          <RadioDiv>
            <span>A tabela será mostrada ao selecionar o informe de custos</span>
          </RadioDiv>}
      </InputDiv>

      <Button
        variant="contained"
        
        style={{ marginLeft: "90%" }}
        type="submit"
        disabled={isDisabled}
      >
        Salvar
      </Button>
    </StyledBox>
  </FormProvider>
  );
}
