import { Page, View, Text, Image } from "@react-pdf/renderer";
import { IListByProvider } from "../../types/export";
import { styles } from "./styles";
import { ServiceRow } from "../ServiceRow";
import { useEffect, useState } from "react";
import { add, format } from "date-fns";
import budgetIcon from "../../assets/styled-icons/export-icons/budget.png";

export function ExportPage(props: any) {
  const [servicesRendered, setServicesRendered] = useState(<></>);
  function renderToScreen() {
    if (props.exportedBudget.saved_services.length < 1) {
      return <Text> Sem serviços salvos</Text>;
    }

    const accomodationServices = props.exportedBudget.saved_services.filter(
      (service: any) => service.type === "ACCOMMODATIONS"
    );
    const roomServices = props.exportedBudget.saved_services.filter(
      (service: any) => service.type === "ROOMS"
    );
    const AEB = props.exportedBudget.saved_services.filter(
      (service: any) => service.type === "FOOD_AND_DRINKS"
    );
    const EQUIPMENTS = props.exportedBudget.saved_services.filter(
      (service: any) => service.type === "EQUIPMENT"
    );
    const TRANSLATIONS = props.exportedBudget.saved_services.filter(
      (service: any) => service.type === "SIMULTANEOUS_TRANSLATION"
    );
    const supportServices = props.exportedBudget.saved_services.filter(
      (service: any) => service.type === "SUPPORT_TEAMS"
    );
    const subscriptionServices = props.exportedBudget.saved_services.filter(
      (service: any) => service.type === "SUBSCRIPTION"
    );
    const RSVP = props.exportedBudget.saved_services.filter(
      (service: any) => service.type === "RSVP"
    );
    const AIR = props.exportedBudget.saved_services.filter(
      (service: any) => service.type === "AIR"
    );
    const TRANSFER = props.exportedBudget.saved_services.filter(
      (service: any) => service.type === "TRANSFER"
    );
    const COMMUNICATIONS = props.exportedBudget.saved_services.filter(
      (service: any) => service.type === "COMMUNICATIONS"
    );
    const miscelaneousServices = props.exportedBudget.saved_services.filter(
      (service: any) => service.type === "SEVERAL"
    );

    return (
      <>
        <View style={styles.servicesDiv}>
          <View style={styles.rowTitles}>
            <View style={styles.enumDiv}>
              <Text style={styles.textHeader}></Text>
            </View>
            <View style={styles.enumDiv}>
              <Text style={styles.textHeader}>#</Text>
            </View>
            <View style={styles.typeDiv}>
              <Text style={styles.textHeader}>Produto</Text>
            </View>
            <View style={styles.typeDiv}>
              <Text style={styles.textHeader}>Fornecedor</Text>
            </View>
            <View style={styles.obsDiv}>
              <Text style={styles.textHeader}>Comp.</Text>
            </View>
            <View style={styles.date}>
              <Text style={styles.textHeader}>IN</Text>
            </View>
            <View style={styles.date}>
              <Text style={styles.textHeader}>OUT</Text>
            </View>
            <View style={styles.small}>
              <Text style={styles.textHeader}>Diárias</Text>
            </View>
            <View style={styles.small}>
              <Text style={styles.textHeader}>Qtd</Text>
            </View>
            <View style={styles.tariff}>
              <Text style={styles.textHeader}>Tarifa</Text>
            </View>
            <View style={styles.total}>
              <Text style={styles.textHeader}>Total Tarifa</Text>
            </View>
            <View style={styles.total}>
              <Text style={styles.textHeader}>Total Moeda</Text>
            </View>
            <View style={styles.total}>
              <Text style={styles.textHeader}>Total</Text>
              <Text style={styles.textHeader}>Convertido</Text>
            </View>
            <View style={styles.total}>
              <Text style={styles.textHeader}>Taxa</Text>
              <Text style={styles.textHeader}>de serviço</Text>
            </View>
            <View style={styles.total}>
              <Text style={styles.textHeader}>Imposto</Text>
              <Text style={styles.textHeader}>Local</Text>
            </View>
            <View style={styles.total}>
              <Text style={styles.textHeader}>Taxa</Text>
              <Text style={styles.textHeader}>de Turismo</Text>
            </View>
            <View style={styles.small}>
              <Text style={styles.textHeader}>Cambio</Text>
            </View>
            <View style={styles.total}>
              <Text style={styles.textHeader}>Taxa MM (FEE)</Text>
            </View>
            <View style={styles.small}>
              <Text style={styles.textHeader}>NF</Text>
            </View>
          </View>
          {/* <ServiceRow servicesList={props.exportedBudget.saved_services[0]} type="ACCOMMODATIONS" /> */}
          {accomodationServices.length > 0 && (
            <ServiceRow
              servicesList={accomodationServices}
              total={
                props.exportedBudget.general !== null
                  ? props.exportedBudget.general.hotels_total
                  : 0
              }
              totalConverted={
                props.exportedBudget.general !== null
                  ? props.exportedBudget.general.hotels_total_converted
                  : 0
              }
              type="ACCOMMODATIONS"
            />
          )}

          {roomServices.length > 0 && (
            <ServiceRow
              servicesList={roomServices}
              total={
                props.exportedBudget.general !== null
                  ? props.exportedBudget.general.rooms_total
                  : 0
              }
              totalConverted={
                props.exportedBudget.general !== null
                  ? props.exportedBudget.general.rooms_converted
                  : 0
              }
              type="ROOMS"
            />
          )}

          {AEB.length > 0 && (
            <ServiceRow
              servicesList={AEB}
              total={
                props.exportedBudget.general !== null
                  ? props.exportedBudget.general.foods_and_drinks_total
                  : 0
              }
              totalConverted={
                props.exportedBudget.general !== null
                  ? props.exportedBudget.general.foods_and_drinks_converted
                  : 0
              }
              type="AEB"
            />
          )}

          {EQUIPMENTS.length > 0 && (
            <ServiceRow
              servicesList={EQUIPMENTS}
              total={
                props.exportedBudget.general !== null
                  ? props.exportedBudget.general.equipment_total
                  : 0
              }
              totalConverted={
                props.exportedBudget.general !== null
                  ? props.exportedBudget.general.equipment_converted
                  : 0
              }
              type="EQUIPMENT"
            />
          )}

          {TRANSLATIONS.length > 0 && (
            <ServiceRow
              servicesList={TRANSLATIONS}
              total={
                props.exportedBudget.general !== null
                  ? props.exportedBudget.general.simultaneos_translation_total
                  : 0
              }
              totalConverted={
                props.exportedBudget.general !== null
                  ? props.exportedBudget.general
                      .simultaneos_translation_converted
                  : 0
              }
              type="TRANSLATION"
            />
          )}

          {supportServices.length > 0 && (
            <ServiceRow
              servicesList={supportServices}
              total={
                props.exportedBudget.general !== null
                  ? props.exportedBudget.general.support_teams_total
                  : 0
              }
              totalConverted={
                props.exportedBudget.general !== null
                  ? props.exportedBudget.general.support_teams_converted
                  : 0
              }
              type="SUPPORT"
            />
          )}

          {subscriptionServices.length > 0 && (
            <ServiceRow
              servicesList={subscriptionServices}
              total={
                props.exportedBudget.general !== null
                  ? props.exportedBudget.general.subscription_total
                  : 0
              }
              totalConverted={
                props.exportedBudget.general !== null
                  ? props.exportedBudget.general.subscription_converted
                  : 0
              }
              type="SUBS"
            />
          )}

          {RSVP.length > 0 && (
            <ServiceRow
              servicesList={RSVP}
              total={
                props.exportedBudget.general !== null
                  ? props.exportedBudget.general.rsvp_total
                  : 0
              }
              totalConverted={
                props.exportedBudget.general !== null
                  ? props.exportedBudget.general.rsvp_converted
                  : 0
              }
              type="RSVP"
            />
          )}

          {AIR.length > 0 && (
            <ServiceRow
              servicesList={AIR}
              total={
                props.exportedBudget.general !== null
                  ? props.exportedBudget.general.air_total
                  : 0
              }
              totalConverted={
                props.exportedBudget.general !== null
                  ? props.exportedBudget.general.air_converted
                  : 0
              }
              type="AIR"
            />
          )}

          {TRANSFER.length > 0 && (
            <ServiceRow
              servicesList={TRANSFER}
              total={
                props.exportedBudget.general !== null
                  ? props.exportedBudget.general.transfer_total
                  : 0
              }
              totalConverted={
                props.exportedBudget.general !== null
                  ? props.exportedBudget.general.transfer_converted
                  : 0
              }
              type="TRANSFER"
            />
          )}

          {COMMUNICATIONS.length > 0 && (
            <ServiceRow
              servicesList={COMMUNICATIONS}
              total={
                props.exportedBudget.general !== null
                  ? props.exportedBudget.general.communication_total
                  : 0
              }
              totalConverted={
                props.exportedBudget.general !== null
                  ? props.exportedBudget.general.communication_converted
                  : 0
              }
              type="COMMUNICATIONS"
            />
          )}

          {miscelaneousServices.length > 0 && (
            <ServiceRow
              servicesList={miscelaneousServices}
              total={
                props.exportedBudget.general !== null
                  ? props.exportedBudget.general.several_total
                  : 0
              }
              totalConverted={
                props.exportedBudget.general !== null
                  ? props.exportedBudget.general.several_converted
                  : 0
              }
              type="SEVERAL"
            />
          )}
        </View>
      </>
    );
  }
  useEffect(() => {
    let servicesList = renderToScreen();
    setServicesRendered(servicesList);
  }, [props]);

  function renderHeader() {
    return (
      <>
        <View style={styles.exportHeader}>
          <div style={{ ...styles.headerDiv, ...styles.backgroundBlack }}>
            <div style={styles.childHeaderDiv}>
              <Text
                style={{
                  ...styles.whiteText,
                  fontSize: 22,
                  fontWeight: "bold",
                }}
              >
                {props.clientData.clientName}
              </Text>
              <br />
              <Text
                style={{ ...styles.grayText, fontSize: 22, fontWeight: "bold" }}
              >
                Dorflex
              </Text>
            </div>
          </div>
          <div style={{ ...styles.headerDiv, ...styles.backgroundGray }}>
            <div
              style={{
                ...styles.childHeaderDiv,
                ...styles.textSpan,
                ...styles.grayText,
              }}
            >
              <div style={{ padding: "20px 0" }}>
                <Text>Evento</Text>
              </div>
              <br />
              <Text>{props.clientData.eventName}</Text>
              <div style={styles.textDiv}>
                <Text>Inicio: </Text>
                <Text>
                  {format(
                    add(new Date(props.clientData.initialDate), { hours: 3 }),
                    "dd/MM/yyyy"
                  )}
                </Text>
              </div>
              <div style={styles.textDiv}>
                <Text>Término: </Text>
                <Text>
                  {format(
                    add(new Date(props.clientData.finalDate), { hours: 3 }),
                    "dd/MM/yyyy"
                  )}
                </Text>
              </div>
              <div style={styles.textDiv}>
                <div style={styles.textDiv}>
                  <Text>PFZ: </Text>
                  <Text>{props.clientData.pfz}</Text>
                </div>
                <div style={styles.textDiv}>
                  <Text> MAAP: </Text>
                  <Text>{props.clientData.mapp}</Text>
                </div>
              </div>
            </div>
            <div style={styles.childHeaderDiv}>
              <div style={{ ...styles.textDiv, padding: "10px 0" }}>
                <Text>Nº MM</Text>
                <Text style={{ ...styles.textSpan, ...styles.grayText }}>
                  {props.clientData.mmCode}
                </Text>
              </div>

              <br />
              <Text>Atendimento</Text>
              <div style={styles.textDiv}>
                <Text style={{ ...styles.textSpan }}>Solicitante </Text>
                <Text style={{ ...styles.textSpan, ...styles.grayText }}>
                  {props.clientData.clientRequester}
                </Text>
              </div>
              <div style={styles.textDiv}>
                <Text style={{ ...styles.textSpan }}>Atend/Comercial </Text>
                <Text style={{ ...styles.textSpan, ...styles.grayText }}>
                  {props.clientData.attendance}
                </Text>
              </div>
              <div style={styles.textDiv}>
                <Text style={{ ...styles.textSpan }}>Coordenador </Text>
                <Text style={{ ...styles.textSpan, ...styles.grayText }}>
                  {props.clientData.coordinator}
                </Text>
              </div>
            </div>
          </div>
        </View>
        <View style={styles.view}>
          <div style={{ width: "90%" }}>
            <Text style={{ ...styles.header2, fontWeight: "bold" }}>
              Proposta
            </Text>
            <Text
              style={{
                ...styles.header2,
                fontSize: "14px",
                ...styles.grayText,
                marginTop: "10px",
              }}
            >
              Comparações de orçamentos
            </Text>
          </div>
        </View>
      </>
    );
  }
  return (
    <Page size="A3" orientation="landscape" style={styles.page}>
      {props.showHeader && renderHeader()}
      <View style={{ ...styles.view, marginTop: "10px" }}>
        <div style={{ width: "98%", backgroundColor: "#f3f3f3" }}>
          <div style={styles.infoDiv}>
            <div style={{ width: "15%" }}>
              <div style={styles.budgetTitleDiv}>
                <Image
                  src={budgetIcon}
                  style={{ width: "14px", marginRight: "10px" }}
                />
                <Text style={styles.textBudget}>
                  Orçamento {props.index + 1}
                </Text>
              </div>
              <div style={styles.deadlineDiv}>
                <Text style={{ ...styles.grayText, ...styles.textSpan }}>
                  DEADLINE {props.exportedBudget.budget_data.deadline}
                </Text>
              </div>
            </div>
            <div style={{ width: "30%" }}>
              <div style={styles.locationDiv}>
                <Text style={styles.textHeader}>
                  {props.exportedBudget.budget_data.place} -{" "}
                </Text>
                <Text style={{ ...styles.grayText, ...styles.textSpan }}>
                  {props.exportedBudget.budget_data.address}
                </Text>
              </div>
              <div style={styles.locationDiv}>
                <div style={styles.childBudgetDiv}>
                  <Text style={{ ...styles.grayText, ...styles.textSpan }}>
                    Início{" "}
                  </Text>
                  <Text style={{ ...styles.grayText, ...styles.textSpan }}>
                    {format(
                      add(new Date(props.clientData.initialDate), { hours: 3 }),
                      "dd/MM/yyyy"
                    )}
                  </Text>
                </div>
                <div style={styles.childBudgetDiv}>
                  <Text style={{ ...styles.grayText, ...styles.textSpan }}>
                    Término{" "}
                  </Text>
                  <Text style={{ ...styles.grayText, ...styles.textSpan }}>
                    {format(
                      add(new Date(props.clientData.finalDate), { hours: 3 }),
                      "dd/MM/yyyy"
                    )}
                  </Text>
                </div>
              </div>
            </div>
            <div style={{ width: "15%" }}>
              <div style={styles.tagsDiv}>
                <Text style={{ ...styles.grayText, ...styles.textSpan }}>
                  RFP:{" "}
                </Text>
                <Text style={{ ...styles.grayText, ...styles.textSpan }}>
                  {props.exportedBudget.budget_data.pp === "yes"
                    ? "SIM"
                    : "NÃO"}
                </Text>
              </div>
              <div style={styles.tagsDiv}>
                <Text style={{ ...styles.grayText, ...styles.textSpan }}>
                  TAG GMP:{" "}
                </Text>
                <Text style={{ ...styles.grayText, ...styles.textSpan }}>
                  {props.exportedBudget.budget_data.tag_map}
                </Text>
              </div>
            </div>
            <div style={{ width: "15%" }}>
              <div style={styles.tagsDiv}>
                <Text style={{ ...styles.grayText, ...styles.textSpan }}>
                  {props.exportedBudget.budget_data.disponibility}
                </Text>
              </div>
              <div style={styles.tagsDiv}>
                <Text style={{ ...styles.grayText, ...styles.textSpan }}>
                  {props.exportedBudget.budget_data.site}
                </Text>
              </div>
            </div>
            <div style={{ width: "25%" }}>
              <div style={styles.locationDiv}>
                <Text style={{ ...styles.grayText, ...styles.textSpan }}>
                  {props.exportedBudget.budget_data.description}
                </Text>
              </div>
            </div>
          </div>
          <div style={styles.table}>{servicesRendered}</div>
        </div>
      </View>
    </Page>
  );
}
