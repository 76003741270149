import { createContext, useContext, useState } from "react";
import {
  AccomodationFieldType, AerialFieldType, CompanionFieldsType, DisplacementFieldsType, OthersFieldType, PrecongressFieldType, RsvpType, SpeakersFieldType, TransfersFieldType
} from "../types/rsvp/index";
import RsvpService from "../services/rsvp.service";

export type RsvpContextProps = {
  notification?: any;
  loadingProject?: boolean;

  rsvp?: RsvpType;
  rsvpId?: string;
  projectId?: string;
  accomodation?: AccomodationFieldType;
  aerial?: AerialFieldType
  companion?: CompanionFieldsType
  displacement?: DisplacementFieldsType
  others?: OthersFieldType
  precongress?: PrecongressFieldType
  speakers?: SpeakersFieldType
  transfer?: TransfersFieldType


  createRsvp: (rsvpData: RsvpType) => void;
  loadRsvp: (rsvpId: string) => void;
  closeNotification: () => void;
  showNotification: (message: string, severity: string) => void;

  updateAccomodation: (accomodation: AccomodationFieldType) => void
  updateAerial: (aerial: AerialFieldType) => void
  updateCompanion: (companion: CompanionFieldsType) => void
  updateDisplacement: (displacement: DisplacementFieldsType) => void
  updateOthers: (others: OthersFieldType) => void
  updatePrecongress: (precongress: PrecongressFieldType) => void
  updateSpeakers: (speakers: SpeakersFieldType) => void
  updateTransfer: (transfer: TransfersFieldType) => void
  clearProject: () => void;
};

const RsvpContext = createContext<RsvpContextProps>(
  {} as RsvpContextProps

);

function RsvpProvider({ children }: any) {
  const [loadingProject, setLoadingRsvp] = useState(false);

  const [notification, setNotification] = useState<any>({
    open: false,
    message: "",
    severity: "success",
  });

  const [rsvp, setRsvp] = useState<RsvpType>();
  const [rsvpId, setRsvpId] = useState<string>();
  const [projectId, setProjectId] = useState<string>();
  const [accomodation, setAccomodation] = useState<AccomodationFieldType>();
  const [aerial, setAerial] = useState<AerialFieldType>()
  const [companion, setCompanion] = useState<CompanionFieldsType>()
  const [displacement, setDisplacement] = useState<DisplacementFieldsType>()
  const [others, setOthers] = useState<OthersFieldType>()
  const [precongress, setPrecongress] = useState<PrecongressFieldType>()
  const [speakers, setSpeakers] = useState<SpeakersFieldType>()
  const [transfer, setTransfer] = useState<TransfersFieldType>()



  async function updateAccomodation(accomodation: AccomodationFieldType) {
    if (rsvpId) await RsvpService.update(rsvpId, { accomodation })
    setAccomodation(accomodation)
    showNotification(`Salvo com sucesso!`)
  }
  async function updateAerial(aerial: AerialFieldType) {
    if (rsvpId) await RsvpService.update(rsvpId, { aerial })
    setAerial(aerial)
    showNotification(`Salvo com sucesso!`)

  }
  async function updateCompanion(companion: CompanionFieldsType) {
    if(rsvpId) await RsvpService.update(rsvpId, {companion})
    setCompanion(companion)
    showNotification(`Salvo com sucesso!`)
   }
  async function updateDisplacement(displacement: DisplacementFieldsType) {
    if (rsvpId) await RsvpService.update(rsvpId, { displacement })
    setDisplacement(displacement)
    showNotification(`Salvo com sucesso!`)
  }
  async function updateOthers(others: OthersFieldType) {
    if(rsvpId) await RsvpService.update(rsvpId, {others})
    setOthers(others)
    showNotification(`Salvo com sucesso!`)
   }
  async function updatePrecongress(precongress: PrecongressFieldType) {
    if (rsvpId) await RsvpService.update(rsvpId, { precongress })
    setPrecongress(precongress)
    showNotification(`Salvo com sucesso!`)
  }
  async function updateSpeakers(speakers: SpeakersFieldType) {
    if(rsvpId) await RsvpService.update(rsvpId, {speakers})
    setSpeakers(speakers)
    showNotification(`Salvo com sucesso!`)
   }
  async function updateTransfer(transfer: TransfersFieldType) {
    if(rsvpId) await RsvpService.update(rsvpId, {transfer})
    setTransfer(transfer)
    showNotification(`Salvo com sucesso!`)
  }


  function showNotification(message: string, severity = "success") {
    setNotification({ open: true, message, severity });
  }

  function closeNotification() {
    setNotification({ ...notification, open: false });
  }

  async function loadRsvp(projectId: string) {
    setLoadingRsvp(true);
    setProjectId(projectId);
    const rsvpData = await RsvpService.get(projectId).then();
    setRsvp(rsvpData);
    setRsvpId(rsvpData._id);

    const {
      accomodation,
      aerial,
      companion,
      displacement,
      others,
      precongress,
      speakers,
      transfer,
    } = rsvpData;

    setAccomodation(accomodation)
    setAerial(aerial)
    setCompanion(companion)
    setDisplacement(displacement)
    setOthers(others)
    setPrecongress(precongress)
    setSpeakers(speakers)
    setTransfer(transfer)

    setTimeout(() => setLoadingRsvp(false), 700)
  }

  async function createRsvp(rsvpData: RsvpType) {
    if (rsvpData._id) return showNotification("Não é possível criar", "error");

    const rsvp = await RsvpService.create({ ...rsvpData, project: projectId });
    showNotification("Criado com Sucesso!", "success");
    setRsvp(rsvp);
    setRsvpId(rsvp._id)
  }

  function clearProject() {
    setRsvp(undefined)
    setRsvpId(undefined)
    setAccomodation(undefined)
    setAerial(undefined)
    setCompanion(undefined)
    setDisplacement(undefined)
    setOthers(undefined)
    setPrecongress(undefined)
    setSpeakers(undefined)
    setTransfer(undefined)
  }

  return (
    <RsvpContext.Provider
      value={{
        notification,
        loadingProject,
        rsvp,
        rsvpId,
        projectId,
        accomodation,
        aerial,
        companion,
        displacement,
        others,
        precongress,
        speakers,
        transfer,
        createRsvp,
        loadRsvp,
        closeNotification,
        showNotification,
        updateAccomodation,
        updateAerial,
        updateCompanion,
        updateDisplacement,
        updateOthers,
        updatePrecongress,
        updateSpeakers,
        updateTransfer,
        clearProject
      }}
    >
      {children}
    </RsvpContext.Provider>
  );
}

const useRsvp = () => useContext(RsvpContext);
export { RsvpProvider, useRsvp };
