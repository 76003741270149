import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as SelectMaterial,
  SelectProps,
} from "@mui/material";
import React, { forwardRef } from "react";

interface Props extends SelectProps {
  helperText?: string;
}

const SelectForm = forwardRef<HTMLInputElement, Props>(
  ({ label, children, helperText, ...props }, ref) => {
    return (
      <Box width={"100%"} margin={'0 1'}>
        <span style={{ fontSize: 15, paddingBottom: '10px' }}>
          {label}
        </span>

        <SelectMaterial
          {...props}
          size="small"
          label=""
          sx={{ width: "100%" }}
          MenuProps={{ style: { maxHeight: 450 } }}
        >
          <MenuItem value="">
            <em>Não selecionado</em>
          </MenuItem>
          {children}
        </SelectMaterial>

        {props.error && (
          <FormHelperText error={props.error}>{helperText}</FormHelperText>
        )}
      </Box>
    );
  }
);

export default SelectForm;
