export type AccomodationFieldType = {
  _id?: string;
  accomodationManagement: string
  accomodationManagementObs: string;
  responsibleContact: string[]
  responsibleAgency: string[]
  accomodationUpgrades: string
  upgradeValues: UpgradeValue
};


type UpgradeValuesFields = {
  description: string;
  valueType: string;
  obs: string
}

type UpgradeValue = {
  aptSingle: UpgradeValuesFields;
  aptDouble: UpgradeValuesFields;
  aptTriple: UpgradeValuesFields;
  proDouble: UpgradeValuesFields;
  singleTriple: UpgradeValuesFields;
  extraBed: UpgradeValuesFields;
  cradle: UpgradeValuesFields;
  policyForChildren: UpgradeValuesFields;
}




export enum ResponsibleContactEnum {
  RESPONSIBLE_DATA = 'Dados do Responsável',
  NAME = 'Nome',
  PHONE = 'Fone',
  EMAIL = 'e-mail',
}

export enum ResponsibleAgencyEnum {
  OFFICIAL_AGENCY = 'Agência oficial',
  HOTEL = 'Hotel',
}