import { useState, createContext } from "react";
import { format, add } from "date-fns";
import {
  ProjectContextData,
  ProjectProviderType,
} from "../types/projectContext";
import {
  IBriefingData,
  ICustomerResgister,
  IFinancialData,
  IProjectData,
} from "../types/initialBriefing";
import {
  IAeBInterface,
  ILogisticaData,
  IMeetingsData,
  IParking,
  ITransfers,
  IWebConnections,
} from "../types/logistica";
import {
  ICadastroAereo,
  IManagementFields,
  IPagamentoData,
} from "../types/aereo";
import { api } from "../services/apiClient";
import { toast } from "react-toastify";
import { IBudgetData } from "../types/budget";
import {
  IAccomodationState,
  IAerialChildrenProps,
  IAerialState,
  ICompanionState,
  IDisplacementState,
  IFlightClasses,
  IGuestCosts,
  IOthersState,
  IPreCongressChildrenProps,
  IPreCongressState,
  IRSVPData,
  IRSVPStates,
  IResponsibleContacts,
  ISpeakersState,
  ITransferState,
} from "../types/rsvp";
import { IProjectSummary } from "../types/project";
import {
  AEB_DATA,
  CRIATIVO_DATA,
  CUSTOMER_REGISTER,
  FINANCIAL_DATA,
  INITIAL_BRIEFING,
  LOGISTICA_DATA,
  MANAGEMENT_DATA,
  MEETINGS_DATA,
  PAGAMENTO_DATA,
  PARKING_DATA,
  PRODUCAO_DATA,
  PROJECT_CRIATIVE_DATA,
  PROJECT_DATA,
  PROJECT_PRODUCAO_DATA,
  RSVP_DATA,
  TRANFERS_DATA,
  WEB_CONNECTIONS,
} from "../utils/projectContextInitialStates";
import { GridRowId } from "@mui/x-data-grid";
import { IProjectCriativoData, IProjectCriativoProducaoData } from "../types/projectCriativeProduction";

export const ProjectContext = createContext<ProjectContextData>(
  {} as ProjectContextData
);

const ProjectProvider: React.FC<ProjectProviderType> = ({ children }) => {
  const [isANewProject, setIsANewProjectData] = useState<boolean>(false);
  const [projectId, setProjectId] = useState("");
  const [criativoId, setCriativoId] = useState("");
  const [producaoId, setProducaoId] = useState("");
  const [logisticaId, setLogisticaId] = useState("");
  const [aereoId, setAereoId] = useState("");
  const [rsvpId, setRsvpId] = useState("");
  const [budgets, setBudgetsData] = useState<IBudgetData[]>([]);
  const [budgetsToExport, setBudgetsToExport] = useState<GridRowId[]>([]);
  const [briefing, setBriefingData] = useState<IBriefingData>(
    INITIAL_BRIEFING as IBriefingData
  );
  const [customerRegister, setCustomerRegisterData] =
    useState<ICustomerResgister>(CUSTOMER_REGISTER as ICustomerResgister);
  const [financial, setFinancialData] = useState<IFinancialData>(
    FINANCIAL_DATA as IFinancialData
  );
  const [allProjects, setAllProjectsData] = useState<IProjectSummary[]>([]);
  const [project, setProjectData] = useState<IProjectData>(
    PROJECT_DATA as IProjectData
  );

  function setIsANewProject(flag: boolean) {
    setIsANewProjectData(flag);
  }
  function setBriefing(briefing: IBriefingData) {
    setBriefingData(briefing);
  }
  function setCustomerRegister(customerRegister: ICustomerResgister) {
    setCustomerRegisterData(customerRegister);
  }
  function setFinancial(financial: IFinancialData) {
    setFinancialData(financial);
  }
  function setProject(project: IProjectData) {
    setProjectData(project);
  }
  function setProjectID(id: string) {
    setProjectId(id);
  }
  function setProducaoID(id: string) {
    setProducaoId(id);
  }
  function setCriativoID(id: string) {
    setCriativoId(id);
  }
  function setLogisticaID(id: string) {
    setLogisticaId(id);
  }
  function setAereoID(id: string) {
    setAereoId(id);
  }
  function setRsvpID(id: string) {
    setRsvpId(id);
  }
  function setBudgets(budgets: IBudgetData[]) {
    setBudgetsData(budgets);
  }
  const [criativo, setCriativoData] = useState<IProjectCriativoData>(
    PROJECT_CRIATIVE_DATA as IProjectCriativoData
  );
  const [producao, setProducaoData] = useState<IProjectCriativoProducaoData>(
    PROJECT_PRODUCAO_DATA as IProjectCriativoProducaoData
  );
  function setCriativo(criativo: IProjectCriativoData) {
    setCriativoData(criativo);
  }
  function setProducao(producao: IProjectCriativoProducaoData) {
    setProducaoData(producao);
  }

  const [aeb, setAebData] = useState<IAeBInterface>(AEB_DATA as IAeBInterface);
  const [logistica, setLogisticaData] = useState<ILogisticaData>(
    LOGISTICA_DATA as ILogisticaData
  );
  const [meetings, setMeetingsData] = useState<IMeetingsData>(
    MEETINGS_DATA as IMeetingsData
  );
  const [parking, setParkingData] = useState<IParking>(
    PARKING_DATA as IParking
  );
  const [transfers, setTransfersData] = useState<ITransfers>(
    TRANFERS_DATA as ITransfers
  );
  const [webConnetions, setWebConnectionsData] = useState<IWebConnections>(
    WEB_CONNECTIONS as IWebConnections
  );
  function setAeB(aeb: IAeBInterface) {
    setAebData(aeb);
  }
  function setLogistica(logistica: ILogisticaData) {
    setLogisticaData(logistica);
  }
  function setMeetings(meetings: IMeetingsData) {
    setMeetingsData(meetings);
  }
  function setParking(parking: IParking) {
    setParkingData(parking);
  }
  function setTransfers(transfers: ITransfers) {
    setTransfersData(transfers);
  }
  function setWebConnections(webConnections: IWebConnections) {
    setWebConnectionsData(webConnections);
  }

  const [cadastroAereo, setCadastroAereoData] = useState<ICadastroAereo[]>([]);
  const [management, setManagementData] = useState<IManagementFields>(
    MANAGEMENT_DATA as IManagementFields
  );
  const [pagamento, setPagamentoData] = useState<IPagamentoData>(
    PAGAMENTO_DATA as IPagamentoData
  );

  function setICadastroAereo(cadastroAereo: ICadastroAereo[]) {
    setCadastroAereoData(cadastroAereo);
  }
  function setManagement(management: IManagementFields) {
    setManagementData(management);
  }
  function setPagamento(pagamento: IPagamentoData) {
    setPagamentoData(pagamento);
  }
  const [rsvp, setRsvpData] = useState<IRSVPStates>(RSVP_DATA);
  function setRSVP(rsvp: IRSVPStates) {
    setRsvpData(rsvp);
  }
  const [isLoading, setIsLoading] = useState(false);
  function setLoading(value: boolean) {
    setIsLoading(value);
  }

  const [mmCode, setMMCode] = useState("");

  async function getProducaoData(project_id: string) {
    try {
      const response = await api.get(`/production/get`, {
        params: { briefing_id: project_id },
      });
      let {
        _id,
        briefing_id,
        speaker,
        speakerObs,
        musicalAttraction,
        musicalAttractionObs,
        content,
        contentObs,
        equipment,
        equipmentObs,
        activations,
        specialMoment,
        teamBuilding,
        transportValet,
        scenography3d,
        actives,
        gifts,
        decorationsObs,
        speakersMCs,
        equipmentsText,
        attentionPoints,
        extras
      } = response.data;
      setProducaoID(_id);
      let producao = {
        briefing_id: briefing_id !== undefined ? briefing_id : "",
        speaker: speaker !== undefined ? speaker : "",
        speakerObs: speakerObs !== undefined ? speakerObs : "",
        musicalAttraction: musicalAttraction !== undefined ? musicalAttraction : "",
        musicalAttractionObs: musicalAttractionObs !== undefined ? musicalAttractionObs : "",
        content: content !== undefined ? content : [],
        contentObs: contentObs !== undefined ? contentObs : "",
        equipment: equipment !== undefined ? equipment : "",
        equipmentObs: equipmentObs !== undefined ? equipmentObs : "",
        activations: activations !== undefined ? activations : "",
        specialMoment: specialMoment !== undefined ? specialMoment : "",
        teamBuilding: teamBuilding !== undefined ? teamBuilding : "",
        transportValet: transportValet !== undefined ? transportValet : "",
        scenography3d: scenography3d !== undefined ? scenography3d : "",
        actives: actives !== undefined ? actives : "",
        gifts: gifts !== undefined ? gifts : "",
        decorationsObs: decorationsObs !== undefined ? decorationsObs : "",
        speakersMCs: speakersMCs !== undefined ? speakersMCs : "",
        equipmentsText: equipmentsText !== undefined ? equipmentsText : "",
        attentionPoints: attentionPoints !== undefined ? attentionPoints : "",
        extras: extras !== undefined ? extras : "",
      };
      setProducao(producao);
      return { producao };
    } catch (err: any) {
      toast.error(err.message);
    }
  }
  async function getInitialBriefing(project_id: string) {
    try {
      const response = await api.get(
        `/briefing/get-initial-briefing/${project_id}`
      );
      const {
        _id,
        customer_corporate_name,
        customer_document,
        external_client_id,
        customer_name,
        customer_address,
        customer_phone,
        customer_email,
        customer_cell,
        customer_cell_leader,
        briefing_event_name,
        briefing_length,
        briefing_event_type,
        briefing_briefing_date,
        briefing_delivery_date,
        briefing_presentation_date,
        briefing_concurrence,
        briefing_is_international,
        briefing_number_agencies,
        briefing_occupation_area,
        briefing_observation,
        financial_has_budget,
        financial_budget_value,
        financial_payment_date,
        financial_fees_charged,
        financial_has_advance_money,
        financial_advance_money,
        financial_logistic_value,
        financial_direct_customer_payment,
        financial_aerial_value,
        financial_aerial_value_fee_mm,
        financial_rsvp_value,
        financial_total_value_invoiced,
        financial_creation_value,
        financial_value_fee_service,
        financial_production_value,
        financial_billing_term,
        financial_form_payment,
        financial_debit_note,
        financial_financing,
        project_responsible,
        project_event_location,
        project_event_type,
        project_initial_date,
        project_final_date,
        project_public,
        project_profile,
        project_participants_number,
        project_address,
        project_streaming,
        project_event_duration,
        project_initial_hour,
        project_final_hour,
        project_objectives,
        project_challenges,
        project_observation,
        has_pfz,
        pfz,
        has_mapp,
        mapp,
        attendance_1,
        attendance_2,
        coordinator_1,
        coordinator_2,
        commercial_new_business,
        customer_segment_client,
        mm_code,
        year_code,
        financial_observation,
        file_url,
      } = response.data;

      if (mm_code !== undefined) setMMCode(mm_code + "/" + year_code);

      let customerRegister: ICustomerResgister = {
        id:
          response.data.external_client_id !== undefined
            ? response.data.external_client_id
            : "",
        companyName:
          response.data.customer_corporate_name !== undefined
            ? response.data.customer_corporate_name
            : "",
        companyRegisterNumber:
          response.data.customer_document !== undefined
            ? response.data.customer_document
            : "",
        address: customer_address !== undefined ? customer_address : "",
        applicantName: customer_name !== undefined ? customer_name : "",
        contact: customer_phone !== undefined ? customer_phone : "",
        email: customer_email !== undefined ? customer_email : "",
        cell: customer_cell || "",
        cellLeader: customer_cell_leader || "",
        attendance_1: attendance_1 !== undefined ? attendance_1 : "",
        coordinator_1: coordinator_1 !== undefined ? coordinator_1 : "",
        attendance_2: attendance_2 || "",
        coordinator_2: coordinator_2 || "",
        commercial_new_business: commercial_new_business || "",
        clientSegment: customer_segment_client || "",
      };
      setCustomerRegister(customerRegister);

      let briefing: IBriefingData = {
        briefing_length: briefing_length || "",
        eventName: !!briefing_event_name ? briefing_event_name : "",
        eventType: !!briefing_event_type ? briefing_event_type : "",
        briefingDate: !!briefing_briefing_date
          ? format(
              add(new Date(briefing_briefing_date), { hours: 3 }),
              "yyyy-MM-dd"
            )
          : "",
        deliveryDate: !!briefing_delivery_date
          ? format(
              add(new Date(briefing_delivery_date), { hours: 3 }),
              "yyyy-MM-dd"
            )
          : "",
        presentationDate: !!briefing_presentation_date
          ? format(
              add(new Date(briefing_presentation_date), { hours: 3 }),
              "yyyy-MM-dd"
            )
          : "",
        competition:
          briefing_concurrence !== undefined ? briefing_concurrence : null,
        isInternational:
          briefing_is_international !== undefined
            ? briefing_is_international
            : null,
        competitionObs:
          briefing_number_agencies !== undefined
            ? briefing_number_agencies
            : "",
        projectArea:
          briefing_occupation_area !== undefined
            ? briefing_occupation_area
            : null,
        fileName: file_url,
        observations:
          briefing_observation !== undefined ? briefing_observation : "",
        isPfizer: has_pfz !== undefined ? has_pfz : null,
        isMapp: has_mapp !== undefined ? has_mapp : null,
        pfizerInputs: pfz !== undefined ? pfz : "",
        mappInputs: mapp !== undefined ? mapp : "",
      };
      setBriefingData(briefing);
      let financial: IFinancialData = {
        isBudgetDefined:
          financial_has_budget !== undefined ? financial_has_budget : null,
        budgetValue:
          financial_budget_value !== undefined
            ? financial_budget_value
            : undefined,
        paymentDate: !!financial_payment_date
          ? format(
              add(new Date(financial_payment_date), { hours: 3 }),
              "yyyy-MM-dd"
            )
          : "",
        advance:
          financial_has_advance_money !== undefined
            ? financial_has_advance_money
            : null,
        advanceValue:
          financial_advance_money !== undefined ? financial_advance_money : "",
        observations:
          financial_observation !== undefined ? financial_observation : "",
        budgetValueAerial: financial_aerial_value,
        budgetValueAerialFeeMM: financial_aerial_value_fee_mm,
        budgetValueLogistics: financial_logistic_value,
        budgetDirectCustomerPayment: financial_direct_customer_payment,
        budgetValueCreative: financial_creation_value,
        budgetValueFeeService: financial_value_fee_service,
        budgetValueRSVP: financial_rsvp_value,
        budgetTotalValueInvoiced: financial_total_value_invoiced,
        budgetValueProduction: financial_production_value,
        billingPeriod: financial_billing_term,
        paymentMethod: financial_form_payment,
        debtNote: financial_debit_note,
        financing: financial_financing,
      };
      setFinancialData(financial);
      let project: IProjectData = {
        eventResponsible:
          project_responsible !== undefined ? project_responsible : "",
        project_event_location: project_event_location || "",
        eventType: project_event_type !== undefined ? project_event_type : "",
        initialDate: !!project_initial_date
          ? format(
              add(new Date(project_initial_date), { hours: 3 }),
              "yyyy-MM-dd"
            )
          : "",
        finalDate: !!project_final_date
          ? format(
              add(new Date(project_final_date), { hours: 3 }),
              "yyyy-MM-dd"
            )
          : "",
        initialHour:
          project_initial_hour !== undefined ? project_initial_hour : "",
        finalHour: project_final_hour !== undefined ? project_final_hour : "",
        streamed: project_streaming !== undefined ? project_streaming : null,
        eventDuration:
          project_event_duration !== undefined ? project_event_duration : "",
        eventObjective:
          project_objectives !== undefined ? project_objectives : "",
        customerChallenges:
          project_challenges !== undefined ? project_challenges : "",
        public: project_public !== undefined ? project_public : "",
        profile: project_profile !== undefined ? project_profile : "",
        guestAmount:
          project_participants_number !== undefined
            ? project_participants_number
            : 0,
        address: project_address !== undefined ? project_address : "",
        observations:
          project_observation !== undefined ? project_observation : "",
      };
      setProjectData(project);
      return response.data === undefined
        ? undefined
        : { customerRegister, briefing, financial, project };
    } catch (error: any) {
      toast.error(error);
    }
  }

  let notFilled = [undefined, ""];
  async function getLogisticaData(project_id: string) {
    try {
      let response = await api.get(`/logistics/get`, {
        params: { briefing_id: project_id },
      });

      setLogisticaID(response.data._id);
      let logistica = {
        specificKPI:
          response.data.has_kpi !== undefined ? response.data.has_kpi : null,
        kpiObs:
          response.data.kpi_observation !== undefined
            ? response.data.kpi_observation
            : "",
      };
      setLogisticaData(logistica);
      let meetings = {
        meetingsRoom:
          response.data.has_meet_room !== undefined
            ? response.data.has_meet_room
            : null,
        meetingsRoomObs:
          response.data.meet_room_observation !== undefined
            ? response.data.meet_room_observation
            : "",
        meetingsRoomGuestNumber:
          response.data.meet_room_number_people !== undefined
            ? response.data.meet_room_number_people
            : "",
        meetingsRoomsNumber:
          response.data.meet_room_number_rooms !== undefined
            ? response.data.meet_room_number_rooms
            : "",
        meetingsRoomCheckIn: !notFilled.includes(
          response.data.meet_room_date_in
        )
          ? format(
              add(new Date(response.data.meet_room_date_in), { hours: 3 }),
              "yyyy-MM-dd"
            )
          : "",
        meetingsRoomCheckOut: !notFilled.includes(
          response.data.meet_room_accommodation_date_out
        )
          ? format(
              add(new Date(response.data.meet_room_accommodation_date_out), {
                hours: 3,
              }),
              "yyyy-MM-dd"
            )
          : "",
      };
      setMeetings(meetings);
      let aeb = {
        aeb:
          response.data.has_drinks_foods !== undefined
            ? response.data.has_drinks_foods
            : null,
        aebOptions:
          response.data.drinks_foods_options !== undefined
            ? response.data.drinks_foods_options
            : "",
        equipments:
          response.data.has_equipment !== undefined
            ? response.data.has_equipment
            : null,
        equipmentsObs:
          response.data.equipment_description !== undefined
            ? response.data.equipment_description
            : "",
        equipmentsQtd:
          response.data.equipment_number !== undefined
            ? response.data.equipment_number
            : "",
        admin:
          response.data.has_administrator !== undefined
            ? response.data.has_administrator
            : null,
        adminType:
          response.data.coordinator_type !== undefined
            ? response.data.coordinator_type
            : "",
        adminQtd:
          response.data.administrator_quantity !== undefined
            ? response.data.administrator_quantity
            : "", // missing
        aebCheckIn: !notFilled.includes(response.data.drinks_foods_date_in)
          ? format(
              add(new Date(response.data.drinks_foods_date_in), { hours: 3 }),
              "yyyy-MM-dd"
            )
          : "",
        aebCheckOut: !notFilled.includes(response.data.drinks_foods_date_out)
          ? format(
              add(new Date(response.data.drinks_foods_date_out), { hours: 3 }),
              "yyyy-MM-dd"
            )
          : "",
        aebObs:
          response.data.drinks_foods_observations !== undefined
            ? response.data.drinks_foods_observations
            : "",
      };
      setAeB(aeb);

      let diversos = {
        diversos:
          response.data.has_transfers !== undefined
            ? response.data.has_transfers
            : null,
        diversosQtd:
          response.data.transfers_quantity !== undefined
            ? response.data.transfers_quantity
            : "",
        diversosCheckIn: !notFilled.includes(response.data.transfers_date_in)
          ? format(
              add(new Date(response.data.transfers_date_in), { hours: 3 }),
              "yyyy-MM-dd"
            )
          : "",
        diversosCheckOut: !notFilled.includes(response.data.transfers_date_out)
          ? format(
              add(new Date(response.data.transfers_date_out), { hours: 3 }),
              "yyyy-MM-dd"
            )
          : "",
        diversosObs:
          response.data.transfers_observations !== undefined
            ? response.data.transfers_observations
            : "",
      };
      setTransfers(diversos);
      let webConnections = {
        webConnection:
          response.data.has_access_point !== undefined
            ? response.data.has_access_point
            : null,
        webConnectionSpeed:
          response.data.access_point_speed !== undefined
            ? response.data.access_point_speed
            : "",
        webConnectionObs:
          response.data.access_point_observations !== undefined
            ? response.data.access_point_observations
            : "",
        webConnectionQtd:
          response.data.access_point_quantity !== undefined
            ? response.data.access_point_quantity
            : "",
        webConnectionCheckIn: !notFilled.includes(
          response.data.access_point_date_in
        )
          ? format(
              add(new Date(response.data.access_point_date_in), { hours: 3 }),
              "yyyy-MM-dd"
            )
          : "",
        webConnectionCheckOut: !notFilled.includes(
          response.data.access_point_date_out
        )
          ? format(
              add(new Date(response.data.access_point_date_out), { hours: 3 }),
              "yyyy-MM-dd"
            )
          : "",
      };
      setWebConnections(webConnections);
      let parking = {
        parking:
          response.data.has_parking !== undefined
            ? response.data.has_parking
            : null,
        parkingValue:
          response.data.parking_value !== undefined
            ? response.data.parking_value
            : "",
        parkingObs:
          response.data.parking_observations !== undefined
            ? response.data.parking_observations
            : "",
      };
      setParking(parking);
      return { webConnections, diversos, parking, meetings, logistica, aeb };
    } catch (err: any) {
      toast.error(err);
    }
  }
  async function getCriativoData(project_id: string) {
    try {
      let response = await api.get(`/creative/get`, {
        params: { briefing_id: project_id },
      });

      setCriativoID(response.data._id);
      let criativo = {
        theme:
          response.data.has_theme !== undefined
            ? response.data.has_theme
            : null,
            briefing_id:
            response.data.briefing_id !== undefined
            ? response.data.has_theme
            : "",
        contentResponsibility: null,
        contentResponsibilityObs:
          response.data.contentResponsibilityObs !== undefined
            ? response.data.contentResponsibilityObs
            : "",
          creativeDirectionals: null,
        createArtMM:
          response.data.createArtMM !== undefined
            ? response.data.createArtMM
            : "",
        creativeDirectionalsExemple:
          response.data.creativeDirectionalsExemple !== undefined
            ? response.data.creatcreativeDirectionalsExemplee_art_obervations
            : "",
        gifts:
          response.data.gifts !== undefined
            ? response.data.gifts
            : null,
        moreOneArtOption:
          response.data.gmoreOneArtOptionfts !== undefined
              ? response.data.moreOneArtOption
              : null,
        ownKVObs:
          response.data.own_delivery_obervations !== undefined
            ? response.data.own_delivery_obervations
            : "",
        keyMessages:
          response.data.keyMessages !== undefined
            ? response.data.keyMessages
            : null,
         scenography3dExpense:
          response.data.scenography3dExpense !== undefined
            ? response.data.scenography3dExpense
            : "",
        scenographyDigital3d:
          response.data.scenographyDigital3d !== undefined
            ? response.data.scenographyDigital3d
            : null,
        typeArtMaterial:
          response.data.typeArtMaterial !== undefined
            ? response.data.typeArtMaterial
            : "",
        typeArtMaterialObs:
          response.data.typeArtMaterialObs !== undefined
            ? response.data.typeArtMaterialObs
            : "",
        typeKVMaterial: response.data.typeKVMaterial !== undefined ? response.data.typeKVMaterial : null,
        typeMaterialObs:
          response.data.typeMaterialObs !== undefined
            ? response.data.typeMaterialObs
            : ""
      };
      setCriativo(criativo);
      return { criativo };
    } catch (err: any) {
      toast.error(err);
    }
  }
  async function getRSVPData(project_id: string) {
    try {
      let response = await api.get(`/rsvp/get`, {
        params: { briefing_id: project_id },
      });
      let preCongressState: IPreCongressState = {
        presence: response.data.pre_event.presence.presence_type,
        presenceOthers: response.data.pre_event.presence.others,
        congressLogistics: response.data.pre_event.congress.congress_type,
        congressLogisticsOthers: response.data.pre_event.congress.others,
        rsvpSystem: response.data.pre_event.system_rsvp.has_applied,
        rsvpSystemObs: response.data.pre_event.system_rsvp.others,
        sponsorDivergence: response.data.pre_event.divergence.divergence_type,
        sponsorDivergenceOthers: response.data.pre_event.divergence.others,
        guestsRequiredData: response.data.pre_event.invitation.invitation_type,
        defaultDocuments: false,
        defaultDocumentsOthers: response.data.pre_event.invitation.others,
        memberInstitution:
          response.data.pre_event.member.institution_to_be_confirmed,
        memberInstitutionOthers: response.data.pre_event.member.others,
        institutionConfirmation: response.data.pre_event.member.others,
        foodService: response.data.pre_event.food.food_type,
        foodServiceOthers: response.data.pre_event.food.others,
      };
      let preCongressChildren: IPreCongressChildrenProps = {
        passport_name:
          response.data.pre_event.invitation.has_full_name_as_in_passport,
        passport_number: response.data.pre_event.invitation.has_passport_number,
        passport_validity:
          response.data.pre_event.invitation.has_passport_validity,
        vist: response.data.pre_event.invitation.has_has_valid_visa,
        vaccin_card: response.data.pre_event.invitation.has_intl_vacc_cert,
        tag_name: response.data.pre_event.invitation.has_badge_name,
        birth_day: response.data.pre_event.invitation.has_date_of_birth,
        cpf: response.data.pre_event.invitation.has_cpf,
        rg: response.data.pre_event.invitation.has_rg,
        phone: response.data.pre_event.invitation.has_cell_phone,
        landline: response.data.pre_event.invitation.has_landline_phone,
        crm_crf_coren:
          response.data.pre_event.invitation.has_crm_or_crf_or_coren,
        specialty: response.data.pre_event.invitation.has_specialty,
        institution:
          response.data.pre_event.invitation.has_workplace_institution,
        complete_adress: response.data.pre_event.invitation.has_full_address,
      };
      let displacement: IDisplacementState = {
        airportChange:
          response.data.displacement.change_airport.change_airport_type,
        airportChangeOthers: response.data.displacement.change_airport.others,
        parking: response.data.displacement.parking.parking_type,
        parkingOthers: "",
        vehicleAuthorization:
          response.data.displacement.vehicle_information
            .vehicle_information_type,
        vehicleAuthorizationOthers:
          response.data.displacement.vehicle_information.others,
        refund: response.data.displacement.reimbursement.reimbursement_type,
        refundOthers: response.data.displacement.reimbursement.others,
      };
      let accomodation: IAccomodationState = {
        accomodationManagement:
          response.data.accommodation.antecipation_hosting
            .antecipation_hosting_type,
        accomodationManagementOthers:
          response.data.accommodation.antecipation_hosting.others,
        responsibleContact: false,
        valueUpgrade:
          response.data.accommodation.upgrade_values.updade_value_type,
      };
      let guestCosts: IGuestCosts = {
        aptSingle: {
          extra:
            response.data.accommodation.upgrade_values.apt_single.description,
          pension:
            response.data.accommodation.upgrade_values.apt_single.value_type,
          obs: response.data.accommodation.upgrade_values.apt_single.obs,
        },
        aptDouble: {
          extra:
            response.data.accommodation.upgrade_values.apt_double.description,
          pension:
            response.data.accommodation.upgrade_values.apt_double.value_type,
          obs: response.data.accommodation.upgrade_values.apt_double.obs,
        },
        aptTriple: {
          extra:
            response.data.accommodation.upgrade_values.apt_triple.description,
          pension:
            response.data.accommodation.upgrade_values.apt_triple.value_type,
          obs: response.data.accommodation.upgrade_values.apt_triple.obs,
        },
        singleExDouble: {
          extra:
            response.data.accommodation.upgrade_values.pro_double.description,
          pension:
            response.data.accommodation.upgrade_values.pro_double.value_type,
          obs: response.data.accommodation.upgrade_values.pro_double.obs,
        },
        singleExTriple: {
          extra:
            response.data.accommodation.upgrade_values.pro_double.description,
          pension:
            response.data.accommodation.upgrade_values.pro_double.value_type,
          obs: response.data.accommodation.upgrade_values.pro_double.obs,
        },
        extraBed: {
          extra:
            response.data.accommodation.upgrade_values.extra_bed.description,
          pension:
            response.data.accommodation.upgrade_values.extra_bed.value_type,
          obs: response.data.accommodation.upgrade_values.extra_bed.obs,
        },
        cradle: {
          extra: response.data.accommodation.upgrade_values.cradle.description,
          pension: response.data.accommodation.upgrade_values.cradle.value_type,
          obs: response.data.accommodation.upgrade_values.cradle.obs,
        },
        childPolicy: {
          extra:
            response.data.accommodation.upgrade_values.policy_for_children
              .description,
          pension:
            response.data.accommodation.upgrade_values.policy_for_children
              .value_type,
          obs: response.data.accommodation.upgrade_values.policy_for_children
            .obs,
        },
      };
      let responsibleContact: IResponsibleContacts = {
        agency: response.data.accommodation.person_contacts.has_official_agency,
        hotel: response.data.accommodation.person_contacts.has_hotel,
        responsibleName: response.data.accommodation.person_contacts.has_name,
        responsiblePhone: response.data.accommodation.person_contacts.has_phone,
        responsibleEmail: response.data.accommodation.person_contacts.has_email,
        responsibleData: false,
      };
      responsibleContact.responsibleData =
        responsibleContact.agency &&
        responsibleContact.hotel &&
        responsibleContact.responsibleEmail &&
        responsibleContact.responsibleName &&
        responsibleContact.responsiblePhone;

      let companion: ICompanionState = {
        companionOption: response.data.escort.escort.escort_type,
        companionOptionOthers: response.data.escort.escort.others,
        hasntAttended: response.data.escort.opt_work.others,
        optedForCompanion: response.data.escort.substitution_nomination.others,
      };
      let flightClasses: IFlightClasses = {
        economic: response.data.aerial.specify.has_economic,
        ecnomicPremium: response.data.aerial.specify.has_economic_premium,
        executive: response.data.aerial.specify.has_executive,
        first: response.data.aerial.specify.has_first,
        all: false,
      };
      flightClasses.all =
        flightClasses.economic &&
        flightClasses.ecnomicPremium &&
        flightClasses.executive &&
        flightClasses.first;

      let aerial: IAerialState = {
        paymentMethod: response.data.aerial.payment.payment_type,
        paymentMethodOthers: response.data.aerial.payment.others,
        paymentMethodMaxValue: response.data.aerial.payment.max_value,
        guestSolicitations:
          response.data.aerial.guest_solicitation
            .guest_solicitation_allowed_aproved_type,
        changeInAccomodation:
          response.data.aerial.guest_solicitation.has_accommodation,
        changeInFlight: response.data.aerial.guest_solicitation.has_aerial,
        guestSolicitationOthers: response.data.aerial.guest_solicitation.others,
        guestSolicitationsPeriod: "",
        changeInPlaneTickets:
          response.data.aerial.change_ticket.change_ticket_type,
        changeInPlaneTicketsOthers: response.data.aerial.change_ticket.others,
        guestSolicitationPeriod:
          response.data.aerial.guest_solicitation
            .guest_solicitation_hours_daily_type,
      };
      let defaultDocuments: IAerialChildrenProps = {
        passport: response.data.aerial.document.has_passport,
        docVist: response.data.aerial.document.has_consular_visa,
        travelInsuranceClient: response.data.aerial.document.has_travel_client,
        travelInsuranceGuest: response.data.aerial.document.has_travel_guest,
        vaccinCardTripleViral:
          response.data.aerial.document.has_mmr_vacine_certificate,
        vaccinCardYellowFever:
          response.data.aerial.document.has_yellow_vacine_certificate,
        docRg: response.data.aerial.document.has_identity_document,
        docCnh: response.data.aerial.document.has_driving_license,
        others: response.data.aerial.document.others,
      };

      let transfer: ITransferState = {
        transferCompanion: response.data.transfer.escort_type,
        companionValue: response.data.transfer.escort.others,
        companionOthers: response.data.transfer.escort.others,
        scheduleChange: response.data.transfer.change_hours.change_hour_type,
        scheduleChangeOthers: response.data.transfer.change_hours.others,
      };
      let speakers: ISpeakersState = {
        details: response.data.guest.details,
        actions: response.data.guest.actions,
      };
      let others: IOthersState = {
        rsvpDetail: response.data.others.details,
        shirtSize: response.data.others.size,
        traditionalReport: response.data.others.report_rsvp.report_type,
      };

      let rsvp: IRSVPStates = {
        id: response.data._id,
        ...preCongressState,
        ...preCongressChildren,
        ...displacement,
        ...accomodation,
        ...guestCosts,
        ...responsibleContact,
        ...companion,
        ...aerial,
        ...defaultDocuments,
        ...flightClasses,
        ...transfer,
        ...speakers,
        ...others,
      };
      return {
        rsvp,
        preCongressState,
        preCongressChildren,
        displacement,
        accomodation,
        guestCosts,
        responsibleContact,
        companion,
        aerial,
        defaultDocuments,
        flightClasses,
        transfer,
        speakers,
        others,
      };
    } catch (err: any) {
      toast.error(err);
    }
  }
  async function getAereoData(project_id: string) {
    try {
      let response = await api.get(`/aerial/get`, {
        params: { briefing_id: project_id },
      });
      let transfers = response.data.map((transfer: any) => {
        return {
          id: transfer._id,
          qtdDeparture: transfer.quantity_gone,
          qtdRegress: transfer.quantity_back,
          dateDeparture: format(
            add(new Date(transfer.date_gone), { hours: 3 }),
            "yyyy-MM-dd"
          ),
          dateRegress: format(
            add(new Date(transfer.date_back), { hours: 3 }),
            "yyyy-MM-dd"
          ),
          flightClass: transfer.class,
          airlineOfPreference: transfer.class_description,
          flightType: transfer.type_flight,
          departureAirport: transfer.origin_airport_id,
          regressAirport: transfer.destination_airport_id,
          airportOfPreference: transfer.airport_preference,
          paymentMethod: transfer.type_payment,
          paymentMethodDescription: transfer.payment_description,
          connections: transfer.connections,
          observations: transfer.comments,
        };
      });

      return {
        transfers,
        average: response.data[0].typeAerial,
        concurrence: response.data[0].competition,
      };
    } catch (err: any) {
      toast.error(err);
    }
  }
  async function getBudgetsData(project_id: string) {
    try {
      let response = await api.get(`/budget/get`, {
        params: { briefing_id: project_id },
      });
      let formatedBudgets = response.data.map((el: any) => {
        return {
          id: el._id,
          budgetName: el.name,
          mmNumber: el.mm_number,
          clientName: el.name_of_client,
          createdAt: el.created_at,
          responsible: el.responsible,
          responsibleEmail: el.responsible_email,
          status: el.status,
          disponibility: el.availability,
          deadline: el.deadline,
          country: el.country,
          city: el.city_uf,
          site: el.site,
          observations: el.description,
          location: el.place,
          address: el.address,
          rfp: el.pp,
          tagGMP: el.tag_map,
          product: el.customer_product,
          negotiationVersion: el.negotiation_version,
        };
      });
      setBudgets(formatedBudgets);
      return budgets;
    } catch (err: any) {
      toast.error(err);
    }
  }
  async function getAllProjects() {
    try {
      let response = await api.get(`/briefing/list-initial-briefing`);
      setAllProjectsData(response.data);
    } catch (error: any) {
      toast.error(error);
    }
  }
  function setBudgetListToExport(list: GridRowId[]) {
    setBudgetsToExport(list);
  }
  function setInitialState() {
    setBriefing(INITIAL_BRIEFING);
    setAeB(AEB_DATA);
    setPagamento(PAGAMENTO_DATA);
    setManagement(MANAGEMENT_DATA);
    setICadastroAereo([]);
    setWebConnections(WEB_CONNECTIONS);
    setLogistica(LOGISTICA_DATA);
    setMeetings(MEETINGS_DATA);
    setParking(PARKING_DATA);
    setTransfers(TRANFERS_DATA);
    setCriativo(PROJECT_CRIATIVE_DATA);
    setProducao(PROJECT_PRODUCAO_DATA);
    setFinancial(FINANCIAL_DATA);
    setCustomerRegister(CUSTOMER_REGISTER);
    setProject(PROJECT_DATA);
    setRSVP(RSVP_DATA);
    setProjectID("");
    setCriativoID("");
    setLogisticaID("");
    setAereoID("");
    setProducaoID("");
    setRsvpID("");
    setMMCode("");
  }

  // export
  const [exportData, setExportData] = useState<any>();
  const [exportType, setExportType] = useState<string>();

  return (
    <ProjectContext.Provider
      value={{
        budgetsToExport,
        setBudgetListToExport,
        exportData,
        setExportData,
        exportType,
        setExportType,
        mmCode,
        setInitialState,
        isANewProject,
        projectId,
        criativoId,
        producaoId,
        aereoId,
        logisticaId,
        rsvpId,
        briefing,
        customerRegister,
        financial,
        project,
        criativo,
        producao,
        aeb,
        logistica,
        meetings,
        parking,
        transfers,
        webConnetions,
        cadastroAereo,
        management,
        pagamento,
        rsvp,
        budgets,
        allProjects,
        isLoading,
        getAllProjects,
        setIsANewProject,
        setAeB,
        setBriefing,
        setPagamento,
        setManagement,
        setICadastroAereo,
        setWebConnections,
        setLogistica,
        setMeetings,
        setParking,
        setTransfers,
        setCriativo,
        setProducao,
        setFinancial,
        setCustomerRegister,
        setProject,
        setRSVP,
        setProjectID,
        setCriativoID,
        setLogisticaID,
        setAereoID,
        setProducaoID,
        setRsvpID,
        setBudgets,
        getInitialBriefing,
        getProducaoData,
        getLogisticaData,
        getCriativoData,
        getBudgetsData,
        getAereoData,
        getRSVPData,
        setIsLoading,
        setMMCode,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export { ProjectProvider };
