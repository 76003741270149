import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import background from "../../assets/background.png";
import logoImg from "../../assets/logo_text.png";

import { useTranslation } from "react-i18next";
import {
  TextField,
  Button,
  Box,
  Card,
  Typography,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import { HiOutlineMail } from "react-icons/hi";
import UserService from "../../services/user.service";

export default function ForgotPassword() {
  const { t } = useTranslation();
  const history = useHistory();
  const [user, setUser] = useState<string>("");
  const [loading, setLoading] = useState(false);

  function submitHandler() {
    if (!user) return toast.error("Insira um email válido");

    setLoading(true);
    UserService.recoveryPassword(user)
      .then(() => {
        toast.success("Email enviado com sucesso!");
      })
      .catch((error) => {
        toast.error("Erro ao enviar email");
      })
      .finally(() => setLoading(false));
  }

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      width={"100vw"}
      height={"100vh"}
      sx={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          p: 3,
          pt: 4,
          width: 408,
          borderRadius: "16px",
        }}
      >
        <Box display={"flex"} justifyContent={"center"}>
          <img src={logoImg} alt="" />
        </Box>

        <Typography variant={"h4"}>Recuperar senha</Typography>
        <Typography variant={"body1"} color={"#475467"}>
          Será enviado um link de acesso para seu email de cadastro
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box ml={"5px"} mr={"12px"}>
            <HiOutlineMail size={"24px"} color="#A0A3A6" />
          </Box>

          <TextField
            label={"Email"}
            size="small"
            onChange={(e: any) => {
              setUser(e.target.value);
            }}
            value={user}
            variant="outlined"
            fullWidth
          />
        </Box>

        <Box display={"flex"} flexDirection={"column"} gap={2}>
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={submitHandler}
            disabled={loading}
          >
            {!loading ? t("forgot.submitButton") : <CircularProgress />}
          </Button>

          <Button
            variant={"text"}
            onClick={() => history.push("/")}
            color={"primary"}
          >
            Voltar
          </Button>
        </Box>
      </Card>
    </Box>
  );
}
