import {
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import { useMutation, useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { AuthContext } from "../../contexts/auth";
import ClientService from "../../services/client.service";
import { SEGMENT_OPTIONS } from "../../utils/customerSegments";
import RightSideBar from "../novos/Customers/RightSideBar";
import { LabeledInput } from "../UI/LabeledInput";
import SelectForm from "../UI/Select";
import { id } from "date-fns/locale";

type Props = {
  edit: any;
};
export function Customer({ edit }: Props) {
  const { actionOptions } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation()
  const customerId = location.search.split('=')

  const { data: clientData, isLoading: clientLoading } = useQuery({
    queryKey: ["client"],
    queryFn: () => ClientService.getClients({ id: customerId[1] })
  });
 
  const [state, setState] = useState({} as any)
  useEffect(() => {
    if(!!clientData) setState(clientData)
  }, [clientData])
    
  //   {
  //   _id: edit?._id || undefined,
  //   handle_id: edit?.handle_id || null, // como saber qual handle id passar?
  //   name: edit?.name || "",
  //   nickname: edit?.nickname || "",
  //   segment: edit?.segment || "",
  //   email: edit?.email || "",
  //   tax_id: edit?.tax_id || "",
  //   country: edit?.country || "",
  //   state: edit?.state || "",
  //   city: edit?.city || "",
  //   neighborhood: edit?.neighborhood || "",
  //   address: edit?.address || "",
  //   number: edit?.number || "",
  //   zip_code: edit?.zip_code || "",
  //   phone: edit?.phone || "",
  //   street_type: edit?.street_type || "",
  //   complement: edit?.complement || "",
  //   latitude: edit?.latitude || 0,
  //   longitude: edit?.longitude || 0,
  //   origin: edit?.origin || "",
  //   business_group: edit?.business_group || "",
  //   business_group_name: edit?.business_group_name || "",
  //   custom_fields: edit?.custom_fields || "",
  //   companies: edit?.companies || {},
  //   contracts: edit?.contracts || "",
  //   agency_contract: edit?.agency_contract || true,
  //   events_contract: edit?.events_contract || true,
  //   has_supplier: edit?.has_supplier || false,
  //   collaborator_mm: edit?.collaborator_mm || true,
  //   physical_person: edit?.physical_person || false,
  //   juridical_person: edit?.juridical_person || false,
  //   national: edit?.national || false,
  //   international: edit?.international || false,
  //   cnpj_cpf: edit?.cnpj_cpf || "",
  //   client_requester: edit?.client_requester || "",
  //   external_client: edit?.external_client || true,
  // });
  const { mutate: fetchCnpjData, isLoading: isLoadingFetchCnpj } = useMutation(
    ClientService.fetchCnpjData,
    {
      onSuccess: (apiData) => {
        if (apiData) {
          setState((prevState: any) => ({
            ...prevState,
            zip_code: apiData.CEP || "",
            country: "Brasil",
            state: apiData.UF || "",
            city: apiData.MUNICIPIO || "",
            neighborhood: apiData.BAIRRO || "",
            street_type: apiData.LOGRADOURO || "",
            email: apiData.EMAIL || "",
            number: apiData.NUMERO || "",
            complement: apiData.COMPLEMENTO || "",
          }));
        }
      },
      onError: (error) => {
        console.error("Error fetching CNPJ data", error);
      },
    }
  );

  const { mutate: createClient, isLoading: isLoadingCreate } = useMutation(
    ClientService.create,
    {
      onSuccess: () => {
        toast.success("Client criado com sucesso!");
        history.push("/partners/customers");
      },
      onError: (error) => {
        toast.error("Não foi possível criar o cliente");
      },
    }
  );

  const { mutate: updateClient, isLoading: isLoadingUpdate } = useMutation(
    (data: any) => ClientService.update(state._id, data),
    {
      onSuccess: () => {
        toast.success("Cliente atualizado com sucesso!");
        history.push("/partners/customers");
      },
      onError: (error) => {
        toast.error("Não foi possível atualizar o cliente");
      },
    }
  );
  const handleSubmmit = (e: React.MouseEvent) => {
    const data = {
      ...state,
    };
    delete data._id;
    state._id ? updateClient(data) : createClient({ ...data, origin: "MMIDD" });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.name === "number") {
      const numberRegex = new RegExp("^[0-9]*$");
      if (!numberRegex.test(e.target.value)) {
        e.target.value = e.target.value.replace(/\D/g, "");
      }
    }
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleTaxIdBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const unmaskedCnpj = value.replace(/\D/g, "");
    fetchCnpjData(unmaskedCnpj);
  };
  const isDisabled =
    !actionOptions.updateParceiros || !actionOptions.createParceiros;
  return (
    <Box display={"flex"} width={"100%"} columnGap={2} py={1}>
      <Card style={{ width: edit ? "70%" : "100%", padding: "5px 10px" }}>
        <Box display={"flex"} flexDirection={"column"} width={"90%"}>
          <Box display={"flex"} gap={2}>
            <FormControl>
              <FormLabel>Fornecedor</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.has_supplier}
                      onChange={(e) =>
                        setState({
                          ...state,
                          has_supplier: e.target.checked,
                        })
                      }
                    />
                  }
                  label={"Sim"}
                />
              </FormGroup>
            </FormControl>
            <FormControl>
              <FormLabel>Colaborador MM</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.collaborator_mm}
                      onChange={(e) =>
                        setState({
                          ...state,
                          collaborator_mm: e.target.checked,
                        })
                      }
                    />
                  }
                  label={"Sim"}
                />
              </FormGroup>
            </FormControl>
          </Box>

          <FormControl style={{ width: "30%" }}>
            <SelectForm
              label="Segmento do Cliente"
              value={state.segment}
              onChange={(e) => setState({ ...state, segment: e.target.value })}
            >
              {SEGMENT_OPTIONS.map((value, index) => (
                <MenuItem key={index} value={value}>
                  {value}
                </MenuItem>
              ))}
            </SelectForm>
          </FormControl>

          <FormControl>
            <FormLabel id="person-type">Pessoa</FormLabel>
            <RadioGroup
              aria-labelledby="person-type"
              name="document_type"
              value={
                state.physical_person ? "physical_person" : "juridical_person"
              }
              onChange={(e) =>
                setState({
                  ...state,
                  physical_person: e.target.value === "physical_person",
                  juridical_person: e.target.value === "juridical_person",
                })
              }
            >
              <FormControlLabel
                value="physical_person"
                control={<Radio />}
                label="Física"
              />
              <FormControlLabel
                value="juridical_person"
                control={<Radio />}
                label="Jurídica"
              />
            </RadioGroup>
          </FormControl>

          <FormControl>
            <FormLabel id="event-location">Local</FormLabel>
            <RadioGroup
              aria-labelledby="event-location"
              defaultValue="nacional"
              name="radio-buttons-group"
              value={state.international ? "international" : "national"}
              onChange={(e) =>
                setState({
                  ...state,
                  international: e.target.value === "international",
                  national: e.target.value === "national",
                })
              }
            >
              <FormControlLabel
                value="national"
                control={<Radio />}
                label="Nacional"
              />
              <FormControlLabel
                value="international"
                control={<Radio />}
                label="Internacional"
              />
            </RadioGroup>
          </FormControl>
          <ReactInputMask
            // @ts-ignore
            maskPlaceholder={null}
            mask={
              state.physical_person ? "999.999.999 - 99" : "99.999.999/9999-99"
            }
            value={state.tax_id}
            onChange={handleChange}
            onBlur={handleTaxIdBlur}
            name="tax_id"
          >
            <LabeledInput
              style={{ width: "90%" }}
              size="small"
              label={state.physical_person ? "CPF" : "CNPJ"}
              name="tax_id"
              value={state.tax_id}
              onChange={handleChange}
              placeholder={
                state.physical_person
                  ? "000.000.000 - 00"
                  : "00.000.000/0000-00"
              }
            />
          </ReactInputMask>
          <LabeledInput
            size="small"
            style={{ width: "90%" }}
            label="Solicitante do Cliente"
            name="client_requester"
            value={state.client_requester}
            onChange={handleChange}
          />
          <LabeledInput
            size="small"
            style={{ width: "90%" }}
            label="Nome"
            name="name"
            value={state.name}
            onChange={handleChange}
          />
          <LabeledInput
            size="small"
            style={{ width: "90%" }}
            label="Nome de uso/apelido"
            name="name"
            value={state.name}
            onChange={handleChange}
          />
          <LabeledInput
            size="small"
            style={{ width: "90%" }}
            label="Nome Grupo"
            name="business_group_name"
            value={state.business_group_name}
            onChange={handleChange}
          />

          <FormLabel
            style={{
              borderBottom: "1px solid",
              marginTop: "15px",
              width: "105%",
              padding: 0,
            }}
          >
            Contato
          </FormLabel>

          <LabeledInput
            size="small"
            style={{ width: "90%" }}
            fullWidth
            label="E-mail"
            name="email"
            value={state.email}
            onChange={handleChange}
          />
          <ReactInputMask
            // @ts-ignore
            maskPlaceholder={null}
            mask="(99) 99999-9999"
            value={state.phone}
            onChange={handleChange}
            name="phone"
          >
            <LabeledInput
              style={{ width: "90%" }}
              size="small"
              label="Telefone"
              name="phone"
              value={state.phone}
              onChange={handleChange}
              placeholder="(00) 0 0000 0000"
            />
          </ReactInputMask>

          <FormLabel
            style={{
              borderBottom: "1px solid",
              marginTop: "15px",
              width: "105%",
            }}
          >
            Endereço
          </FormLabel>

          <ReactInputMask
            // @ts-ignore
            maskPlaceholder={null}
            mask="99999-999"
            value={state.zip_code}
            onChange={handleChange}
            name="zip_code"
          >
            <LabeledInput
              style={{ width: "90%" }}
              size="small"
              label="CEP"
              name="zip_code"
              value={state.zip_code}
              onChange={handleChange}
              placeholder="00000-000"
            />
          </ReactInputMask>
          <LabeledInput
            size="small"
            style={{ width: "90%" }}
            fullWidth
            label="País"
            value={state.country}
            name="country"
            onChange={handleChange}
          />

          <LabeledInput
            size="small"
            style={{ width: "90%" }}
            fullWidth
            label="Estado"
            value={state.state}
            name="state"
            onChange={handleChange}
          />

          <LabeledInput
            size="small"
            style={{ width: "90%" }}
            fullWidth
            label="Município"
            value={state.city}
            name="city"
            onChange={handleChange}
          />

          <LabeledInput
            size="small"
            style={{ width: "90%" }}
            fullWidth
            label="Bairro"
            value={state.neighborhood}
            name="neighborhood"
            onChange={handleChange}
          />

          <LabeledInput
            size="small"
            style={{ width: "90%" }}
            fullWidth
            label="Logradouro"
            value={state.street_type}
            name="street_type"
            onChange={handleChange}
          />
          <Box display={"flex"} width={"90%"} columnGap={1}>
            <LabeledInput
              size="small"
              style={{ width: "100%" }}
              fullWidth
              label="Número"
              value={state.number}
              name="number"
              onChange={handleChange}
            />

            <LabeledInput
              size="small"
              style={{ width: "100%" }}
              fullWidth
              label="Complemento"
              value={state.complement}
              name="complement"
              onChange={handleChange}
            />
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}>
          <Button
            style={{
              marginRight: "15px",
              textTransform: "none",
            }}
            variant="outlined"
            onClick={() => history.push("/partners/customers")}
          >
            Cancelar
          </Button>

          <Button
            style={{
              background: "#1361A4",
              marginRight: "15px",
              color: "#FFFFFF",
              textTransform: "none",
            }}
            disabled={isDisabled}
            variant="contained"
            onClick={handleSubmmit}
          >
            Salvar e Fechar
          </Button>
        </Box>
      </Card>
      {edit && <RightSideBar editClient={edit} />}
    </Box>
  );
}
