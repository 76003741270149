import styled from "styled-components";
import background from "../../assets/background.png";
import { Card } from "../../components/Card";
import { TextField } from "@mui/material";

export const Container = styled.div`
  margin: auto;
  width: calc(100% - 36px);
  height: calc(100vh - 36px);
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 18px;
  background: url(${background});
  background-repeat: no-repeat;
  background-size: cover;

 
`;

export const LoginCard = styled(Card)`
  max-width: 408px;
  height: 390px;
  width: 100%;
  background: #F9FCFF;
  padding: 10px 24px;
  color: #2C2E30;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  margin: auto;
`;
export const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 90%;
  margin: 0 auto;
  height: 120px;
  .login-input {
    color: #fff;
  }
  
`;
export const HelpersDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  maxwidth: 408px;

  span {
    margin: auto;
    cursor: pointer;
    color: #1361A4;
    font-weight: bold;
  }
`;
export const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: auto;

  button {
    margin: 20px auto;
  }
`;

export const StyledTextField = styled(TextField)`
  width: 408px;
  color: #ffffff;
  margin-top: 10px;
`;
export const RowDiv = styled.div`
 display: flex;
 align-items: center;
 justify-content: flex-start;
 `
