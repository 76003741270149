import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Input } from "../../../components/Input";
import { Button, FormGroup, FormLabel } from "@mui/material";
import { FormInputMultiCheckbox } from "../../novos/RSVP/PreCongress/FormMultipleCheckBox";
import styled from "styled-components";
import { StyledBox } from "../../novos/RSVP/styles";
import { createOptionsFromEnum } from "../../../utils/formatters";
import * as ProductionInfo from '../../../types/production/enums'

type FormProps = {
  productionData: any
  onSubmit(form: any): void
}
const HorizontalDivider = styled.hr`
width: 100%;
border-color: #fefefe;
color: #fefefe;
`

const initialState = {
  speaker: [],
  speakerObs: '',
  musicalAttraction: [],
  musicalAttractionObs: '',
  content: [],
  contentObs: '',
  equipment: [],
  equipmentObs: '',
  agencyActivation: [],
  agencyActivationObs: '',
  specialMoment: [],
  specialMomentDinnerObs: '',
  specialMomentObs: '',
  teamBuilding: '',
  transportationService: [],
  transportationServiceObs: '',
  decoration: '',
  activation: '',
  gifts: '',
  musicalAttractionDescription: '',
  speakerDescription: '',
  equipmentDescription: '',
  keyPoint: [],
  keyPointObs: '',
  productLaunchEvent: [],
  productLaunchEventObs: '',
  eventServices: [],
  eventServicesObs: '',
  schenography: ""
}

const ProductionForm = ({ productionData, onSubmit }: FormProps) => {
  const methods = useForm({
    defaultValues: initialState,
    mode: "all",
  });
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = methods


  const speakerOptions = createOptionsFromEnum(ProductionInfo.SpeakerEnum)
  const musicalAttractionOptions = createOptionsFromEnum(ProductionInfo.MusicalAttractionEnum)
  const contentOptions = createOptionsFromEnum(ProductionInfo.ContentEnum)
  const equipmentOptions = createOptionsFromEnum(ProductionInfo.EquipmentEnum)
  const activationOptions = createOptionsFromEnum(ProductionInfo.ActivationEnum)
  const specialMomentOptions = createOptionsFromEnum(ProductionInfo.SpecialMomentEnum)
  const transportationServiceOptions = createOptionsFromEnum(ProductionInfo.TransportationServiceEnum)
  const keyPointOptions = createOptionsFromEnum(ProductionInfo.KeyPointEnum)
  const producLaunchOptions = createOptionsFromEnum(ProductionInfo.ProductLaunchEvent)
  const eventServicesOptions = createOptionsFromEnum(ProductionInfo.EventServicesEnum)

  useEffect(() => {

    if (!!productionData){
      
      reset(productionData);}
    else{
      reset(initialState)}
  }, [productionData]);


  return (
      <StyledBox onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormLabel>Palestrante</FormLabel>
          <FormInputMultiCheckbox
            name={"speaker"}
            control={control} label={'label'}
            options={speakerOptions}
            setValue={setValue}
            previousSelected={!!productionData.speaker ? productionData.speaker : []} // adicionar quando integrar com o backend
            optionSelectAll={false}
          />
        </FormGroup>
        <Input
          multiline
          label=""
          {...register("speakerObs")}
          placeholder="Especificação"
        />
          <HorizontalDivider />

        <FormGroup>
          <FormLabel>Atração musical</FormLabel>
          <FormInputMultiCheckbox
            name={"musicalAttraction"}
            control={control} label={'label'}
            options={musicalAttractionOptions}
            setValue={setValue}
            previousSelected={!!productionData.musicalAttraction ? productionData.musicalAttraction : []} // adicionar quando integrar com o backend
            optionSelectAll={false}
          />
        </FormGroup>
        <Input
          multiline
          label="Especificação do genêro"
          {...register("musicalAttractionObs")}
          placeholder="Escrever exemplo/guide (Ex: Nome: Festa junina da firma; Tema: Junino e HH; KV: guia da marca"
        />
          <HorizontalDivider />

        <FormGroup>
          <FormLabel>Conteúdo</FormLabel>
          <FormInputMultiCheckbox
            name={"content"}
            control={control} label={'label'}
            options={contentOptions}
            setValue={setValue}
            previousSelected={!!productionData.content ? productionData.content : []} // adicionar quando integrar com o backend
            optionSelectAll={false}
          />
        </FormGroup>
        <Input
          multiline
          label="Especificação"
          {...register("contentObs")}
          placeholder="Escrever exemplo/guide (Ex: Nome: Festa junina da firma; Tema: Junino e HH; KV: guia da marca"
        />
          <HorizontalDivider />

        <FormGroup>
          <FormLabel>Equipamentos</FormLabel>
          <FormInputMultiCheckbox
            name={"equipment"}
            control={control} label={'label'}
            options={equipmentOptions}
            setValue={setValue}
            previousSelected={!!productionData.equipment ? productionData.equipment : []} // adicionar quando integrar com o backend
            optionSelectAll={false}
          />
        </FormGroup>
        <Input
          multiline
          label="Especificação"
          {...register("equipmentObs")}
          placeholder="Escrever exemplo/guide (Ex: Nome: Festa junina da firma; Tema: Junino e HH; KV: guia da marca"
        />
  <HorizontalDivider />
        <FormGroup>
          <FormLabel>Ativações</FormLabel>
          <FormInputMultiCheckbox
            name={"agencyActivation"}
            control={control} label={'label'}
            options={activationOptions}
            setValue={setValue}
            previousSelected={!!productionData.agencyActivation ? productionData.agencyActivation : []} // adicionar quando integrar com o backend
            optionSelectAll={false}
          />
        </FormGroup>
        <HorizontalDivider />
        <FormGroup>
          <FormLabel>Momento Especial</FormLabel>
          <FormInputMultiCheckbox
            name={"specialMoment"}
            control={control} label={'label'}
            options={specialMomentOptions}
            setValue={setValue}
            previousSelected={!!productionData.specialMoment ? productionData.specialMoment : []} // adicionar quando integrar com o backend
            optionSelectAll={false}
          />
        </FormGroup>
        <Input
          multiline
          label="Especificação Jantar de premiação"
          {...register("specialMomentDinnerObs")}
          placeholder="Especificação"
        />
        <Input
          multiline
          label="Especificação"
          {...register("specialMomentObs")}
          placeholder="Especificação"
        />
          <HorizontalDivider />

        <Input
          multiline
          label="Team Building"
          {...register("teamBuilding")}
          placeholder="Descrever (se for palestrante repetir o do campo"
        />

        <FormGroup>
          <FormLabel>Transporte e valet</FormLabel>
          <FormInputMultiCheckbox
            name={"transportationService"}
            control={control} label={'label'}
            options={transportationServiceOptions}
            setValue={setValue}
            previousSelected={!!productionData.transportationService ? productionData.transportationService : []} // adicionar quando integrar com o backend
            optionSelectAll={false}
          />
        </FormGroup>
        <Input
          multiline
          label=""
          {...register("transportationServiceObs")}
          placeholder="Outros"
        />
  <HorizontalDivider />
        <Input
          multiline
          label="Decoração - Variações"
          {...register("decoration")}
          placeholder="Descrever formas de pagamento"
        />

        <Input
          multiline
          label="3D/Cenografia"
          {...register("schenography")}
          placeholder="Descrever"
        />

        <Input
          multiline
          label="Ativação(es)"
          {...register("activation")}
          placeholder="Descrever"
        />

        <Input
          multiline
          label="Brindes"
          {...register("gifts")}
          placeholder="Descrever quais e quantidades"
        />
        <Input
          multiline
          label="Atração musical"
          {...register("musicalAttractionDescription")}
          placeholder="Descrever quais"
        />

        <Input
          multiline
          label="Speaker/MC's e/ou Palestrantres"
          {...register("speakerDescription")}
          placeholder="Descrever(ex. nome ou banda)"
        />

        <Input
          multiline
          label="Equipamentos"
          {...register("equipmentDescription")}
          placeholder="Descrever(ex. treliça)"
        />
          <HorizontalDivider />
        <FormGroup>
          <FormLabel>Pontos de atenção de produção</FormLabel>
          <FormInputMultiCheckbox
            name={"keyPoint"}
            control={control} label={'label'}
            options={keyPointOptions}
            setValue={setValue}
            previousSelected={!!productionData ? productionData.keyPoint : []} // adicionar quando integrar com o backend
            optionSelectAll={false}
          />
        </FormGroup>
        <Input
          multiline
          label=""
          {...register("keyPointObs")}
          placeholder="Especificação"
        />
          <HorizontalDivider />
        <FormGroup>
          <FormLabel>Lançamento de produto</FormLabel>
          <FormInputMultiCheckbox
            name={"productLaunchEvent"}
            control={control} label={'label'}
            options={producLaunchOptions}
            setValue={setValue}
            previousSelected={!!productionData ? productionData.productLaunchEvent : []} // adicionar quando integrar com o backend
            optionSelectAll={false}
          />
        </FormGroup>
        <Input
          multiline
          label=""
          {...register("productLaunchEventObs")}
          placeholder="Especificação"
        />
  <HorizontalDivider />

        <FormGroup>
          <FormLabel>Extras</FormLabel>
          <FormInputMultiCheckbox
            name={"eventServices"}
            control={control} label={'label'}
            options={eventServicesOptions}
            setValue={setValue}
            previousSelected={!!productionData ? productionData.eventServices : []} // adicionar quando integrar com o backend
            optionSelectAll={false}
          />
        </FormGroup>
        <Input
          multiline
          label=""
          {...register("eventServicesObs")}
          placeholder="Especificação"
        />

        <Button
          variant="contained"
          
          style={{ position: 'relative', left: '90%', marginTop: '10px', width: '80px' }}
          type="submit"
        >
          Salvar
        </Button>
      </StyledBox>
  );
};

export default ProductionForm;
