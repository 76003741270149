import { ReactNode, useState } from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

interface SummarySectionProps {
  title: string;
  children?: ReactNode;
}

const SummarySection = ({ title, children }: SummarySectionProps) => {
  const [open, setOpen] = useState(true);
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          cursor: "pointer",
          margin: "8px 0px",
          borderBottom: "1px solid #989898",
          paddingBottom: 4,
        }}
        onClick={() => setOpen(!open)}
      >
        <span>{title}</span>
        {!open ? <FaCaretDown /> : <FaCaretUp />}
      </div>
      {open && children}
    </div>
  );
};

export default SummarySection;
