import React, { useState, useContext, ChangeEvent } from "react";
import { useHistory } from "react-router-dom";
import { LoginData } from "../../types/example";
import { TextField, Button, CircularProgress, FormControl, FormControlLabel, Checkbox, InputAdornment } from "@mui/material";
import { AuthContext } from "../../contexts/auth";
import logoImg from '../../assets/logo_text.png'

import {
  Container,
  LoginCard,
  InputDiv,
  HelpersDiv,
  ButtonDiv,
  RowDiv,
} from "./styles";
import { useTranslation } from "react-i18next";
import { AiOutlineLock, AiOutlineUser } from "react-icons/ai";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

export default function Login() {
  const { handleSubmit } = useForm()
  const { signIn } = useContext(AuthContext);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [keepConnected, setkeepConnected] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showRegisterMessage, setShowRegisterMessage] = useState(false);
  const { t } = useTranslation();
  const DOMAIN = "@grupommeventos.com";
  const isEmailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
  const TextFieldProps = { style: { width: "408px" } };
  async function submitHandler() {
    try {
      const validateDomainEmail = isEmailRegex.test(email)

      // if (!validateDomainEmail) {
      //   return toast.warn(`O email deve ser do domínio ${DOMAIN}`);
      // }

      if (email.trim() === "" || password.trim() === "")
        return toast.info("Insira os dados corretamente para login");

      const requestData: LoginData = validateDomainEmail ? {
        email,
        password,
        user_name: '', 
        keep_connected: keepConnected
      } : {
        email: '',
        password,
        user_name: email,
        keep_connected: keepConnected
      };
      await signIn(requestData);
    } catch (error) {
      toast.error("Credenciais inválidas. Verifique a informação")
    }
  }

  return (
    <Container>
      <LoginCard>
        <img src={logoImg} alt="" style={{ margin: '0 auto' }} />

        <h1 style={{ fontWeight: "500" }}>Login</h1>

        <form onSubmit={handleSubmit(submitHandler)}>
          <InputDiv>
            <RowDiv>
              <AiOutlineUser size={20} />  &nbsp;
              <TextField
                placeholder={t("login.email")}
                label={t("login.email")}
                InputProps={{
                  ...TextFieldProps,

                }}
                size="small"
                onChange={(e: any) => {
                  setEmail(e.target.value);
                }}
                value={email}
                variant="outlined"

              />
            </RowDiv>
            <RowDiv>
              <AiOutlineLock size={20} /> &nbsp;

              <TextField
                type="password"
                placeholder={t("login.senha")}
                label={t("login.senha")}
                InputProps={{
                  ...TextFieldProps,

                }}
                size="small"
                onChange={(e: any) => {
                  setPassword(e.target.value);
                }}
                value={password}
                variant="outlined"
                fullWidth
              />
            </RowDiv>
          </InputDiv>
          <FormControl fullWidth>
            <FormControlLabel
              label="Manter Conectado"
              control={
                <Checkbox
                  checked={keepConnected}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => { setkeepConnected(e.target.checked) }}
                  name="responsibleName"
                />
              }
            />
          </FormControl>
          <ButtonDiv>
            <Button
              type="submit"
              variant={"contained"}
              color={"primary"}
              fullWidth

            >
              {!isLoading ? t("login.button") : <CircularProgress />}
            </Button>
          </ButtonDiv>
        </form>
        <Button
          type="submit"
          variant="text"
          color="primary"
          onClick={() => {
            history.push("/esqueci");
          }}
          fullWidth
          style={{
            margin: '0 auto'
          }}

        >
          Esqueci a Senha
        </Button>
        <HelpersDiv>
          {/* <span
            onClick={() => {
              history.push("/esqueci");
            }}
          >
            Esqueci a senha
          </span> */}
        </HelpersDiv>
      </LoginCard>
    </Container>
  );
}
