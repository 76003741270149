import { createContext, useContext, useState } from "react";

import { BudgetService } from "../services/budget.service";
import {
  Budget,
  BudgetItemTypeEnum,
  BudgetStatus,
} from "../types/budget/budget";
import { ServiceData } from "../types/budget/service-item";

export type BudgetContextProps = {
  notification?: any;
  loadingBudget?: boolean;
  projectId?: string;
  budgetList?: Budget[];
  negotiations: Budget[];
  approveds: Budget[];
  activeBudget?: Budget;
  activeBudgetId?: string;
  activeBudgetServices?: ServiceData[];
  activeBudgetServicesPerSupplier: StringMap;
  activeBudgetTotals: any;
  createBudget: (budgetData: Budget) => void;
  updateBudget: (budgetId: string, budgetData: Budget) => void;
  loadBudgetServices: (budgetId: string) => void;
  loadBudgetList: (projectId: string) => void;
  closeNotification: () => void;
  showNotification: (message: string, severity: string) => void;
  clearBudget: () => void;
  calculateBudgetTotals: (payload: any) => void;
};
type StringMap = {
  [key in keyof typeof BudgetItemTypeEnum | string]: any;
};
const BudgetContext = createContext<BudgetContextProps>(
  {} as BudgetContextProps
);

function BudgetProvider({ children }: any) {
  const [loadingBudget, setLoadingBudget] = useState(false);

  const [notification, setNotification] = useState<any>({
    open: false,
    message: "",
    severity: "success",
  });

  const [activeBudget, setActiveBudget] = useState<Budget>();
  const [activeBudgetTotals, setActiveBudgetTotals] = useState<any>();
  const [activeBudgetServices, setActiveBudgetServices] = useState<
    ServiceData[]
  >([] as ServiceData[]);
  const [activeBudgetServicesPerSupplier, setActiveBudgetServicesPerSupplier] =
    useState<StringMap>({} as StringMap);
  const [budgetList, setBudgetList] = useState<Budget[]>([] as Budget[]);
  const [activeBudgetId, setActiveBudgetId] = useState<string>();
  const [projectId, setProjectId] = useState<string>();

  const negotiations =
    budgetList?.filter(
      (budget) => budget.status === BudgetStatus.NEGOTIATION
    ) ?? [];

  const approveds =
    budgetList?.filter(
      (budget) =>
        budget.status === BudgetStatus.APPROVED ||
        budget.status === BudgetStatus.APPROVED_NEGOTIATION
    ) ?? [];

  async function updateBudget(budgetId: string, budgetData: Budget) {
    try {
      if (budgetId) {
        await BudgetService.update(budgetId, budgetData)
        !!projectId ? await loadBudgetList(projectId) : null
        showNotification(`Salvo com sucesso!`)
      }
    } catch (error) {
      showNotification(`Não foi possível atualizar o orçamento`, 'warning')
    }
  }
  async function loadBudgetServices(budgetId: string) {
    if(budgetId.trim() === '') return
    try {
     
        // setActiveBudget(newActiveBudget)
        // setActiveBudgetId(budgetId)

        const budgetServices = await BudgetService.getServicesFromBudget(
          budgetId
        );
        const servicesPerSupplier = filterServicesPerSupplier(budgetServices)
        for(const [key, value] of Object.entries(servicesPerSupplier)) {
          servicesPerSupplier[key] = filterServicesByItemType(value)
        }
        setActiveBudgetServicesPerSupplier(servicesPerSupplier);
      
    } catch (error) {
      showNotification(
        "Não foi possível carregar os serviços salvos para o orçamento",
        "warning"
      );
    }
  }
  interface StringMap { [key: string]: any; }
  function filterServicesPerSupplier(budgetServices: ServiceData[]) {
    const servicesPerSupplier: StringMap = {}
    budgetServices.map(el => {
      if(!!servicesPerSupplier[el.providerId]) {
        servicesPerSupplier[el.providerId].push(el)
      } else {
        servicesPerSupplier[el.providerId] = [el]
      }
    })
    return servicesPerSupplier
  }
    function filterServicesByItemType(budgetServices: ServiceData[]) {
    const filtered: StringMap = {
      ACCOMMODATIONS: undefined,
      ROOMS: undefined,
      AEB: undefined,
      EQUIPMENTS: undefined,
      TRANSLATIONS: undefined,
      SUPPORT: undefined,
      SUBSCRIPTION: undefined,
      RSVP: undefined,
      AIR: undefined,
      TRANSFER: undefined,
      COMMUNICATIONS: undefined,
      SEVERAL: undefined,
      TOTALS: undefined,
    };
    for (const [key, value] of Object.entries(filtered)) {
      const services = budgetServices.filter((service) => service.type === key);
      filtered[key] = services;
    }
    return filtered
  }
  function showNotification(message: string, severity = "success") {
    setNotification({ open: true, message, severity });
  }

  function closeNotification() {
    setNotification({ ...notification, open: false });
  }

  async function loadBudgetList(projectId: string) {
    setLoadingBudget(true);

    try {
      setProjectId(projectId);
      const budgetList = await BudgetService.getBudgetList(projectId);
      if (budgetList.length > 0) {
        setBudgetList(budgetList);
       
        await loadBudgetServices(budgetList[0]._id);
        await calculateBudgetTotals(budgetList[0]._id)
        setActiveBudget(budgetList[0]);
        setActiveBudgetId(budgetList[0]._id);
      } else {
        setBudgetList([]);
        setActiveBudget({} as Budget);
        setActiveBudgetId(undefined);
      }
    } catch (error) {
      showNotification("Não foi possível carregar", "warning");
    }
    setLoadingBudget(false);
  }

  async function createBudget(data: Budget) {
    try {
      const budgetData = await BudgetService.create(data);
      showNotification("Criado com Sucesso!", "success");
      setBudgetList([...budgetList, budgetData]);
      setActiveBudget(budgetData);
      setActiveBudgetId(budgetData._id);
    } catch (error) {
      showNotification("Não foi possível criar", "warning");
    }
  }
  async function calculateBudgetTotals(payload: any) {
    try {
      const budgetTotals = await BudgetService.calculateBudgetTotals(payload)
      setActiveBudgetTotals(budgetTotals)
    } catch (error) {
      showNotification(
        "Não foi possível calcular os totais do orçamento",
        "warning"
      );
    }
  }

  function clearBudget() {
    setBudgetList([]);
    setActiveBudget({} as Budget);
    setActiveBudgetId(undefined);
    setActiveBudgetServicesPerSupplier({} as StringMap);
    setProjectId(undefined);
  }

  return (
    <BudgetContext.Provider
      value={{
        notification,
        loadingBudget,
        projectId,
        budgetList,
        negotiations,
        approveds,
        activeBudgetServices,
        activeBudgetServicesPerSupplier,
        activeBudget,
        activeBudgetId,
        activeBudgetTotals,
        createBudget,
        updateBudget,
        loadBudgetServices,
        loadBudgetList,
        closeNotification,
        showNotification,
        clearBudget,
        calculateBudgetTotals,
      }}
    >
      {children}
    </BudgetContext.Provider>
  );
}

const useBudget = () => useContext(BudgetContext);
export { BudgetProvider, useBudget };
