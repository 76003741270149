import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import UserService from "../../../../services/user.service";
import { UserProfileDragAndDrop } from "../Users/UserProfileDragAndDrop";
import { toast } from "react-toastify";
import { LabeledInput } from "../../../UI/LabeledInput";
import RoleService from "../../../../services/roles.service";
import ReactInputMask from "react-input-mask";
import LoadingModal from "../../../LoadingModal";
import RolePermissionsService from "../../../../services/rolesPermissions.service";
import { AuthContext } from "../../../../contexts/auth";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const Profile = () => {
  const { actionOptions } = useContext(AuthContext);
  const [roles, setRoles] = useState<any[]>([]);

  useEffect(() => {
    RolePermissionsService.allRoles().then((res) => {
      setRoles(res);
    });
  }, []);
  const { isLoading, data, error, status } = useQuery({
    queryKey: ["profile"],
    queryFn: () => UserService.getMyProfile(),
  });

  const [profileData, setProfileData] = useState({
    _id: "",
    name: "",
    user_name: "",
    email: "",
    role_id: "",
    function_id: "",
    description: "",
    phone: "",
    image_url: "",
    active: false,
  });

  useEffect(() => {
    if (status === "success") {
      setProfileData(data);
    }
  }, [status, data]);

  function typesRender() {
    return roles.map((el: any) => {
      return <MenuItem value={el._id}>{el.name}</MenuItem>;
    });
  }

  const { mutate: updateUser, isLoading: isLoadingUpdate } = useMutation(
    (data: any) => UserService.update(profileData._id, data),
    {
      onSuccess: () => {
        toast.success("Usuário atualizado com sucesso!");
      },
      onError: (error) => {
        toast.error("Não foi possível atualizar o usuário");
      },
    }
  );

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
  }
  const submitHandler = async () => {
    const data = {
      ...profileData,
    };

    const role = await RolePermissionsService.roles({ id: data.function_id });

    if (role.name === "Administrador") {
      data.role_id = "USER_ADMINISTRATOR";
    } else {
      data.role_id = "USER_GUEST";
    }

    updateUser(data);
  };

  async function handleUpload(files: File[]) {
    let src = URL.createObjectURL(files[0]);
    // create syntetic event
    let reader = new FileReader();

    reader.readAsDataURL(files[0]);

    reader.onload = function () {
      let e = {
        target: {
          name: "image_url",
          value: reader.result,
        },
      } as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
      handleChange(e);
    };

    reader.onerror = function () {};
  }
  const isDisabled =
    !actionOptions["createControle de acesso"] &&
    !actionOptions["updateControle de acesso"];
  return (
    <Box
      px={"24px"}
      pt={"24px"}
      pb={"28px"}
      sx={{ flex: 1 }}
      gap={2}
      display={"flex"}
      justifyContent={"space-between"}
    >
      <LoadingModal isLoading={isLoading} />
      <Box
        display={"flex"}
        mb={4}
        alignItems={"flex-start"}
        flex={1}
        width={"100%"}
        flexDirection={"column"}
        gap={2}
      >
        <Box
          display={"flex"}
          alignItems={"flex-start"}
          width={"45%"}
          mb={2}
          flexDirection={"column"}
        >
          {!profileData.image_url ? (
            <>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                width={"100%"}
                margin={"auto"}
              >
                <strong>Foto de Perfil </strong>{" "}
                <span> usar foto sem fundo ou fundo branco</span>
              </Box>
              <UserProfileDragAndDrop
                count={1}
                formats={["png", "jpeg", "jpg"]}
                onUpload={handleUpload}
                name={"image_url"}
              />
            </>
          ) : (
            <Box display={"flex"} flexDirection={"column"}>
              <img
                src={profileData.image_url}
                alt=""
                width="120px"
                height="120px"
                style={{ borderRadius: "50%" }}
              />
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setProfileData({ ...profileData, image_url: "" });
                }}
              >
                <span>
                  Upload concluído clique aqui para substituir a imagem
                </span>
              </p>
            </Box>
          )}
        </Box>
        <Box
          display={"flex"}
          alignItems={"flex-start"}
          flexDirection={"column"}
          flex={1}
          gap={2}
          width={"100%"}
        >
          <Box
            display={"flex"}
            alignItems={"flex-start"}
            gap={2}
            width={"100%"}
          >
            <LabeledInput
              label="Nome completo"
              value={profileData.name}
              name="name"
              onChange={handleChange}
            />
            <LabeledInput
              label="login"
              value={profileData.user_name}
              name="user_name"
              onChange={handleChange}
            />
          </Box>
          <Box
            display={"flex"}
            alignItems={"flex-start"}
            gap={2}
            width={"100%"}
          >
            <LabeledInput
              label="Email"
              value={profileData.email}
              name="email"
              onChange={handleChange}
            />
            {/* <LabeledInput label="Celular" value={profileData.phone} name="phone" onChange={handleChange} /> */}
            <ReactInputMask
              //@ts-ignore
              maskPlaceholder={null}
              mask="(99) 99999-9999"
              value={profileData.phone}
              onChange={handleChange}
              name="phone"
            >
              <LabeledInput
                fullWidth
                size="small"
                label="Celular"
                name="phone"
                value={profileData.phone}
                onChange={handleChange}
                placeholder="(00) 0 0000 0000"
              />
            </ReactInputMask>
          </Box>
          <Box
            display={"flex"}
            alignItems={"flex-start"}
            flexDirection={"column"}
            flex={1}
            gap={2}
            width={"98%"}
            margin={1}
          >
            <Box
              display={"flex"}
              alignItems={"flex-start"}
              flexDirection={"column"}
              width={"100%"}
            >
              <InputLabel>Função</InputLabel>
              <FormControl style={{ width: "97%" }} size="small">
                <Select
                  style={{ width: "50%" }}
                  value={profileData.function_id}
                  onChange={(e) => {
                    setProfileData({
                      ...profileData,
                      function_id: e.target.value,
                    });
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  size="small"
                  name="function_id"
                  MenuProps={{
                    style: {
                      maxHeight: 450,
                    },
                  }}
                >
                  {typesRender()}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            gap={2}
            width={"100%"}
          >
            <Button
              disabled={isDisabled}
              variant="contained"
              onClick={submitHandler}
            >
              Salvar
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;
