import { View, Text, Image } from "@react-pdf/renderer";
import { IListByProvider } from "../../types/export";
import { styles } from "./styles";
import { add, format } from "date-fns";
import AerialImg from "../../assets/styled-icons/ProgressBarAerial.png";
import {
  ACCOMMODATIONS,
  ROOMS,
  AEB,
  RSVP,
  SUBSCRIPTION,
  SUPPORT,
  AIR,
  SEVERAL,
  EQUIPMENTS,
  TRANSFER,
  COMMUNICATIONS,
  TRANSLATIONS,
} from "../../utils/serviceTypesFromBudget";

import aerialIcon from "../../assets/styled-icons/export-icons/aerial.png";
import accommodationsIcon from "../../assets/styled-icons/export-icons/accommodations.png";
import aebIcon from "../../assets/styled-icons/export-icons/aeb.png";
import equipmentIcon from "../../assets/styled-icons/export-icons/equipments.png";
import otherIcon from "../../assets/styled-icons/export-icons/other.png";
import rsvpIcon from "../../assets/styled-icons/export-icons/rsvp.png";
import subscriptionIcon from "../../assets/styled-icons/export-icons/subscription.png";
import supportIcon from "../../assets/styled-icons/export-icons/support.png";
import transferIcon from "../../assets/styled-icons/export-icons/transfer.png";
import translationsIcon from "../../assets/styled-icons/export-icons/translations.png";
import roomsIcon from "../../assets/styled-icons/export-icons/rooms.png";

interface IServiceRowProps {
  servicesList: any[];
  type: string;
}
export function DetailsRow(props: IServiceRowProps) {
  function getProductName(service: string) {
    let productName: any = "";
    switch (props.type) {
      case "ACCOMMODATIONS":
        productName = ACCOMMODATIONS.find((el) => el.value === service)?.text;
        break;
      case "ROOMS":
        productName = ROOMS.find((el) => el.value === service)?.text;
        break;
      case "AEB":
        productName = AEB.find((el) => el.value === service)?.text;
        break;
      case "RSVP":
        productName = RSVP.find((el) => el.value === service)?.text;
        break;
      case "SUBS":
        productName = SUBSCRIPTION.find((el) => el.value === service)?.text;
        break;
      case "SUPPORT":
        productName = SUPPORT.find(
          (el) => el.value === service
        )?.text;
        break;
      case "AIR":
        productName = AIR.find((el) => el.value === service)?.text;
        break;
      case "SEVERAL":
        productName = SEVERAL.find(
          (el) => el.value === service
        )?.text;
        break;
      case "EQUIPMENT":
        productName = EQUIPMENTS.find(
          (el) => el.value === service
        )?.text;
        break;
      case "TRANSLATION":
        productName = TRANSLATIONS.find(
          (el) => el.value === service
        )?.text;
        break;
      case "TRANSFER":
        productName = TRANSFER.find((el) => el.value === service)?.text;
        break;
      case "COMMUNICATIONS":
        productName = COMMUNICATIONS.find(
          (el) => el.value === service
        )?.text;
        break;
    }
    return productName;
  }
  function getServiceIcon() {
    let serviceIcon = "";
    switch (props.type) {
      case "ACCOMMODATIONS":
        serviceIcon = accommodationsIcon;
        break;
      case "ROOMS":
        serviceIcon = roomsIcon;
        break;
      case "AEB":
        serviceIcon = aebIcon;
        break;
      case "RSVP":
        serviceIcon = rsvpIcon;
        break;
      case "SUBS":
        serviceIcon = subscriptionIcon;
        break;
      case "SUPPORT":
        serviceIcon = supportIcon;
        break;
      case "AIR":
        serviceIcon = aerialIcon;
        break;
      case "SEVERAL":
        serviceIcon = otherIcon;
        break;
      case "EQUIPMENT":
        serviceIcon = equipmentIcon;
        break;
      case "TRANSLATION":
        serviceIcon = translationsIcon;
        break;
      case "TRANSFER":
        serviceIcon = transferIcon;
        break;
      case "COMMUNICATIONS":
        serviceIcon = transferIcon;
        break;
    }
    return serviceIcon;
  }
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <View
        style={{
          ...styles.serviceTypeDiv,
          height: `${60 * props.servicesList.length}px`,
        }}
      >
        <Image src={getServiceIcon()} style={{ width: "60%" }} />
      </View>
      <View style={{ borderBottom: "1px solid #ccc" }}>
        {props.servicesList.map((service, index) => {
          return (
            <View key={Math.random()} style={styles.rowNormal}>
              <View style={styles.simpleDiv}>
                <Text style={styles.text}>
                  {getProductName(service.service_type)}
                </Text>
              </View>
              <View style={styles.simpleDiv}>
                <Text style={styles.text}>Fornecedor</Text>
              </View>
              <View style={styles.simpleDiv}>
                <Text style={styles.text}>{service.observation}</Text>
              </View>
              <View style={styles.doubleDiv}>
                <Text style={styles.text}>{service.composition}</Text>
              </View>
              <View style={styles.doubleDiv}>
                <Text style={styles.text}>{service.form_of_payment}</Text>
              </View>
              <View style={styles.doubleDiv}>
                <Text style={styles.text}>{service.cancellation_policy}</Text>
              </View>
            </View>
          );
        })}
      </View>
    </div>
  );
}
