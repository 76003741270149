import {
  Autocomplete as AutocompleteField,
  AutocompleteProps,
  Box,
  InputLabel,
} from "@mui/material";

interface Props extends AutocompleteProps<any, any, any, any> {
  label: string;
  error?: boolean;
  inputValue?: string;
  isLoading?: boolean;
}

export const Autocomplete: React.FC<Props> = ({
  label,
  isLoading,
  error = false,
  ...props
}) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"flex-start"}
      width={"100%"}
      margin={0}
    >
      <InputLabel sx={{ fontSize: 13 }} error={!!error}>
        {label}
      </InputLabel>
      <AutocompleteField
        {...props}
        fullWidth
        sx={{ ...props.sx, fontSize: 15 }}
        loading={isLoading}
        loadingText={"Carregando..."}
      />
    </Box>
  );
};
