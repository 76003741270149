import {
  TextField,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  SelectChangeEvent,
  Chip
} from "@mui/material";
import { Theme, useTheme } from '@mui/material/styles';
import { ContentDiv, RowDiv, } from "../styles";
import { CoordinatorItem } from "../CoordinatorItem";
import { FaCrown, FaPlus } from "react-icons/fa";
import { StyledP } from "../styles";
import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { useQuery } from "react-query";
import UserService from "../../../../services/user.service";
import { LabeledAutoComplete } from "../../../UI/LabeledAutoComplete";

interface IProps {
  data: any;
  onChange: (event: any) => void;
}

export function RegisterStep({ data, onChange }: IProps) {
  const { data: usersData, error: userError, isLoading  } = useQuery({
    queryKey: ["cells-all-users"],
    queryFn: () => UserService.allUsers(),
  });
  const [leaderSearchTerm, setLeaderSearchTerm] = useState('')
  const [leaders, setLeaders] = useState<any>([])
  const [showTooltip, setShowToolTip] = useState(false)
  const [coordinatorSearchTerm, setCoordinatorSearchTerm] = useState('')
 
  const handleChangeLeaders = (event: SyntheticEvent<Element, Event>, newValue: any) => {
    let e = {
      target: {
        name: 'leader_user_ids',
        value: newValue.map((el: any) => el._id)
      }
    }
    setLeaders(newValue)
    onChange(e)
    setLeaderSearchTerm('')
  };

  const handleChangeCoordinators = (event: SyntheticEvent<Element, Event>, newValue: any) => {
    let e = {
      target: {
        name: 'coordinators_user_ids',
        value: newValue.map((el: any) => el._id)
      }
    }
    onChange(e)
    setCoordinatorSearchTerm('')
  };


  const showChangeLeader = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setShowToolTip(true)
  }

  const setNewLeader = (tagValue: any[], option: any) => {
    const leadersArr = tagValue.filter((el: any) => el._id !== option._id)
    leadersArr.unshift(option)
    setLeaders(leadersArr)
    let e = {
      target: {
        name: 'leader_user_ids',
        value: leadersArr.map((el: any) => el._id)
      }
    }
    onChange(e)
  }

  useEffect(() => {
    if(data.leader_user_ids.toString() === '') return
    let leaderArr = data.leader_user_ids.map((el: any) => {
      const user = usersData?.find(user => user._id === el)
      if (user) return user
      else return null
    })
    setLeaders(leaderArr || [])
  }, [usersData])

  return (
    <>
      <ContentDiv>

        <RowDiv>
          <InputLabel>Nome da célula</InputLabel>
          <TextField
            value={data.name}
            onChange={onChange}
            size="small"
            name="name"
          />
        </RowDiv>
        <RowDiv>
          <InputLabel>Descrição</InputLabel>
          <TextField
            value={data.description}
            onChange={onChange}
            size="small"
            multiline
            name="description"
          />
        </RowDiv>
        <RowDiv>

        </RowDiv>
      </ContentDiv>
      <ContentDiv style={{ width: '100%' }}>
        <LabeledAutoComplete
          multiple
          options={usersData || []}
          inputValue={leaderSearchTerm}
          value={leaders}
          disablePortal
          getOptionLabel={(option) => option?.name || ""}
          size="small"
          label='Líder da Célula'

          renderInput={(params) => {
            return (
              <TextField

                {...params}
                onChange={(
                  e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  setLeaderSearchTerm(e.target.value);
                }}
              />
            );
          }}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                label={
                  <span style={{ alignItems: 'center' }}>
                    {index === 0 ? <FaCrown /> : ''} {option?.name || ''}
                  </span>
                }
                onClick={(e) => setNewLeader(tagValue, option)}
                onMouseEnter={showChangeLeader}
                onMouseLeave={() => setShowToolTip(false)}
              />
            ));
          }}

          onChange={handleChangeLeaders}
        />
        <div style={{ height: '10px' }}>
          <span style={showTooltip ? { display: 'block', } : { display: 'none' }}>
            Clique para definir como líder.
          </span>
        </div>
      </ContentDiv>
      <ContentDiv style={{ width: '100%' }}>
        <LabeledAutoComplete
          multiple
          options={usersData || []}
          inputValue={coordinatorSearchTerm}
          value={usersData?.filter((el: any) => data.coordinators_user_ids.includes(el._id)) || []}
          disablePortal
          getOptionLabel={(option) => option.name || ""}
          size="small"
          label='Coordenadores da Célula'
          renderInput={(params) => {
            return (
              <TextField

                {...params}
                onChange={(
                  e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  setCoordinatorSearchTerm(e.target.value);
                }}
              />
            );
          }}
          onChange={handleChangeCoordinators}
        />

      </ContentDiv>

    </>
  );
}
