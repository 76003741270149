import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  TextField,
  Button,
  Box,
} from "@mui/material";
import { InputDiv, SummaryDiv, RadioDiv, InputRow, StyledBox } from "../styles";
import { useContext, useEffect, useState } from "react";
import { Input } from "../../../Input";
import { FormProvider, useForm } from "react-hook-form";
import { useRsvp } from "../../../../contexts/rsvp.context";
import { CompanionFieldsType, RsvpType } from "../../../../types/rsvp/index";

type Props = {
  onSubmitNav: () => void
  isDisabled: boolean
}

export function Companion({ onSubmitNav, isDisabled }: Props) {
  const { rsvpId, updateCompanion, createRsvp, companion } = useRsvp()
  const obj: CompanionFieldsType = {
    companionOption: "",
    companionOptionObs: "",
    companionOptionOutOfListObs: "",
    substitutionIndicationObs: ""
  }
  const methods = useForm({
    defaultValues: obj,
  })
  const { handleSubmit, register, watch, setValue, reset } = methods

  function onSubmit(form: CompanionFieldsType) {
    rsvpId ? updateCompanion(form) : createRsvp({ companion: form } as RsvpType)
    onSubmitNav()
  }

  useEffect(() => {
    if (companion) {
      reset(companion);
    }
  }, [companion]);

  return (
    <FormProvider {...methods}>
      <StyledBox onSubmit={handleSubmit(onSubmit)} >
        <h3>4.Acompanhante</h3>
        <InputDiv>
          <SummaryDiv>
            <strong>Optante por acompanhantes</strong>
            <span>
              Padrão de complience, indica a não participação de acompanhante
            </span>
          </SummaryDiv>
          <RadioDiv>
            <FormControl>
              <RadioGroup
                value={watch("companionOption")}
                onChange={(e) => setValue("companionOption", e.target.value)}
              >
                <FormControlLabel
                  value="NOT_ALLOWED"
                  control={<Radio />}
                  label="Não é permitido"
                />
                <FormControlLabel
                  value="ALLOWED_WITH_COST"
                  control={<Radio />}
                  label="Permite, ciente de responsabilidade de custo, e veta a participação nos eventos(nem refeições). Pode ter intermédio da MM"
                />
                <FormControlLabel
                  value="ALLOWED_WITHOUT_MM"
                  control={<Radio />}
                  label="Permite, ciente de responsabilidade de custo, e veta a participação nos eventos(nem refeições). Sem intermédio da MM"
                />
                <FormControlLabel
                  value="CASE_BY_CASE_ANALYSIS"
                  control={<Radio />}
                  label="Análise de caso a caso junto ao coordenador operacional"
                />

              </RadioGroup>
            </FormControl>
            <Input
              label="Outros"
              size="small"
              {...register('companionOptionObs')}
            />
          </RadioDiv>
        </InputDiv>
        <InputDiv>
          <SummaryDiv>
            <strong>Optante por acompanhantes</strong>
            <span>Colega não incluso na lista</span>
          </SummaryDiv>
          <RadioDiv>
            <Input
              label="Outros"
              size="small"
              {...register('companionOptionOutOfListObs')}
            />
          </RadioDiv>
        </InputDiv>
        <InputDiv>
          <SummaryDiv>
            <strong>Indicação de substituição por não poder comparecer</strong>
          </SummaryDiv>
          <RadioDiv>
            <Input
              label="Outros"
              size="small"
              {...register('substitutionIndicationObs')}
            />
          </RadioDiv>
        </InputDiv>

        <Button
          disabled={isDisabled}
          variant="contained"
          
          style={{ marginLeft: "90%" }}
          type="submit">
          Salvar
        </Button>
      </StyledBox>
    </FormProvider>

  );
}
