import axios from "axios";
import { api } from "./apiClient";

export default class SupplierService {
  static async getProviders(params: any): Promise<any[]> {
    if (params.id.trim() === "") return [];
    return api
      .get("/supplier/get", { params })
      .then((response) => response.data);
  }
  static async sync(): Promise<void> {
    return api.get("/supplier/sync-external").then((response) => response.data);
  }
  static async create(data: any): Promise<any> {
    return api.post("/supplier/create", data).then((response) => response.data);
  }

  static async update(id: string, data: any): Promise<any> {
    return api
      .put(`/supplier/update/${id}`, data)
      .then((response) => response.data);
  }
  static async getProvidersBySearch(params: any): Promise<{ data: [] }> {
    return api
      .get("/supplier/get-by-search", { params })
      .then((response) => response.data);
  }
  static async search(
    params: any
  ): Promise<{ total: number; providers: any[] }> {
    return api
      .get(`/supplier/get-by-search/`, { params })
      .then((response) => response.data);
  }
  static async fetchCnpjData(cnpj: string): Promise<any> {
    try {
      const response = await axios.get(
        `https://api-publica.speedio.com.br/buscarcnpj?cnpj=${cnpj}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching CNPJ data", error);
      return {};
    }
  }
}
