import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  TextField,
  FormGroup,
  Checkbox,
  Button,
  Box,
  Switch
} from "@mui/material";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { InputDiv, SummaryDiv, RadioDiv, StyledBox } from "../styles";
import { BsCaretDown, BsCaretUp } from "react-icons/bs";
// import SaveImg from "../../../assets/styled-icons/Save.png";

// import { Switch } from "../../../UI/Switch";
import { FormProvider, useForm } from "react-hook-form";
import { useRsvp } from "../../../../contexts/rsvp.context";
import { Input } from "../../../Input";
import { MandatoryDataDocTypesEnum, PrecongressFieldType, RsvpType } from "../../../../types/rsvp/index";
import { FormInputMultiCheckbox } from "./FormMultipleCheckBox";

type Props = {
  onSubmitNav: () => void
  isDisabled: boolean
}


export function PreCongress({ onSubmitNav, isDisabled }: Props) {
  const { rsvpId, updatePrecongress, createRsvp, precongress } = useRsvp()

  const documentsOptions = (Object.keys(MandatoryDataDocTypesEnum) as Array<keyof typeof MandatoryDataDocTypesEnum>).map(el => {
    return { label: MandatoryDataDocTypesEnum[el], value: el }
  })

  const obj: PrecongressFieldType = {
    presence: '',
    congressLogistics: '',
    sponsorshipDivergence: '',
    mandatoryData: '',
    memberInfo: '',
    alimentation: '',
    mandatoryDataDocs: [],
    presenceObs: "",
    congressLogisticsObs: "",
    sponsorshipDivergenceObs: "",
    mandatoryDataObs: "",
    memberInfoObs: "",
    memberInstitution: "",
    alimentationObs: "",
    rsvpSystem: false,
    rsvpSystemObs: ""
  }
  const methods = useForm({
    defaultValues: obj,
  })
  const { handleSubmit, register, watch, setValue, reset, control } = methods
  const [mandatoryDataDocsState, setMandatoryDataDocsState] = useState([] as string[])
  const [isSwitchOn, setSwitchOn] = useState(false);
  const [observation, setObservation] = useState('');

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setSwitchOn(checked);
  };

  const handleObservationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setObservation(event.target.value);
  };

  function onSubmit(form: PrecongressFieldType) {
    rsvpId ? updatePrecongress(form) : createRsvp({ precongress: form } as RsvpType)
    onSubmitNav()
  }

  useEffect(() => {
    if (precongress) {
      setMandatoryDataDocsState(precongress.mandatoryDataDocs)
      reset(precongress);
    }
  }, [precongress]);

  return (
    <FormProvider {...methods}>
      <StyledBox onSubmit={handleSubmit(onSubmit)}>
        <h3>1.Briefing</h3>
        <InputDiv>
          <SummaryDiv>
            <strong>Presença</strong>
            <span>
              Perda de programação deve ser ciente pelo convidado. Análise feita
              pelo cliente sob a programação obrigatória
            </span>
          </SummaryDiv>
          <RadioDiv>
            <FormControl>
              <RadioGroup
                value={watch("presence")}
                onChange={(e) => setValue("presence", e.target.value)}
              >
                <FormControlLabel
                  value="PARTIAL_WITH_ANALYSIS"
                  control={<Radio />}
                  label="Parcial com análise"
                />
                <FormControlLabel
                  value="PARTIAL_WITHOUT_ANALYSIS"
                  control={<Radio />}
                  label="Parcial sem análise"
                />
                <FormControlLabel
                  value="NOT_PARTIAL"
                  control={<Radio />}
                  label="Não parcial"
                />

              </RadioGroup>

            </FormControl>
            <Input
              label="Outros"
              {...register('presenceObs')}
              multiline
              size="small"
            />
          </RadioDiv>
        </InputDiv>
        <br/>
        <InputDiv>
          <SummaryDiv>
            <strong>Anexo Logística de RSVP</strong>
            <span>
              Anexa planilha RSVP
            </span>
          </SummaryDiv>
          <RadioDiv>
          <FormControl>
              <RadioGroup
                value={watch("congressLogistics")}
                onChange={(e) => setValue("congressLogistics", e.target.value)}
              >
                <FormControlLabel
                  value="CLIENT_LOGISTICS_REGISTRATIONS"
                  control={<Radio />}
                  label="Logística, participações e inscrições pelo cliente. Cursos gratuitos selecionada na inscrição"
                />
                <FormControlLabel
                  value="CLIENT_LOGISTICS_REGISTRATIONS_POST_SELECTION"
                  control={<Radio />}
                  label="Logística, participações e inscrições pelo cliente. Cursos gratuitos selecionada pós inscrição"
                />
                <FormControlLabel
                  value="CLIENT_LOGISTICS_REGISTRATIONS_PAYMENTS"
                  control={<Radio />}
                  label="Logística, participações, inscrições e pagamento pelo cliente."
                />
                <FormControlLabel
                  value="CLIENT_LOGISTICS_REGISTRATIONS_GUEST_PAYMENT"
                  control={<Radio />}
                  label="Logística, participações, inscrições e pagamento pelo cliente de responsabilidade do convidado."
                />
                <FormControlLabel
                  value="GUEST_LOGISTICS_REGISTRATIONS_PAYMENT"
                  control={<Radio />}
                  label="Logística, participações, inscrições e pagamento de responsabilidade do convidado."
                />
                <FormControlLabel
                  value="NO_CONGRESS"
                  control={<Radio />}
                  label="Não haverá congresso"
                />

              </RadioGroup>
            </FormControl>
            <Input
              placeholder="Observação"
              {...register('congressLogisticsObs')}
              multiline
              size="small"
              style={{ width: '158%' }}
            />
          </RadioDiv>
        </InputDiv>
        <InputDiv>
          <SummaryDiv>
            <strong>Sistema RSVP</strong>
            <span>
              Informar dados (data, horário, local se houver, transfer,
              estacionamentos, permissão de acompanhante, traje, etc)
            </span>
          </SummaryDiv>
          <RadioDiv>
          <FormControlLabel
            control={<Switch {...register('rsvpSystem', 
            { required: 'Campo obrigatório' })} />}
            label="Aplica"
          />

            <Input
              label="Outros"
              {...register('rsvpSystemObs')}
              multiline
              size="small"
            />
          </RadioDiv>
        </InputDiv>

        {/* <InputDiv>
          <SummaryDiv>
            <strong>Divergência de patrocínio</strong>
            <span>
              Determinar dados e informar sobre divergências para coordenação
            </span>
          </SummaryDiv>
          <RadioDiv>
            <FormControl>
              <RadioGroup
                value={watch("sponsorshipDivergence")}
                onChange={(e) => setValue("sponsorshipDivergence", e.target.value)}
              >
                <FormControlLabel
                  value="CORRECT"
                  control={<Radio />}
                  label="Corretos"
                />
                <FormControlLabel
                  value="CORRECT_WITH_DISCREPANCIES"
                  control={<Radio />}
                  label="Corretos mas informar divergências "
                />
              </RadioGroup>
            </FormControl>
            <Input
              label="Outros"
              {...register('sponsorshipDivergenceObs')}
              multiline
              size="small"
            />
          </RadioDiv>
        </InputDiv> */}
        <InputDiv>
          <SummaryDiv>
            <strong>Dados obrigatórios confirmados com convidados</strong>
            <span>
              Informar dados (data, horário, local se houver, transfer,
              estacionamentos, permissão de acompanhante, traje, etc). Sem refação
              por motivo de dados faltantes.
            </span>
          </SummaryDiv>
          <RadioDiv>
            <FormControl>
              <RadioGroup
                value={watch("mandatoryData")}
                onChange={(e) => setValue("mandatoryData", e.target.value)}
              >
                <FormControlLabel
                  value="NO_NEED_TO_CONFIRM_DATA"
                  control={<Radio />}
                  label="Não precisa confirmar dados"
                />
                <FormControlLabel
                  value="INVITATION_LETTER_MODEL_SENT"
                  control={<Radio />}
                  label="Modelo de carta convite enviado(adaptar planilha RSVP)"
                />
              </RadioGroup>
              <FormGroup>
                <FormLabel>Documentos necessários</FormLabel>
                <FormInputMultiCheckbox
                  name={"mandatoryDataDocs"}
                  control={control} label={'label'}
                  options={documentsOptions}
                  setValue={setValue}
                  previousSelected={mandatoryDataDocsState || []}
                  optionSelectAll={true}
                  setControlledState={setMandatoryDataDocsState}
                />
              </FormGroup>


            </FormControl>
            <Input
              label="Outros"
              {...register('mandatoryDataObs')}
              multiline
              size="small"
            />
          </RadioDiv>
        </InputDiv>
        <InputDiv>
          <SummaryDiv>
            <strong>Membro</strong>
            <span>Em caso de confirmação, não requer envio de comprovação</span>
          </SummaryDiv>
          <RadioDiv>
            <Input label="Instituição a se confirmar" {...register('memberInstitution')} size="small" />
            <FormControl>
              <RadioGroup
                value={watch("memberInfo")}
                onChange={(e) => setValue("memberInfo", e.target.value)}
              >
                <FormControlLabel
                  value="NOT_REQUIRED"
                  control={<Radio />}
                  label="Não é necessário confirmar essa informação"
                />
                <FormControlLabel
                  value="NOT_APPLICABLE"
                  control={<Radio />}
                  label="Não se aplica pra esse evento"
                />
              </RadioGroup>
            </FormControl>
            <Input
              label="Outros"
              {...register('memberInfoObs')}
              multiline
              size="small"
            />
          </RadioDiv>
        </InputDiv>
        <InputDiv>
        <SummaryDiv>
          <strong>Alimentação por conta do cliente?</strong>
          <br />
          <span>
            Durante período do evento
          </span>
        </SummaryDiv>
        <RadioDiv>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <FormControlLabel
              control={<Switch checked={isSwitchOn} onChange={handleSwitchChange} />}
              label={isSwitchOn ? 'Sim - por conta do cliente' : 'Não'}
            />
            {isSwitchOn && (
              <Input
                value={observation}
                onChange={handleObservationChange}
                placeholder="Observações"
                multiline
                size="small"
                style={{ width: '158%' }}
              />
            )}
          </div>
        </RadioDiv>
      </InputDiv>
        <Button
          disabled={isDisabled}
          variant="contained"
          style={{ position: 'relative', left: '90%' }}
          type="submit"
        >
          Salvar
        </Button>
      </StyledBox>
    </FormProvider>
  );
}
