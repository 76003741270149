import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useContext } from "react";
import { IoIosClose } from "react-icons/io";
import { TabPanel } from "../../../../TabPanel";
import { CellService } from "../../../../../services/cell.service";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { AiFillSave } from "react-icons/ai";
import { RegisterStep } from "../../../../Settings/GroupModal/RegisterStep";
import { ProjectStep } from "../../../../Settings/GroupModal/ProjectsStep";
import { Permissions } from "../../../../Settings/GroupModal/Permissions";
import { AuthContext } from "../../../../../contexts/auth";

type Props = {
  cell?: any;
  onClose: () => void;
  onSuccess?: () => void;
  onGoTo(destination: string): void
};


function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const CellModal: React.FC<Props> = (props) => {
  const {actionOptions} = useContext(AuthContext)
  const queryClient = useQueryClient()

  const { onClose, onSuccess, cell, onGoTo } = props;
  const [tabActive, setTabActive] = React.useState(0);

  const { mutate: createCell, isLoading: isLoadingCreate } = useMutation(
    CellService.create,
    {
      onSuccess: () => {
        toast.success("Célula criada com sucesso!");
        onSuccess && onSuccess();

      },
      onError: (error) => {
        toast.error('Não foi possível criar a célula')
      }
    }
  );

  const { mutate: updateCell, isLoading: isLoadingUpdate } = useMutation(
    (data: any) => CellService.update(cell._id, data),
    {
      onSuccess: () => {
        toast.success("Célula atualizada com sucesso!");
        onSuccess && onSuccess();

      },
      onError: (error) => {
        toast.error('Não foi possível atualizar a Célula')
      }
    }
  );

  const [cellData, setCellData] = React.useState({
    active: cell?.active || false,
    name: cell?.name || "",
    description: cell?.description || "",
    leader_user_ids: cell?.leader_user_ids || [''],
    coordinators_user_ids: cell?.coordinators_user_ids || ['']
  });

  function handleChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const { name, value } = e.target;

    setCellData({ ...cellData, [name]: value });
  }

  const handleSubmmit = (e: React.MouseEvent) => {
    cell ? updateCell(cellData) : createCell(cellData);
    const { name } = e.target as HTMLButtonElement;
    if (name === 'save-close') onClose()
  };
  const isDisabled = isLoadingCreate || isLoadingUpdate || (!actionOptions['createControle de acesso'] && !actionOptions['updateControle de acesso'])
  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <Box p={4}>
        <Box display={"flex"} alignItems={"center"} mb={3} gap={2}>
          <Typography variant="h5">Célula</Typography>

          <ButtonGroup
            disableElevation
            variant="outlined"
            aria-label="Change status"
            size="small"
          >
            <Button
              onClick={() => setCellData({ ...cellData, active: true })}
              sx={{
                textTransform: "none",
                color: cellData.active ? "#1361A" : "#666",
                fontWeight: cellData.active ? 700 : 400,
              }}
            >
              Ativo
            </Button>

            <Button
              onClick={() => setCellData({ ...cellData, active: false })}
              sx={{
                textTransform: "none",
                color: !cellData.active ? "#1361A" : "#999",
                fontWeight: !cellData.active ? 700 : 400,
              }}
            >
              Inativo
            </Button>
          </ButtonGroup>
        </Box>

        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <IoIosClose width={24} height={24} />
          </IconButton>
        ) : null}
        <Tabs
          value={tabActive}
          onChange={(e, index) => { setTabActive(index) }}
          indicatorColor="primary"
          aria-label="full width tabs example"
        >
          <Tab
            label="Cadastro"
            {...a11yProps(0)}
            sx={{ textTransform: "none" }}
          />
          <Tab
            label="Usuários/Clientes/Projetos"
            {...a11yProps(1)}
            sx={{ textTransform: "none", color: '#ccc', opacity: 0.7 }}
          />

          <Tab
            label="Permissão"
            {...a11yProps(2)}
            sx={{ textTransform: "none", color: '#ccc', opacity: 0.7 }}
          />
        </Tabs>

        <TabPanel value={tabActive} index={0}>
          <RegisterStep data={cellData} onChange={handleChange} />
        </TabPanel>

        <TabPanel value={tabActive} index={1}>
          <ProjectStep onGoTo={onGoTo} />
        </TabPanel>

        <TabPanel value={tabActive} index={2}>
          <Permissions />
        </TabPanel>
        <TabPanel value={tabActive} index={2}>
          <div></div>
        </TabPanel>

        <Box display={"flex"} justifyContent={"flex-end"} gap={2} mt={4}>
          <Button variant="outlined" onClick={onClose} style={{ textTransform: 'none' }}>
            Cancelar
          </Button>

          <Button
            variant="contained"
            onClick={handleSubmmit}
            name="save-close"
            disabled={isDisabled} style={{ textTransform: 'none' }}
          >
            Salvar e Fechar
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmmit}
            name="save"
            disabled={isDisabled} style={{ textTransform: 'none' }}
          >
            <AiFillSave size={22} />

          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CellModal;
