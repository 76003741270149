import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Box, Button, Card, styled} from "@mui/material";
import {useQuery} from "react-query";
import DashBoardService from "../../services/dashboard.service";
import {BRIEFING_STATUS_OPTIONS} from "../../utils/briefingStatus";
import {useProject} from "../../contexts/project.context";
import {AuthContext} from "../../contexts/auth";
import {Item} from "./Item";
import backgroundImage from "../../assets/background1.png";

const ScrollableContainer = styled(Box)`
    margin-top: 150px;
    overflow-y: auto;
    height: calc(100vh - 200px);
`;

export default function StatusCount() {
    const {accessOptions} = useContext(AuthContext)
    const {} = useProject()
    const history = useHistory()
    const {isLoading, error, data} = useQuery({
        queryKey: [`status-count`],
        queryFn: () => DashBoardService.getStatusCount(),
    });

    function handleGotoProjects(target: string) {
        history.push({
            pathname: '/projetos',
            search: '?status=' + target
        })
    }

    function mapTitleToColumnColor(title: string): string | null {
        switch (title) {
            case "Aprovado":
            case "Auditado":
            case "Faturado":
            case "Finalizado":
                return "green";
            case "Cancelado":
            case "Cancelado/Auditado":
            case "Cancelado/Cobrado":
            case "Declinado":
            case "Perdido":
                return "red";
            case "Em Aprovação":
            case "Pré-fechamento":
            case "Aguardando Cliente":
            case "Liberado Operação":
            case "Prestação de Contas":
                return "yellow";
            case "Orçamento":
            case "Duplicidade":
            case "Compartilhado Financeiro":
            case "Financeiro":
                return "blue";
            default:
                return null;
        }
    }

    const [columns, setColumns] = useState<any>({
        "blue": [],
        "yellow": [],
        "red": [],
        "green": [],
    });

    useEffect(() => {
        if (data) {
            const newColumns: any = {
                "blue": [],
                "yellow": [],
                "red": [],
                "green": [],
            };

            data.forEach((item: any) => {
                const title = BRIEFING_STATUS_OPTIONS.find((el) => el.value === item.status)?.text;
                const column = mapTitleToColumnColor(title || "");
                if (column) {
                    newColumns[column].push(item);
                }
            });

            setColumns(newColumns);
        }
    }, [data]);

    return (
        <Card
            style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center 45px',
                position: 'relative',
            }}
        >
            <ScrollableContainer>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                    gap="14px"
                    padding='12px'
                >
                    {Object.entries(columns).map(([color, columnItems]) => (
                        <Box key={color}>
                            {(columnItems as any[]).map((item: any) => (
                                <Item
                                    title={BRIEFING_STATUS_OPTIONS.find((el) => el.value === item.status)?.text || "não deu"}
                                    count={item.count}
                                    key={item.status || ""}
                                    target={item.status || ""}
                                    onClick={handleGotoProjects}
                                    columnIndex={Object.keys(columns).indexOf(color)} // Adicione esta linha
                                />
                            ))}
                        </Box>
                    ))}
                </Box>
            </ScrollableContainer>
            <Button
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: 2,
                    position: "absolute",
                    top: "4px",
                    right: 0,
                    mt: 0.3,
                    mr: 5,
                    backgroundColor: "#007aff",
                    color: "white",
                    '&:hover': {
                        backgroundColor: '#1976d2',
                    },
                }}
                onClick={() => history.push('/projeto')}
            >
                Novo Projeto
            </Button>
            <Box sx={{position: 'absolute', top: '10px', left: '25px'}}>
                <span style={{color: 'darkgray', fontSize: '22px'}}>Dashboard /</span>
                <span style={{color: 'gray', fontSize: '22px', fontWeight: 'bold'}}> Status</span>
            </Box>
        </Card>
    );
}
