import format from "date-fns/format";

import { currencyFormatter } from "./currencyFormatter";

export const formatPhoneNumber = (str: string) => {
  // Filter only numbers from the input
  const cleaned = `${str}`.replace(/\D/g, "");

  // Check if the input is of correct length
  const match = cleaned.match(/^(\d{2})(\d{1})(\d{4})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]} ${match[3]}-${match[4]}`;
  }

  return str;
};

export const formatDateTime = (str: any) => {
  const formatedDate = format(new Date(str), "dd/MM/yyyy  hh:mm");
  return formatedDate;
};

export function createOptionsFromEnum(_enum: any): any {
  return (Object.keys(_enum) as Array<keyof typeof _enum>).map((el) => {
    return { label: _enum[el], value: el };
  });
}

export const formatProfitability = (value: number) => {
  return `${`${value?.toFixed(2)}`.replace(".", ",")}%`;
};

export const formatCurrency = (value: number) => {
  return currencyFormatter.format(value);
};
