import { yupResolver } from "@hookform/resolvers/yup";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FaCaretDown } from "react-icons/fa";
import * as yup from "yup";

import { useProject } from "../../../../../contexts/project.context";
import CurrencyInputForm from "../../../../CurrencyInputForm";
import { Input } from "../../../../Input";
import { RadioGroup } from "../../../../UI/RadioGroup";
import { Switch } from "../../../../UI/Switch";

const earningTypes: any[] = [
  { label: "Nota fiscal", value: "INVOICE" },
  { label: "Nota de débito", value: "DEBIT_NOTE" },
];
const paymentTypes: any[] = [
  {
    label: "Faturado para o cliente, faturado no fornecedor (Gera fatura)",
    value: "BILLED_TO_CLIENT_BILLED_TO_SUPPLIER",
  },
  {
    label:
      "Faturado para o cliente, com cartão de crédito no fornecedor (Gera fatura)",
    value: "BILLED_TO_CLIENT_CREDIT_CARD_SUPPLIER",
  },
  {
    label: "Faturado para o cliente, com invoice pro fornecedor (Gera fatura)",
    value: "BILLED_TO_CLIENT_INVOICE_SUPPLIER",
  },
  {
    label: "Despesa interna, faturada no fornecedor (Gera fatura)",
    value: "INTERNAL_EXPENSE_BILLED_TO_SUPPLIER",
  },
  {
    label:
      "Cartão de crédito do cliente, com cartão de crédito no fornecedor (Apenas Fee)",
    value: "CLIENT_CREDIT_CARD_SUPPLIER_CREDIT_CARD",
  },
  { label: "Pagamento direto (Apenas Fee)", value: "DIRECT_PAYMENT" },
];

const validationSchema = yup.object({
  customerBudget: yup.number(),
  paymentTerm: yup.string(),
  acceptAdvances: yup.boolean(),
  advanceObservations: yup.string(),
  hasDrawnRisk: yup.boolean(),
  monthlyTax: yup.number(),
  drawnRiskObservations: yup.string(),
  makesDirectPayments: yup.boolean(),
  directPaymentsObservations: yup.string(),
  fee: yup.string(),
  paymentMethod: yup.string(),
  paymentMethodObservations: yup.string(),
  briefingObservations: yup.string(),
  observationsEvent: yup.string(),
});

const BriefingAccordion = () => {
  const { event } = useProject();
  const methods = useForm({
    defaultValues: {
      customerBudget: event?.customerBudget,
      paymentTerm: event?.paymentTerm,
      acceptAdvances: true,
      advanceObservations: event?.advanceObservations,
      hasDrawnRisk: event?.hasDrawnRisk,
      monthlyTax: event?.monthlyTax,
      drawnRiskObservations: event?.drawnRiskObservations,
      makesDirectPayments: event?.makesDirectPayments,
      fee: event?.fee,
      paymentMethod: event?.paymentMethod,
      paymentMethodObservations: event?.paymentMethodObservations,
      briefingObservations: event?.briefingObservations,
      directPaymentsObservations: event?.directPaymentsObservations,
      observationsEvent: event?.observationsEvent,
    },
    resolver: yupResolver(validationSchema),
    mode: "all",
  });
  const { register } = methods;
  return (
    <Accordion TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary
        aria-controls="panel1a-content"
        expandIcon={<FaCaretDown color="white" />}
        id="panel1a-header"
        style={{
          maxHeight: "40px",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#1361A4",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <span style={{ color: "white" }}>Briefing - Dados do Evento</span>
          {/* <div
            style={{ color: "white", marginRight: 16, cursor: "pointer" }}
            onClick={(e) => e.stopPropagation()}
          >
            Salvar
          </div> */}
        </div>
      </AccordionSummary>

      <AccordionDetails>
        <FormProvider {...methods}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 4,
              marginBottom: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                marginTop: 1.5,
              }}
            >
              <CurrencyInputForm
                label="Bugdet do cliente"
                {...register("customerBudget")}
                disabled
              />
            </Box>

            <Input
              label="Prazo de pagamento do cliente"
              {...register("paymentTerm")}
              style={{ marginTop: -2 }}
              disabled
            />
          </Box>

          <Box display={"flex"} alignItems={"center"} gap={8}>
            <Box marginTop={1}>
              <Switch
                label="Faz adiantamento?"
                valueLabel={event?.acceptAdvances ? "Sim" : "Não"}
                checked={event?.acceptAdvances}
              />
            </Box>

            <Input
              label="Observações"
              disabled
              {...register("advanceObservations")}
            />
          </Box>

          <Box display={"flex"} alignItems={"center"} gap={6}>
            <Box marginTop={1}>
              <Switch
                label="Possui risco sacado?"
                valueLabel={event?.hasDrawnRisk ? "Sim" : "Não"}
                checked={event?.hasDrawnRisk}
              />
            </Box>
            <Box display={"flex"} flex={1} gap={3}>
              <Input
                label="Qual é a taxa mensal?"
                disabled
                {...register("monthlyTax")}
              />
              <Input
                label="Observações"
                disabled
                {...register("drawnRiskObservations")}
              />
            </Box>
          </Box>

          <Box display={"flex"} alignItems={"center"} gap={8}>
            <Box marginTop={1}>
              <Switch
                label="Paga fornecedores diretamente?"
                valueLabel={event?.makesDirectPayments ? "Sim" : "Não"}
                checked={event?.makesDirectPayments}
              />
            </Box>
            <Input
              label="Observações"
              disabled
              {...register("directPaymentsObservations")}
            />
          </Box>

          <Box display={"flex"} alignItems={"center"}>
            <Input label="Honorários" disabled {...register("fee")} />
          </Box>

          <Box display={"flex"} alignItems={"center"} marginTop={2}>
            <RadioGroup.Root
              label="Forma de faturamento"
              value={
                earningTypes.find((item) => item.value === event?.billingMethod)
                  ?.value
              }
            >
              {earningTypes.map(({ value, label }, index) => (
                <RadioGroup.Item
                  key={index}
                  label={label}
                  value={value}
                  disabled={!event?.billingMethod}
                />
              ))}
            </RadioGroup.Root>
          </Box>

          <Box display={"flex"} alignItems={"center"} marginTop={2}>
            <RadioGroup.Root
              label="Forma de pagamento"
              value={
                paymentTypes.find((item) => item.value === event?.paymentMethod)
                  ?.value
              }
            >
              {paymentTypes.map(({ value, label }, index) => (
                <RadioGroup.Item
                  key={index}
                  label={label}
                  value={value}
                  disabled={!event?.paymentMethod}
                />
              ))}
            </RadioGroup.Root>
          </Box>

          <Box display={"flex"} alignItems={"center"}>
            <Input
              label="Observações"
              disabled
              {...register("observationsEvent")}
            />
          </Box>
        </FormProvider>
      </AccordionDetails>
    </Accordion>
  );
};

export default BriefingAccordion;
