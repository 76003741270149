export const TRANSLATIONS_EN = {
  sim: "Yes",
  nao: "No",
  valueOptions: "Value / %",
  convertedValue: "Converted Value",
  issHelper: "ISS Value",
  search: "Search",
  value: "Value",
  observations: "Observations",
  description: "Description",
  save: "Save",
  address: "Address",
  quantity: "Quantity",
  checkIn: "Check In",
  checkOut: "Check Out",
  options: "Options",
  dataVoo: "Flight Date",
  horaVoo: "Flight Hour",
  open: "Open",
  location: "Location",
  city: "City/State",
  country: "Country",
  site: "Site",
  radioErrorMessage: "Select yes or not",
  createSuccess: "Success create!",
  services: "Services",
  serviceTypes: "Service Types",
  valueType: "Value Type",
  currency: "Currency",
  taxes: "Taxes",
  loadServices: "View Services",
  totalValue: "Total Value",
  taxesHelper: "Select Tax Types",
  mmTaxesHelper: "MM Events Taxes",
  totalCostWithTaxes: "Total Cost + Taxes",
  totalCostConverted: "Total Cost Converted",
  exchange: "Exchange",
  login: {
    header: "Sign in",
    email: "Email or username",
    senha: "Password",
    button: "Sign in",
    register: "Register",
    esqueceu: "Forgot your password?",
  },
  forgot: {
    header: "Forgot my password",
    helper:
      "To reset your password, inform the username or email registered in your account and we will send you a link with the instructions.",
    email: "Email or username",
    submitButton: "Send",
    cancelButton: "Cancel",
    submitHeader: "Reset request password executed!",
    submitHelper:
      "A link has been sent to your email to reset your password. Pay attention to the instructions in the body of the email to complete the password recovery process.",
  },
  mainNavBar: {
    positions: {},
  },
  mainSideBar: {
    init: "Home",
    activities: "Activities",
    newBriefing: "New Briefing",
    budgets: "Budgets",
    clients: "Clients",
    reports: "Reports",
    myData: "My Data",
    documents: "Documents",
    audit: "Audit",
    settings: "Settings",
    exit: "Exit",
  },
  briefingNavBar: {
    initialBriefing: "Initial Briefing",
    creative: "Creative",
    logistics: "Logistics",
    aerial: "Aerial",
    rsvp: "RSVP",
    budget: "Budget",
    negociation: "Negociation",
    audit: "Audit ",
    production: "Produção",
  },
  briefingPage: {
    greeting: "Hello",
    welcomeMesssage: "Welcome to the new briefing worksheet!",
  },
  initialBriefingForm: {
    customerRegisterData: {
      header: "Customer Registering",
      companyName: "Company Name",
      document: "CNPJ",
      applicantName: "Applicant Name",
      contact: "Telephone",
      email: "E-mail",
    },
    briefingData: {
      header: "Briefing Data",
      eventName: "Event Name",
      eventType: "Event Type",
      delivery: "Delivery",
      briefing: "Briefing",
      presentation: "Presentation",
      customerInputs: "Customer Inputs",
      inputTypes: "Input types",
      competition: "Is it a competition?",
      competitionPlaceholder: "How many and which agencies are involved?",
      projectArea: "Is the project in our area of expertise?",
      fileName: "File Name",
    },
    financialInformation: {
      header: 'Finan"cial Information',
      budget: "Do we have a budget set?",
      paymentDate: "Final Date",
      suppliers: "Extra Suppliers",
      fees: "Fees Charged",
      advance: "Advance Money",
    },
    projectData: {
      header: "Project Data",
      eventResponsible: "Responsible of the event",
      eventType: "Event Type",
      initialDate: "Begin Date",
      finalDate: "Final Date",
      initialHour: "Begin Hour",
      finalHour: "Final Hour",
      eventFormat: "Event Format",
      streamed: "Will be Streamed?",
      platformTypes: "Platform Types",
      eventDuration: "Event Duration",
      eventObjective: "Event Objective",
      customerChallenges: "Customer Challenges",
      public: "Public",
      profile: "Profile",
      guestAmount: "Guests amount",
    },
  },
  creativeForm: {
    header: "Creation Registration",
    theme: "Do you have KV, name or theme?",
    directional: "Directional",
    keys: "Key Messages",
    art: "Need to create art?",
    ownKV: "Do you have your own KV to upload?",
    ownKVObs: `Note: In case of KV created by the client or another agency,
            it is important to inform that we need the file open to unfold the requested parts.
            KV made in Canvas and sending stock images, are not kvs and generates rework for the team. `,
    contentResponsibility: "Content Responsibility:",
    art2: "Need to unfold some art?",
    d3: "Does it have 3D?",
    scenographyExpense: "Expected scenography expenditure:",
    options: "Need to present more than one option?",
    client: "Client",
    agency: "Agency",
    both: "Client and Agency",
    invalidFields: {
      has_theme: "The 'Theme' field is required",
      create_art: "The field 'Create art' is required",
      own_delivery: "The field 'KV proper for sending' is mandatory",
      content_responsability: "The 'Content Responsibility' field is required",
      unfolder_art: "The field 'Unfold art' is required",
      is_3d: "The '3D' field is required",
      more_one_option: "The field 'More than one option' is required",
    },
  },
  producaoFrom: {
    header: "Production",
    decoration: "Miscellaneous - Decoration",
    scenography: "Scenography",
    scenographyCompany: "Producer",
    gifts: "Do you have gifts?",
    activation: "Do you have activations?",
    physycalMaterial: "Need physical material for guests?",
    directional: "Which directional?",
    material: "Material",
    logistics: "Logistica",
    musicShow: "Do you have Musical Attraction?",
    musicShowObs: "Inform which or some specific genre",
    speakers: `Do you have a speaker? MC's or Speakers.`,
    speakersObs: "Inform linked to the concept",
    residual: "Need any post-event residual?",
    residualObs: "Inform how many and which ones.",
    equipment: "Equipment:",
    equipmentObs: "Inform how many and which ones.",
    generalObs:
      "Obs.: In case that within the convention we have a launch of a product or we have delivery of ipads or the president will not be present but will make the opening through transmission, please punctuate this information in this field.",
  },
  aereoForm: {
    header: "Flight Registration",
    aereo: "Do you have a flight?",
    passengersAmount: "Passengers number",
    eventCity: "Event City",
    eventLocation: "Event Location",
    origin: "Inform Origin",
    destination: "Enter Destination",
    origin2: "Enter Origin back",
    destination2: "Enter Destination back",
    airportPreferences: "Airport Preference",
    compliance: "Compliance",
    flightType: "Flight Types",
    scales: "Scales",
    ticketsType: "Ticket Types",
    defaultAirCompany: "Do you have a preferred airline?",
    defaultAirCompanyName: "Inform which",
    meetings: "Will there be a meeting on the day of arrival and departure?",
    meetingsSchedule: "Enter times",
    defaultPaymentMethod: "Payment method, the same for the event?",
    paymentMethod: "Payment Methods",
    fieldsHelper: "Management Fields",
    fields: "Types of Fields",
    data: "Data",
    insurance: "Travel Insurance?",
    savedTransfers: "Saved Excerpts",
    saveTransfer: "Save Excerpt",
    invalidFields: {
      has_aerial: "The field 'Has an airline registration' is mandatory",
      has_preference_cia: "The field 'Has CIA' is mandatory",
      has_meeting_on_arrival_and_departure_day:
        "The field 'Payment method' is required",
      has_same_payment_method_as_the_event:
        "The field 'Air insurance' is mandatory",
    },
  },
  logisticaForm: {
    header: "Logistics Register",
    specificKPI: "What specific KPIs would the customer like to have?",
    accommodation: "Do you have accommodation?",
    accommodationHotel: "Hotel Name",
    accommodationGuestNumber: "Number of Guests",
    meetingsRoom: "Do you have meeting rooms or support?",
    meetingsRoomGuestNumber: "N° People",
    meetingsRoomsNumber: "Number of Rooms",
    webConnection: "Miscellaneous - Internet Point",
    webConnectionQtd: "Amount of Points",
    webConnectionSpeed: "Speed",
    parking: "Miscellaneous - Parking",
    aeb: "Food and Beverage.",
    equipments: "Do you have any equipment?",
    equipmentsObs: "What equipment",
    admin: "Do you have an administrator?",
    adminType: "Coordination type",
    transfers: "Miscellaneous - Transfers",
  },
  rsvpForm: {
    header: "RSVP registration",
    rsvpActive: "RSVP ACTIVE - Mon to Fri 8:15am to 6pm",
    saveTheDate: "Sending Save The Date",
    reminder: "Sending Reminder",
    acknowledgment: "Thank You Submission",
  },
  auditForm: {
    header: "Access log",
    creation: "Creation date",
    user: "User",
    userName: "User Name",
    info: "Information",
  },
  budget: {
    communications: "Communication",
    budgetName: "Budget Name",
    mmNumber: "MM number",
    responsible: "Responsible",
    responsibleEmail: "Responsible Email",
    clientName: "Client Name",
    status: "Status",
    creationDate: "Creation date",
    listBudgets: "List budgets",
    addBudget: "Add Budget",
    savedBudgets: "All Budgets",
    addCoin: "Add Coin",
    savedCoins: "All coins",
    savedHotels: "All hotels",
    hotel: "Accommodations",
    rooms: "Rooms",
    equipments: "Equipment",
    aeb: "Food and Beverage",
    translation: "Simultaneous Translation",
    signIn: "Signup",
    rsvp: "RSVP",
    aerial: "Air",
    transfer: "Transfer",
    diversos: "Miscellaneous",
    supportTeam: "Support Teams",
    serviceTypes: "Service Types",
    valueType: "Value Type",
    currency: "Currency",
    taxes: "Taxes",
    suppliers: "Suppliers",
    daysAmount: "Daily",
    guestAmount: "QTE",
    addSupplier: "Add Supplier",
    description: "Description",
  },
  supplier: {
    location: "Location",
    city: "City/State",
    country: "Country",
    accommodationPeriod: "Hosting Period",
    meetingPeriod: "Meeting Period",
    mainRoomArea: "Footage, Main Room // Right Foot",
    availability: "Availability",
    negotiation: "Trading",
    website: "Site",
    supplierName: "Supplier Name",
  },
  simpleTax: {
    default: "Tax",
    service: "Service Tax",
    local: "Local TAX",
    iof: "IOF",
    mm: "MM(FEE) Tax",
    nfMM: "NF MM Type",
    turism: "Tourism Tax",
  },
};
