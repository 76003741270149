import {
  Alert,
  Box,
  Card,
  Chip,
  CircularProgress,
  Slide,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { VerticalTab } from "../../components/VerticalTabs";
import { ReactComponent as ClientIcon } from "../../assets/svg/project/client.svg";
import { ReactComponent as AssignTimeIcon } from "../../assets/svg/project/assign-time.svg";
import { ReactComponent as BriefingIcon } from "../../assets/svg/project/briefing.svg";
import { ReactComponent as EventIcon } from "../../assets/svg/project/event-data.svg";
import { ReactComponent as PrevisionIcon } from "../../assets/svg/project/prevision.svg";
import ProjectClient from "./templates/ProjectClient";
import ProjectBriefing from "./templates/ProjectBriefing";
import ProjectAssignTime from "./templates/ProjectAssignTime";
import ProjectEvent from "./templates/ProjectEvent";
import ProjectFinance from "./templates/ProjectFinance";
import { useProject } from "../../contexts/project.context";
import ProjectStatusModal from "./templates/ProjectStatusModal";
import { FaCarSide } from "react-icons/fa";

const ProjectStep = () => {
  const { loadingProject} = useProject();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get("projectId");
  const [optionActive, setOptionActive] = useState(0);
  const [showUpdateStatus, setShowUpdateStatus] = useState(false);

  const options = [
    {
      title: "Cliente",
      description: "Adicione o cliente",
      icon: <ClientIcon />,
      template: <ProjectClient onNext={() => setOptionActive(1)} />,
    },
    {
      title: "Atribuir time",
      description: "Adicione as células",
      icon: <AssignTimeIcon />,
      template: <ProjectAssignTime onNext={() => setOptionActive(2)} />,
    },
    {
      title: "Dados do evento",
      description: "Adicione o tipo e nome do evento",
      icon: <EventIcon />,
      template: <ProjectEvent />,
    },
    // {
    //   title: "Previsão de custo e financeiro",
    //   description: "Adicione taxas, lucros e faturamento",
    //   icon: <PrevisionIcon />,
    //   template: <ProjectFinance />,
    // },
  ];


  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      pt={2}
      pb={4}
      bgcolor={"#F9FCFF"}
      flex={1}
      width={'100%'}
      overflow='auto'
    >
      

      {loadingProject && (
        <Box
          display={"flex"}
          width={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={2}
          py={10}
          mt={10}
        >
          <Typography variant={"h6"}>Carregando...</Typography>
          <CircularProgress />
        </Box>
      )}

      <Card
        sx={{
          display: "flex",
          mt: 1,
          mx: 5,
          boxShadow: "0px 4px 18px 0px #4B465C1A",
          maxWidth: 1480,
        }}
      >
        {!loadingProject && (
          <>
            <VerticalTab.Root>
              {options.map(({ title, description, icon }, index) => (
                <VerticalTab.Item
                  key={index}
                  onClick={() => setOptionActive(index)}
                >
                  <VerticalTab.Icon
                    icon={icon}
                    isActive={optionActive === index}
                  />
                  <VerticalTab.Content
                    title={title}
                    description={description}
                  />
                </VerticalTab.Item>
              ))}
            </VerticalTab.Root>

            {options[optionActive] && options[optionActive].template}
          </>
        )}
      </Card>
    </Box>
  );
};

export default ProjectStep;
