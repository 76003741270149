import { api } from "./apiClient";

export default class BriefingService {
  static async getAll(): Promise<[]> {
    return api
      .get("/briefing/list-initial-briefing")
      .then((response) => response.data);
  }

  static async get(id: string): Promise<any> {
    return api
      .get(`/briefing/get-initial-briefing/${id}`)
      .then((response) => response.data);
  }

  static async getStatusList(): Promise<any> {
    return api.get(`/status-briefing`).then((response) => response.data);
  }

  static async getBriefingStatus(briefing_id: string): Promise<any> {
    return api
      .get(`/status-briefing/briefing/${briefing_id}`)
      .then((response) => response.data);
  }

  static async getStatusById(id: string): Promise<any> {
    return api.get(`/status-briefing/${id}`).then((response) => response.data);
  }

  static async updateStatus(data: any): Promise<any> {
    return api.post(`/status-briefing`, data).then((response) => response.data);
  }

  static async search(params: any):  Promise<{ total: number; briefings: any[] }> {
    return api.get(`/briefing/get-by-search/`, {params}).then((response) => response.data);
  }
}
