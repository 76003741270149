export type Budget = {
  project: string;
  _id?: string;
  status?: string;
  name?: string;
  description?: string;
  place?: string;
  address?: string;
  country?: string;
  cityUf?: string;
  site?: string;
  availability?: string;
  deadline?: string;
  pp?: string;
  tagMap?: string;
  customerProduct?: string;
  billingModel: string;
  negotiationVersion: number;
};

export enum BudgetStatus {
  OPEN = "OPEN",
  APPROVED = "APPROVED",
  NEGOTIATION = "NEGOTIATION",
  APPROVED_NEGOTIATION = "APPROVED_NEGOTIATION",
  DECLINED = "DECLINED",
  AUDITED = "AUDITED",
  ON_BUDGET = "ON_BUDGET",
  ON_APPROVAL = "ON_APPROVAL",
  CLOSURE = "CLOSURE",
}

export enum BudgetItemTypeEnum {
  ACCOMMODATIONS = "Hospedagem",
  ROOMS = "Salas",
  AEB = "A & B e Catering",
  EQUIPMENTS = "Equipamentos",
  TRANSLATIONS = "Tradução simultânea",
  SUPPORT = "Equipe e suporte",
  SUBSCRIPTION = "Inscrição",
  RSVP = "RSVP",
  AIR = "Aéreo",
  TRANSFER = "Transfer",
  COMMUNICATIONS = "Comunicação",
  SEVERAL = "Outros",
}
export enum BudgetItemTotalRelation {
  hotels = "Hospedagem",
  rooms = "Salas",
  foodAndDrinks = "A & B e catering",
  equipment = "Equipamentos",
  simultaneousTranslation = "Tradução simultânea",
  suportTeams = "Equipe e suporte",
  subscription = "Inscrição",
  rsvp = "RSVP",
  air = "Aéreo",
  transfer = "Transfer",
  communications = "Comunicação",
  several = "Outros",
}
export enum BudgetTotalsCosts {
  PREVISION = "Custo total previsão",
  SERVICE = "Taxa de Serviço",
  ISS = "ISS",
  TARIFF = "Tarifa",
  LOCAL_TAX = "Imposto Local",
  TOURISM_TAX = "Taxa de Turismo",
  FEE = "Taxa MM (Fee)",
  NFMM = "Tipo de NF MM",
  EXCHANGE = "Cambio",
  NFMM_TAX = "Impostos NF MM",
  EXTRAS_PREVISION = "Previsão de extras",
  TOTAL_COST_PREVISION = "Total previsão de custos",
}
export enum BudgetCostsKeysToTotals {
  PREVISION = "costForecastSubtotal",
  SERVICE = "totalServiceTax",
  ISS = "ISS",
  TARIFF = "totalTariff",
  IOF = "totalTaxIof",
  MM_TAX = "Taxa MM eventos(fees)",
  EXCHANGE = "totalExchange",
  NFMM_TAX = "totalNfMm",
  EXTRAS_PREVISION = "extras",
  TOTAL_COST_PREVISION = "total",
}

export enum BudgetSummaryActionOptions {
  DUPLICATE = 'Duplicar',
  DELETE = 'Excluir',
  APROVE = "Aprovar",
  DENY = "Reprovar",
  NEGOCIATE = "Enviar para negociação",
  // IMPORT = 'Importar de logística',
  // ORDER_ASC = 'Ordenar valores do maior para o menor',
  // ORDER_DESC = 'Ordenar valores do menor para o maior'
}
