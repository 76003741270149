import { Box } from "@mui/material";
import React from "react";

interface Props {
  children: React.ReactNode;

  onClick?: () => void;
}

export function VerticalTabItem({ children, onClick }: Props) {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      px={"10px"}
      gap={"10px"}
      sx={{ cursor: "pointer" }}
      onClick={onClick}
    >
      {children}
    </Box>
  );
}
