import { Snackbar, Alert, Slide, AlertColor } from "@mui/material"
import { NotificationFields } from "../../../types/notification"

type NotificationProps = {
  notification: NotificationFields
  closeNotification(): void
}
const Notification: React.FC<NotificationProps> = ({notification, closeNotification}: NotificationProps) => {
 return <Snackbar
  anchorOrigin={{ vertical: "top", horizontal: "right" }}
  autoHideDuration={6000}
  open={notification.open}
  onClose={closeNotification}
  TransitionComponent={(props) => <Slide {...props} direction="left" />}
>
  <Alert
    onClose={closeNotification}
    severity={notification.severity}
    sx={{ width: "100%" }}
  >
    {notification.message}
  </Alert>
</Snackbar>
}


export default Notification