import { Box } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { AerialServiceItem } from "../AerialServiceItem"
import { SaveButton } from "../../../UI/SaveButton"
import { AuthContext } from "../../../../contexts/auth"

type PropTypes = {
  ticketsData: any[]
  aerialId: string,
  project: string
  onSubmit(form: any ): void
}

const AerialAccordion: React.FC<PropTypes> = ({ ticketsData, aerialId, project, onSubmit }: PropTypes) => {
  const {actionOptions} = useContext(AuthContext)
  const [savedData, setSavedData] = useState<any[]>(ticketsData || [])
  const [expanded, setExpanded] = useState(-1)
  function deleteAerialService(serviceIndex: number) {
    const newArr = [...savedData]
    newArr.splice(serviceIndex, 1)
    setSavedData(newArr)
  }
  function updateAerialService(form: any, index: number) {
    const newSavedData = savedData
    newSavedData[index] = form
    setSavedData(newSavedData)
  }
  function handleAddEmptyService() {
    setSavedData([...savedData, {
      outwardQuantity: 0,
      returnQuantity: 0,
      outwardDate: '',
      returnDate: '',
      aerialMesh: false,
      outwardAirport: '',
      returnAirport: '',
      airportConnections: [],
      airportPreference: false,
      flightClass: [],
      insurance: false,
    }])
    setExpanded(-1)
  }
  function handleChange(index:number, state: boolean) {
    if(index === expanded) return setExpanded(-1)
    setExpanded(index)
  }
  // useEffect(()=> {
  //   if(!!ticketsData) setSavedData(ticketsData)
  // },[ticketsData])
  function renderItems(data: any[]) {
   return data.map((service: any, index: any) => {
      return <Box key={index}>
        <AerialServiceItem
          handleDeleteService={deleteAerialService}
          handleSaveService={updateAerialService}
          data={service}
          expanded={expanded === index}
          onChange={handleChange}
          title={`Aéreo ${index + 1}`} index={index} />

      </Box>
    })
  }
  return <Box sx={{
    maxHeight: '65vh',
    minHeight: '500px',
    overflow: 'auto',
    padding: '10px',
    width: '90%',
    overflowX: 'hidden',
  }}>
    <span>Cadastro Aéreo</span>
    {renderItems(savedData)}
    <span
      style={{
        color: '#015EFF',
        cursor: 'pointer',
        padding: '5px' 
      }}
      onClick={handleAddEmptyService}
    > + Adicionar</span>

    {savedData.length > 0 && <div style={{width: '150px', marginLeft: '70%'}} onClick={() => onSubmit({_id: aerialId, ticketsData: savedData, project})}>
    <SaveButton isDisabled={!actionOptions.updateProjetos }/>
    </div> }
  </Box >



}

export { AerialAccordion }