import { Box } from "@mui/material"
import LogisticsTypesTabItem from "../LogisticsTypesTabItem"
import { RiCustomerService2Line, RiHotelBedLine, RiRestaurantLine, RiTranslate } from 'react-icons/ri'
import { FaRegCalendarCheck, FaRegHandPointer } from "react-icons/fa"
import { MdOutlineAirplaneTicket, MdOutlineTransferWithinAStation } from "react-icons/md"
import { BsSignpost } from "react-icons/bs"
import { PiDotsThreeOutline } from "react-icons/pi"
import { useEffect, useState } from "react"

type PropTypes = {
  logisticTypes: any
  onChange(newList: any): void
  isDisabled: boolean
}

const LogisticTypesTab: React.FC<PropTypes> = ({
  logisticTypes,
  onChange,
  isDisabled,
}: PropTypes) => {
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get("projectId");
  const [activeLogisticTypes, setActiveLogisticTypes] = useState({
    _id: logisticTypes._id || '',
    briefing_id: logisticTypes.briefing_id || '',
    project: logisticTypes.project || '',
    accommodations: logisticTypes.accommodations || false,
    rooms: logisticTypes.rooms || false,
    food_and_drinks: logisticTypes.food_and_drinks || false,
    equipments: logisticTypes.equipments || false,
    simultaneous_translation: logisticTypes.simultaneous_translation || false,
    support_teams: logisticTypes.support_teams || false,
    subscription: logisticTypes.subscription || false,
    rsvp: logisticTypes.rsvp || false,
    air: logisticTypes.air || false,
    transfer: logisticTypes.transfer || false,
    comunication: logisticTypes.comunication || false,
    several: logisticTypes.several || false,
  })

  useEffect(()=> {
    setActiveLogisticTypes({
      _id: logisticTypes._id || '',
      briefing_id: logisticTypes.briefing_id || '',
      project: logisticTypes.projectId,
      accommodations: logisticTypes.accommodations || false,
      rooms: logisticTypes.rooms || false,
      food_and_drinks: logisticTypes.food_and_drinks || false,
      equipments: logisticTypes.equipments || false,
      simultaneous_translation: logisticTypes.simultaneous_translation || false,
      support_teams: logisticTypes.support_teams || false,
      subscription: logisticTypes.subscription || false,
      rsvp: logisticTypes.rsvp || false,
      air: logisticTypes.air || false,
      transfer: logisticTypes.transfer || false,
      comunication: logisticTypes.comunication || false,
      several: logisticTypes.several || false,
    })
  },[logisticTypes])
  const changeIsActive = (key: string, value: boolean) => {
    const newList = {
     ...logisticTypes,
     [key]: value
    }
    setActiveLogisticTypes(newList)
    onChange(newList)
  }
  return <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '25%',
    padding: '0 5px',
    borderRight: '1px solid #ddd',
    gap: 2

  }}>
    <LogisticsTypesTabItem
      isDisabled={isDisabled}
      icon={<RiHotelBedLine size={20} />}
      title={"Hospedagem"}
      isActive={activeLogisticTypes.accommodations}
      typeKey="accommodations"
      changeIsActive={changeIsActive} />
      <LogisticsTypesTabItem
      isDisabled={isDisabled}
      icon={<RiHotelBedLine size={20} />}
      title={"Salas"}
      isActive={activeLogisticTypes.rooms}
      typeKey="rooms"
      changeIsActive={changeIsActive} />
    
    <LogisticsTypesTabItem
      isDisabled={isDisabled}
      icon={<RiRestaurantLine size={20} />}
      title={"A & B e Catering"}
      isActive={activeLogisticTypes.food_and_drinks}
      typeKey="food_and_drinks"
      changeIsActive={changeIsActive} />
    
    <LogisticsTypesTabItem
      isDisabled={isDisabled}
      icon={<RiTranslate size={20} />}
      title={"Equipamentos"}
      isActive={activeLogisticTypes.equipments}
      typeKey="equipments"
      changeIsActive={changeIsActive} />
    
    <LogisticsTypesTabItem
      isDisabled={isDisabled}
      icon={<RiTranslate size={20} />}
      title={"Tradução simultânea"}
      isActive={activeLogisticTypes.simultaneous_translation}
      typeKey="simultaneous_translation"
      changeIsActive={changeIsActive} />

    
    <LogisticsTypesTabItem
      isDisabled={isDisabled}
      icon={<RiCustomerService2Line size={20} />}
      title={"Equipe e suporte"}
      isActive={activeLogisticTypes.support_teams}
      typeKey="support_teams"
      changeIsActive={changeIsActive} />
    
    <LogisticsTypesTabItem
      isDisabled={isDisabled}
      icon={<FaRegHandPointer size={20} />}
      title={"Inscrição"}
      isActive={activeLogisticTypes.subscription}
      typeKey="subscription"
      changeIsActive={changeIsActive} />
    
    <LogisticsTypesTabItem
      isDisabled={isDisabled}
      icon={<FaRegCalendarCheck size={20} />}
      title={"RSVP"}
      isActive={activeLogisticTypes.rsvp}
      typeKey="rsvp"
      changeIsActive={changeIsActive} />
    
    <LogisticsTypesTabItem
      isDisabled={isDisabled}
      icon={<MdOutlineAirplaneTicket size={20} />}
      title={"Aéreo"}
      isActive={activeLogisticTypes.air}
      typeKey="air"
      changeIsActive={changeIsActive} />
    
    <LogisticsTypesTabItem
      isDisabled={isDisabled}
      icon={<MdOutlineTransferWithinAStation size={20} />}
      title={"Transfer"}
      isActive={activeLogisticTypes.transfer}
      typeKey="transfer"
      changeIsActive={changeIsActive} />
    
    <LogisticsTypesTabItem
      isDisabled={isDisabled}
      icon={<BsSignpost size={20} />}
      title={"Comunicação"}
      isActive={activeLogisticTypes.comunication}
      typeKey="comunication"
      changeIsActive={changeIsActive} />
    
    <LogisticsTypesTabItem
      isDisabled={isDisabled}
      icon={<PiDotsThreeOutline size={20} />}
      title={"Outros"}
      isActive={activeLogisticTypes.several}
      typeKey="several"
      changeIsActive={changeIsActive} />
  </Box>
}

export default LogisticTypesTab