import {
  SwitchProps,
  styled,
  Switch as SwitchMaterial,
  Box,
  FormLabel,
  FormControlLabel,
} from "@mui/material";
import { forwardRef } from "react";

interface Props extends SwitchProps {
  label: string;
  width?: number;
  valueLabel?: string;
}

export const Switch = styled(
  forwardRef<HTMLButtonElement, Props>(
    ({ label, valueLabel, width, ...props }: Props, ref) => (
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        sx={{
          "& label": { fontSize: 13 },
          "& span": { fontSize: 14 },
        }}
      >
        <FormLabel sx={{ whiteSpace: "nowrap" }}>{label}</FormLabel>
        <FormControlLabel
          label={valueLabel || ""}
          sx={{ margin: 0, fontSize: 13, marginTop: "8px" }}
          control={
            <SwitchMaterial
              ref={ref}
              focusVisibleClassName=".Mui-focusVisible"
              disableRipple
              size="small"
              {...props}
            />
          }
        />
      </Box>
    )
  )
)(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  marginRight: 8,

  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#1361A4",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#1361A4",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
