import axios from "axios";

import { api } from "./apiClient";

export default class ClientService {
  static async getClients(params?: any): Promise<any[]> {
    return api.get("/client/get", { params }).then((response) => response.data);
  }
  static async getRequesters(id: string): Promise<any[]> {
    return api
      .get("/requester/search", { params: { client: id } })
      .then((response) => response.data);
  }

  static async sync(): Promise<void> {
    return api.get("/client/sync-external").then((response) => response.data);
  }
  static async create(data: any): Promise<any> {
    return api.post("/client/create", data).then((response) => response.data);
  }

  static async update(id: string, data: any): Promise<any> {
    return api
      .put(`/client/update/${id}`, data)
      .then((response) => response.data);
  }
  static async delete(id: string): Promise<any> {
    return api.delete(`/client/delete/${id}`).then((response) => response.data);
  }
  static async search(params: any): Promise<{ total: number; clients: any[] }> {
    return api
      .get(`/client/get-by-search/`, { params })
      .then((response) => response.data);
  }

  static async fetchCnpjData(cnpj: string): Promise<any> {
    try {
      const response = await axios.get(
        `https://api-publica.speedio.com.br/buscarcnpj?cnpj=${cnpj}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching CNPJ data", error);
      return {};
    }
  }
}
