import { Budget } from "../types/budget/budget";
import { ServiceData } from "../types/budget/service-item";
import { api } from "./apiClient";

export class BudgetService {
    static async getBudgetList(projectId: string): Promise<any> {
        return api.get('/budget/get?', {
            params: {
                project: projectId
            }
        }).then(response => response.data)
    }
    static async getServicesFromBudget(budgetId: string): Promise<any> {
        return api.get('/budget-items/get?', {
            params: {
                budgetId: budgetId
            }
        }).then(response => response.data)
    }
    
    static async update(id: string, budgetData: any): Promise<any> {
        return api.put(`/budget/update/${id}`, {...budgetData}).then(response => response.data)
    }
    static async create(data: Budget): Promise<any> {
        return api.post('/budget/create', data).then(response => response.data)
    }
    static async createService(data: ServiceData): Promise<any> {
        return api.post('/budget-items/create', data).then(response => response.data)
    }
    static async updateService(data: ServiceData): Promise<any> {
        return api.put(`/budget-items/update/${data._id}`, data).then(response => response.data)
    }
    static async deleteService(id: string): Promise<any> {
        return api.delete(`budget-items/delete/${id}`).then(response => response.data)
    }
    static async calculateService(data: Budget): Promise<any> {
        return api.post('/budget-items/calculate-item', data).then(response => response.data)
    }

    static async calculateBudgetTotals(data: any): Promise<any> {
        return api.post('/budget-total/calculate-total', data).then(response => response.data)
    }
    static async exportXLX(data: any): Promise<any> {
        return api.post('/export-budget/export', data).then(response => {
            return response.data
        })
    }
}