import styled from "styled-components";

export const Container = styled.div`
  max-width: 260px;
  display: flex;
  flex-direction: column;
  color: #4b465c;
  background: #fff;
  padding: 10px 15px;
  box-shadow: 0px 4px 18px 0px #4b465c1a;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;
export const MainContent = styled.div`
  padding: 30px 0;
  flex: 1;
`;
export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid #ebebeb;
  padding-top: 16px;
  padding-bottom: 16px;

  p {
    padding: 0;
    margin: 0;
  }
`;

export const LogoDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 22px 0;
`;
export const UserInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  color: #1361a4;
  margin-left: 10px;
`;

export const StyledIcon = styled.div<{ isActive: boolean }>`
    img {
        width: 24px;
        height: 24px;
        filter: ${(props) => (props.isActive ? "brightness(0) invert(1)" : "grayscale(100%) brightness(0%) invert(0%)")};
    }
`;