import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridSortDirection,
} from "@mui/x-data-grid";
import React, { useContext, useState } from "react";
import UserService from "../../../../services/user.service";
import RolesModal from "./RolesModal";
import { useQuery, useQueryClient } from "react-query";
import { RoleUserMap } from "../../../../types/user.type";
import { MdEdit, MdSearch } from "react-icons/md";
import TableComponent from "../../../CustomDataGrid";
import { Column } from "react-table";
import { Type } from "typescript";
import { FaCircle } from "react-icons/fa";
import { formatPhoneNumber } from "../../../../utils/formatters";
import { TbEyeEdit } from "react-icons/tb";
import { FunctionDescDiv } from "./styles";
import { VscChevronDown } from "react-icons/vsc";
import { FiUserPlus } from "react-icons/fi";
import RolePermissionsService from "../../../../services/rolesPermissions.service";
import { AuthContext } from "../../../../contexts/auth";

const Roles = () => {
  const { actionOptions } = useContext(AuthContext);
  const isDisabled = !actionOptions["createControle de acesso"];
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [page, setPage] = useState<any>({
    page: 0,
    pageSize: 10,
    order: "asc" as GridSortDirection,
    sortField: "name",
    search: "",
    isActive: undefined,
  });

  const { isLoading, data, error } = useQuery({
    queryKey: ["roles", page],
    queryFn: () => RolePermissionsService.allRoles(),
  });
  const [roleEdit, setRoleEdit] = useState<any>();
  const [showRolesModal, setShowRolesModal] = useState(false);
  const [filter, setFilter] = useState({ search: "", status: "all" });

  interface IFunctionRow extends Type {
    _id: string;
    name: string;
    active: boolean;
    description: string;
    users: any[];
  }
  const columns2: Column<IFunctionRow>[] = [
    {
      Header: "FUNÇÕES",
      accessor: "name",
      Filter: "",
      Cell: ({ cell }) => {
        return (
          <FunctionDescDiv>
            <div>
              <span>
                {cell.row.original.active ? (
                  <FaCircle size={10} color="green" />
                ) : (
                  <FaCircle size={10} color="red" />
                )}{" "}
              </span>
            </div>
            <div>
              <span>{cell.row.values.name}</span>
              <br />
              <span>{cell.row.original.description}</span>
            </div>
          </FunctionDescDiv>
        );
      },
    },
    {
      Header: "USUÁRIOS",
      accessor: "users",
      Filter: "",
      Cell: ({ cell }) => {
        let activeUsers = cell.value.filter((el: any) => el.active === true);
        let inactiveUsers = cell.value.filter((el: any) => el.active === false);
        return (
          <span>
            {" "}
            {`${activeUsers.length} ativos; ${inactiveUsers.length} inativos`}
          </span>
        );
      },
    },
    {
      Header: "",
      accessor: "_id",
      Cell: ({ cell }) => (
        <div
          onClick={() => handleFunctionEdit(cell.value)}
          style={{ cursor: "pointer" }}
        >
          {" "}
          <TbEyeEdit size={18} style={{ cursor: "pointer" }} />
        </div>
      ),
      Filter: "",
      disableSortBy: true,
    },
  ];

  function handleSearch() {
    const isActive = filter.status === "all" ? undefined : filter.status;
    setPage({ ...page, isActive, search: filter.search });
  }
  function handleFunctionEdit(id: string) {
    let selectedFunction = data.find((role: any) => role._id === id);
    if (!!selectedFunction) {
      setRoleEdit(selectedFunction);
      setShowRolesModal(true);
    }
  }

  return (
    <Box px={"24px"} pt={"24px"} pb={"28px"} sx={{ flex: 1 }}>
      {(showRolesModal || roleEdit) && (
        <RolesModal
          role={roleEdit}
          onClose={() => {
            setShowRolesModal(false);
            setRoleEdit(undefined);
          }}
          onSuccess={() => queryClient.invalidateQueries("roles")}
        />
      )}

      <Box display={"flex"} mb={4} alignItems={"center"} flex={1}>
        <Box display={"flex"} alignItems={"center"} flex={1} gap={2}>
          <Button
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="outlined"
            onClick={(e) => setAnchorEl(e.currentTarget)}
            endIcon={<VscChevronDown />}
            sx={{
              borderColor: "#DBDADE",
              color: "#4B465C",
              fontSize: "14px",
              textTransform: "none",
            }}
          >
            Ações
          </Button>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              disabled={isDisabled}
              onClick={() => {
                setShowRolesModal(true);
                setAnchorEl(null);
              }}
            >
              <FiUserPlus color="#1361A4" /> &nbsp; Adicionar Função
            </MenuItem>
          </Menu>
        </Box>
      </Box>

      <Box sx={{ minHeight: 400 }}>
        <TableComponent
          columns={columns2}
          isLoading={isLoading}
          data={data || []}
        />
      </Box>
    </Box>
  );
};

export default Roles;
