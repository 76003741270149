import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { BsCaretDown, BsCaretUp } from "react-icons/bs";


interface FormInputProps {
  name: string;
  control: any;
  label: string;
  setValue?: any;
  options: any[]
  previousSelected: any[],
  optionSelectAll: boolean
  setControlledState?: (selectedItems: any[]) => void
}

export const FormInputMultiCheckbox: React.FC<FormInputProps> = ({
  name,
  control,
  setValue,
  label,
  options,
  previousSelected,
  optionSelectAll,
  setControlledState
}) => {
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [showChildren, setShowChildren] = useState(!optionSelectAll);
  // we are handling the selection manually here
  const handleSelect = (value: any) => {
    const isPresent = selectedItems.indexOf(value);
    if (isPresent !== -1) {
      const remaining = selectedItems.filter((item: any) => item !== value);
      setSelectedItems(remaining);
    } else {
      setSelectedItems((prevItems: any) => [...prevItems, value]);

    }
  };
  const handleSelectAll = () => {
    const isAlreadySelected = selectedItems.length === options.length
    if (isAlreadySelected) {
      setSelectedItems([])
    }
    else {
      setSelectedItems(options.map(option => option.value))

    }
  }

  useEffect(() => {
    setValue(name, selectedItems);
    if(!!setControlledState) setControlledState(selectedItems)
  }, [selectedItems]);
  useEffect(() => {
    if (JSON.stringify(previousSelected) === JSON.stringify(selectedItems)) return
    setSelectedItems(previousSelected)
  }, [previousSelected])

  return (
    <FormControl size={"small"} variant={"outlined"}>
      <Box display={'flex'} flexDirection={'column'}>
        {optionSelectAll && <FormGroup style={{ marginLeft: '10px' }} row>

          <FormControlLabel
            label={
              showChildren ? (
                <BsCaretUp onClick={() => setShowChildren(false)} />
              ) : (
                <BsCaretDown onClick={() => setShowChildren(true)} />
              )
            }
            control={<></>}
          />
          <FormControlLabel
            control={
              <Controller
                name={name}
                render={({ field }) => {
                  return (
                    <Checkbox
                      checked={selectedItems.length === options.length}
                      onChange={() => handleSelectAll()}
                    />
                  );
                }}
                control={control}
              />
            }
            label={"Selecionar todos"}
          />
        </FormGroup>}
        {showChildren && options.map((option: any) => {
          return (
            <FormControlLabel
              control={
                <Controller
                  name={name}
                  render={({ field }) => {
                    return (
                      <Checkbox
                        checked={selectedItems.includes(option.value)}
                        onChange={() => handleSelect(option.value)}
                      />
                    );
                  }}
                  control={control}
                />
              }
              label={option.label}
              key={option.value}
            />
          );
        })}
      </Box>
    </FormControl>
  );
};
