import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { da } from "date-fns/locale";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { AiFillSave } from "react-icons/ai";
import { IoIosClose } from "react-icons/io";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";

import { AuthContext } from "../../../../contexts/auth";
import RoleService from "../../../../services/roles.service";
import RolePermissionsService from "../../../../services/rolesPermissions.service";
import UserService from "../../../../services/user.service";
import { History } from "../../../Settings/UserModal/History";
import { Info } from "../../../Settings/UserModal/Info";
import { Projects } from "../../../Settings/UserModal/Projects";
import { RegisterStep } from "../../../Settings/UserModal/RegisterStep";
import { TabPanel } from "../../../TabPanel";

type Props = {
  user?: any;
  onClose: () => void;
  onSuccess?: () => void;
};

type UserProps = {
  active: boolean;
  name: string;
  email: string;
  phone: string;
  function_id: string;
  role_id: string;
  description: string;
  image_url: string;
  user_name: string;
  password?: string;
  password_confirmation?: string;
};

const DOMAIN = "@grupommeventos.com";

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const UserModal: React.FC<Props> = (props) => {
  const { actionOptions } = useContext(AuthContext);
  const { onClose, onSuccess, user } = props;
  const [tabActive, setTabActive] = React.useState(0);

  const { data: roles, isLoading } = useQuery(
    "get_roles",
    RolePermissionsService.allRoles
  );
  const { mutate: createUser, isLoading: isLoadingCreate } = useMutation(
    UserService.create,
    {
      onSuccess: () => {
        toast.success("Usuário criado com sucesso!");
        onSuccess && onSuccess();
      },
      onError: (error) => {
        toast.error("Não foi possível criar o usuário");
      },
    }
  );

  const { mutate: updateUser, isLoading: isLoadingUpdate } = useMutation(
    (data: any) => UserService.update(user._id, data),
    {
      onSuccess: () => {
        toast.success("Usuário atualizado com sucesso!");
        onSuccess && onSuccess();
      },
      onError: (error) => {
        toast.error("Não foi possível atualizar o usuário");
      },
    }
  );

  const [userData, setUserData] = React.useState<UserProps>({
    active: user?.active || false,
    name: user?.name || "",
    email: user?.email || "",
    phone: user?.phone || "",
    function_id: user?.function_id || "",
    role_id: user?.role_id || "USER_GUEST",
    description: "",
    image_url: "",
    user_name: user?.user_name || "",
    password: "",
    password_confirmation: "",
  });

  useEffect(() => {
    if (!user) {
      setUserData({
        active: false,
        name: "",
        email: "",
        phone: "",
        function_id: "",
        role_id: "USER_GUEST",
        description: "",
        image_url: "",
        user_name: "",
        password: "",
        password_confirmation: "",
      });
    }
  }, [user]);

  function handleChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const { name, value } = e.target;

    setUserData({ ...userData, [name]: value });
  }

  const handleSubmmit = async (e: React.MouseEvent) => {
    console.log(userData);
    const validateDomainEmail = new RegExp(`${DOMAIN}\s*$`).test(
      userData.email
    );

    if (!validateDomainEmail) {
      return toast.warn(`O email deve ser do domínio ${DOMAIN}`);
    }
    const data = {
      ...userData,
    };

    const role = await RolePermissionsService.roles({ id: data.function_id });

    console.log(role);

    if (role.name === "Administrador") {
      data.role_id = "USER_ADMINISTRATOR";
    } else {
      data.role_id = "USER_GUEST";
    }
    if (data.password !== data.password_confirmation) {
      toast.warn("As senhas não coferem");
      return;
    }
    if (data.password === "" && user) {
      delete data.password;
      delete data.password_confirmation;
    } else if (data.password === "" && !user) {
      toast.warn(
        "Não é possível criar o usuário sem que a senha seja informada"
      );
    }
    user ? updateUser(data) : createUser(data);
    const { name } = e.target as HTMLButtonElement;
    if (name === "save-close") onClose();
  };

  const isDisabled =
    isLoadingCreate ||
    isLoadingUpdate ||
    (!actionOptions["createControle de acesso"] &&
      !actionOptions["updateControle de acesso"]);

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <Box p={4}>
        <Box display={"flex"} alignItems={"center"} mb={3} gap={2}>
          <Typography variant="h5">Usuário</Typography>

          <ButtonGroup
            disableElevation
            variant="outlined"
            aria-label="Change status"
            size="small"
          >
            <Button
              onClick={() => setUserData({ ...userData, active: true })}
              sx={{
                textTransform: "none",
                color: userData.active ? "#1361A" : "#666",
                fontWeight: userData.active ? 700 : 400,
              }}
            >
              Ativo
            </Button>

            <Button
              onClick={() => setUserData({ ...userData, active: false })}
              sx={{
                textTransform: "none",
                color: !userData.active ? "#1361A" : "#999",
                fontWeight: !userData.active ? 700 : 400,
              }}
            >
              Inativo
            </Button>
          </ButtonGroup>
        </Box>

        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <IoIosClose width={24} height={24} />
          </IconButton>
        ) : null}
        <Tabs
          value={tabActive}
          onChange={(e, index) => setTabActive(0)}
          indicatorColor="primary"
        >
          <Tab
            label="Cadastro"
            {...a11yProps(0)}
            sx={{ textTransform: "none" }}
          />

          <Tab
            label="Histórico"
            {...a11yProps(3)}
            sx={{ textTransform: "none", color: "#ccc", opacity: 0.7 }}
          />
        </Tabs>

        <TabPanel value={tabActive} index={0}>
          <RegisterStep
            data={userData}
            onChange={handleChange}
            rolesArr={roles || []}
            isEditing={!user}
          />
        </TabPanel>

        <TabPanel value={tabActive} index={1}>
          <History />
        </TabPanel>

        <Box display={"flex"} justifyContent={"flex-end"} gap={2} mt={4}>
          <Button
            variant="outlined"
            onClick={onClose}
            style={{ textTransform: "none" }}
          >
            Cancelar
          </Button>

          <Button
            variant="contained"
            onClick={handleSubmmit}
            disabled={isDisabled}
            style={{ textTransform: "none" }}
            name="save-close"
          >
            Salvar e Fechar
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmmit}
            disabled={isDisabled}
            style={{ textTransform: "none" }}
            name="save"
          >
            <AiFillSave size={22} />
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UserModal;
