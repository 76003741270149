import { useState } from "react";
import { FaFilter } from "react-icons/fa";
import { Column } from "react-table";

interface IProps {
    column: any
}

export function ColumnFilter(props: IProps) {
    const [showFilterInput, setShowFilterInput] = useState(false)
    const { filterValue, setFilter } = props.column

    return <span style={{ marginLeft: '15px' }}>
        {showFilterInput
            ? <>
                <br />
                <input
                    style={{ position: 'relative', zIndex: 999 }}
                    value={filterValue || ''}
                    onBlur={() => { setShowFilterInput(false) }}
                    onChange={(e) => setFilter(e.target.value)}
                />
            </>
            : <FaFilter size={10} onClick={(e) => {
                e.stopPropagation()
                setShowFilterInput(true)
                return

            }
            } />}
    </span>
}