import { ProjectRequester, Requesting } from "../types";
import { api } from "./apiClient";

export default class RequestingService {
  static async create(dto: any): Promise<ProjectRequester> {
    return api.post("/requester", dto).then((response) => response.data);
  }

  static async update(id: string, dto: any): Promise<ProjectRequester> {
    return api.put(`/requester/${id}`, dto).then((response) => response.data);
  }
  static async getById(id: string): Promise<ProjectRequester> {
    return api.get(`/requester/${id}`).then((response) => response.data);
  }

  static async getBySearch(params: any): Promise<any[]> {
    return api
      .get("/requester/search", { params })
      .then((response) => response.data);
  }
}
