import { Box, InputLabel, TextField, TextFieldProps } from "@mui/material";

export function LabeledInput(props: TextFieldProps) {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"flex-start"}
      width={"100%"}
      my={1}
    >
      <InputLabel style={{ fontSize: "13px" }}>{props.label}</InputLabel>
      <TextField style={{ margin: 0 }} {...props} label={null} />
    </Box>
  );
}
