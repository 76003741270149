import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Route } from "./Route";
import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";
import Briefing from "../pages/Briefing";
import { Export } from "../pages/Export";
import { HealthCheck } from "../pages/HealthCheck";
import { AccessControl } from "../pages/AccessControl";
import Dashboard from "../pages/Dashboard";
import BriefingDetail from "../pages/BriefingDetail";
import { Reports } from "../pages/reports";
import NewPassword from "../pages/NewPassword";
import ConfirmNewUser from "../pages/ConfirmNewUser";
import { Partners } from "../pages/Partners";
import Project from "../pages/Project";
import Projects from "../pages/Projects";

const Routes: React.FC = () => (
  <>
    <Switch>
      <Route path="/login" exact component={Login} />
      <Route path="/export" exact isPrivate component={Export} />
      <Route path="/esqueci" exact component={ForgotPassword} />
      <Route path="/nova-senha" exact component={NewPassword} />
      <Route path="/confirmar-novo/:token" exact component={ConfirmNewUser} />
      <Route path="/access-control" isPrivate exact component={AccessControl} />
      <Route path="/reports" isPrivate exact component={Reports} />
      <Route path="/partners" isPrivate component={Partners} />
      <Route path="/projetos" isPrivate component={Projects} />
      <Route path="/projeto" isPrivate component={Project} />

      <Route path="/briefings" isPrivate exact component={Briefing} />
      <Route path="/briefing/:id" isPrivate exact component={BriefingDetail} />
      <Route path="/briefing" isPrivate exact component={BriefingDetail} />

      <Route path="/dashboard" isPrivate exact component={Dashboard} />

      <Route path="/health" exact component={HealthCheck} />

      {/* <Route path="/test" isPrivate exact component={TestPage} /> */}
      <Route path="*" component={Login} />
    </Switch>
  </>
);

export { Routes };
