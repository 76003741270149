import { api } from "./apiClient";

export class CellService {
    static async cells(params: any): Promise<any> {
        return api.get('/cell/get', {params}).then((response) => response.data)
    }
    static async allCells(): Promise<any> {
        return api.get('/cell/all').then((response) => response.data)
    }
    static async create(data: any): Promise<any> {
        return api.post('/cell/create', data).then((response) => response.data)
    }
    static async update(id:string, data: any): Promise<any> {
        return api.put(`/cell/update/${id}`, data).then((response) => response.data)
    }
    static async delete(id:string): Promise<any> {
        return api.delete(`/cell/delete/${id}`).then((response) => response.data)
    }


}