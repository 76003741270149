import {
  Box,
  Checkbox,
  Dialog,
  InputLabel,
  Switch,
  Typography,
} from "@mui/material";
import { GridSortDirection } from "@mui/x-data-grid";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { FaCircle } from "react-icons/fa";
import { TbEyeEdit } from "react-icons/tb";
import { useQuery } from "react-query";
import { Column } from "react-table";
import { Type } from "typescript";

import { TYPE_PERMISSIONS } from "../../../../../utils/constPermissions";
import TableComponent from "../../../../CustomDataGrid";
import { options } from "../../../../SideBar2";
import { FunctionDescDiv } from "../styles";

type Props = {
  modules?: any;
  setActive: any;
  active: Array<ArrayActive> | [];
  optsState: any;
  setOpts: any;
};

interface ArrayActive {
  title: string;
  type: string;
  status: boolean;
}

const initialOptions = [
  {
    name: "Dashboard",
    type: {
      create: { tmp: false, active: false },
      delete: { tmp: false, active: false },
      update: { tmp: false, active: false },
      view: { tmp: false, active: false },
    },
  },
  {
    name: "Projetos",
    type: {
      create: { tmp: false, active: false },
      delete: { tmp: false, active: false },
      update: { tmp: false, active: false },
      view: { tmp: false, active: false },
      global: { tmp: false, active: false },
    },
  },
  {
    name: "Parceiros",
    type: {
      create: { tmp: false, active: false },
      delete: { tmp: false, active: false },
      update: { tmp: false, active: false },
      view: { tmp: false, active: false },
    },
  },
  {
    name: "Relatórios",
    type: {
      create: { tmp: false, active: false },
      delete: { tmp: false, active: false },
      update: { tmp: false, active: false },
      view: { tmp: false, active: false },
    },
  },

  {
    name: "Controle de acesso",
    type: {
      create: { tmp: false, active: false },
      delete: { tmp: false, active: false },
      update: { tmp: false, active: false },
      view: { tmp: false, active: false },
    },
  },
];

const ListModulesPermissions: React.FC<Props> = (props) => {
  const { modules, active, setActive, optsState, setOpts } = props;
  const [optionsState, setOptionsState] = useState<any>([]);
  const [originalOptionsState, setOriginalOptionsState] = useState<any>([]);

  const [page, setPage] = useState<any>({
    page: 0,
    pageSize: 10,
    order: "asc" as GridSortDirection,
    sortField: "name",
    search: "",
    isActive: undefined,
  });

  const seeAllProjects = useMemo(
    () =>
      !!optionsState.find((obj: any) => obj?.name === "Projetos")?.type?.global
        ?.active,
    [optionsState]
  );

  const handleChange = (title: string, action: string, checked: boolean) => {
    const objs = optionsState.find((obj: any) => obj.name === title);
    const objsIndex = optionsState.findIndex((obj: any) => obj.name === title);

    if (!objs.type[action]) {
      objs.type[action] = { active: checked };
    }

    objs.type[action].active = checked;

    const newOptions = JSON.parse(JSON.stringify(optionsState));
    newOptions[objsIndex] = objs;
    setOptionsState(newOptions);
    setOpts(newOptions);
  };

  useEffect(() => {
    if (!!modules && Array.isArray(modules[0])) {
      setOptionsState(modules[0]);
      setOpts(modules[0]);
    } else {
      setOptionsState(initialOptions);
      setOpts(initialOptions);
    }
  }, [modules]);

  interface IModulosRow extends Type {
    _id: string;
    name: string;
    active: boolean;
    description: string;
    type: any;
  }

  const columns: Column<IModulosRow>[] = [
    {
      Header: "MÓDULOS",
      accessor: "name",
      Filter: "",
    },
    {
      Header: "Ler",
      accessor: "type",
      id: "read",
      Filter: "",
      Cell: ({ cell }) => {
        return (
          <div>
            <Checkbox
              checked={cell.value.view.active}
              onChange={(e) =>
                handleChange(cell.row.values.name, "view", e.target.checked)
              }
              inputProps={{ "aria-label": "controlled" }}
            />
          </div>
        );
      },
    },
    {
      Header: "Editar",
      accessor: "type",
      id: "edit",
      Filter: "",
      Cell: ({ cell }) => {
        return (
          <Checkbox
            checked={cell.value.update.active}
            onChange={(e) =>
              handleChange(cell.row.values.name, "update", e.target.checked)
            }
            inputProps={{ "aria-label": "controlled" }}
          />
        );
      },
    },
    {
      Header: "Criar",
      accessor: "type",
      id: "create",
      Filter: "",
      Cell: ({ cell }) => {
        return (
          <Checkbox
            checked={cell.value.create.active}
            onChange={(e) =>
              handleChange(cell.row.values.name, "create", e.target.checked)
            }
            inputProps={{ "aria-label": "controlled" }}
          />
        );
      },
    },
    {
      Header: "Deletar",
      accessor: "type",
      id: "delete",
      Filter: "",
      Cell: ({ cell }) => {
        return (
          <Checkbox
            checked={cell.value.delete.active}
            onChange={(e) =>
              handleChange(cell.row.values.name, "delete", e.target.checked)
            }
            inputProps={{ "aria-label": "controlled" }}
          />
        );
      },
    },
  ];

  return (
    <Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        mb={3}
        mt={2}
        gap={2}
      >
        <Box sx={{ borderBottom: 1, width: "100%" }}>
          <InputLabel>Listar módulos</InputLabel>
        </Box>

        <Box sx={{ minHeight: 200, width: "100%" }}>
          <Box display={"flex"} alignItems={"center"} marginBottom={1}>
            <InputLabel sx={{ color: "#4B465C", fontSize: 14 }}>
              Visualizar todos os projetos criados
            </InputLabel>
            <Switch
              checked={seeAllProjects}
              onChange={(e) =>
                handleChange("Projetos", "global", e.target.checked)
              }
            />
          </Box>
          <TableComponent
            columns={columns}
            isLoading={false}
            data={optionsState}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ListModulesPermissions;
