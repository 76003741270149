import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  IconButton,
  InputLabel,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useContext, useState } from "react";
import { IoIosClose } from "react-icons/io";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { AiFillSave } from "react-icons/ai";
import ListModulesPermissions from "../ListModulesPermissions";
import RolePermissionsService from "../../../../../services/rolesPermissions.service";
import { AuthContext } from "../../../../../contexts/auth";

type Props = {
  role?: any;
  onClose: () => void;
  onSuccess?: (roleData: any) => void;
};

interface ArrayActive {
  title: string;
  type: string;
  status: boolean;
}

const RoleModal: React.FC<Props> = (props) => {
  const { actionOptions } = useContext(AuthContext);
  const { onClose, onSuccess, role } = props;
  const [optsState, setOpts] = useState<any>([]);

  const [active, setActive] = useState<Array<ArrayActive>>([]);

  const { mutate: createRole, isLoading: isLoadingCreate } = useMutation(
    RolePermissionsService.create,
    {
      onSuccess: () => {
        toast.success("Função criada com sucesso!");
        onSuccess && onSuccess(roleData);
      },
      onError: (error) => {
        toast.error("Não foi possível criar a função");
      },
    }
  );

  const { mutate: updateRole, isLoading: isLoadingUpdate } = useMutation(
    (data: any) => RolePermissionsService.update(role._id, data),
    {
      onSuccess: () => {
        toast.success("Função atualizada com sucesso!");
        onSuccess && onSuccess(initialRoleData);
      },
      onError: (error) => {
        toast.error("Não foi possível atualizar a função");
      },
    }
  );

  const [initialRoleData, setInitialRoleData] = React.useState(role);

  const [roleData, setRoleData] = React.useState({
    name: initialRoleData?.name || "",
    description: initialRoleData?.description || "",
    active: initialRoleData?.active || true,
    modules: initialRoleData?.modules || [],
  });

  function handleChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const { name, value } = e.target;

    setRoleData({ ...roleData, [name]: value });
  }

  const handleSubmmit = (e: React.MouseEvent) => {
    e.preventDefault();
    let updatedRoleData = { ...roleData };
    if (optsState.length > 0) {
      updatedRoleData.modules = [...optsState];
    }
    role ? updateRole(updatedRoleData) : createRole(updatedRoleData);
    const { name } = e.target as HTMLButtonElement;
    if (name === "save-close") onClose();
  };
  const isDisabled =
    isLoadingCreate ||
    isLoadingUpdate ||
    (!actionOptions["createControle de acesso"] &&
      !actionOptions["updateControle de acesso"]);
  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <Box p={4}>
        <Box display={"flex"} alignItems={"center"} mb={3} gap={2}>
          <Typography variant="h5">
            {!role ? "Criar" : "Editar"} Função
          </Typography>

          <ButtonGroup
            disableElevation
            variant="outlined"
            aria-label="Change status"
            size="small"
          >
            <Button
              onClick={() => setRoleData({ ...roleData, active: true })}
              sx={{
                textTransform: "none",
                color: roleData.active ? "#1361A" : "#666",
                fontWeight: roleData.active ? 700 : 400,
              }}
            >
              Ativo
            </Button>

            <Button
              onClick={() => setRoleData({ ...roleData, active: false })}
              sx={{
                textTransform: "none",
                color: !roleData.active ? "#1361A" : "#999",
                fontWeight: !roleData.active ? 700 : 400,
              }}
            >
              Inativo
            </Button>
          </ButtonGroup>
        </Box>

        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <IoIosClose width={24} height={24} />
          </IconButton>
        ) : null}
        <Box
          component="form"
          sx={{ "& .MuiTextField-root": { m: 1 } }}
          autoComplete="off"
          width={"100%"}
        >
          <InputLabel>
            {!role ? "Crie" : "Edite"} as informações da função
          </InputLabel>

          <InputLabel> Nome função</InputLabel>
          <TextField
            fullWidth
            size="small"
            name="name"
            value={roleData.name}
            onChange={handleChange}
            placeholder="Nome função"
          />

          <InputLabel>Descrição</InputLabel>

          <TextField
            fullWidth
            size="small"
            name="description"
            value={roleData.description}
            onChange={handleChange}
            multiline
            placeholder="Descrição"
          />

          <ListModulesPermissions
            optsState={optsState}
            setOpts={setOpts}
            setActive={setActive}
            active={active}
            modules={roleData.modules}
          />
        </Box>
        <Box display={"flex"} justifyContent={"flex-end"} gap={2} mt={4}>
          <Button
            variant="outlined"
            onClick={onClose}
            style={{ textTransform: "none" }}
          >
            Cancelar
          </Button>

          <Button
            variant="contained"
            onClick={handleSubmmit}
            disabled={isDisabled}
            style={{ textTransform: "none" }}
            name="save-close"
          >
            Salvar e Fechar
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmmit}
            disabled={isDisabled}
            style={{ textTransform: "none" }}
            name="save"
          >
            <AiFillSave size={22} />
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default RoleModal;
