import styled from "styled-components";

export const StyledH2After = styled.h2`

  font-size: 1rem;
  position: relative;
  width: 90%;

  span {
  background-color: white;
  padding-right: 10px;
  z-index: 5;
  cursor: pointer;
  }

  &:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 150px;
  right: 0;
  height: 0.5em;
  border-top: 1px solid #d1d1d1;
  z-index: 1;
  }
`

export const StyledH2Before = styled.h2`
  font-size: 1rem;
  position: relative;
  width: 90%;
  text-align: right;
  margin-right: 10px;

  span {
  background-color: white;
  cursor: pointer;
  padding-right: 10px;
  z-index: 5;

  }

  &:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 17%;
  left: 0;
  height: 0.5em;
  border-top: 1px solid #d1d1d1;
  z-index: 1;
  }
`