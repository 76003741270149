import { Box, Button } from "@mui/material";
import { format } from "date-fns";
import { useState } from "react";
import { useQuery } from "react-query";
import { Column } from "react-table";
import { IProjectRow } from "../../../../../pages/Projects/project-row";
import ProjectService from "../../../../../services/project.service";
import { BRIEFING_STATUS_OPTIONS } from "../../../../../utils/briefingStatus";
import { GridSortDirection } from "@mui/x-data-grid";
import TableComponent from "../../../../CustomDataGrid";
import { useHistory } from "react-router-dom";

interface Props {
  requesterId: string;
}

const ProjectList: React.FC<Props> = ({ requesterId }) => {
  const INITIAL_STATE = {
    page: 0,
    pageSize: 10,
    order: "desc" as GridSortDirection,
    sortField: "mmCode",
  };
  const history = useHistory();
  const [projectData, setProjectData] = useState(INITIAL_STATE);

  const { isLoading, data, error } = useQuery({
    queryKey: ["projects", projectData],
    queryFn: () => {
      return ProjectService.getByRequesterId({ ...projectData, requesterId });
    },
    refetchOnWindowFocus: false,
  });

  const columns: Column<IProjectRow>[] = [
    {
      Filter: "",
      accessor: "mmCode",
      Header: "Número MM",
      Cell: ({ cell }) => (
        <span> {`${cell.value}/${cell.row.original.yearCode}`} </span>
      ),
      width: 90,
      disableSortBy: true,
    },
    {
      id: "eventName",
      Filter: "",
      accessor: "event",
      Header: "Nome do evento",
      Cell: ({ cell }) => <span> {`${cell.value?.name || "---"} `} </span>,
      width: 110,
      disableSortBy: true,
    },
    {
      id: "eventDate",
      Filter: "",
      accessor: "event",
      Header: "Início do Evento",
      width: 120,
      Cell: ({ cell }) => {
        return (
          <span>
            {cell.value?.startDate
              ? format(new Date(cell.value?.startDate), "dd/MM/yyyy")
              : "---"}
          </span>
        );
      },
      disableSortBy: true,
    },
    {
      Filter: "",
      accessor: "status",
      width: 70,
      Header: "Status",
      Cell: ({ cell }) => {
        return (
          <span>
            {cell.value
              ? BRIEFING_STATUS_OPTIONS.find(
                  ({ value }) => value === cell?.value
                )?.text
              : "A definir"}
          </span>
        );
      },
      disableSortBy: true,
    },
    {
      Filter: "",
      accessor: "_id",
      Header: "Ações",
      width: 50,
      Cell: ({ cell }) => (
        <Button
          variant="outlined"
          onClick={() => history.push(`/projeto?projectId=${cell.value}`)}
        >
          Abrir
        </Button>
      ),
      disableSortBy: true,
    },
  ];
  const updatePage = (pageNumber: number) => {
    setProjectData({ ...projectData, page: pageNumber });
  };
  const updateRowsPerPage = (limit: number) => {
    setProjectData({ ...projectData, pageSize: limit, page: 0 });
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      flex={1}
      py={2}
      px={1}
      maxHeight={"400px"}
      bgcolor={"#F9FCFF"}
    >
      <TableComponent
        columns={columns}
        data={data || []}
        isLoading={isLoading}
        givenPage={projectData.page}
        rowsPerPage={projectData.pageSize}
        total={data?.total || 0}
        onPageChange={updatePage}
        onRowsPerPageChange={updateRowsPerPage}
      />
    </Box>
  );
};
export default ProjectList;
