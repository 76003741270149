import { api } from "./apiClient";

export default class ProductService {
  static async create(dto: any): Promise<any> {
    return api.post("/product", dto).then((response) => response.data);
  }

  static async update(id: string, dto: any): Promise<any> {
    return api.put(`/product/${id}`, dto).then((response) => response.data);
  }

  static async get(productId: string): Promise<any> {
    return api.get(`/product/${productId}`).then((response) => response.data);
  }

  static async getUsingCodes(params: any): Promise<any> {
    return api
      .get(`/product/get-products-by-codes`, {
        params
      })
      .then((response) => response.data);
  }
  static async getForCreativeMaterials(params: any): Promise<any> {
    return api
      .get(`/product/get-products-by-codes`, {
        params
      })
      .then((response) => response.data);
  }
}
