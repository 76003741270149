import { Box } from "@mui/material"
import { LabeledInput } from "../../../UI/LabeledInput"
import { useEffect, useState } from "react"
import { format, differenceInDays, addHours } from 'date-fns'
import { toast } from "react-toastify"


type PropTypes = {
  data: any
  onFieldChange(value: string, field: string): void

}
const DefaultChildren: React.FC<PropTypes> = ({ data, onFieldChange }: PropTypes) => {
  const [state, setState] = useState({
    out_date: !!data.out_date ? data.out_date : '',
    input_date: !!data.input_date ? format(new Date(data.input_date), 'yyyy-MM-dd') : '',
    quantity: !!data.quantity ? data.quantity : null,
    daily: !!data.daily ? data.daily : null
  })

  const dailyCalc = differenceInDays(new Date(data.out_date), new Date(data.input_date)) || 0


  function handleStateChange(e: React.ChangeEvent<HTMLInputElement>) {
    console.log(e.target.value)
    const newState = { ...state, [e.target.name]: e.target.value }
    
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
    onFieldChange(e.target.value, e.target.name)
  }

  function handleBlur() {
    const dailyCalc = differenceInDays(new Date(state.out_date), new Date(state.input_date))
    if (dailyCalc < 0) {
      onFieldChange(state.input_date, 'out_date')
      return toast.error('Data fim não pode ser anterior à data inicio')
    }
  }


  return <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '90%'
  }}>

    <Box sx={{
      display: 'grid',
      alignItems: 'center',
      width: '100%',
      gap: 2,
      gridTemplateColumns: data.type === "AIR" ? '2fr 1fr 2fr 1fr' : '1fr 1fr',

    }}
    >
      {data.type === 'AIR' ? <>
        <LabeledInput
          name='input_date'
          label={'Data início'}
          // value={format(addHours(new Date(data.input_date), 4), 'yyyy-MM-dd')}
          value={data.input_date.substr(0,10)}
          type="date"
          onBlur={(e)=> handleBlur()}
          onChange={handleStateChange} />
        <LabeledInput
          name='input_hour'
          label={'Hora início'}
          value={data.input_hour}
          type="time"

          onChange={handleStateChange} />
        <LabeledInput
          name='out_date'
          label={'Data fim'}
          // value={format(addHours(new Date(data.out_date), 4), 'yyyy-MM-dd')}
          value={data.out_date.substr(0,10)}
          type="date"
          onBlur={(e)=> handleBlur()}
          onChange={handleStateChange} />

        <LabeledInput
          name='out_hour'
          label={'Hora fim'}
          value={data.out_hour}
          type="time"

          onChange={handleStateChange} /></> : <>
        <LabeledInput
          name='input_date'
          label={'Data início'}
          value={data.input_date.substr(0,10)}
          type="date"
          onBlur={(e)=> handleBlur()}
          onChange={handleStateChange} />
        <LabeledInput
          name='out_date'
          label={'Data fim'}
          value={data.out_date.substr(0,10)}
          type="date"
          onBlur={(e)=> handleBlur()}
          onChange={handleStateChange} /></>}

      <LabeledInput
        name='daily'
        disabled
        label={'Diárias totais'}
        value={dailyCalc + 1}

        onChange={handleStateChange} />
      <LabeledInput
        name='quantity'
        label={'Quantidade'}
        value={data.quantity}
        onChange={handleStateChange} />
    </Box>

  </Box>
}


export { DefaultChildren }
