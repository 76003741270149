import { ToggleButtonGroup } from "@mui/material";
import { styled } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";

type PropTypes = {
  value?: boolean;
  setValue: (type: string, newValue: boolean) => void;
  type: string;
  align?: string;
};

export default function YesOrNoSwitch({
  value,
  setValue,
  type,
  align = "flex-end",
}: PropTypes) {
  return (
    <ToggleButtonGroup
      size="small"
      color="primary"
      value={value}
      onChange={(e, newValue: boolean) => {
        setValue(type, newValue);
      }}
      exclusive
      aria-label="Platform"
      style={{ alignSelf: align }}
    >
      <ToggleButton
        sx={{ px: 1.25 }}
        style={
          value === true
            ? { background: "#1976D2", color: "white" }
            : { color: "#333" }
        }
        value
      >
        S
      </ToggleButton>
      <ToggleButton
        sx={{ px: 1.25 }}
        style={
          value === false
            ? { background: "#EA5455", color: "white" }
            : { color: "#333" }
        }
        value={false}
      >
        N
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
