import DragAndDrop from "../../../../UI/FileDragAndDrop"
import {LuImagePlus} from 'react-icons/lu'
interface UserProfileDragAndDropProps {
    onUpload(files: File[]): void
    count?: number
    formats?: string[]
    name: string
  }
export function UserProfileDragAndDrop({onUpload, count, formats, name}: UserProfileDragAndDropProps){
    return <DragAndDrop  onUpload={onUpload} count={count} formats={formats}>
        <LuImagePlus size={30}/>
        <span style={{color: '#1361A4'}} >Clique para inserir</span>
        <span> ou arraste PNG ou JPG (3mb)</span>
        
    </DragAndDrop>
}