import styled from "styled-components";
import { Card } from "../../components/Card";
export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 36px);
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: auto;
`;
export const Content = styled.div`
  width: 100%;
  height: calc(100vh - 36px);
  display: flex;
  flex: 1;
  flex-direction: row;
  background: rgba(43, 139, 156, 0.05);
  margin: 0 auto;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
`;
