import {
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import ReactInputMask from "react-input-mask";

import { AuthContext } from "../../../../contexts/auth";
import RoleService from "../../../../services/roles.service";
import { LabeledInput } from "../../../UI/LabeledInput";

type Props = {
  data: any;
  onChange: (event: any) => void;
  rolesArr: any[];
  isEditing: boolean;
};

export const RegisterStep: React.FC<Props> = ({
  onChange,
  data,
  rolesArr,
  isEditing,
}) => {
  const { user } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(true);

  function typesRender() {
    return rolesArr.map((el: any) => {
      return <MenuItem value={el._id}>{el.name}</MenuItem>;
    });
  }

  function passwordAdornment() {
    return !showPassword ? (
      <FaEyeSlash
        style={{ cursor: "pointer" }}
        onClick={() => setShowPassword(true)}
      />
    ) : (
      <FaEye
        style={{ cursor: "pointer" }}
        onClick={() => setShowPassword(false)}
      />
    );
  }

  return (
    <Box
      component="form"
      sx={{ "& .MuiTextField-root": { m: 1 } }}
      autoComplete="off"
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"flex-start"}
    >
      <Box display={"flex"} width={"100%"}>
        <LabeledInput
          fullWidth
          size="small"
          label="Nome Completo"
          name="name"
          value={data.name}
          onChange={onChange}
          placeholder="Nome Completo"
        />
      </Box>
      <Box display={"flex"} width={"100%"}>
        <LabeledInput
          fullWidth
          size="small"
          label="Login"
          value={data.user_name}
          onChange={(e) => {
            e.target.name = "user_name";
            onChange(e);
          }}
          placeholder="nome.sobrenome"
        />
      </Box>

      <Box display={"flex"} width={"100%"}>
        <LabeledInput
          fullWidth
          size="small"
          label="Email"
          onChange={(e) => {
            e.target.name = "email";
            onChange(e);
          }}
          value={data.email}
          placeholder="nome.sobrenome@mmeventos.com.br"
        />
      </Box>

      <Box display={"flex"} width={"100%"}>
        <ReactInputMask
          // @ts-ignore
          maskPlaceholder={null}
          mask="(99) 99999-9999"
          value={data.phone}
          onChange={onChange}
          name="phone"
        >
          <LabeledInput
            fullWidth
            size="small"
            label="Celular"
            name="phone"
            value={data.phone}
            onChange={onChange}
            placeholder="(00) 0 0000 0000"
          />
        </ReactInputMask>
      </Box>

      <Box
        display={"flex"}
        justifyContent={"space-between"}
        width={"100%"}
        mt={2}
      >
        <FormControl style={{ width: "100%" }} size="small">
          <InputLabel size="small" id="demo-simple-select-label">
            Função
          </InputLabel>
          <Select
            value={data.function_id}
            onChange={onChange}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Função"
            size="small"
            name="function_id"
            MenuProps={{
              style: {
                maxHeight: 450,
              },
            }}
          >
            {typesRender()}
          </Select>
        </FormControl>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"flex-start"}
        width={"100%"}
        my={1}
        ml={1}
      />
      <Box width={"100%"}>
        <LabeledInput
          fullWidth
          size="small"
          label="Senha"
          value={data.password}
          onChange={(e) => {
            e.target.name = "password";
            onChange(e);
          }}
          onFocus={() => {
            if (data.password === "") {
              setTimeout(() => {
                setShowPassword(false);
              }, 100);
            }
          }}
          placeholder="senha"
          type={!showPassword ? "password" : "text"}
          InputProps={{
            endAdornment: passwordAdornment(),
          }}
        />
        <LabeledInput
          fullWidth
          size="small"
          label="Confirme a senha"
          value={data.password_confirmation}
          onChange={(e) => {
            e.target.name = "password_confirmation";
            onChange(e);
          }}
          onFocus={() => {
            if (data.password === "") {
              setTimeout(() => {
                setShowPassword(false);
              }, 100);
            }
          }}
          placeholder="Confirme a senha"
          type={!showPassword ? "password" : "text"}
          InputProps={{
            endAdornment: passwordAdornment(),
          }}
        />
      </Box>
    </Box>
  );
};
