import { Box } from "@mui/material";

interface Props {
  icon: any;
  isActive: boolean;
  color?: string;
  onClick?: () => void;
}

export function VerticalTabIcon(props: Props) {
  const { isActive, color } = props;

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      width={40}
      height={40}
      bgcolor={!!color ? color : !isActive ? "#f1f1f1" : "#1361A4"}
      borderRadius={"6px"}
      sx={{
        "& svg, & svg path": {
          fill: color === '#1361A4' ? "#fff" : !isActive ? "#000" : "#fff",
        },
      }}
    >
      {props.icon}
    </Box>
  );
}
