import React, { useContext, useEffect, useMemo, useState } from "react";
import { ProjectContext } from "../../contexts/project";
import LoadingModal from "../../components/LoadingModal";
import { DataGrid, GridColDef, GridSortDirection, GridSortItem } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import BriefingService from "../../services/briefing.service";
import { useQuery } from "react-query";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import UserService from "../../services/user.service";
import { BRIEFING_STATUS_OPTIONS } from "../../utils/briefingStatus";
import TableComponent from "../../components/CustomDataGrid";
import { Type } from "typescript";
import { IBriefingRow } from "./briefing-row";
import { Column } from "react-table";

const INITIAL_STATE = {
  page: 0,
  pageSize: 20,
  order: "desc" as GridSortDirection,
  sortField: "mm_code",
  search: "",
  status: "all",
  userId: "all",
}

export default function Briefing() {
  const history = useHistory();
  const { t } = useTranslation();
  const { setProjectID } = useContext(ProjectContext);

  const [briefingData, setBriefingData] = useState(INITIAL_STATE)
  const { isLoading, data, error } = useQuery({
    queryKey: ["briefings_search", briefingData],
    queryFn: () => BriefingService.search(briefingData),
  });

  const { data: usersData } = useQuery({
    queryKey: ['users-data'],
    queryFn: () => UserService.allUsers()
  });
 


  const columns: Column<IBriefingRow>[] = [
    {
      Filter: '',
      accessor: "mm_code",
      Header: "Número MM",
      Cell: ({ cell }) => <span> {`${cell.value} / ${cell.row.original.year_code}`} </span>,
      width: 90,
    },
    {
      Filter: '',
      accessor: "briefing_event_name", Header: "Nome do evento"
    },
    {
      Filter: '',
      accessor: "customer_corporate_name",
      Header: "Nome da empresa",
    },
    {
      Filter: '',
      accessor: "user_id",
      Header: "Criador",
      width: 80,
      Cell: ({ cell }) => <span> {cell.value ? usersData?.find((el) => el._id === cell.value)?.name : "A definir"}</span>

    },
    {
      Filter: '',
      accessor: "created_at",
      Header: t("auditForm.creation"),
      width: 100,
      Cell: ({ cell }) => {
        return <span>{cell.value ? format(new Date(cell.value), "dd/MM/yyyy") : ""}</span>;
      },
    },
    // {
    //   Filter: '',//   
    //   accessor: "status",
    //   width: 90,
    //   Header: "Status",
    //   Cell: ({ cell }) =>
    //     <span>
    //       {
    //         cell.value
    //           ? BRIEFING_STATUS_OPTIONS.find(
    //             ({ value }) => value === cell.value.type
    //           )?.text
    //           : "A definir"
    //       }
    //     </span>
    // },
    {
      Filter: '',
      accessor: "briefing_event_type",
      Header: "Tipo do evento",
      width: 90,
      Cell: ({ cell }) => <span>{cell.value || "A definir"}</span>,
    },
    // {
    //   Filter: '',
    //   accessor: "coordinator_1",
    //   Header: "Coordenador",
    //   width: 170,
    // },
    // { Filter: '',
    //   accessor: "attendance_1", Header: "Atendente", width: 170, },
    {
      Filter: '',
      accessor: "_id",
      Header: "Ações",
      width: 60,
      Cell: ({ cell }) => (
        <Button
          variant="outlined"
          onClick={() => history.push(`/briefing/${cell.value}`)}
        >
          Abrir
        </Button>
      ),
    },
  ];


  const updatePage = (pageNumber: number) => {
    setBriefingData({ ...briefingData, page: pageNumber })
  }
  const updateRowsPerPage = (limit: number) => {
    setBriefingData({ ...briefingData, pageSize: limit, page: 0 })
  }
  useEffect(() => {
    setProjectID("");
  }, []);

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        flex={1}
        py={4}
        px={3}
        bgcolor={"#F9FCFF"}
      >
        <Box display={"flex"}>
          <Typography variant={"h5"}>Projetos</Typography>
        </Box>

        <Card
          sx={{
            padding: "24px",
            display: "flex",
            flexDirection: "column",
            mt: 4,
            boxShadow: "0px 4px 18px 0px #4B465C1A",
            overflow: "auto",
          }}
        >
          <Box display={"flex"} mb={4} alignItems={"center"} flex={1}>
            <Box display={"flex"} alignItems={"center"} flex={1} gap={2}>
              <TextField
                size="small"
                placeholder="Pesquisar por MM, nome do evento, nome da empresa, atendimento, coordenador ou líder da célula"
                value={briefingData.search}
                onChange={(e) =>
                  setBriefingData({ ...briefingData, search: e.target.value })
                }
                sx={{ width: "50%" }}
              />

              <FormControl sx={{ width: "20%" }}>
                <InputLabel id="filter-status">Status</InputLabel>
                <Select
                  labelId="filter-status"
                  label="Status"
                  size="small"
                  value={briefingData.status}
                  onChange={(e) =>
                    setBriefingData({
                      ...briefingData,
                      status: e.target.value,
                      page: 0,
                      pageSize: 20
                    })
                  }
                >
                  <MenuItem value={"all"}>Todos</MenuItem>

                  {BRIEFING_STATUS_OPTIONS.map(({ value, text }) => (
                    <MenuItem key={value} value={value}>
                      {text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ width: "20%" }}>
                <InputLabel size="small" id="filter-user">Usuário</InputLabel>
                <Select
                  labelId="filter-status"
                  label="Usuário"
                  size="small"
                  value={briefingData.userId}
                  onChange={(e) =>
                    setBriefingData({
                      ...briefingData,
                      userId: e.target.value,
                      page: 0,
                      pageSize: 20
                    })
                  }
                >
                  <MenuItem value={"all"}>Todos</MenuItem>

                  {usersData
                    ?.filter((user: any) => user.active)
                    .map((user: any) => (
                      <MenuItem key={user._id} value={user._id}>
                        {user.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>

            <Button
              variant="contained"
              onClick={() => history.push(`/briefing`)}
            >
              Novo Briefing
            </Button>
          </Box>

          <TableComponent
            columns={columns}
            data={data?.briefings || []}
            isLoading={isLoading}
            givenPage={briefingData.page}
            rowsPerPage={briefingData.pageSize}
            total={data?.total || 0}
            onPageChange={updatePage}
            onRowsPerPageChange={updateRowsPerPage}


          />
        </Card>
      </Box>
    </>
  );
}
