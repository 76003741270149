import { yupResolver } from "@hookform/resolvers/yup";
import { Autocomplete, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { object, string } from "yup";

import { useProject } from "../../../contexts/project.context";
import ClientService from "../../../services/client.service";
import { Requesting } from "../../../types";
import { Client } from "../../../types/client.type";
import { Input } from "../../Input";
import RequesterInfo from "../components/RequesterInfo";
import TabContent from "../components/TabContent";

interface ProjectClientProps {
  onNext: () => void;
}

const emptyRequesting = { _id: "", name: "", email: "" };
const emptyClient = { _id: "", name: "", email: "", segment: "", tax_id: "" };

const schema = object({
  client: object({
    _id: string(),
    name: string(),
    email: string(),
    segment: string(),
    tax_id: string(),
  }).required("Cliente é obrigatório"),
});

const ProjectClient = ({ onNext }: ProjectClientProps) => {
  const { client, updateClient, project } = useProject();
  const [hasRequester, setHasRequester] = useState(false);
  const [requestings, setRequestings] = useState<Requesting[]>([
    emptyRequesting,
  ]);

  const updateRequestings = (newRequesting: any) => {
    setRequestings([newRequesting]);
  };

  const {
    handleSubmit,
    setValue,
    reset,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      client: emptyClient as Client,
    },
    resolver: yupResolver(schema),
    mode: "all",
  });

  const clientWatch = watch("client");
  const { data: clientsData, isLoading: clientLoading } = useQuery(
    "clients",
    ClientService.getClients
  );

  function onSubmit(form: any) {
    if (!clientWatch?._id || clientWatch._id.length < 0) {
      toast.error("Cliente é obrigatório.");
      return;
    }
    if (!requestings || requestings.length === 0 || !requestings?.[0]?._id) {
      toast.error("Solicitante é obrigatório.");
      return;
    }
    updateClient({
      clientSelected: form.client,
      requestings: requestings.filter((requesting) => requesting._id),
    });
    onNext();
  }

  useEffect(() => {
    if (client) {
      reset({ client: client.clientSelected });
      setRequestings([...client.requestings]);
    }
  }, [client]);
  useEffect(() => {
    if (errors) {
      for (const [key, value] of Object.entries(errors)) {
        if (value.message) toast.error(value.message);
      }
    }
  }, [errors]);

  return (
    <TabContent onSubmit={handleSubmit(onSubmit)}>
      <Autocomplete
        options={clientsData || []}
        getOptionLabel={(option) => {
          if (option.name !== "")
            return `${option.name}/${option.nickname} - ${option.tax_id}`;
          if (option.name === "" && !!option._id)
            return "Cliente sem nome. Atualize na área de clientes";
          return "";
        }}
        renderOption={(props, option) => {
          let label = "";
          if (option.name !== "")
            label = `${option.name}/${option.nickname} - ${option.tax_id}`;
          if (option.name === "" && !!option._id)
            label = "Cliente sem nome. Atualize na área de clientes";
          return (
            <li {...props} key={option._id}>
              {label}
            </li>
          );
        }}
        value={clientWatch}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        onChange={(_, client) => {
          if (client === null)
            return setValue("client", emptyClient), clearErrors("client");
          setValue("client", client), clearErrors("client");
        }}
        loading={clientLoading}
        loadingText={"Carregando..."}
        renderInput={(params) => (
          <Input label="Cliente cadastrado *" {...params} />
        )}
      />
      <Box display="flex" alignItems="center" gap={2}>
        <Input
          label="CNPJ"
          value={clientWatch?.tax_id}
          disabled
          placeholder="Defina o CNPJ do cliente na aba 'Parceiros'"
        />
        <Input
          label="Segmento"
          value={clientWatch?.segment}
          disabled
          placeholder="Defina o segmento do cliente na aba 'Parceiros'"
        />
      </Box>
      <RequesterInfo
        client={clientWatch?._id}
        requester={
          project?.requester
            ? project?.requester
            : requestings?.[0] || {
                name: "",
                email: "",
                phone: "",
              }
        }
        onUpdateRequestings={updateRequestings}
      />
    </TabContent>
  );
};

export default ProjectClient;
