import { api } from "./apiClient";

export class SupplierLogistic {
    static async getServicesByProjectIdAndType(params: any): Promise<any> {
        return api.get('/logistic-service/get', {params}).then((response) => response.data)
    }
    static async getLogistics(projectId: string): Promise<any> {
        return api.get(`/logistics/get`, {
            params: {
                projectId
            }
        })
    }
    static async allSaved(): Promise<any> {
        return api.get('/logistics/list').then((response) => response.data)
    }
    static async create(data: any): Promise<any> {
        return api.post('/logistics/create', data).then((response) => response.data)
    }
    static async createService(data: any): Promise<any> {
        return api.post('/logistic-service/create', data).then((response) => response.data)
    }
    static async update(id:string, data: any): Promise<any> {
        return api.put(`/logistics/update/${id}`, data).then((response) => response.data)
    }

    static async updateService(id:string, data: any): Promise<any> {
        return api.put(`/logistic-service/update/${id}`, data).then((response) => response.data)
    }
    static async deleteService(id:string): Promise<any> {
        return api.delete(`/logistic-service/delete/${id}`).then((response) => response.data)
    }


}