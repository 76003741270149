export enum ContentTypeEnum  {
  THIRD_PARTY_AGENCY = 'Agência terceira',
  CLIENT = 'Cliente',
  MM = 'MM',
}

export enum CreativeDirectionalEnum {
  EVENT_OR_PROJECT_NAME = 'Nome (do evento ou do projeto)',
  THEME = 'Tema',
  KV_OPEN_FILE_ONLY = 'KV (somente arquivo aberto)',
}

export type CreativeFormFields = {
  contentType: string[],
  contentTypeObs: string,
  creativeDirectional: string[],
  creativeDirectionalExample: string,
  keyMessages: string,
  kvMaterialType: string,
  kvMaterialTypeObs: string,
  artMaterialType: string[], // ou array de strings
  artMaterialTypeObs: string,
  digitalSchenographyMaterialType: string[], // ou array de ''s
  digitalSchenographyMaterialTypeValue: number,
  artCreation: string, // ou array de ''s
  artCreationAditionalOptions: string,
  gifts: string,
  files: [] // daí aqui tu que vê como vai ser o tipo de envio e tipo de retorno
}