import { api } from "./apiClient";

export default class CreativeService {
  static async create(dto: any): Promise<any> {
    return api.post("/creative", dto).then((response) => response.data);
  }

  static async update(id: string, dto: any): Promise<any> {
    return api.put(`/creative/${id}`, dto).then((response) => response.data);
  }


  static async get(projectId: string): Promise<any> {
    return api.get(`/creative/project/${projectId}`).then((response) => response.data);
  }


}
