import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { object, string } from "yup";

import { useProject } from "../../../contexts/project.context";
import ClientService from "../../../services/client.service";
import { Requesting } from "../../../types";
import { Client } from "../../../types/client.type";
import TabContent from "../../Project/components/TabContent";
import { SummaryAccordion } from "../components/SummaryAccordion/index";

interface ProjectClientProps {
  onNext: () => void;
}

const emptyRequesting = { _id: "", name: "", email: "" };
const emptyClient = { _id: "", name: "", email: "", segment: "", tax_id: "" };

const schema = object({
  client: object({
    _id: string(),
    name: string(),
    email: string(),
    segment: string(),
    tax_id: string(),
  }).required("Cliente é obrigatório"),
});

const ProjectSummary = ({ onNext }: ProjectClientProps) => {
  const { client, updateClient, project } = useProject();
  const [requestings, setRequestings] = useState<Requesting[]>([
    emptyRequesting,
  ]);

  const {
    handleSubmit,
    setValue,
    reset,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      client: emptyClient as Client,
    },
    resolver: yupResolver(schema),
    mode: "all",
  });

  const clientWatch = watch("client");

  function onSubmit(form: any) {
    if (!clientWatch?._id || clientWatch._id.length < 0) {
      toast.error("Cliente é obrigatório.");
      return;
    }
    if (!requestings || requestings.length === 0 || !requestings?.[0]?._id) {
      toast.error("Solicitante é obrigatório.");
      return;
    }
    updateClient({
      clientSelected: form.client,
      requestings: requestings.filter((requesting) => requesting._id),
    });
    onNext();
  }

  return (
    <TabContent onSubmit={handleSubmit(onSubmit)} showSubmit={false}>
      <SummaryAccordion.Root>
        <SummaryAccordion.Briefing />
        <SummaryAccordion.OnApproval />
        <SummaryAccordion.Approved />
        <SummaryAccordion.Ending />
        <SummaryAccordion.Audited />
      </SummaryAccordion.Root>
    </TabContent>
  );
};

export default ProjectSummary;
