import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { IoMdClose } from "react-icons/io";
import ReactInputMask from "react-input-mask";
import { object, string } from "yup";

import RequestingService from "../../../../services/requesting.service";
import { regexEmail } from "../../../../utils/utils";
import { Input } from "../../../Input";
import { useMutation, useQueryClient } from "react-query";
import { NotificationFields } from "../../../../types/notification";

interface RequesterModalProps {
  isOpen: boolean;
  editClient: any;
  onClose: () => void;
}

const schema = object({
  name: string().required("Nome é obrigatório"),
  email: string().matches(regexEmail).required("Email é obrigatório"),
  phone: string().min(14).required("Telefone é obrigatório"),
}).required();

const CreateRequesterModal = ({
  isOpen,
  editClient,
  onClose,
}: RequesterModalProps) => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: undefined,
    },
    resolver: yupResolver(schema),
    mode: "all",
  });

  const [notification, setNotification] = useState<NotificationFields>({
    open: false,
    severity: "success",
    message: "",
  });
  function closeNotification() {
    setNotification({ ...notification, open: false });
  }

  const queryClient = useQueryClient();

  const handleCloseDialog = () => {
    reset();
    onClose();
  };

  async function onSubmit(form: any) {
    createRequester({
      ...form,
      client: editClient?._id,
    });
  }

  const { mutate: createRequester, isLoading: isLoadingCreate } = useMutation(
    (state: any) => RequestingService.create(state),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("requesters-data");
        handleCloseDialog();
      },
      onError: (error) => {
        setNotification({
          open: true,
          severity: "error",
          message: "Não foi possível criar",
        });
      },
    }
  );

  return (
    <Dialog onClose={handleCloseDialog} open={isOpen} fullWidth maxWidth={"sm"}>
      <DialogTitle sx={{ m: 0, px: 4, paddingTop: 3, color: "#1361A4" }}>
        Cadastrar novo Solicitante
      </DialogTitle>
      <ul style={{ color: "#A19BB8" }}>
        <li>Não abrevie nomes para evitar homônimos</li>
        <li>Email com domínio de empresa se possível</li>
      </ul>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 16,
          top: 16,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <IoMdClose />
      </IconButton>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={{ px: 4 }}>
          <Box display={"flex"} flexDirection={"column"}>
            <Input label="Nome" {...register("name")} error={!!errors.name} />
            <Input
              label="Email"
              {...register("email")}
              error={!!errors.email}
            />

            <Controller
              control={control}
              name="phone"
              render={({ field: { onChange, onBlur, ref } }) => (
                <ReactInputMask
                  // @ts-ignore
                  maskPlaceholder={null}
                  mask="(99) 99999-9999"
                  onChange={onChange}
                  onBlur={onBlur}
                  inputRef={ref}
                >
                  <Input
                    label="Telefone"
                    placeholder="(00) 0 0000 0000"
                    error={!!errors.phone}
                  />
                </ReactInputMask>
              )}
            />
          </Box>
        </DialogContent>

        <DialogActions sx={{ px: 4, py: 3 }}>
          <Button type="button" variant="outlined" onClick={handleCloseDialog}>
            Cancelar
          </Button>

          <Button
            type="submit"
            onClick={handleSubmit(onSubmit)}
            disabled={isLoadingCreate}
          >
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateRequesterModal;
