import {
  Alert,
  Box,
  Card,
  Chip,
  Snackbar,
  Slide,
  Tab,
  Tabs,
  Typography,
  Button,
} from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import AerialStep from "../../components/Aereo";
import { AlertModal } from "../../components/AlertModal";
import Criativo from "../../components/Criativo/Criativo";
import Producao from "../../components/Criativo/Producao";
import Logistica from "../../components/Logistics";
import BriefingAuditModal from "../../components/novos/Audit/Modal/AuditModal";
import AuditModal from "../../components/novos/Audit/Modal/AuditModal";
import BudgetStep from "../../components/novos/Budget";
import ClosingStep from "../../components/novos/Closing";
import NegotiationStep from "../../components/novos/Negotiation";
import RSVPStep from "../../components/novos/RSVP";
import ProjectStep from "../../components/Project";
import ProjectStatusModal from "../../components/Project/templates/ProjectStatusModal";
import SummaryStep from "../../components/Summary";
import { useProject } from "../../contexts/project.context";
import { BRIEFING_STATUS_OPTIONS } from "../../utils/briefingStatus";
import BoothStep from "../../components/novos/Booth";

const StyledSpan = styled.span`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`;

const Project = () => {
  const {
    loadingProject,
    loadProject,
    project,
    clearProject,
    notification,
    closeNotification,
  } = useProject();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get("projectId");
  const [showUpdateStatus, setShowUpdateStatus] = useState(false);

  const tabs = [
    {
      index: 0,
      label: "Dados Cadastrais",
      template: ProjectStep,
      area: "project",
    },
    { index: 1, label: "Resumo", template: SummaryStep, area: "" },
    { index: 1, label: "Logística", template: Logistica, area: "logistics" },
    { index: 2, label: "Aéreo", template: AerialStep, area: "" },
    { index: 3, label: "RSVP", template: RSVPStep, area: "" },
    { index: 4, label: "Criativo", template: Criativo, area: "" },
    { index: 5, label: "Produção", template: Producao, area: "" },
    { index: 6, label: "Estande", template: BoothStep, area: "" },
    // { index: 7, label: "Orçamento", template: BudgetStep, area: 'budget' },
    // { index: 8, label: "Fechamento", template: ClosingStep, area: 'closing' },
  ];
  const [tabActive, setTabActive] = useState(0);
  const [pendingTab, setPendingTab] = useState(-1);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showAuditModal, setShowAuditModal] = useState(false);
  const Template = tabs[tabActive].template;

  useEffect(() => {
    if (pendingTab >= 0) {
      if (localStorage.getItem("hideAlert") !== "0") {
        setShowAlertModal(true);
      } else {
        setTabActive(pendingTab);
        setPendingTab(-1);
      }
    }
  }, [pendingTab]);

  function changeTab(status: boolean) {
    if (status && !!projectId) setTabActive(pendingTab);
    setShowAlertModal(false);
    setPendingTab(-1);
  }
  function handleChangeTab(index: any) {
    if (projectId) setPendingTab(index);
  }
  useEffect(() => {
    if (projectId) loadProject(projectId);
    return clearProject();
  }, []);

  const pageName = project
    ? `Editar projeto MM ${project.mmCode}.${project.yearCode}`
    : "Criar projeto";
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      py={4}
      px={3}
      bgcolor={"#F9FCFF"}
      flex={1}
    >
      {notification.open && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={6000}
          open={notification.open}
          onClose={closeNotification}
          TransitionComponent={(props) => <Slide {...props} direction="left" />}
        >
          <Alert
            onClose={closeNotification}
            severity={notification.severity}
            sx={{ width: "100%" }}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      )}

      {showUpdateStatus && (
        <ProjectStatusModal
          isOpen={showUpdateStatus}
          onClose={() => setShowUpdateStatus(false)}
          status={project?.status}
        />
      )}
      {showAuditModal && (
        <AuditModal
          isOpen={showAuditModal}
          onClose={() => setShowAuditModal(false)}
          area={tabs[tabActive].area}
          label={tabs[tabActive].label}
        />
      )}

      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box gap={1}>
          <Box display={"flex"} gap={1}>
            <Typography variant={"h5"} color={"#A1A1A1"}>
              Projetos/
            </Typography>
            <Typography variant={"h5"}>{pageName}</Typography>
          </Box>

          <Typography variant={"caption"}>
            Adicione as informações de um projeto{" "}
            {projectId &&
              project &&
              ` - última edição ${format(
                new Date(project.updatedAt),
                "dd/MM - HH:mm"
              )} por ${project.lastUserUpdated?.name ?? ""}`}
          </Typography>
        </Box>

        {projectId && project?.status && (
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            <Chip
              label={
                BRIEFING_STATUS_OPTIONS.find(
                  (item) => item.value === project.status.status
                )?.text
              }
              onClick={() => setShowUpdateStatus(true)}
              onDelete={() => setShowUpdateStatus(true)}
              deleteIcon={<MdEdit />}
            />

            {tabs[tabActive].area !== "" && (
              <Button
                variant="outlined"
                color="info"
                onClick={() => setShowAuditModal(true)}
              >
                Auditoria
              </Button>
            )}
          </Box>
        )}
      </Box>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 4,
          boxShadow: "0px 4px 18px 0px #4B465C1A",
          width: "100%",
        }}
      >
        <AlertModal
          handleClose={() => changeTab(false)}
          isOpen={showAlertModal}
          makeDecision={changeTab}
        />

        <Tabs
          value={tabActive}
          onChange={(e, index) => handleChangeTab(index)}
          indicatorColor="primary"
          sx={{ textTransform: "none" }}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.index}
              label={tab.label}
              sx={{ textTransform: "none" }}
            />
          ))}
        </Tabs>
        <Box
          overflow={"auto"}
          sx={{
            backgroundColor: "#F9FCFF",
          }}
        >
          <Template />
          {projectId && (
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              px={10}
              py={2}
              gap={2}
            >
              {tabActive > 0 && (
                <StyledSpan onClick={() => handleChangeTab(tabActive - 1)}>
                  <FaCaretLeft />
                  Voltar
                </StyledSpan>
              )}
              {tabActive < tabs.length - 1 && (
                <StyledSpan onClick={() => handleChangeTab(tabActive + 1)}>
                  Avançar
                  <FaCaretRight />
                </StyledSpan>
              )}
            </Box>
          )}
        </Box>
      </Card>
    </Box>
  );
};

export default Project;
