import { Box, FormControlLabel, FormGroup } from "@mui/material"
import { Switch } from "../../UI/Switch"
import { t } from "i18next"
import { ReactNode } from "react"

type PropTypes = {
  icon: ReactNode
  title: string,
  typeKey: string
  isActive: boolean,
  isDisabled: boolean,
  changeIsActive(type: string, value: boolean): void

}

const LogisticsTypesTabItem: React.FC<PropTypes> = ({
  changeIsActive,
  icon,
  isActive,
  title,
  typeKey,
  isDisabled
}: PropTypes) => {
  return <Box sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  }}>
    <div style={{
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      minWidth: '180px'
    }}>
      <div style={{
        padding: '6px',
        marginRight: '8px',
        borderRadius: '5px',
        background: '#A5A3AE4D',
        display: 'flex',
        alignItems: 'center'
      }}>{icon}</div>
      <span>{title}</span>
    </div>
    <FormGroup row>
      <FormControlLabel
        control={
          <Switch
            disabled={isDisabled}
            checked={isActive}
            onChange={(e) => {
              changeIsActive(typeKey, e.target.checked)
            } } label={""}          /> 
        }
        label={''}
      />
    </FormGroup>
  </Box>
}

export default LogisticsTypesTabItem