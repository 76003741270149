import React, { useMemo } from 'react';
import { useTable, usePagination, useSortBy, useFilters } from 'react-table';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Box, CircularProgress } from '@mui/material';
import TablePaginationActions from './ActionsComponent';
import { ColumnFilter } from './ColumnFilter';
import { ColumnSort } from './ColumnSort';
import { InfoDiv } from './styles';




export interface ITable<T> {
  columns: any;
  data: T[];
  isLoading: boolean,
  onPageChange?(page: number): void
  onRowsPerPageChange?(limit: number): void
  total?: number
  givenPage?: number
  rowsPerPage?: number
}

export function TableComponent<T extends readonly object[]>({
  columns,
  data,
  isLoading,
  onPageChange,
  onRowsPerPageChange,
  total,
  givenPage,
  rowsPerPage
}: ITable<T>) {

  const columnData = useMemo(()=> columns, [columns])
  const rowData = useMemo(()=> data, [data])
  const defaultColumn = useMemo(() => ({
    Filter: ColumnFilter
  }), [])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },

  } = useTable({data: rowData,
    defaultColumn,
    columns: columnData,
  }, useFilters, useSortBy, usePagination);

  function filterRowsByPage(items: any[], pageSize: number, pageIndex: number) {
    if(givenPage) return items
    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize;
    const filteredItems = items.slice(startIndex, endIndex);
    return filteredItems;
  }

  return (
    <div style={{ backgroundColor: "#FFF", width: '100%',  }}>

      <TableContainer component={Paper}  style={{ maxHeight: '580px', minHeight: '400px' }}>
        <Table stickyHeader {...getTableProps()}  style={{height: '100%'}}>
          <TableHead  >
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()} style={{width:'100%'}}>
                {headerGroup.headers.map(column => (
                  <TableCell {...column.getHeaderProps({
                    ...column.getSortByToggleProps(),
                    style: !!column.width ? {
                      width: column.width
                    } : undefined
                    })}>
                    <span style={{ fontWeight: '600', color: '#4B465C' }}> {column.render('Header')}</span>

                    {column.canSort ? <ColumnSort column={column}  /> : null}

                    {column.canFilter ? column.render('Filter') : null}

                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          {data.length > 0 ?
            <TableBody {...getTableBodyProps()}>
              {filterRowsByPage(rows, rowsPerPage || pageSize, givenPage || pageIndex).map(row => {
                prepareRow(row);
                return (
                  <TableRow style={{verticalAlign: 'top'}} {...row.getRowProps()}>
                    {row.cells.map((cell: any) => (
                      <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody> : isLoading ? <InfoDiv><CircularProgress/></InfoDiv> : <InfoDiv>Sem dados para listar</InfoDiv>
        }

        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={total || data.length}
        page={givenPage || pageIndex}
        sx={{
          '.MuiTablePagination-spacer': {
            display: 'none'
          },
          '.MuiTablePagination-toolbar': {
            padding: '0 3px'
          }
        }}
        onPageChange={(event, newPage) => {
          if (!!onPageChange) return onPageChange(newPage)
          gotoPage(newPage)

        }}
        rowsPerPage={rowsPerPage || pageSize}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        labelRowsPerPage="Linhas por página"
        labelDisplayedRows={({ from, to, count }) => {
          if(!!givenPage && !!rowsPerPage && !!total) {
            let from = (givenPage) * rowsPerPage
            let to = from + rowsPerPage > total ? total : from + rowsPerPage
            return `${from} - ${to} de ${total}`;
          }
          return `${from} - ${to} de ${count}`;
        }}
        onRowsPerPageChange={event => {
          if(!!onRowsPerPageChange) return onRowsPerPageChange(Number(event.target.value))
          setPageSize(Number(event.target.value));
        }}
        ActionsComponent={TablePaginationActions}
      />




    </div>
  )
}



export default TableComponent;