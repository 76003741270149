import {
  Box,
  Button,
  Card,
  Chip,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { GridSortDirection } from "@mui/x-data-grid";
import React, { useContext, useState } from "react";
import { FaSyncAlt } from "react-icons/fa";
import { MdSearch } from "react-icons/md";
import { TbEyeEdit, TbTrash } from "react-icons/tb";
import { useQuery } from "react-query";
import { Column } from "react-table";
import { toast } from "react-toastify";
import { Type } from "typescript";

import { AuthContext } from "../../../../contexts/auth";
import ClientService from "../../../../services/client.service";
import TableComponent from "../../../CustomDataGrid";

type Props = {
  onSelect(type: string, item: any): void;
};
const INITIAL_STATE = {
  page: 0,
  pageSize: 10,
  order: "asc" as GridSortDirection,
  sortField: "name",
  search: "",
  origin: "",
};
export function Customers({ onSelect }: Props) {
  const { actionOptions } = useContext(AuthContext);
  const [clientData, setClientData] = useState<any>(INITIAL_STATE);
  const updatePage = (pageNumber: number) => {
    setClientData({ ...clientData, page: pageNumber });
  };
  const updateRowsPerPage = (limit: number) => {
    setClientData({ ...clientData, pageSize: limit, page: 0 });
  };
  const handleEdit = (id: string): void => {
    const item = data?.clients.find((el) => el._id === id);
    if (item) onSelect("client", item);
  };
  const handleDelete = async (id: string): Promise<void> => {
    try {
      await ClientService.delete(id);
    } catch (error) {
      toast.error("Não foi possível apagar o Cliente");
    }
  };

  const { isLoading, data, error } = useQuery({
    queryKey: ["clients_search", clientData],
    queryFn: () => ClientService.search(clientData),
  });

  const handleSync = async () => {
    await ClientService.sync();
  };
  interface IClientRow extends Type {
    _id: string;
    handle_id: string;
    name: string;
    nickname: string;
    business_group_name: string;
    tax_id: string;
    projs: any[];
  }

  const columns: Column<IClientRow>[] = [
    {
      Header: "ID",
      accessor: "handle_id",
      Filter: "",
      width: 60,
    },
    {
      Header: "Cliente",
      accessor: "name",
      Filter: "",
      width: 300,
      Cell: ({ cell }) => {
        return (
          <div>
            <span>
              <strong>{cell.row.original.nickname}</strong> -{" "}
              {cell.row.original.business_group_name}
            </span>
            <br />
            <span>{cell.row.original.name}</span>
          </div>
        );
      },
    },
    {
      Header: <span>CNPJ OU CPF </span>,
      accessor: "tax_id",
      Filter: "",
      Cell: ({ cell }) => {
        return (
          <div>
            <span>{cell.row.values.tax_id}</span>
          </div>
        );
      },
    },
    {
      Header: <span>PROJETOS</span>,
      accessor: "projs",
      Filter: "",
      Cell: ({ cell }) => {
        return (
          <div>
            <span>{cell.value.length} ativos</span>
          </div>
        );
      },
    },
    {
      Header: "",
      accessor: "_id",
      width: 60,
      Cell: ({ cell }) => (
        <div
          onClick={() => {}}
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          {" "}
          <Button
            variant="outlined"
            style={{ maxWidth: "20px" }}
            onClick={() => {
              handleEdit(cell.value);
            }}
          >
            <TbEyeEdit size={18} style={{ cursor: "pointer" }} />
          </Button>
          {/* <Button
            variant="outlined"
            style={{ maxWidth: "20px" }}
            disabled={!actionOptions.deleteParceiros}
            onClick={() => {
              handleDelete(cell.value);
            }}
          >
            <TbTrash size={18} style={{ cursor: "pointer" }} />
          </Button> */}
        </div>
      ),
      Filter: "",
      disableSortBy: true,
    },
  ];

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        width={"98%"}
        margin="5px auto"
      >
        <Box width={"20%"}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" size="small">
              Origem cadastro
            </InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={clientData.origin}
              label="Origem cadastro"
              size="small"
              onChange={(e: any) => {
                setClientData({ ...clientData, origin: e.target.value });
              }}
              MenuProps={{
                style: {
                  maxHeight: 450,
                },
              }}
            >
              <MenuItem value="BENNER"> BENNER</MenuItem>
              <MenuItem value="MMIDD"> MMIDD</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <TextField
          size="small"
          placeholder="Buscar cnpj, cpf, célula ou cliente"
          value={clientData.search}
          onChange={(e) => {
            setClientData({ ...clientData, search: e.target.value });
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <MdSearch />
              </InputAdornment>
            ),
          }}
          sx={{ width: "30%" }}
        />
      </Box>
      <Box height={"60px"} width={"95%"} margin={"0 auto"}>
        {clientData.search.length > 0 && (
          <Chip
            label={clientData.search}
            variant="outlined"
            onDelete={() => setClientData(INITIAL_STATE)}
          />
        )}
        {clientData.origin.length > 0 && (
          <Chip
            label={`Origem: ${clientData.origin}`}
            variant="outlined"
            onDelete={() => setClientData({ ...clientData, origin: "" })}
          />
        )}
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        width={"98%"}
        margin="5px auto"
      >
        <TableComponent
          columns={columns}
          data={data?.clients || []}
          isLoading={isLoading}
          givenPage={clientData.page}
          rowsPerPage={clientData.pageSize}
          total={data?.total || 0}
          onPageChange={updatePage}
          onRowsPerPageChange={updateRowsPerPage}
        />
        <Box
          display={"flex"}
          alignItems={"center"}
          style={{ cursor: "pointer" }}
          onClick={handleSync}
        >
          <Box
            display={"flex"}
            justifyContent={"space-around"}
            padding={1}
            alignItems={"center"}
          >
            <FaSyncAlt />
          </Box>
          <span>Sincronizar com Benner</span>
        </Box>
      </Box>
    </>
  );
}
