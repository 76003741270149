import styled from "styled-components";
import { Card } from "../Card";

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledCard = styled(Card)`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 0 16px 16px 16px;
  margin: 10px auto;

  div {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    width: 90%;
    align-items: topline;
  }
  strong {
    width: 30%;
  }
  box-shadow: none;
  background: #fff;
`;

export const BriefingNav = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BriefingContent = styled.div``;

export const InputDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  align-items: baseline;
  justify-content: flex-start;
`;
export const RadioDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  align-items: center;
  justify-content: left;
`;
