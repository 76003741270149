import ApprovedAccordion from "./Approved";
import AuditedAccordion from "./Audited";
import BriefingAccordion from "./Briefing";
import EndingAccordion from "./Ending";
import OnApprovalAccordion from "./OnApproval";
import { Root } from "./Root";

export const SummaryAccordion = {
  Root,
  Briefing: BriefingAccordion,
  OnApproval: OnApprovalAccordion,
  Approved: ApprovedAccordion,
  Ending: EndingAccordion,
  Audited: AuditedAccordion,
};
