import React from "react";
import { AuthProvider } from "./auth";
import { ProjectProvider } from "./project";
import { ProjectProviderNew } from "./project.context";
import { RsvpProvider } from "./rsvp.context";
import { BudgetProvider } from "./projectbudgets.context";
type ProviderProps = {
  children: React.ReactNode;
};
const AppProvider: React.FC<ProviderProps> = ({ children }) => {
  return (
    <AuthProvider>
      <ProjectProviderNew>
        <RsvpProvider>
          <BudgetProvider>
            {children}
          </BudgetProvider>
        </RsvpProvider>
      </ProjectProviderNew>

    </AuthProvider>
  );
};

export { AppProvider };
