import {
  Box,
  Card,
  CircularProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as PrevisionIcon } from "../../../assets/svg/project/prevision.svg";
import ProductionForm from "./ProductionForm";
import { VerticalTab } from "../../VerticalTabs";
import { NotificationFields } from "../../../types/notification";
import { useQuery, useMutation, useQueryClient } from "react-query";
import ProductionService from "../../../services/production.service";
import Notification from "../../UI/Notification";
import { productionDefaultValues } from "../../../types/production/enums";


const ProductionStep = () => {
  const [productionData, setProductionData] = useState(productionDefaultValues)
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get("projectId") || '';
  const [optionActive, setOptionActive] = useState(0);

  const [notification, setNotification] = useState<NotificationFields>({
    open: false,
    severity: 'success',
    message: ''
  })
  function closeNotification() {
    setNotification({ ...notification, open: false })
  }
  const { isLoading } = useQuery({
    queryKey: [`production-data`],
    queryFn: () => ProductionService
      .get(projectId),
    onSuccess: (data) => {
      setProductionData(data)
    }
  })

  const { mutate: updateProduction, isLoading: isLoadingUpdate } = useMutation(
    (state: any) => ProductionService.update(state._id, state),
    {
      onSuccess: () => {
        setNotification({
          open: true,
          severity: 'success',
          message: 'Salvo com sucesso!'
        })
      },
      onError: (error) => {
        setNotification({
          open: true,
          severity: 'error',
          message: 'Não foi possível salvar'
        })
      }
    }
  );
  const { mutate: createProduction, isLoading: isLoadingCreate } = useMutation(
    (state: any) => ProductionService.create(state),
    {
      onSuccess: (data) => {
        setProductionData(data)
        setNotification({
          open: true,
          severity: 'success',
          message: 'Salvo com sucesso!'
        })
      },
      onError: (error) => {
        setNotification({
          open: true,
          severity: 'error',
          message: 'Não foi possível salvar'
        })
      }
    }
  );
  function onSubmit(form: any) {
    form._id ? updateProduction({ ...form, project: projectId }) : createProduction({ ...form, project: projectId })
  }
  const options = [
    {
      title: "Cadastro produção",
      description: "",
      icon: <PrevisionIcon />,
      template: <ProductionForm onSubmit={onSubmit} productionData={productionData} />,
    },
   
  ];


  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      pt={2}
      pb={4}
      bgcolor={"#F9FCFF"}
      flex={1}
      width={'100%'}
      overflow='auto'
    >
      <Notification notification={notification} closeNotification={closeNotification} />

      { isLoading ? (
        <Box
          display={"flex"}
          width={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={2}
          py={10}
          mt={10}
        >
          <Typography variant={"h6"}>Carregando...</Typography>
          <CircularProgress />
        </Box>
      ) : <Card
      sx={{
        display: "flex",
        mt: 1,
        mx: 5,
        boxShadow: "0px 4px 18px 0px #4B465C1A",
        maxWidth: 1480,
      }}
    >
    
        <>
          <VerticalTab.Root>
            {options.map(({ title, description, icon }, index) => (
              <VerticalTab.Item
                key={index}
                onClick={() => setOptionActive(index)}
              >
                <VerticalTab.Icon
                  icon={icon}
                  isActive={optionActive === index}
                />
                <VerticalTab.Content
                  title={title}
                  description={description}
                />
              </VerticalTab.Item>
            ))}
          </VerticalTab.Root>

          {options[optionActive] && options[optionActive].template}
        </>
      
    </Card>}
    </Box>
  );
};

export default ProductionStep;
