export enum PaymentMethodsEnum {
  CASH = 'Dinheiro',
  CARD = 'Cartão',
  INVOICED = 'Faturado',
}

export enum BillingEnum {
  WITHOUT_SIGNAL = 'Sem sinal',
  WITH_SIGNAL = 'Com sinal',
  // WITH_SIGNAL_CREDIT = 'Com sinal (crédito)',
}


export type FinancialFormType = {
  aerialMesh: boolean
  insurance: boolean;
  paymentMethod: string[]
  paymentMethodObs: string
  invoincing: string,
  invoincingObs: string
}

export enum FlightClass {
  ECONOMIC = 'Econômica',
  PREMIUM_ECONOMIC = 'Econ. premium',
  BUSINESS = 'Executiva',
  FIRST_CLASS = 'Primeira classe',
}
