import { ReactComponent as PreCongressImg } from "../../../assets/styled-icons/ProgressBarPreCongress.svg";
import { ReactComponent as DisplacementImg } from "../../../assets/styled-icons/ProgressBarDisplacement.svg";
import { ReactComponent as HotelImg } from "../../../assets/styled-icons/ProgressBarHotel.svg";
import { ReactComponent as CompanionImg } from "../../../assets/styled-icons/ProgressBarCompanion.svg";
import { ReactComponent as AerialImg } from "../../../assets/styled-icons/ProgressBarAerial.svg";
import { ReactComponent as TransferImg } from "../../../assets/styled-icons/ProgressBarTransfer.svg";
import { ReactComponent as SpeakersImg } from "../../../assets/styled-icons/ProgressBarSpeakers.svg";
import { ReactComponent as OthersImg } from "../../../assets/styled-icons/ProgressBarOthers.svg";
import { PreCongress } from "./PreCongress";
import { Displacement } from "./Displacement";
import { Accomodation } from "./Accomodation";
import { Companion } from "./Companion";
import { Aerial } from "./Aerial";
import { Transfer } from "./Transfer";
import { Speakers } from "./Speakers";
import { Others } from "./Others";
import { Box, Snackbar, Alert, Typography, CircularProgress, Card, Slide } from "@mui/material";
import ProjectStatusModal from "../../Project/templates/ProjectStatusModal";
import { VerticalTab } from "../../VerticalTabs";
import { useContext, useEffect, useState } from "react";
import { useRsvp } from "../../../contexts/rsvp.context";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../../contexts/auth";

const RSVPStep = () => {
  const {actionOptions} = useContext(AuthContext)
  const [optionActive, setOptionActive] = useState(0);
  const { loadRsvp, loadingProject, notification, closeNotification, showNotification, clearProject } = useRsvp()
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get("projectId");
  const handleSubmitNav = () => {
    if(optionActive === 7) return
    else setOptionActive(optionActive + 1)
  }
  const isDisabled = !actionOptions.createProjetos && !actionOptions.updateProjetos
  const options = [
    {
      title: "Briefing",
      description: "",
      icon: <PreCongressImg />,
      template: <PreCongress isDisabled={isDisabled} onSubmitNav={handleSubmitNav} />,
    },
    {
      title: "Deslocamento",
      description: "",
      icon: <DisplacementImg />,
      template: <Displacement isDisabled={isDisabled} onSubmitNav={handleSubmitNav} />,
    },
    {
      title: "Hospedagem",
      description: "",
      icon: <HotelImg />,
      template: <Accomodation isDisabled={isDisabled} onSubmitNav={handleSubmitNav} />,
    },
    {
      title: "Acompanhante",
      description: "",
      icon: <CompanionImg />,
      template: <Companion isDisabled={isDisabled} onSubmitNav={handleSubmitNav} />,
    },
    {
      title: "Aéreo/Seguro Viagem",
      description: "",
      icon: <AerialImg />,
      template: <Aerial isDisabled={isDisabled} onSubmitNav={handleSubmitNav} />,
    },
    {
      title: "Transfer",
      description: "",
      icon: <TransferImg />,
      template: <Transfer isDisabled={isDisabled} onSubmitNav={handleSubmitNav} />,
    },
    {
      title: "Palestrantes/Speakers/Convidados VIPs",
      description: "",
      icon: <SpeakersImg />,
      template: <Speakers isDisabled={isDisabled} onSubmitNav={handleSubmitNav} />,
    }, {
      title: "Outros",
      description: "",
      icon: <OthersImg />,
      template: <Others isDisabled={isDisabled} onSubmitNav={handleSubmitNav} />,
    },
  ]
  useEffect(() => {
    if (projectId) loadRsvp(projectId);

    return () => {
      clearProject();
    };
  }, []);
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      pt={2}
      pb={4}
      bgcolor={"#F9FCFF"}
      flex={1}
      width={'100%'}
      overflow='auto'
    >
      {notification.open && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={6000}
          open={notification.open}
          onClose={closeNotification}
          TransitionComponent={(props) => <Slide {...props} direction="left" />}
        >
          <Alert
            onClose={closeNotification}
            severity={notification.severity}
            sx={{ width: "100%" }}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      )}


{loadingProject ? (
        <Box
          display={"flex"}
          width={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={2}
          py={10}
          mt={10}
        >
          <Typography variant={"h6"}>Carregando...</Typography>
          <CircularProgress />
        </Box>
      ) :  <Card
        sx={{
          display: "flex",
          mt: 1,
          mx: 5,
          boxShadow: "0px 4px 18px 0px #4B465C1A",
          maxWidth: 1480,
        }}
      >
        <VerticalTab.Root>
          {options.map(({ title, description, icon }, index) => (
            <VerticalTab.Item
              key={index}
              onClick={() => setOptionActive(index)}
            >
              <VerticalTab.Icon
                icon={icon}
                isActive={optionActive === index}
              />
              <VerticalTab.Content
                title={title}
                description={description}
              />
            </VerticalTab.Item>
          ))}
        </VerticalTab.Root>

        {options[optionActive] && options[optionActive].template}


      </Card>}
    </Box>
  );
}

export default RSVPStep