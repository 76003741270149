import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";

import { AuditService } from "../../../../services/audit.service";
import UserService from "../../../../services/user.service";
import { Input } from "../../../Input";
import ChangesList from "../ChangesList/ChangesList";

interface RequesterModalProps {
  isOpen: boolean;
  area: string;
  label: string;
  onClose: () => void;
}

const AuditModal = ({ isOpen, onClose, label, area }: RequesterModalProps) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get("projectId") || "";

  const [selectedUser, setSelectedUser] = useState("all");
  const { data: usersData } = useQuery({
    queryKey: ["users-data"],
    queryFn: () => UserService.allUsers(),
  });

  const { data: auditData } = useQuery({
    queryKey: ["audit-data", selectedUser],
    queryFn: () =>
      AuditService.getByProjectAndArea({
        project: projectId,
        area,
        user: !selectedUser || selectedUser == "all" ? null : selectedUser,
      }),
  });

  return (
    <Dialog onClose={onClose} open={isOpen} fullWidth maxWidth={"lg"}>
      <DialogTitle sx={{ m: 0, px: 4, paddingTop: 3, color: "#1361A4" }}>
        Auditoria - {label}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 16,
          top: 16,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <IoMdClose />
      </IconButton>
      <DialogContent sx={{ px: 4, marginTop: -4 }}>
        <Box display={"flex"} flexDirection={"column"} height={"60vh"}>
          <Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"flex-end"}
              gap={2}
              width={"70%"}
              padding={1}
            >
              {/* <Input disabled placeholder="Buscar por campos" InputProps={{
                endAdornment: <InputAdornment position="end" ><FaSearch /> </InputAdornment>
              }} /> */}
              <FormControl sx={{ width: "40%" }}>
                <InputLabel size="small" id="filter-user">
                  Usuário
                </InputLabel>
                <Select
                  value={selectedUser}
                  onChange={(e: any) => {
                    setSelectedUser(e.target.value);
                  }}
                  labelId="filter-status"
                  label="Usuário"
                  size="small"
                >
                  <MenuItem value={"all"}>Todos</MenuItem>

                  {usersData
                    ?.filter((user: any) => user.active)
                    .map((user: any) => (
                      <MenuItem key={user._id} value={user._id}>
                        {user.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <ChangesList data={auditData || []} />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AuditModal;
