import { FormControlLabel, Radio, FormControlLabelProps } from "@mui/material";
import React from "react";

interface Props {
  value: string;
  label: string;
  disabled?: boolean;
}

const RadioGroupItem = ({ value, label, disabled = false }: Props) => {
  return (
    <FormControlLabel
      disabled={disabled}
      value={value}
      label={label}
      control={<Radio />}
      sx={{
        "& span.MuiTypography-root": {
          fontSize: 14,
        },
      }}
    />
  );
};

export default RadioGroupItem;
