import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import getValidationErrors from "../../services/getValidationErrors";
import CustomModal from "../CustomModal";
import { NewBudgetDiv } from "./styles";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { ProjectContext } from "../../contexts/project";
import { GiCancel } from "react-icons/gi";
import { IStatusData } from "../../types/briefing";
import { AuthContext } from "../../contexts/auth";
import { BRIEFING_STATUS_OPTIONS } from "../../utils/briefingStatus";
import { format } from "date-fns";
import BriefingService from "../../services/briefing.service";

const STATUS_INITIAL_STATE = {
  id: "",
  briefingId: "",
  status: "",
  conclusionDate: "",
  justification: "",
};

export function StatusModal(props: any) {
  const { briefingId } = props;
  const { setIsLoading } = useContext(ProjectContext);
  const { user } = useContext(AuthContext);
  const [selectedStatus, setSelectedStatus] = useState({
    text: "",
    value: "",
  });
  useEffect(() => {
    if (props.status) {
      setSelectedStatus({ text: "", value: props.status.type });
      setStatus({
        id: props.status._id,
        status: props.status.type,
        justification: props.status.justification,
        conclusionDate: props.status.estimated_completion_date,
      });
    }
  }, []);

  const [status, setStatus] = useState<IStatusData>(STATUS_INITIAL_STATE);
  async function submitHandler() {
    try {
      setIsLoading(true);
      const schema = Yup.object().shape({
        status: Yup.string()
          .required("O campo 'Status' é obrigatório")
          .nullable(),
        conclusionDate: Yup.string()
          .required("O campo 'Data de previsão' é obrigatório")
          .nullable(),
        justification: Yup.string()
          .required("O campo 'Justificativa' é obrigatório")
          .nullable(),
      });
      await schema.validate(status, { abortEarly: false });

      const statusData = {
        briefing_id: briefingId,
        type: status.status,
        date_hour: format(new Date(), "yyyy-MM-dd HH:mm"),
        estimated_completion_date: status.conclusionDate,
        justification: status.justification,
      };

      await BriefingService.updateStatus(statusData);

      setStatus(STATUS_INITIAL_STATE);
      toast.success("Status atualizado com sucesso!");
      props.handleClose("addStatus");
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        Object.values(errors).map((error) => {
          toast.error(error);
        });
      }
      toast.error(err.message);
    }
    setIsLoading(false);
  }
  function typesRender(typesArray: any[], textKey: string) {
    return typesArray.map((el) => {
      return (
        <MenuItem value={el.value} key={Math.random()}>
          {el[textKey]}
        </MenuItem>
      );
    });
  }
  const { t } = useTranslation();

  function triggerStatusChange(e: any) {
    setSelectedStatus({
      text: "",
      value: e.target.value,
    });
    setStatus({ ...status, status: e.target.value });
  }

  return (
    <CustomModal
      isOpen={props.isOpen}
      handleClose={props.handleClose}
      target="statusModal"
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <h3 style={{ color: "#1361A4" }}>Alterar Status</h3>
        <div style={{ cursor: "pointer", paddingTop: "20px" }}>
          <GiCancel
            size={20}
            color="#444444"
            onClick={() => {
              props.handleClose("statusModal");
            }}
          />
        </div>
      </div>

      <NewBudgetDiv>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Selecione o Status
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            MenuProps={{
              style: {
                maxHeight: 450,
              },
            }}
            label="Selecione o Status"
            size="small"
            value={selectedStatus.value}
            onChange={triggerStatusChange}
          >
            {typesRender(BRIEFING_STATUS_OPTIONS, "text")}
          </Select>
        </FormControl>
      </NewBudgetDiv>
      <NewBudgetDiv>
        <TextField
          fullWidth
          value={format(new Date(), "yyyy-MM-dd HH:mm")}
          disabled={true}
          variant="outlined"
          label="Data / hora alteração"
          size="small"
          type="datetime-local"
          inputProps={{ min: "2000-01-24", max: "2050-05-31" }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          fullWidth
          value={status.conclusionDate}
          variant="outlined"
          label="Data previsão de conclusão"
          onChange={(e) => {
            setStatus({ ...status, conclusionDate: e.target.value });
          }}
          size="small"
          type="date"
          inputProps={{ min: "2000-01-24", max: "2050-05-31" }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          fullWidth
          value={""}
          variant="outlined"
          label="Previsão de dias do Status"
          size="small"
          disabled
        />
      </NewBudgetDiv>
      <NewBudgetDiv>
        <TextField
          fullWidth
          value={user.name}
          disabled
          variant="outlined"
          label="Alterado por:"
          size="small"
        />
      </NewBudgetDiv>
      <NewBudgetDiv>
        <TextField
          fullWidth
          value={status.justification}
          onChange={(e: any) => {
            setStatus({ ...status, justification: e.target.value });
          }}
          variant="outlined"
          label="Justificativa"
          size="small"
        />
      </NewBudgetDiv>
      <NewBudgetDiv>
        <div style={{ margin: "auto" }}>
          <Button
            onClick={submitHandler}
            type="button"
            id="addHotel"
            size="small"
            style={{
              background: "#1361A4",
              color: "#FFFFFF",
              width: "200px",
            }}
          >
            {t("save")}
          </Button>
        </div>
      </NewBudgetDiv>
    </CustomModal>
  );
}
