import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Button,
  Switch,
} from "@mui/material";
import { InputDiv, SummaryDiv, RadioDiv, StyledBox } from "../styles";
import { useEffect, useState } from "react";
import { Input } from "../../../Input";
import { useRsvp } from "../../../../contexts/rsvp.context";
import { DisplacementFieldsType, RsvpType } from "../../../../types/rsvp/index";
import { FormProvider, useForm } from "react-hook-form";

type Props = {
  onSubmitNav: () => void
  isDisabled: boolean
}

export function Displacement({onSubmitNav, isDisabled}: Props) {
  const { rsvpId, updateDisplacement, createRsvp, displacement } = useRsvp()
  const obj: DisplacementFieldsType = {
    airportExchange: '',
    parking: '',
    vehicleAuthInfo: '',
    gasOrTollRefund: '',
    airportExchangeObs: '',
    parkingObs: '',
    vehicleAuthObs: '',
    gasOrTollRefundObs: '',
  }
  const methods = useForm({
    defaultValues: obj,
  })
  const { handleSubmit, register, watch, setValue, reset } = methods
  const [isSwitchOn, setSwitchOn] = useState(false);
  const [observation, setObservation] = useState('');

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setSwitchOn(checked);
  };

const handleObservationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setObservation(event.target.value);
};

  function onSubmit(form: DisplacementFieldsType) {
    rsvpId ? updateDisplacement(form) : createRsvp({ displacement: form } as RsvpType)
    onSubmitNav()
  }

  useEffect(() => {
    if (displacement) {
      reset(displacement);
    }
  }, [displacement]);



  return (<FormProvider {...methods}>
    
    <StyledBox  onSubmit={handleSubmit(onSubmit)} >
      <h3>2.Deslocamento</h3>
      <InputDiv>
        <SummaryDiv>
          <strong>Troca de aeroporto</strong>
          <br />
          <span>
            Como será o deslocamento(caso tenha reembolso confirmar em RSVP e
            alinhar com convidado)
          </span>
        </SummaryDiv>
        <RadioDiv>
          <FormControl>
            <RadioGroup
              value={watch("airportExchange")}
              onChange={(e) => setValue("airportExchange", e.target.value)}
            >
              <FormControlLabel
                value="TRANSFER_PRE_APPROVED"
                control={<Radio />}
                label="Transfer pré aprovado, por conta do cliente"
              />
              <FormControlLabel
                value="TRANSFER_ONLY_UPON_REQUEST"
                control={<Radio />}
                label="Transfer somente se o convidado solicitar e mediante aprovação do cliente"
              />
              <FormControlLabel
                value="GUEST_BEARING_EXPENSES_NON_REFUNDABLE"
                control={<Radio />}
                label="Por conta do convidado e sem direito a reembolso"
              />
              <FormControlLabel
                value="GUEST_BEARING_EXPENSES_WITH_REFUND"
                control={<Radio />}
                label="Por conta do convidado e com direito a reembolso"
              />
              <FormControlLabel
                value="NOT_APPLICABLE"
                control={<Radio />}
                label="Não se aplica a esse evento"
              />
            </RadioGroup>
          </FormControl>
          <Input
            {...register('airportExchangeObs')}
            label="Outros"
            multiline
            size="small"
          />
        </RadioDiv>
      </InputDiv>
      <InputDiv>
        <SummaryDiv>
          <strong>Estacionamento por conta do cliente?</strong>
          <br />
          <span>
            Caso vá com veículo próprio, sendo da capital ou do interior.
          </span>
        </SummaryDiv>
        <RadioDiv>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <FormControlLabel
              control={<Switch checked={isSwitchOn} onChange={handleSwitchChange} />}
              label={isSwitchOn ? 'Sim - por conta do cliente' : 'Não'}
            />
            {isSwitchOn && (
              <Input
                value={observation}
                onChange={handleObservationChange}
                placeholder="Observações"
                multiline
                size="small"
                style={{ width: '158%' }}
              />
            )}
          </div>
        </RadioDiv>
      </InputDiv>
      <InputDiv>
        <SummaryDiv>
          <strong>Informações de autorização de entrada do veículo</strong>
          <br />
          <span>
            Caso vá com veículo próprio, sendo da capital ou do interior.
          </span>
        </SummaryDiv>
        <RadioDiv>
          <FormControl>
            <RadioGroup
              value={watch("vehicleAuthInfo")}
              onChange={(e) => setValue("vehicleAuthInfo", e.target.value)}
            >
              <FormControlLabel
                value="ONLY_VEHICLE_TYPE_AND_PLATE"
                control={<Radio />}
                label="Sim - apenas tipo do veículo e placa"
              />
              <FormControlLabel
                value="VEHICLE_TYPE_PLATE_NAME_RG"
                control={<Radio />}
                label="Sim - tipo do veículo, placa, nome e RG do motorista"
              />
              <FormControlLabel
                value="NOT_NEEDED"
                control={<Radio />}
                label="Não é necessário"
              />
            </RadioGroup>
          </FormControl>
          <Input
            {...register('vehicleAuthObs')}
            label="Outros"

            multiline
            size="small"
          />
        </RadioDiv>
      </InputDiv>
      <InputDiv>
        <SummaryDiv>
          <strong>Reembolso de pedágio e ou gasolina</strong>
          <br />
          <span>
            Procedimentos informados em 8.Outros detalhados para confirmação no
            RSVP
          </span>
        </SummaryDiv>
        <RadioDiv>
          <FormControl>
            <RadioGroup
              value={watch("gasOrTollRefund")}
              onChange={(e) => setValue("gasOrTollRefund", e.target.value)}
            >
              <FormControlLabel
                value="UPON_PRESENTATION_OF_DOCUMENTS"
                control={<Radio />}
                label="Sim - mediante a apresentação de comprovantes(informar em 8.Outro) "
              />
              <FormControlLabel
                value="NO"
                control={<Radio />}
                label="Não"
              />
            </RadioGroup>
          </FormControl>
          <Input
            {...register('gasOrTollRefundObs')}
            label="Outros"

            multiline
            size="small"
          />
        </RadioDiv>
      </InputDiv>

      <Button
      disabled={isDisabled}
        variant="contained"
        
        style={{ marginLeft: "90%" }}
        type="submit"
      >
        Salvar
      </Button>
    </StyledBox>
  </FormProvider>
  );
}
