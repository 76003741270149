import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material"
import { createOptionsFromEnum } from "../../../../../utils/formatters"
import { BudgetSummaryActionOptions } from "../../../../../types/budget/budget"
import { FaEllipsisV, FaEye, FaTrashAlt } from "react-icons/fa"

type Props = {
  triggerAction(action: 'edit' | 'delete'): void
}
const ActionSelect: React.FC<Props> = ({triggerAction}: Props) => {

  return <Box sx={{ minWidth: 80, display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }} >
    <FaEye style={{cursor: 'pointer'}} color="#667085" onClick={()=>{triggerAction('edit')}}/>
    {/* <FaTrashAlt style={{cursor: 'pointer'}} color="#667085" onClick={()=>{triggerAction('delete')}}/> */}
    

  </Box>
}

export default ActionSelect