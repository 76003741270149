export const TRANSLATIONS_PT = {
  sim: "Sim",
  valueOptions: "Valor / %",
  convertedValue: "Valor Convertido",
  issHelper: "Valor ISS",
  nao: "Não",
  search: "Buscar",
  value: "Valor",
  observations: "Observações",
  description: "Descrição",
  materialType: "Tipo Material",
  fileName: "Nome do Arquivo",
  save: "Salvar",
  address: "Endereço",
  quantity: "Quantidade",
  checkIn: "Data Entrada",
  checkOut: "Data Saída",
  options: "Opções",
  dataVoo: "Data do vôo",
  horaVoo: "Hora do vôo",
  radioErrorMessage: "Selecione sim ou não",
  createSuccess: "Criado com sucesso!",
  editedSuccess: "Editado com sucesso!",
  services: "Serviços",
  serviceTypes: "Tipos de Serviço",
  open: "Abrir",
  valueType: "Tipo de valor",
  currency: "Moeda",
  taxes: "Taxas",
  loadServices: "Visualizar Serviços",
  totalValue: "Valor Total",
  taxesHelper: "Selecione Tipos de Taxas",
  mmTaxesHelper: "Taxas MM Eventos",
  totalCostWithTaxes: "Custo Total",
  totalCostConverted: "Custo Total convertido",
  exchange: "Câmbio",
  attendance: "Atendimento / Comercial",
  eventCoordinator: "Coordenador do evento",
  location: "Local",
  city: "Cidade/UF",
  country: "País",
  site: "Site",
  subtotalCostPrevisio: "Previsão de custo",
  accommodationPeriodIn: "Início período de acomodação",
  accommodationPeriodOut: "Fim período de acomodação",
  login: {
    header: "Entrar",
    email: "Email ou nome de usuário",
    senha: "Senha",
    button: "Entrar",
    register: "Cadastre-se!",
    registerMessage: `Obrigado por querer se cadastrar ! 
    Ainda estamos em processo de desenvolvimento, por favor para se cadastrar entre em contato com o nossa central de atendimento no número: (xx) xxxx-xxxx e realizaremos todo processo de cadastro em nosso banco de clientes.`,
    esqueceu: "Esqueceu?",
  },
  forgot: {
    header: "Esqueci minha senha ",
    helper:
      "Para redefinir sua senha, informe o usuário ou email cadastrado na sua conta e lhe enviaremos um link com as intruções.",
    email: "Email ou nome de usuário",
    submitButton: "Enviar",
    cancelButton: "Cancelar",
    submitHeader: "Solicitação de redefinição de senha executada!",
    submitHelper:
      "Foi enviado para seu e-mail um link, para redefirnir sua senha. Esteja atento as instruções no corpo do e-mail para concluir o o processo de recuperação de senha.",
  },
  mainNavBar: {
    positions: {},
  },
  mainSideBar: {
    init: "Início",
    activities: "Atividades",
    newBriefing: "Novo Briefing",
    budgets: "Orçamentos",
    clients: "Clientes",
    reports: "Relatórios",
    myData: "Meus Dados",
    documents: "Documentos",
    audit: "Auditoria",
    settings: "Configurações",
    exit: "Sair",
  },
  briefingNavBar: {
    initialBriefing: "Briefing Inicial",
    creative: "Criativo",
    logistics: "Logística",
    aerial: "Aéreo",
    rsvp: "RSVP",
    budget: "Orçamento",
    negociation: "Negociação",
    audit: "Auditoria ",
    production: "Produção",
  },
  briefingPage: {
    greeting: "Olá",
    welcomeMesssage: "Seja Bem-vindo a planilha de novo briefing!",
  },
  initialBriefingForm: {
    customerRegisterData: {
      header: "Cliente",
      companyName: "Nome da Empresa",
      document: "CNPJ",
      applicantName: "Nome Solicitante",
      contact: "Telefone",
      email: "E-mail",
    },
    briefingData: {
      header: "Dados do Briefing",
      eventName: "Nome do Evento",
      eventType: "Tipo de Evento",
      delivery: "Entrega",
      briefing: "Briefing",
      presentation: "Apresentação",
      customerInputs: "Insumos Cliente.",
      inputTypes: "Tipos de Insumos",
      competition: "É uma concorrência?",
      competitionPlaceholder: "Quantas e quais agencias envolvidas?",
      projectArea: "O projeto está em nossa área de atuação?",
    },
    financialInformation: {
      header: "Informações Financeiras",
      budget: "Qual budget definido?",
      paymentDate: "Data Final",
      suppliers: "Fornecedores extras.",
      fees: "Honorários praticados",
      advance: "Adiantamento",
    },
    projectData: {
      header: "Dados do Evento",
      eventResponsible: "Responsável do evento",
      eventType: "Formato de Evento",
      initialDate: "Data Inicial",
      finalDate: "Data de Vencimento",
      initialHour: "Hora de início",
      finalHour: "Hora de término",
      eventFormat: "Formato do Evento",
      public: "Público",
      profile: "Perfil",
      guestAmount: "N° Pessoas",
      streamed: "Será Transmitido?",
      platformTypes: "Tipos de Plataformas",
      eventDuration: "Duração do Evento",
      eventObjective: "Objetivos do Evento",
      customerChallenges: "Desafios do Cliente",
    },
  },
  creativeForm: {
    header: "Cadastro Criação",
    theme: "Teria KV, nome ou tema?",
    directional: "Direcional",
    keys: "Key Messages",
    art: "Precisa criar arte?",
    ownKV: "Possui KV próprio para envio?",
    ownKVObs: `Obs.: Em caso de KV criado pelo próprio cliente ou outra agência,
            é importante informar que precisamos do arquivo aberto para desdobrar as peças solicitadas. 
            KV feito no Canvas e envio de imagens de banco de imagens, não são kvs e gera retrabalho para a equipe. `,
    contentResponsibility: "Responsabilidade do conteúdo:",
    art2: "Precisa desdobrar alguma arte?",
    d3: "Precisa criar 3D?",
    scenographyExpense: "Expectativa de gasto com cenografia:",
    options: "Necessidade de apresentar mais de uma opção?",
    client: "Cliente",
    agency: "Agência",
    both: "Cliente e Agência",
    invalidFields: {
      has_theme: "O campo 'Tema' é obrigatório",
      create_art: "O campo 'Criar arte' é obrigatório",
      own_delivery: "O campo 'KV próprio para envio' é obrigatório",
      content_responsability:
        "O campo 'Responsabilidade do conteúdo' é obrigatório",
      unfolder_art: "O campo 'Desdobra arte' é obrigatório",
      is_3d: "O campo '3D' é obrigatório",
      more_one_option: "O campo 'Mais de uma opção' é obrigatório",
    },
  },
  producaoForm: {
    header: "Produção",
    decoration: "Diversos - Decoração",
    scenography: "Cenografia",
    scenographyCompany: "Produtora",
    gifts: "Possui brindes?",
    activation: "Possui Ativações?",
    physycalMaterial: "Necessidade de material fisico para os convidados?",
    directional: "Qual direcional?",
    material: "Material",
    logistics: "Logistica",
    musicShow: "Possui Atração Musical?",
    musicShowObs: "Informar qual ou algum genero específico",
    speakers: "Possui Speaker? MC’s ou Palestrantes.",
    speakersObs: "Informar atrelado ao conceito",
    residual: "Necessidade de algum residual pós evento?",
    residualObs: "Informar quantos e quais.",
    equipment: "Equipamentos:",
    equipmentObs: "Informar quantos e quais.",
    generalObs:
      "Obs.: Em caso de dentro da convenção termos um lançamento de um produto ou termos entrega de ipads ou presidente não estará presente mas fará a abertura através de transmissão, favor pontuar essas informações dentro deste campo.",
  },
  aereoForm: {
    header: "Cadastro Aéreo",
    aereo: "Possui Aéreo?",
    passengersAmount: "N° Passageiros",
    eventCity: "Cidade do Evento",
    eventLocation: "Local do Evento",
    origin: "Informar Origem",
    destination: "Informar Destino",
    origin2: "Informar Origem de volta",
    destination2: "Informar Destino de volta",
    airportPreferences: "Preferencia de aéroporto",
    compliance: "Compliance",
    flightType: "Tipos de Voos",
    scales: "Escalas",
    ticketsType: "Tipos Passagens",
    defaultAirCompany: "Possui CIA aérea de preferência?",
    defaultAirCompanyName: "Informar qual",
    meetings: "Terá reunião no dia da chegada e saída?",
    meetingsSchedule: "Informar horários",
    defaultPaymentMethod: "Forma de Pagamento, a mesma para o evento?",
    paymentMethod: "Formas de Pagamento",
    fields: "Tipos de Campos",
    fieldsHelper: "Campos Gerenciais",
    data: "Dados",
    insurance: "Seguro Viagem?",
    savedTransfers: "Trechos Salvos",
    saveTransfer: "Salvar Trecho",
    invalidFields: {
      has_aerial: "O campo 'Possui cadastro aéreo' é obrigatório",
      has_preference_cia: "O campo 'Possui CIA' é obrigatório",
      has_meeting_on_arrival_and_departure_day:
        "O campo 'Forma de pagamento' é obrigatório",
      has_same_payment_method_as_the_event:
        "O campo 'Seguro aéreo' é obrigatório",
    },
  },
  logisticaForm: {
    header: "Cadastro Logística",
    specificKPI: "Quais os KPIs especifos que o cliente gostaria de ter?",
    accommodation: "Possui hospedagem?",
    accommodationHotel: "Nome Hotel",
    accommodationGuestNumber: "Quantidade de Hospedes",
    meetingsRoom: "Possui salas de reuniões ou apoio?",
    meetingsRoomGuestNumber: "N° Pessoas",
    meetingsRoomsNumber: "N° de Salas",
    webConnection: "Diversos - Ponto de Internet",
    webConnectionQtd: "Quantidade de Pontos",
    webConnectionSpeed: "Velocidade",
    parking: "Diversos - Estacionamento",
    aeb: "Alimentos e Bebidas.",
    equipments: "Possui Equipamentos?",
    equipmentsObs: "Quais equipamentos",
    admin: "Possui Administradora?",
    adminType: "Tipo de cordenação",
    transfers: "Diversos - Tranfers",
  },
  rsvpForm: {
    header: "Cadastro RSVP",
    rsvpActive: "RSVP ATIVO - Seg a Sex 8h15 as 18h",
    saveTheDate: "Envio de Save The Date",
    reminder: "Envio de Reminder",
    acknowledgment: "Envio de Agradecimento",
  },
  auditForm: {
    header: "Registro de acesso",
    creation: "Data de criação",
    user: "Usuário",
    userName: "Nome do Usuário",
    info: "Informações",
  },
  budget: {
    communications: "Comunicação",
    budgetName: "Nome do orçamento",
    mmNumber: "Número MM",
    responsible: "Responsável",
    responsibleEmail: "Email do Responsável",
    clientName: "Nome do cliente",
    status: "Status",
    creationDate: "Data de criação",
    listBudgets: "Listar orçamentos",
    addBudget: "Adicionar orçamento",
    savedBudgets: "Todos Orçamentos",
    addCoin: "Adicionar moeda",
    savedCoins: "Todas as moedas",
    savedHotels: "Todos os hoteis",
    hotel: "Hospedagens",
    rooms: "Salas",
    equipments: "Equipamentos",
    aeb: "Alimentos e Bebidas",
    translation: "Tradução Simultanea",
    signIn: "Inscrição",
    rsvp: "RSVP",
    aerial: "Aéreo",
    transfer: "Transfer",
    diversos: "Diversos",
    supportTeam: "Equipes de Suporte",
    serviceTypes: "Tipos de Serviço",
    valueType: "Tipo de valor",
    currency: "Moeda",
    taxes: "Taxas",
    suppliers: "Fornecedores",
    daysAmount: "Diárias",
    guestAmount: "QTE",
    addSupplier: "Adicionar Fornecedor",
    serviceDescription: "Descritivo",
  },
  supplier: {
    location: "Local",
    city: "Cidade/UF",
    country: "Páis",
    accomodationPeriod: "Período de Hospedagem",
    meetingPeriod: "Período de Reunião",
    mainRoomArea: "Metragem, sala Principal // Pé Direito",
    disponibility: "Disponibilidade",
    negociation: "Negociação",
    website: "Site",
    supplierName: "Nome do Fornecedor",
  },
  simpleTax: {
    default: "Tarifa",
    service: "Taxa de Serviço",
    local: "Imposto Local",
    iof: "IOF",
    mm: "Taxa MM(FEE)",
    nfMM: "Tipo de NF MM",
    turism: "Taxa Turismo",
  },
};
