import { TableCell } from "@mui/material";
import styled from "styled-components";

export const HeaderCell = styled.div`
    display: flex;
    align-items:center;
    font-weight: 600;
    color:#4B465C;
    justify-content: space-between;
    `
export const InfoDiv = styled.div`
    position: absolute;
    margin: auto;
    top: 40%;
    left: 55%;
    font-size: 1.2rem;
`