import {
  Box,
  Card,
  CircularProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { VerticalTab } from "../../../components/VerticalTabs";
import { ReactComponent as ClientIcon } from "../../../assets/svg/project/client.svg";
import { useProject } from "../../../contexts/project.context";
import Booth from "./templates/Booth/Booth";

const BoothStep = () => {
  const { loadingProject} = useProject();
  const [optionActive, setOptionActive] = useState(0);

  const options = [
    {
      title: "Informações de Estande",
      description: "",
      icon: <ClientIcon />,
      template: <Booth />,
    },
  ];


  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      pt={2}
      pb={4}
      bgcolor={"#F9FCFF"}
      flex={1}
      width={'100%'}
      overflow='auto'
    >
      

      {loadingProject && (
        <Box
          display={"flex"}
          width={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={2}
          py={10}
          mt={10}
        >
          <Typography variant={"h6"}>Carregando...</Typography>
          <CircularProgress />
        </Box>
      )}

      <Card
        sx={{
          display: "flex",
          mt: 1,
          mx: 5,
          boxShadow: "0px 4px 18px 0px #4B465C1A",
          maxWidth: 1480,
        }}
      >
        {!loadingProject && (
          <>
            <VerticalTab.Root>
              {options.map(({ title, description, icon }, index) => (
                <VerticalTab.Item
                  key={index}
                  onClick={() => setOptionActive(index)}
                >
                  <VerticalTab.Icon
                    icon={icon}
                    isActive={optionActive === index}
                  />
                  <VerticalTab.Content
                    title={title}
                    description={description}
                  />
                </VerticalTab.Item>
              ))}
            </VerticalTab.Root>

            {options[optionActive] && options[optionActive].template}
          </>
        )}
      </Card>
    </Box>
  );
};

export default BoothStep;
