import { Box, InputLabel } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

interface Props {
  name: string;
  label: string;
  control?: any;
  error?: string;
  onBlur?:() => void
}

const DatePickerForm: React.FC<Props> = ({ name, label, error, onBlur }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ...restField } }) => (
        <Box width={"100%"}>
          <InputLabel sx={{ fontSize: 13 }} error={!!error}>
            {label}
          </InputLabel>

          <DatePicker
            label={""}
            onChange={(event) => onChange(event)}
            {...restField}
            slotProps={{
              textField: {
                error: !!error,
                helperText: error,
                FormHelperTextProps: { sx: { marginLeft: 0 } },
                onBlur: onBlur
              },
            }}
          />
        </Box>
      )}
    />
  );
};

export default DatePickerForm;
