import styled from "styled-components";
import { Card } from "../../Card";
import { Box } from "@mui/material";

export const Container = styled.form`
  display: flex;
  flex-direction: column;
`;

export const StyledBox = styled.form`
  max-height: 65vh;
  min-height: 500px;
  overflow: auto;
  padding: 10px;
  width: 90%;
  overflow-x: hidden;
`
export const OptionsDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-evenly;
  width: 50%;
  cursor: pointer;
`;

export const IconDiv = styled.div`
  margin: auto;
  display: flex;
  width: 40%;
  height: 50px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  border: 1px solid #ccc;
  div:last-child {
    border: 0;
  }
`;
export const ColDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 50px;
  align-items: center;
  justify-content: center;
  border-right: 1px #ccc solid;
  width: 100%;
  span {
    color: #333333;
    font-size: 14px;
  }
`;

export const StyledCard = styled(Card)`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;

  strong {
    width: 30%;
  }
  box-shadow: none;
  background: #fff;
`;

export const BriefingNav = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BriefingContent = styled.div``;

export const InputDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  align-items: baseline;
  justify-content: flex-start;
  padding: 5px 0;
  border-bottom: 1px solid #eee;
`;
export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
  padding: 5px 0;
  div {
    margin-right: 5px;
  }
  span {
    font-size: 12px;
  }
`;
export const RadioDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: baseline;
  justify-content: flex-start;
  span {
    font-size: 14px;
  }
`;
export const SummaryDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  align-items: baseline;
  justify-content: flex-start;
  margin-right: 5px;
  span {
    font-size: 12px;
    width: 80%;
    color: #444;
  }
  strong {
    font-size: 14px;
    width: 100%;
  }
`;
