export const TRANSLATIONS_ES = {
  sim: "Si",
  nao: "No",
  valueOptions: "Valor / %",
  convertedValue: "Valor Convertido",
  issHelper: "Valor ISS",
  search: "Buscar",
  value: "Valor",
  observations: "Observaciones",
  description: "Descripción",
  open: "Abrir",
  save: "Guardar",
  address: "Dirección",
  quantity: "Cantidad",
  checkIn: "Check In",
  checkOut: "Salida",
  options: "Opciones",
  dataVoo: "Fecha de vuelo",
  horaVoo: "Hora de Vuelo",
  radioErrorMessage: "Seleccione sí o no",
  createSuccess: "creado con éxito!",
  services: "Servicios",
  serviceTypes: "Tipos de servicio",
  valueType: "Tipo de valor",
  currency: "Moneda",
  taxes: "Impuestos",
  loadServices: "Ver servicios",
  totalValue: "Valor total",
  taxesHelper: "Seleccionar tipos de impuestos",
  mmTaxesHelper: "Impuestos de eventos MM",
  totalCostWithTaxes: "Costo total + impuestos",
  exchange: "Cambio",
  totalCostConverted: "Costo Total convertido",
  location: "Local",
  city: "Ciudad/UF",
  country: "País",
  site: "Site",
  login: {
    header: "Entrar",
    email: "Correo electrónico o nombre de usuario",
    senha: "Contraseña",
    button: "Entrar",
    register: "Registrar",
    esqueceu: "¿Olvidó?",
  },
  forgot: {
    header: "Olvidé mi contraseña",
    helper:
      "Para restablecer su contraseña, ingrese el nombre de usuario o la dirección de correo electrónico registrada en su cuenta y le enviaremos un enlace con instrucciones.",
    email: "Correo electrónico o nombre de usuario",
    submitButton: "Enviar",
    cancelButton: "Cancelar",
    submitHeader: "¡Solicitud de restablecimiento de contraseña ejecutada!",
    submitHelper:
      "Se ha enviado un enlace a su correo electrónico para restablecer su contraseña. Tenga en cuenta las instrucciones en el cuerpo del correo electrónico para completar el proceso de recuperación de contraseña.",
  },
  mainNavBar: {
    positions: {},
  },
  mainSideBar: {
    init: "Início",
    activities: "Actividades",
    newBriefing: "Nuevo Informe",
    budgets: "Presupuestos",
    clients: "Clientes",
    reports: "Informes",
    myData: "Mis Datos",
    documents: "Documentos",
    audit: "Auditoría",
    settings: "Configuración",
    exit: "Salir",
  },
  briefingNavBar: {
    initialBriefing: "Informe inicial",
    creative: "Creativo",
    logistics: "Logística",
    aerial: "Aerio",
    rsvp: "RSVP",
    negotiation: "Negociación",
    budget: "Presupuesto",
    audit: "Auditoría",
    production: "Produção",
  },
  briefingPage: {
    greeting: "Hola",
    welcomeMesssage: "¡Bienvenido a la nueva hoja informativa!",
  },
  initialBriefingForm: {
    customerRegisterData: {
      header: "Registro de cliente",
      companyName: "Nombre de la empresa",
      document: "CNPJ",
      applicantName: "Nombre del solicitante",
      contact: "Teléfono",
      email: "Correo electrónico",
    },
    briefingData: {
      header: "Datos informativos",
      eventName: "Nombre del evento",
      eventType: "Formato de Evento",
      delivery: "Entrega",
      presentation: "Presentación",
      customerInputs: "Entradas del cliente.",
      inputTypes: "Tipos de entrada",
      competition: "¿Es una competición?",
      competitionPlaceholder: "¿Cuántas y cuáles agencias involucradas?",
      projectArea: "¿Está el proyecto en nuestra área de especialización?",
      fileName: "Nombre del archivo",
    },
    financialInformation: {
      header: "Información financiera",
      budget: "¿Tenemos un conjunto de presupuesto?",
      paymentDate: "Fecha Final",
      suppliers: "Proveedores adicionales.",
      fees: "Tarifas cobradas",
      advance: "Ventaja",
    },
    projectData: {
      header: "Datos del proyecto",
      eventResponsible: "Responsable del evento",
      eventType: "Formato de Evento",
      initialDate: "Fecha Inicial",
      finalDate: "Fecha de finalización",
      initialHour: "Hora de inicio",
      finalHour: "Hora de finalización",
      eventFormat: "Formato de Evento",
      public: "Público",
      profile: "Perfil",
      guestAmount: "N° Personas",
      streamed: "¿Se transmitirá?",
      platformTypes: "Tipos de Plataforma",
      eventDuration: "Duración del evento",
      eventObjective: "Objetivos del Evento",
      customerChallenges: "Desafíos del Cliente",
    },
  },
  creativeForm: {
    header: "Registro de creación",
    theme: "¿Tienes KV, nombre o tema?",
    directional: "Direccional",
    keys: "Mensajes clave",
    art: "¿Necesitas crear arte?",
    ownKV: "¿Tienes tu propio KV para cargar?",
    ownKVObs: `Nota: en caso de KV creado por el cliente u otra agencia,
        Es importante informar que necesitamos el archivo abierto para desplegar las partes solicitadas.
        Los KV hechos en Canvas y el envío de imágenes de stock, no son kvs y generan retrabajo para el equipo.`,
    contentResponsibility: "Responsabilidad del contenido:",
    art2: "¿Necesitas desplegar algo de arte?",
    d3: "¿Tiene 3D?",
    scenographyExpense: "Gastos de escenografía esperados:",
    options: "¿Necesitas presentar más de una opción?",
    client: "Cliente",
    agency: "Agencia",
    both: "Cliente y Agencia",
    invalidFields: {
      has_theme: "El campo 'Tema' es obligatorio",
      create_art: "El campo 'Crear arte' es obligatorio",
      own_delivery: "El campo 'KV propio para enviar' es obligatorio",
      content_responsability:
        "El campo 'Responsabilidad del contenido' es obligatorio",
      unfolder_art: "El campo 'Desplegar arte' es obligatorio",
      is_3d: "El campo '3D' es obligatorio",
      more_one_option: "El campo 'Más de una opción' es obligatorio",
    },
  },
  producaoForm: {
    header: "Produción",
    decoration: "Varios - Decoración",
    scenography: "Escenografía",
    scenographyCompany: "Productor",
    gifts: "¿Tienes regalos?",
    activation: "¿Tienes activaciones?",
    physycalMaterial: "¿Necesita material físico para invitados?",
    directional: "¿Qué direccional?",
    material: "Material",
    logistics: "Logistica",
    musicShow: "¿Tienes Atracción Musical?",
    musicShowObs: "Informar cual o algun genero especifico",
    speakers: `¿Tienes un altavoz? MC's o oradores.`,
    speakersObs: "Informar vinculado al concepto",
    residual: "¿Necesita algún residuo posterior al evento?",
    residualObs: "Informar cuantos y cuales.",
    equipment: "Equipo:",
    equipmentObs: "Informar cuantos y cuales.",
    generalObs:
      "Obs.: En caso de que dentro de la convención tengamos un lanzamiento de un producto o tengamos entrega de ipads o el presidente no estará presente pero hará la apertura a través de transmisión, por favor marque esta información en este campo.",
  },
  aereoForm: {
    header: "Registro de vuelo",
    aereo: "¿Tienes vuelo?",
    passengersAmount: "Número de pasajeros",
    eventCity: "Ciudad del evento",
    eventLocation: "Ubicación del evento",
    origin: "Informar Origen",
    destination: "Ingresar destino",
    origin2: "Ingresar origen atrás",
    destination2: "Ingresar destino atrás",
    airportPreferences: "Preferencia de aeropuerto",
    compliance: "Cumplimiento",
    flightType: "Tipos de vuelo",
    scales: "Escalas",
    ticketsType: "Tipos de entradas",
    defaultAirCompany: "¿Tiene una aerolínea preferida?",
    defaultAirCompanyName: "Informar cuál",
    meetings: "¿Habrá una reunión el día de llegada y salida?",
    meetingsSchedule: "Ingresar horarios",
    defaultPaymentMethod: "Método de pago, ¿el mismo para el evento?",
    paymentMethod: "Métodos de pago",
    fieldsHelper: "Campos de administración",
    fields: "Tipos de Campos",
    data: "Datos",
    insurance: "¿Seguro de viaje?",
    savedTransfers: "Tramos guardados",
    saveTransfer: "Guardar Tramo",
    invalidFields: {
      has_aerial: "El campo 'Tiene registro de aerolínea' es obligatorio",
      has_preference_cia: "El campo 'Tiene CIA' es obligatorio",
      has_meeting_on_arrival_and_departure_day:
        "El campo 'Método de pago' es obligatorio",
      has_same_payment_method_as_the_event:
        "El campo 'Seguro aéreo' es obligatorio",
    },
  },
  logisticaForm: {
    header: "Registro Logístico",
    specificKPI: "¿Qué KPI específicos le gustaría tener al cliente?",
    accommodation: "¿Tienes alojamiento?",
    accommodationHotel: "Nombre del hotel",
    accommodationGuestNumber: "Número de Huéspedes",
    meetingsRoom: "¿Tienen salas de reuniones o soporte?",
    meetingsRoomGuestNumber: "N° Personas",
    meetingsRoomsNumber: "Número de salas",
    webConnection: "Varios - Punto de Internet",
    webConnectionQtd: "Cantidad de puntos",
    webConnectionSpeed: "Velocidad",
    parking: "Varios - Estacionamiento",
    aeb: "Alimentos y Bebidas.",
    equipments: "¿Tienes algún equipo?",
    equipmentsObs: "Qué equipo",
    admin: "¿Tienes un administrador?",
    adminType: "Tipo de coordinación",
    transfers: "Varios - Transferencias",
  },
  rsvpForm: {
    header: "Registro RSVP",
    rsvpActive: "RSVP ACTIVO: de lun a vier de 8:15 a.m. a 6 p.m.",
    saveTheDate: "Envio guardar la fecha",
    reminder: "Envio recordatorio",
    acknowledgment: "Envio de Gracias",
  },

  auditFrom: {
    header: "Registro de acceso",
    creation: "Fecha de creación",
    user: "Usuario",
    userName: "Nombre de usuario",
    info: "Información",
  },
  budget: {
    communication: "Comunicación",
    budgetName: "Nombre del presupuesto",
    mmNumber: "Número MM",
    responsible: "Responsable",
    responsibleEmail: "Correo electrónico responsable",
    clientName: "Nombre del cliente",
    status: "Estado",
    creationDate: "Fecha de creación",
    listBudgets: "Lista de presupuestos",
    addBudget: "Añadir Presupuesto",
    savedBudgets: "Todos los presupuestos",
    addCoin: "Añadir moneda",
    savedCoins: "Todas las monedas",
    savedHotels: "Todos los hoteles",
    hotel: "Alojamiento",
    rooms: "Habitaciones",
    equipments: "Equipo",
    aeb: "Alimentos y Bebidas",
    translation: "Traducción simultánea",
    signIn: "",
    rsvp: "RSVP",
    aerial: "Aire",
    transfer: "Transfer",
    diversos: "Varios",
    supportTeam: "Equipos de soporte",
    serviceTypes: "Tipos de servicio",
    valueType: "Tipo de valor",
    currency: "Moneda",
    taxes: "Impuestos",
    suppliers: "Proveedores",
    daysAmount: "Diario",
    guestAmount: "QTE",
    addSupplier: "Añadir Fornecedor",
    serviceDescription: "Descritivo",
  },
  supplier: {
    location: "Ubicación",
    city: "Ciudad/Estado",
    country: "País",
    accommodationPeriod: "Período de alojamiento",
    meetingPeriod: "Período de reunión",
    mainRoomArea: "Area, Sala principal // Pie derecho",
    availability: "Disponibilidad",
    negotiation: "Trading",
    website: "Sitio web",
    supplierName: "Nombre",
  },
  simpleTax: {
    default: "Tarifa",
    service: "Tarifa de Servicio",
    local: "Impuessto Local",
    iof: "IOF",
    mm: "Tarifa MM(FEE)",
    nfMM: "Tipo de NF MM",
    turism: "Imposto al Turismo",
  },
};
