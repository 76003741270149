import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontFamily: ["Public-Sans", "sans-serif"].join(","),
  },

  components: {
    MuiTextField: {
      defaultProps: {
        // The default props to change
        size: "small",
        fullWidth: true,
        InputLabelProps: {
          margin: "dense",
          shrink: true,
        },
      },
    },

    MuiButton: {
      defaultProps: {
        size: "small",
        variant: "contained",
      },

      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});
