import { CircularProgress } from "@mui/material";
import CustomModal from "../CustomModal";

export default function LoadingModal(props: any) {
  return (
    <CustomModal isOpen={props.isLoading} handleClose={() => {}} target={""}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "auto 50px",
        }}
      >
        <h5>Carregando informações</h5> <CircularProgress />
      </div>
    </CustomModal>
  );
}
