import { Box, Modal, autocompleteClasses } from "@mui/material";
type CardProps = {
  children: React.ReactNode;
  isOpen: boolean;
  target: string;
  handleClose: (target: string) => void;
};
const style = {
  position: "relative" as "relative",
  top: "2%",
  left: "25%",
  width: 600,
  maxHeight: 600,
  bgcolor: "background.paper",
  border: "2px solid #eee",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};
const CustomModal: React.FC<CardProps & React.HTMLProps<HTMLDivElement>> = (
  props
) => {
  return (
    <Modal
      open={props.isOpen}
      onClose={() => {
        props.handleClose(props.target);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>{props.children}</Box>
    </Modal>
  );
};

export default CustomModal;
