import { useContext, useEffect, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import BriefingInicial from "../../../BriefingInicial";
import Logistica from "../../../Logistics";
import Aereo from "../../../Aereo";
import Criativo from "../../../Criativo/Criativo";
import Producao from "../../../Criativo/Producao";
import { AlertModal } from "../../../AlertModal";
import { ProjectContext } from "../../../../contexts/project";
import { toast } from "react-toastify";

const tabs = [
  { index: 0, label: "Dados Cadastrais", template: BriefingInicial },
  { index: 1, label: "Logística", template: Logistica },
  { index: 2, label: "Aéreo", template: Aereo },
  // { index: 3, label: "RSVP", template: RSVP },
  { index: 4, label: "Comunicação", template: Criativo },
  { index: 5, label: "Produção", template: Producao },
];

export function BriefingDetailsTab() {
  const { projectId } = useContext(ProjectContext)
  const [tabActive, setTabActive] = useState(0);
  const [pendingTab, setPendingTab] = useState(-1);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const Template = tabs[tabActive].template;

  useEffect(() => {
    if (pendingTab >= 0) {
      if (localStorage.getItem("hideAlert") !== "0") {
        setShowAlertModal(true);
      } else {
        setTabActive(pendingTab);
        setPendingTab(-1);

      }
    }
  }, [pendingTab]);

  function changeTab(status: boolean) {
    if (status && !!projectId) setTabActive(pendingTab);
    setShowAlertModal(false);
    setPendingTab(-1);
  }
  function handleChangeTab(index: any) {
    if (!!projectId) setPendingTab(index)
  }

  return (
    <Box>
      <AlertModal
        handleClose={() => changeTab(false)}
        isOpen={showAlertModal}
        makeDecision={changeTab}
      />

      <Tabs
        value={tabActive}
        onChange={(e, index) => handleChangeTab(index)}
        indicatorColor="primary"
        sx={{ textTransform: "none" }}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.index}
            label={tab.label}
            sx={{ textTransform: "none" }}
          />
        ))}
      </Tabs>

      <Box mt={4}>
        <Template />
      </Box>
    </Box>
  );
}
