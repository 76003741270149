import { BudgetStatus } from "../types/budget/budget";

export const BRIEFING_STATUS_OPTIONS = [
  { text: "Aguardando Cliente", value: "WAITING_CLIENT" },
  { text: "Aprovado", value: "APPROVED" },
  { text: "Aprovado/Cancelado", value: "APPROVED_AND_CANCELED" },
  { text: "Auditado", value: "AUDITED" },
  { text: "Cancelado", value: "CANCELED" },
  { text: "Cancelado/Auditado", value: "CANCELED_AND_AUDITED" },
  { text: "Cancelado/Cobrado", value: "CANCELED_AND_CHARGED" },
  { text: "Compartilhado Financeiro", value: "SHARED_FINANCIAL" },
  { text: "Declinado", value: "DECLINED" },
  { text: "Duplicidade", value: "DUPLICATE" },
  { text: "Em Aprovação", value: "ON_APROVAL" },
  { text: "Faturado", value: "BILLED" },
  { text: "Finalizado", value: "FINISHED" },
  { text: "Financeiro", value: "FINANCIAL" },
  { text: "Liberado Operação", value: "CLEARED_OPERATIONS" },
  { text: "Orçamento", value: "BUDGET" },
  { text: "Perdido", value: "LOST" },
  { text: "Prestação de Contas", value: "LEGAL_REPORTING" },
  { text: "Pré-fechamento", value: "PRE_CLOSING" },
];

export const ALLOWED_STATUS_TRANSITION: { [index: string]: string[] } = {
  BUDGET: ["ON_APROVAL", "DUPLICATE", "SHARED_FINANCIAL", "CANCELED"],
  ON_APROVAL: ["APPROVED", "DECLINED", "LOST", "CANCELED", "BUDGET"],
  APPROVED: ["APPROVED_AND_CANCELED", "CANCELED", "FINISHED", "ON_APROVAL"],
  APPROVED_AND_CANCELED: ["CANCELED_AND_CHARGED", "APPROVED"],
  CANCELED_AND_CHARGED: ["CANCELED_AND_AUDITED", "APPROVED_AND_CANCELED"],
  CANCELED_AND_AUDITED: ["CANCELED_AND_CHARGED"],
  FINISHED: ["PRE_CLOSING", "APPROVED"],
  PRE_CLOSING: ["CLEARED_OPERATIONS", "FINISHED"],
  CLEARED_OPERATIONS: ["WAITING_CLIENT", "PRE_CLOSING"],
  WAITING_CLIENT: ["FINANCIAL", "CLEARED_OPERATIONS"],
  FINANCIAL: ["LEGAL_REPORTING", "WAITING_CLIENT"],
  LEGAL_REPORTING: ["BILLED", "FINANCIAL"],
  BILLED: ["AUDITED", "LEGAL_REPORTING"],
  AUDITED: ["BILLED"],
  SHARED_FINANCIAL: ["CANCELED", "BUDGET"],
  DUPLICATE: ["BUDGET"],
  CANCELED: ["BUDGET", "ON_APROVAL", "APPROVED", "SHARED_FINANCIAL"],
  DECLINED: ["ON_APROVAL"],
  LOST: ["ON_APROVAL"],
};

export const ALLOWED_STATUS_RETURN: { [index: string]: any } = {
  ON_APROVAL: "BUDGET",
  APPROVED: "ON_APROVAL",
  APPROVED_AND_CANCELED: "APPROVED",
  CANCELED_AND_CHARGED: "APPROVED_AND_CANCELED",
  CANCELED_AND_AUDITED: "CANCELED_AND_CHARGED",
  FINISHED: "APPROVED",
  PRE_CLOSING: "FINISHED",
  CLEARED_OPERATIONS: "PRE_CLOSING",
  WAITING_CLIENT: "CLEARED_OPERATIONS",
  FINANCIAL: "WAITING_CLIENT",
  LEGAL_REPORTING: "FINANCIAL",
  BILLED: "LEGAL_REPORTING",
  AUDITED: "BILLED",
  SHARED_FINANCIAL: "BUDGET",
  DUPLICATE: "BUDGET",
  CANCELED: ["BUDGET", "ON_APROVAL", "APPROVED", "SHARED_FINANCIAL"],
  DECLINED: "ON_APROVAL",
  LOST: "ON_APROVAL",
};

export const TRANSITION_REQUIREMENTS: {
  [index: string]: { summary: any; address: boolean };
} = {
  WAITING_CLIENT: { summary: BudgetStatus.CLOSURE, address: true },
  APPROVED: { summary: BudgetStatus.ON_APPROVAL, address: true },
  APPROVED_AND_CANCELED: { summary: BudgetStatus.APPROVED, address: true },
  AUDITED: { summary: BudgetStatus.CLOSURE, address: true },
  CANCELED: { summary: null, address: false },
  CANCELED_AND_AUDITED: { summary: BudgetStatus.APPROVED, address: true },
  CANCELED_AND_CHARGED: { summary: BudgetStatus.APPROVED, address: true },
  SHARED_FINANCIAL: { summary: null, address: false },
  DECLINED: { summary: BudgetStatus.ON_APPROVAL, address: false },
  DUPLICATE: { summary: null, address: false },
  ON_APROVAL: { summary: null, address: false },
  BILLED: { summary: BudgetStatus.CLOSURE, address: true },
  FINISHED: { summary: BudgetStatus.APPROVED, address: true },
  FINANCIAL: { summary: BudgetStatus.CLOSURE, address: true },
  CLEARED_OPERATIONS: { summary: BudgetStatus.CLOSURE, address: true },
  BUDGET: { summary: null, address: false },
  LOST: { summary: BudgetStatus.ON_APPROVAL, address: false },
  LEGAL_REPORTING: { summary: BudgetStatus.CLOSURE, address: true },
  PRE_CLOSING: { summary: BudgetStatus.APPROVED, address: true },
};
