import React, { useEffect, useState } from 'react'

import { useTheme } from '@mui/material/styles'
import { ActionButton, MainPagination } from './styles'
const TablePaginationActions = (props: any) => {
  const { count, page, rowsPerPage, onPageChange } = props


  const [numPages, setNumPages] = useState([<></>])

  useEffect(() => {
    let nPages = count / rowsPerPage > 10 ? 10 : count / rowsPerPage 
    let arrIcons:React.JSX.Element[]  = []
    for(let i=0; i<nPages; i++) {
      arrIcons.push( <ActionButton 
        onClick={()=> {handleGoToPage(i)}}
        style={page === i ?{ border: '1px solid'}: {}}
        key={Math.random()}
      > {i+1}
      </ActionButton>)
    }
    setNumPages(arrIcons)
  }, [props])

  function handleGoToPage(pageIndex: number) {
    onPageChange('', pageIndex)
  }


  const handleBackButtonClick = (event: any) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event: any) => {
    onPageChange(event, page + 1)
  }


  return (
    <MainPagination >

      <ActionButton
        onClick={handleBackButtonClick}
        aria-label="previous page"
      >Anterior
      </ActionButton>
      {numPages.map(icon => icon)}
      <ActionButton
        onClick={handleNextButtonClick}
        aria-label="next page"
      >
       Próximo
      </ActionButton>
    </MainPagination>
  )
}

export default TablePaginationActions
