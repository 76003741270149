import { Project } from "../types";
import { api } from "./apiClient";

export default class ProjectService {
  static async create(dto: any): Promise<Project> {
    return api.post("/project", dto).then((response) => response.data);
  }

  static async update(id: string, dto: any): Promise<Project> {
    return api.put(`/project/${id}`, dto).then((response) => response.data);
  }

  static async updateStatus(dto: any): Promise<Project> {
    return api
      .put(`/project/status/${dto._id}`, dto)
      .then((response) => response.data);
  }

  static async get(projectId: string): Promise<Project> {
    return api.get(`/project/${projectId}`).then((response) => response.data);
  }

  static async getByRequesterId(params: any): Promise<any> {
    return api
      .get(`/project/get-by-requester`, { params })
      .then((response) => response.data);
  }

  static async search(params: any): Promise<{ total: number; entry: any[] }> {
    return api
      .get(`/project`, {
        params,
      })
      .then((response) => response.data);
  }

  static async getMMCodes(params: any): Promise<any> {
    return api
      .get(`/project/get-mm-codes`, {
        params,
      })
      .then((response) => response.data);
  }
}
