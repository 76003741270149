import {BudgetHelperDiv, InputDiv} from "./styles";
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  Box,
  Button,
  createFilterOptions,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {SyntheticEvent, useContext, useEffect, useState} from "react";
import {cnpj, cpf} from "cpf-cnpj-validator";
import {Controller, useForm} from "react-hook-form";
import {IBriefingData, ICustomerResgister, IFinancialData, IProjectData,} from "../../types/initialBriefing";
import * as Yup from "yup";
import {toast} from "react-toastify";
import InputMask from "react-input-mask";
import {api} from "../../services/apiClient";
import getValidationErrors from "../../services/getValidationErrors";
import {ProjectContext} from "../../contexts/project";
import {format} from "date-fns";

import {clientSegment, eventTypes, projectTypes,} from "../../utils/opcoesBriefingInicial";
import CurrencyInput from "../UI/CurrencyInput";
import {LabeledAutoComplete} from "../UI/LabeledAutoComplete";
import {
  CUSTOMER_REGISTER,
  FINANCIAL_DATA,
  INITIAL_BRIEFING,
  PROJECT_DATA
} from "../../utils/projectContextInitialStates";
import {useQuery} from "react-query";
import UserService from "../../services/user.service";

export default function BriefingInicial(props: any) {
  const { register, handleSubmit, control } = useForm();
  const {
    getInitialBriefing,
    projectId,
    briefing,
    financial,
    customerRegister,
    project,
    setCustomerRegister,
  } = useContext(ProjectContext);


  const [customerRegisterData, setCustomerRegisterData] =
    useState<ICustomerResgister>({
      id: "",
      companyName: "",
      companyRegisterNumber: "",
      address: "",
      applicantName: "",
      contact: "",
      email: "",
      cell: "",
      cellLeader: "",
      attendance_1: "",
      attendance_2: "",
      coordinator_1: "",
      coordinator_2: "",
      commercial_new_business: "",
      clientSegment: "",
    });
  const [briefingData, setBriefingData] = useState<IBriefingData>({
    eventName: "",
    eventType: "",
    briefingDate: "",
    deliveryDate: "",
    presentationDate: "",
    competition: null,
    isInternational: null,
    competitionObs: "",
    projectArea: false,
    fileName: "",
    observations: "",
    isPfizer: null,
    isMapp: null,
    pfizerInputs: "",
    mappInputs: "",

    briefing_length: "",
  });
  const [financialData, setFinancialData] = useState<IFinancialData>({
    isBudgetDefined: null,

    budgetValue: 0,
    budgetValueAerial: 0,
    budgetValueAerialFeeMM: 0,
    budgetDirectCustomerPayment: 0,
    budgetValueCreative: 0,
    budgetValueFeeService: 0,
    budgetValueRSVP: 0,
    budgetTotalValueInvoiced: 0,
    advanceValue: 0,

    budgetValueLogistics: "",
    budgetValueProduction: "",
    paymentDate: "",
    advance: null,
    observations: "",
    billingPeriod: "",
    paymentMethod: "",
    debtNote: "",
    financing: "",
  });

  const [projectData, setProjectData] = useState<IProjectData>({
    eventResponsible: "",
    project_event_location: "",
    eventType: "",
    initialDate: "",
    finalDate: "",
    initialHour: "",
    finalHour: "",
    streamed: null, // remove later
    eventDuration: "",
    eventObjective: "",
    customerChallenges: "",
    public: "",
    profile: "",
    guestAmount: 0,
    address: "",
    observations: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [requiredFieldsError, setRequiredFieldsError] = useState({
    briefing_concurrence: false,
    briefing_occupation_area: false,
    financial_has_budget: false,
    financial_has_extra_suppliers: false,
    financial_has_advance_money: false,
    project_streaming: false,
    briefing_is_pfizer: false,
    briefing_is_mapp: false,
  });
  const [inputClientValue, setInputClientValue] = useState("");
  const [inputClientSegmentValue, setInputClientSegmentValue] = useState("");
  const [clientSegmentValue, setClientSegmentValue] = useState("");

  const [eventNameError, setEventNameError] = useState(false);

  function typesRender(typesArray: any[]) {
    return typesArray.map((el) => {
      return (
        <MenuItem value={el} key={Math.random()}>
          {el}
        </MenuItem>
      );
    });
  }

  // Load de clientes na base
  const [clientsArr, setClientsArr] = useState<any[]>([]);
  useEffect(() => {
    async function loadClients() {
      try {
        const response = await api.get("/client/get");
        if (response.data.length > 0) setClientsArr(response.data);
      } catch (error) {
        toast.error("Não foi possível carregar os clientes da base");
      }
    }
    loadClients();
  }, []);

  const { t } = useTranslation();
  async function submitHandler(data: any) {
    setIsLoading(true);
    try {
      const schema = Yup.object().shape({
        briefing_concurrence: Yup.boolean()
          .required("O campo 'Concorrência' é obrigatório")
          .nullable(),
        briefing_event_name: Yup.string()
          .required("O campo 'Nome do Evento' é obrigatório")
          .nullable(),
        financial_has_advance_money: Yup.boolean()
          .required("O campo 'Adiantamento' é obrigatório")
          .nullable(),
        external_client_id: Yup.string()
          .required("Selecione um cliente para prosseguir"),

      });
      const payload: {} = {
        external_client_id: customerRegisterData.id,
        customer_corporate_name: customerRegisterData.companyName,
        customer_document: customerRegisterData.companyRegisterNumber,
        customer_name: customerRegisterData.applicantName,
        customer_address: customerRegisterData.address,
        customer_phone: customerRegisterData.contact,
        customer_email: customerRegisterData.email,
        customer_cell: customerRegisterData.cell,
        customer_cell_leader: customerRegisterData.cellLeader,
        attendance_1: customerRegisterData.attendance_1,
        attendance_2: customerRegisterData.attendance_2,
        coordinator_1: customerRegisterData.coordinator_1,
        coordinator_2: customerRegisterData.coordinator_2,
        commercial_new_business: customerRegisterData.commercial_new_business,
        customer_segment_client: customerRegisterData.clientSegment,
        briefing_event_name: briefingData.eventName,
        briefing_length: briefingData.briefing_length,
        briefing_event_type: briefingData.eventType,
        briefing_briefing_date: briefingData.briefingDate,
        briefing_delivery_date: briefingData.deliveryDate,
        briefing_presentation_date: briefingData.presentationDate,
        has_pfz: briefingData.isPfizer,
        pfz: briefingData.pfizerInputs,
        has_mapp: briefingData.isMapp,
        mapp: briefingData.mappInputs,
        briefing_concurrence: briefingData.competition,
        briefing_is_international: briefingData.isInternational,
        briefing_email: customerRegisterData.email,
        briefing_number_agencies:
          briefingData.competitionObs === null
            ? ""
            : briefingData.competitionObs,
        briefing_occupation_area: briefingData.projectArea,
        briefing_observation: briefingData.observations,
        financial_has_budget: financialData.isBudgetDefined,

        financial_budget_value: financialData.budgetValue,
        financial_aerial_value: financialData.budgetValueAerial,
        financial_aerial_value_fee_mm: financialData.budgetValueAerialFeeMM,
        financial_direct_customer_payment:
          financialData.budgetDirectCustomerPayment,
        financial_creation_value: financialData.budgetValueCreative,
        financial_value_fee_service: financialData.budgetValueFeeService,
        financial_total_value_invoiced: financialData.budgetTotalValueInvoiced,
        financial_advance_money: financialData.advanceValue,

        financial_payment_date: financialData.paymentDate,
        financial_has_advance_money: financialData.advance,

        financial_observation: financialData.observations,
        financial_logistic_value: financialData.budgetValueLogistics,

        financial_rsvp_value: !!financialData.budgetValueRSVP
          ? String(financialData.budgetValueRSVP).replace(",", ".")
          : financialData.budgetValueRSVP,

        financial_production_value: !!financialData.budgetValueProduction
          ? String(financialData.budgetValueProduction).replace(",", ".")
          : financialData.budgetValueProduction,
        financial_billing_term: financialData.billingPeriod,
        financial_form_payment: financialData.paymentMethod,
        financial_debit_note: financialData.debtNote,
        financial_financing: financialData.financing,
        project_responsible: projectData.eventResponsible,
        project_event_location: projectData.project_event_location,
        project_event_type: projectData.eventType,
        project_initial_date: projectData.initialDate,
        project_final_date: projectData.finalDate,
        project_public: projectData.public,
        project_profile: projectData.profile,
        project_participants_number: projectData.guestAmount,
        project_address: projectData.address,
        project_streaming: projectData.streamed,
        project_event_duration: projectData.eventDuration,
        project_initial_hour: projectData.initialHour,
        project_final_hour: projectData.finalHour,
        project_objectives: projectData.eventObjective,
        project_challenges: projectData.customerChallenges,
        project_observation: projectData.observations,
      };
      Object.keys(requiredFieldsError).map((key: any) => {
        if (!payload[key as keyof typeof payload]) {
          let requiredFieldsCopy = requiredFieldsError;
          requiredFieldsCopy[key as keyof typeof requiredFieldsError] = true;
          setRequiredFieldsError(requiredFieldsCopy);
        }
      });
      await schema.validate(payload, { abortEarly: false });

      const formData = new FormData();
      if (
        data.briefingData.fileName === undefined &&
        briefingData.fileName !== null
      ) {
        let blob = await fetch(briefingData.fileName, { mode: "no-cors" }).then(
          (r) => r.blob()
        );
        formData.append("file", blob);
        formData.append("data", JSON.stringify(payload));
      } else {
        formData.append("file", data.briefingData.fileName[0]);
        formData.append("data", JSON.stringify(payload));
      }
      if (!!projectId) {
        await api.put(
          `/briefing/update-initial-briefing/${projectId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        await api
          .post("/briefing/create-initial-briefing", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(async (response) => {
            await api.post("/status-briefing", {
              briefing_id: response.data._id,
              type: "BUDGET",
              date_hour: format(new Date(), "yyyy-MM-dd HH:mm"),
              estimated_completion_date: format(new Date(), "yyyy-MM-dd HH:mm"),
              justification: "status inicial",
            });
          });
      }

      toast.success(t(!projectId ? "createSuccess" : "editedSuccess"));
      window.location.href = "/briefings";
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        Object.values(errors).map((error) => {
          toast.error(error);
        });
      }
    }
    setIsLoading(false);
  }

  useEffect(() => {
    async function loadProject(id: string) {
      setIsLoading(true);
      const response = await getInitialBriefing(id);
      if (response !== undefined) {
        setBriefingData(response.briefing);
        setCustomerRegisterData(response.customerRegister);
        setFinancialData(response.financial);
        setProjectData(response.project);
        setInputClientValue(response.customerRegister.companyName);
        loadClientSegment(response.customerRegister.clientSegment);


      }
      setIsLoading(false);

    }
    if (projectId?.length > 0) loadProject(projectId);
    else if (projectId === "") {
      setCustomerRegisterData(CUSTOMER_REGISTER);
      setBriefingData(INITIAL_BRIEFING);
      setFinancialData(FINANCIAL_DATA);
      setProjectData(PROJECT_DATA);
    }
    else {
      setBriefingData(briefing);
      setFinancialData(financial);
      setCustomerRegister(customerRegister);
      setProjectData(project);
    }
  }, [projectId]);
  function loadClientSegment(value: string) {
    setClientSegmentValue(value);
    setInputClientSegmentValue(value);
  }
  function handleRegisterNumberChange(e: React.ChangeEvent<HTMLInputElement>) {
    let newValue = e.target.value;
    if (newValue.length <= 11) {
      newValue = cpf.format(newValue);
      setCustomerRegisterData({
        ...customerRegisterData,
        companyRegisterNumber: cpf.format(newValue),
      });
    } else {
      setCustomerRegisterData({
        ...customerRegisterData,
        companyRegisterNumber: cnpj.format(newValue),
      });
    }
  }

  function handleContactChange(e: React.ChangeEvent<HTMLInputElement>) {
    let newValue = e.target.value;
    let regex = /^[0-9]/;
    // if (regex.test(newValue) || newValue === '') {
    setCustomerRegisterData({
      ...customerRegisterData,
      contact: newValue.toString(),
    });
    // }
  }
  // filter options MUI autocomplete
  const filterOptions = createFilterOptions({
    stringify: (option: any) => option.name + option.tax_id,
  });

  // auto complete change functions
  function clientChangeHandler(
    e: SyntheticEvent<Element, Event>,
    newValue: any | null
  ) {
    const newCustomerState =
      newValue !== null
        ? {
          ...customerRegisterData,
          id: newValue._id,
          companyName: newValue.name,
          companyRegisterNumber: newValue.tax_id,
          address: `${newValue.address}, ${newValue.number}, ${newValue.city}, ${newValue.country}`,
        }
        : {
          ...customerRegisterData,
          id: "",
          companyName: "",
          companyRegisterNumber: "",
          address: "",
        };
    return setCustomerRegisterData(newCustomerState);
  }
  function clientInputChangeHandler(
    e: SyntheticEvent<Element, Event>,
    newValue: string,
    reason: AutocompleteInputChangeReason
  ) {
    if (newValue === "" && reason === "reset") return;
    setInputClientValue(newValue);
  }

  // auto complete users functions
  const [cellLeaders, setCellLeaders] = useState<any[]>([])
  const { data: usersData, error: userErrorError } = useQuery({
    queryKey: ["briefing-all-users"],
    queryFn: () => UserService.allUsers(),
  });

  function handleCellSelection(newValue: any | null) {
    if (newValue) {
      setCustomerRegisterData({ ...customerRegisterData, cell: newValue._id })
      let leaders = usersData?.filter(user => newValue.leader_user_ids.includes(user._id)) || []
      setCellLeaders(leaders)
    } else {
      setCustomerRegisterData({ ...customerRegisterData, cell: '' })
      setCellLeaders([])
    }
  }
  function handleLeaderSelection(newValue: any | null) {
    if (newValue) {
      setCustomerRegisterData({ ...customerRegisterData, cellLeader: newValue._id })
    } else {
      setCustomerRegisterData({ ...customerRegisterData, cellLeader: '' })
    }
  }



  // function cellLeaderChangeHandler(
  //   e: SyntheticEvent<Element, Event>,
  //   newValue: any | null
  // ) {
  //   setCustomerRegisterData({ ...customerRegisterData, cellLeader: newValue?._id || '' })
  // }
  // const [inputCellLeaderValue, setInputCellLeaderValue] = useState('')
  // function cellLeaderInputChangeHandler(
  //   e: SyntheticEvent<Element, Event>,
  //   newValue: string,
  //   reason: AutocompleteInputChangeReason
  // ) {
  //   if (newValue === "" && reason === "reset") return;
  //   setInputCellLeaderValue(newValue);
  // }


  function clientSegmentChangeHandler(
    e: SyntheticEvent<Element, Event>,
    newValue: any | null
  ) {
    if (!!newValue) {
      setCustomerRegisterData({
        ...customerRegisterData,
        clientSegment: newValue,
      });
      setClientSegmentValue(newValue);
    }
  }
  function clientInputSegmentChangeHandler(
    e: SyntheticEvent<Element, Event>,
    newValue: string,
    reason: AutocompleteInputChangeReason
  ) {
    if (newValue === "" && reason === "reset") return;
    setInputClientSegmentValue(newValue);
  }

  // event name validation
  function eventNameChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.value.length > 99) {
      return setEventNameError(true);
    }

    setBriefingData({ ...briefingData, eventName: e.target.value });
    return setEventNameError(false);
  }

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Box display={"flex"} flexDirection={"column"} gap={2} mb={3}>
        <Typography variant="h6" mb={1}>
          {t("initialBriefingForm.customerRegisterData.header")}
        </Typography>
        <InputDiv>
          <LabeledAutoComplete
            filterOptions={filterOptions}
            options={clientsArr}
            getOptionLabel={(option) => option.name || ""}
            size="small"
            inputValue={inputClientValue}
            onInputChange={clientInputChangeHandler}
            onChange={clientChangeHandler}
            label="Busque por nome ou CNPJ do cliente"
            renderInput={(params) => (
              <TextField
                {...params}

              />
            )}
          />
          <TextField
            value={customerRegisterData.companyName}
            disabled
            label={t("initialBriefingForm.customerRegisterData.companyName")}
          />
        </InputDiv>

        <InputDiv>
          <TextField
            value={customerRegisterData.companyRegisterNumber}
            onChange={handleRegisterNumberChange}
            disabled
            label={t("initialBriefingForm.customerRegisterData.document")}
          />
          <TextField
            value={customerRegisterData.applicantName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCustomerRegisterData({
                ...customerRegisterData,
                applicantName: e.target.value,
              });
            }}
            label={t("initialBriefingForm.customerRegisterData.applicantName")}
          />
        </InputDiv>

        <TextField
          value={customerRegisterData.address}
          disabled
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setCustomerRegisterData({
              ...customerRegisterData,
              address: e.target.value,
            });
          }}
          label={t("address")}
        />

        <InputDiv>
          <InputMask
            mask="(99) 99999-9999"
            value={customerRegisterData.contact}
            onChange={handleContactChange}
          >
            <TextField
              fullWidth
              size="small"
              label={t("initialBriefingForm.customerRegisterData.contact")}
            />
          </InputMask>

          <TextField
            value={customerRegisterData.email}
            fullWidth
            size="small"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCustomerRegisterData({
                ...customerRegisterData,
                email: e.target.value,
              });
            }}
            label={t("initialBriefingForm.customerRegisterData.email")}
          />
        </InputDiv>

        <InputDiv>
          <TextField value={customerRegisterData.cell} label={"grupo"} size="small" onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setCustomerRegisterData({
              ...customerRegisterData,
              cell: e.target.value,
            });
          }} />
          <TextField value={customerRegisterData.cellLeader} label={"Líder de grupo"} size="small" onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setCustomerRegisterData({
              ...customerRegisterData,
              cellLeader: e.target.value,
            });
          }} />
          {/* <CellAutoComplete 
            previousValue={customerRegisterData.cell || ''} 
            onSelect={handleCellSelection}/>
          <LeadersAutoComplete 
            onSelect={handleLeaderSelection} 
            leadersArr={cellLeaders} 
            previousValue={customerRegisterData.cellLeader || ''}/> */}

        </InputDiv>

        <InputDiv>
          <TextField
            value={customerRegisterData.attendance_1}
            fullWidth
            size="small"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCustomerRegisterData({
                ...customerRegisterData,
                attendance_1: e.target.value,
              });
            }}
            label={"Atendimento 1"}
          />
          <TextField
            value={customerRegisterData.coordinator_1}
            fullWidth
            size="small"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCustomerRegisterData({
                ...customerRegisterData,
                coordinator_1: e.target.value,
              });
            }}
            label={"Coordenador do evento 1"}
          />
        </InputDiv>

        <InputDiv>
          <TextField
            value={customerRegisterData.attendance_2}
            fullWidth
            size="small"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCustomerRegisterData({
                ...customerRegisterData,
                attendance_2: e.target.value,
              });
            }}
            label={"Atendimento 2"}
          />
          <TextField
            value={customerRegisterData.coordinator_2}
            fullWidth
            size="small"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCustomerRegisterData({
                ...customerRegisterData,
                coordinator_2: e.target.value,
              });
            }}
            label={"Coordenador do evento 2"}
          />
        </InputDiv>

        <InputDiv>
          <TextField
            value={customerRegisterData.commercial_new_business}
            fullWidth
            size="small"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCustomerRegisterData({
                ...customerRegisterData,
                commercial_new_business: e.target.value,
              });
            }}
            label={"Comercial / novos negócios"}
          />
          <Autocomplete
            options={clientSegment}
            disablePortal
            getOptionLabel={(option) => option}
            sx={{ width: "250px" }}
            size="small"
            inputValue={inputClientSegmentValue}
            value={clientSegmentValue}
            onInputChange={clientInputSegmentChangeHandler}
            onChange={clientSegmentChangeHandler}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Segmento do cliente"
                fullWidth
                size="small"
              />
            )}
          />
        </InputDiv>
      </Box>

      <Box display={"flex"} flexDirection={"column"} gap={2} mb={3}>
        <Typography variant="h6" mb={1}>
          {t("initialBriefingForm.briefingData.header")}
        </Typography>

        <InputDiv>
          <TextField
            multiline
            value={briefingData.eventName}
            fullWidth
            size="small"
            helperText={
              eventNameError
                ? "O nome do evento deve ter no máximo 99 caracteres"
                : ""
            }
            error={eventNameError}
            onChange={eventNameChangeHandler}
            name="eventName"
            label={t("initialBriefingForm.briefingData.eventName")}
          />

          <TextField
            multiline
            value={briefingData.briefing_length}
            fullWidth
            size="small"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setBriefingData({
                ...briefingData,
                briefing_length: e.target.value,
              });
            }}
            name="briefing_length"
            label={"Metragem"}
          />
        </InputDiv>

        <InputDiv>
          <FormControl>
            <InputLabel id="demo-simple-select-label">
              {t("initialBriefingForm.briefingData.eventType")}
            </InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={briefingData.eventType}
              label={t("initialBriefingForm.briefingData.eventType")}
              size="small"
              onChange={(e: any) => {
                setBriefingData({ ...briefingData, eventType: e.target.value });
              }}
              MenuProps={{
                style: {
                  maxHeight: 450,
                },
              }}
            >
              {typesRender(projectTypes)}
            </Select>
          </FormControl>

          <Controller
            name="briefingData.briefingDate"
            control={control}
            rules={{ required: false }}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  type="date"
                  label={t("initialBriefingForm.briefingData.briefing")}
                  size="small"
                  value={briefingData.briefingDate}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setBriefingData({
                      ...briefingData,
                      briefingDate: e.target.value,
                    });
                  }}
                  inputProps={{ min: "2019-01-24", max: "2050-05-31" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              );
            }}
          />
          <Controller
            name="briefingData.deliveryDate"
            control={control}
            rules={{ required: false }}
            render={({ field }) => {
              return (
                <TextField
                  type="date"
                  label={t("initialBriefingForm.briefingData.delivery")}
                  size="small"
                  value={briefingData.deliveryDate}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setBriefingData({
                      ...briefingData,
                      deliveryDate: e.target.value,
                    });
                  }}
                  inputProps={{ min: "2019-01-24", max: "2050-05-31" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              );
            }}
          />
          <Controller
            name="briefingData.presentationDate"
            control={control}
            rules={{ required: false }}
            render={({ field }) => {
              return (
                <TextField
                  type="date"
                  label={t("initialBriefingForm.briefingData.presentation")}
                  size="small"
                  value={briefingData.presentationDate}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setBriefingData({
                      ...briefingData,
                      presentationDate: e.target.value,
                    });
                  }}
                  inputProps={{ min: "2019-01-24", max: "2050-05-31" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              );
            }}
          />
        </InputDiv>

        <InputDiv>
          <FormLabel sx={{ width: 240 }}>PFZ</FormLabel>

          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={briefingData.isPfizer}
            onChange={(e) => {
              e.target.value === "true"
                ? setBriefingData({ ...briefingData, isPfizer: true })
                : setBriefingData({ ...briefingData, isPfizer: false });
            }}
          >
            <FormControlLabel
              value={true}
              control={<Radio />}
              label={t("sim")}
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label={t("nao")}
            />
          </RadioGroup>

          <TextField
            value={briefingData.pfizerInputs}
            fullWidth
            size="small"
            disabled={!briefingData.isPfizer}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setBriefingData({
                ...briefingData,
                pfizerInputs: e.target.value,
              });
            }}
            name="eventInputsType"
            placeholder={"Inserir Dados"}
          />
        </InputDiv>

        {requiredFieldsError.briefing_is_pfizer &&
          briefingData.isPfizer === null && (
            <FormHelperText style={{ color: "red" }}>
              {t("radioErrorMessage")}
            </FormHelperText>
          )}

        <InputDiv>
          <FormLabel sx={{ width: 240 }}>MAPP</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={briefingData.isMapp}
            onChange={(e) => {
              e.target.value === "true"
                ? setBriefingData({ ...briefingData, isMapp: true })
                : setBriefingData({ ...briefingData, isMapp: false });
            }}
          >
            <FormControlLabel
              value={true}
              control={<Radio />}
              label={t("sim")}
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label={t("nao")}
            />
          </RadioGroup>

          <TextField
            value={briefingData.mappInputs}
            fullWidth
            size="small"
            disabled={!briefingData.isMapp}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setBriefingData({ ...briefingData, mappInputs: e.target.value });
            }}
            name="eventInputsType"
            placeholder={"Inserir Dados"}
          />
        </InputDiv>
        {requiredFieldsError.briefing_is_mapp &&
          briefingData.isMapp === null && (
            <FormHelperText style={{ color: "red" }}>
              {t("radioErrorMessage")}
            </FormHelperText>
          )}

        <InputDiv>
          <FormLabel sx={{ width: 240 }}>
            {t("initialBriefingForm.briefingData.competition")}
          </FormLabel>

          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={briefingData.competition}
            onChange={(e) => {
              e.target.value === "true"
                ? setBriefingData({ ...briefingData, competition: true })
                : setBriefingData({ ...briefingData, competition: false });
            }}
          >
            <FormControlLabel
              value="true"
              control={<Radio />}
              label={t("sim")}
            />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label={t("nao")}
            />
          </RadioGroup>

          <TextField
            value={briefingData.competitionObs}
            fullWidth
            size="small"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setBriefingData({
                ...briefingData,
                competitionObs: e.target.value,
              });
            }}
            disabled={!briefingData.competition}
            name="eventInputsType"
            placeholder={t(
              "initialBriefingForm.briefingData.competitionPlaceholder"
            )}
          />
        </InputDiv>

        {requiredFieldsError.briefing_concurrence &&
          briefingData.competition === null && (
            <FormHelperText style={{ color: "red" }}>
              {t("radioErrorMessage")}
            </FormHelperText>
          )}

        <InputDiv>
          <FormLabel sx={{ width: 240 }}>É internacional?</FormLabel>

          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={briefingData.isInternational}
            onChange={(e) => {
              e.target.value === "true"
                ? setBriefingData({ ...briefingData, isInternational: true })
                : setBriefingData({ ...briefingData, isInternational: false });
            }}
          >
            <FormControlLabel
              value="true"
              control={<Radio />}
              label={t("sim")}
            />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label={t("nao")}
            />
          </RadioGroup>
        </InputDiv>

        <InputDiv>
          {briefingData.fileName === null ? (
            <TextField
              {...register("briefingData.fileName", { required: false })}
              fullWidth
              size="small"
              type="file"
            />
          ) : (
            <>
              <Button
                type="button"
                style={{
                  width: "250px",
                  background: "#45BA92",
                  color: "#FFFFFF",
                }}
              >
                <a
                  href={briefingData.fileName}
                  style={{ textDecoration: "none", color: "#FFF" }}
                  target="_blank"
                >
                  Baixar Arquivo
                </a>
              </Button>

              <Button
                type="button"
                style={{
                  width: "250px",
                  background: "#45BA92",
                  color: "#FFFFFF",
                  marginLeft: "30px",
                }}
                onClick={() => {
                  setBriefingData({ ...briefingData, fileName: null });
                }}
              >
                Substituir Arquivo
              </Button>
            </>
          )}
        </InputDiv>
        <InputDiv>
          <TextField
            value={briefingData.observations}
            fullWidth
            size="small"
            id="eventInputsType"
            label={t("observations")}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setBriefingData({
                ...briefingData,
                observations: e.target.value,
              });
            }}
          />
        </InputDiv>
      </Box>

      <Box display={"flex"} flexDirection={"column"} gap={2} mb={3}>
        <Typography variant="h6" mb={1}>
          {t("initialBriefingForm.financialInformation.header")}
        </Typography>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={2}
          sx={{
            border: "1px solid #EEE",
            padding: "5px",
            borderRadius: "4px",
          }}
        >
          <InputDiv>
            <BudgetHelperDiv>
              <CurrencyInput
                label="Valor total"
                name="budgetValue"
                value={financialData.budgetValue}
                onChange={({ floatValue }) => {
                  setFinancialData({
                    ...financialData,
                    budgetValue: floatValue,
                  });
                }}
              />
            </BudgetHelperDiv>

            <BudgetHelperDiv>
              <CurrencyInput
                label="Criação"
                name="budgetValueCreative"
                value={financialData.budgetValueCreative}
                onChange={({ floatValue }) => {
                  setFinancialData({
                    ...financialData,
                    budgetValueCreative: floatValue,
                  });
                }}
              />
            </BudgetHelperDiv>

            <BudgetHelperDiv>
              <CurrencyInput
                label="Valor fee / Serviços MM"
                name="budgetValueFeeService"
                value={financialData.budgetValueFeeService}
                onChange={({ floatValue }) => {
                  setFinancialData({
                    ...financialData,
                    budgetValueFeeService: floatValue,
                  });
                }}
              />
            </BudgetHelperDiv>
          </InputDiv>

          <InputDiv>
            <BudgetHelperDiv>
              <CurrencyInput
                label="RSVP"
                name="budgetValueRSVP"
                value={financialData.budgetValueRSVP}
                onChange={({ floatValue }) => {
                  setFinancialData({
                    ...financialData,
                    budgetValueRSVP: floatValue,
                  });
                }}
              />
            </BudgetHelperDiv>

            <BudgetHelperDiv>
              <CurrencyInput
                label="Aéreo"
                name="budgetValueAerial"
                value={financialData.budgetValueAerial}
                onChange={({ floatValue }) => {
                  setFinancialData({
                    ...financialData,
                    budgetValueAerial: floatValue,
                  });
                }}
              />
            </BudgetHelperDiv>

            <BudgetHelperDiv>
              <CurrencyInput
                label="Valor total faturado"
                name="budgetTotalValueInvoiced"
                value={financialData.budgetTotalValueInvoiced}
                onChange={({ floatValue }) => {
                  setFinancialData({
                    ...financialData,
                    budgetTotalValueInvoiced: floatValue,
                  });
                }}
              />
            </BudgetHelperDiv>

            <BudgetHelperDiv>
              <CurrencyInput
                label="Pagamento direto cliente"
                name="budgetDirectCustomerPayment"
                value={financialData.budgetDirectCustomerPayment}
                onChange={({ floatValue }) => {
                  setFinancialData({
                    ...financialData,
                    budgetDirectCustomerPayment: floatValue,
                  });
                }}
              />
            </BudgetHelperDiv>
          </InputDiv>
        </Box>

        <InputDiv>
          <strong className="form-helper-strong">
            {t("initialBriefingForm.financialInformation.advance")}
          </strong>
          <RadioGroup
            style={{ margin: "auto", width: "30%", height: "35px" }}
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            value={financialData.advance}
            onChange={(e) => {
              e.target.value === "true"
                ? setFinancialData({ ...financialData, advance: true })
                : setFinancialData({ ...financialData, advance: false });
            }}
          >
            <FormControlLabel
              value="true"
              control={<Radio />}
              label={t("sim")}
            />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label={t("nao")}
            />
          </RadioGroup>

          <CurrencyInput
            label="Valor total"
            name="advanceValue"
            value={financialData.advanceValue}
            disabled={!financialData.advance}
            onChange={({ floatValue }) => {
              setFinancialData({ ...financialData, advanceValue: floatValue });
            }}
          />
        </InputDiv>

        {requiredFieldsError.financial_has_advance_money &&
          financialData.advance === null && (
            <FormHelperText style={{ color: "red" }}>
              {t("radioErrorMessage")}
            </FormHelperText>
          )}
        <InputDiv>
          <TextField
            value={financialData.observations}
            fullWidth
            multiline
            size="small"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFinancialData({
                ...financialData,
                observations: e.target.value,
              });
            }}
            placeholder={t("observations")}
          />
        </InputDiv>
        <InputDiv>
          <strong className="form-helper-strong">Prazo de faturamento</strong>
          <TextField
            value={financialData.billingPeriod}
            fullWidth
            multiline
            size="small"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFinancialData({
                ...financialData,
                billingPeriod: e.target.value,
              });
            }}
            placeholder="Prazo de Faturamento"
          />
        </InputDiv>
        <InputDiv>
          <strong className="form-helper-strong">Forma de Pagamento</strong>
          <TextField
            value={financialData.paymentMethod}
            fullWidth
            multiline
            size="small"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFinancialData({
                ...financialData,
                paymentMethod: e.target.value,
              });
            }}
            placeholder="Forma de Pagamento"
          />
        </InputDiv>
        <InputDiv>
          <strong className="form-helper-strong">Nota de Débito</strong>
          <TextField
            value={financialData.debtNote}
            fullWidth
            multiline
            size="small"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFinancialData({
                ...financialData,
                debtNote: e.target.value,
              });
            }}
            placeholder="Nota de Débito"
          />
        </InputDiv>
        <InputDiv>
          <strong className="form-helper-strong">Financiamento</strong>
          <TextField
            value={financialData.financing}
            fullWidth
            multiline
            size="small"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFinancialData({
                ...financialData,
                financing: e.target.value,
              });
            }}
            placeholder="Financiamento"
          />
        </InputDiv>
      </Box>

      <Box display={"flex"} flexDirection={"column"} gap={2} mb={3}>
        <Typography variant="h6" mb={1}>
          Dados do Evento
        </Typography>

        <InputDiv>
          <TextField
            value={projectData.project_event_location}
            fullWidth
            size="small"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setProjectData({
                ...projectData,
                project_event_location: e.target.value,
              });
            }}
            name="project_event_location"
            label={"Local do evento"}
          />
        </InputDiv>
        <InputDiv>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {t("initialBriefingForm.projectData.eventType")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={projectData.eventType}
              label={t("initialBriefingForm.projectData.eventType")}
              size="small"
              MenuProps={{
                style: {
                  maxHeight: 450,
                },
              }}
              onChange={(e: any) => {
                setProjectData({ ...projectData, eventType: e.target.value });
              }}
            >
              {typesRender(eventTypes)}
            </Select>
          </FormControl>

          <Controller
            name="financialData.paymentDate"
            control={control}
            rules={{ required: false }}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  type="date"
                  label={t("initialBriefingForm.projectData.initialDate")}
                  size="small"
                  style={{ width: "40%" }}
                  value={projectData.initialDate}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setProjectData({
                      ...projectData,
                      initialDate: e.target.value,
                    });
                  }}
                  inputProps={{ min: "2000-01-24", max: "2050-05-31" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              );
            }}
          />
          <Controller
            name="financialData.paymentDate"
            control={control}
            rules={{ required: false }}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  type="date"
                  label={t(
                    "initialBriefingForm.financialInformation.paymentDate"
                  )}
                  size="small"
                  style={{ width: "40%" }}
                  value={projectData.finalDate}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setProjectData({
                      ...projectData,
                      finalDate: e.target.value,
                    });
                  }}
                  inputProps={{ min: "2000-01-24", max: "2050-05-31" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              );
            }}
          />
        </InputDiv>
        <InputDiv>
          <TextField
            value={projectData.public}
            fullWidth
            size="small"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setProjectData({ ...projectData, public: e.target.value });
            }}
            name="eventPublic"
            label={t("initialBriefingForm.projectData.public")}
          />
          <TextField
            value={projectData.profile}
            fullWidth
            size="small"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setProjectData({ ...projectData, profile: e.target.value });
            }}
            name=""
            label={t("initialBriefingForm.projectData.profile")}
          />
          <TextField
            value={
              projectData.guestAmount === 0
                ? ""
                : String(projectData.guestAmount)
            }
            fullWidth
            size="small"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (isNaN(Number(e.target.value))) return;
              setProjectData({
                ...projectData,
                guestAmount: Number(e.target.value),
              });
            }}
            name=""
            label={t("initialBriefingForm.projectData.guestAmount")}
          />
        </InputDiv>
        <InputDiv>
          <TextField
            value={projectData.address}
            fullWidth
            size="small"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setProjectData({ ...projectData, address: e.target.value });
            }}
            name="eventAddress"
            label={t("address")}
          />
        </InputDiv>

        <InputDiv>
          <TextField
            value={projectData.eventDuration}
            fullWidth
            size="small"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setProjectData({ ...projectData, eventDuration: e.target.value });
            }}
            name="eventDuration"
            label={t("initialBriefingForm.projectData.eventDuration")}
          />
          <Controller
            name="projectData.initialHour"
            control={control}
            rules={{ required: false }}
            render={({ field }) => {
              return (
                <TextField
                  type="time"
                  label={t("initialBriefingForm.projectData.initialHour")}
                  size="small"
                  value={projectData.initialHour}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setProjectData({
                      ...projectData,
                      initialHour: e.target.value,
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              );
            }}
          />
          <Controller
            name="projectData.finalHour"
            control={control}
            rules={{ required: false }}
            render={({ field }) => {
              return (
                <TextField
                  type="time"
                  label={t("initialBriefingForm.projectData.finalHour")}
                  size="small"
                  value={projectData.finalHour}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setProjectData({
                      ...projectData,
                      finalHour: e.target.value,
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              );
            }}
          />
        </InputDiv>
        <TextField
          value={projectData.eventObjective}
          fullWidth
          size="small"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setProjectData({
              ...projectData,
              eventObjective: e.target.value,
            });
          }}
          name="eventObjectives"
          label={t("initialBriefingForm.projectData.eventObjective")}
        />
        <TextField
          value={projectData.customerChallenges}
          fullWidth
          size="small"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setProjectData({
              ...projectData,
              customerChallenges: e.target.value,
            });
          }}
          name="eventChallenges"
          label={t("initialBriefingForm.projectData.customerChallenges")}
        />

        <InputDiv>
          <TextField
            value={projectData.observations}
            fullWidth
            size="small"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setProjectData({
                ...projectData,
                observations: e.target.value,
              });
            }}
            name="eventChallenges"
            multiline
            label={t("observations")}
          />
          <Button
            style={{
              width: "408px",
              background: "#45BA92",
              color: "#FFFFFF",
            }}
            disabled={isLoading}
            type="submit"
          >
            {!isLoading ? t("save") : "..."}
          </Button>
        </InputDiv>
      </Box>
    </form>
  );
}
