import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { ContentDiv, RowDiv, StyledP, TitleDiv } from "../styles";
import { FaPlus } from "react-icons/fa";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { TableDiv } from "./style";
import { t } from "i18next";

export function Permissions() {

  return (
    <>
      <ContentDiv style={{width: '90%', margin:'auto'}}>
      <FormGroup>
          <InputLabel>Projetos</InputLabel>
          <FormControlLabel
            control={
              <Switch
                checked={true}
                onChange={() => {}}
              />
            }
            label="Todos"
          />
        </FormGroup>
        <br />
        <FormGroup>
        <InputLabel>Visão clientes</InputLabel>

          <FormControlLabel
            control={
              <Switch
                checked={true}
                onChange={() => {}}
              />
            }
            label="Todos"
          />
        </FormGroup>
      </ContentDiv>
    </>
  );
}
