export type PrecongressFieldType = {
  _id?: string;
  presence: string
  congressLogistics: string
  sponsorshipDivergence: string
  mandatoryData: string
  memberInfo: string
  alimentation: string
  mandatoryDataDocs: string[] | []
  presenceObs: string;
  congressLogisticsObs: string;
  sponsorshipDivergenceObs: string;
  mandatoryDataObs: string;
  memberInfoObs: string;
  memberInstitution: string,
  alimentationObs: string;
  rsvpSystem: boolean;
  rsvpSystemObs: string;
};




export enum MandatoryDataDocTypesEnum {
  // STANDARD_DATA = 'Dados Padrão',
  FULL_NAME_AS_PASSPORT = 'Nome completo conforme passaporte',
  PASSPORT_NUMBER = 'Número de passaporte',
  PASSPORT_EXPIRY_DATE = 'Validade do passaporte',
  VISA_EXPIRY_DATE = 'Validade do Visto (caso aplique)',
  INTERNATIONAL_VACCINATION_CERTIFICATE = 'Certificado internacional de vacinação (caso aplique)',
  BADGE_NAME = 'Nome no crachá',
  BIRTH_DATE = 'Data de nascimento',
  CPF = 'CPF',
  IDENTITY_CARD_AND_ISSUER = 'RG e órgão emissor',
  CELL_PHONE = 'Telefone celular',
  LANDLINE_PHONE = 'Telefone fixo',
  PROFESSIONAL_ID_AND_STATE = 'CRM ou CRF ou COREN e estado',
  SPECIALTY = 'Especialidade',
  WORKPLACE = 'Instituição na qual trabalha',
  FULL_ADDRESS = 'Endereço completo',
}